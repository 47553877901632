import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import {connect} from 'react-redux'

import guideImg from '../../assets/images/fastcreatguide-icon.svg'
import { dispatchCurrentProject, getCurrentProject} from '../../redux/01_project.redux'

@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.projects
        }
    },
    {dispatchCurrentProject, getCurrentProject}
)
class ProjectGuide extends Component {
    componentDidMount(){
        this.props.dispatchCurrentProject({})
    }

    createProject = ()=>{
        this.props.history.push('/orgs-management/add')
    }

    render(){
        return (
                <div className='arrowlayout-content'>
                    <img src={guideImg} alt='guide' className='guide-image'/>
                    <div className='guide-text'>暂无项目信息，我们将为您提供一个“快速新建项目”的向导</div>
                    <div className='start-btn' onClick={this.createProject}>立即开始</div>
                </div>
        )
    }

}

export default ProjectGuide