
import axios from '../axios.config'
import {HIDE_LOADING, SHOW_LOADING, hideLoading, showLoading, getOrganizationsByIDs} from './00_common.action'
const CONTENTS_IDS_IN_CURRENT_PLAN ='CONTENTS_IDS_IN_CURRENT_PLAN'
const CONTENTS_CANDIDATES ='CONTENTS_CANDIDATES'
const PLAN_DATA = 'PLAN_DATA';
//reducer
const initState = {
    plans : [],
    total:0,
    start:0,
    contentsIdsInCurrentPlan:[],
    contentsCandidates:[]
}


export function plans(state = initState, action) { 
    switch(action.type){
        case PLAN_DATA:
            return { ...state, 
                plans: action.payload.data,
                total: action.payload.total,
                start: action.payload.start,
             }
        case CONTENTS_IDS_IN_CURRENT_PLAN:
            return{
                contentsIdsInCurrentPlan: action.payload
            }
        case CONTENTS_CANDIDATES:
            return{
                contentsCandidates: action.payload
            }
        case SHOW_LOADING:
             return {
                 ...state,
                 loading: true,
             }
        case HIDE_LOADING:
             return {
                 ...state,
                 loading: false
             }
        default:
            return state
    }
}

//action creator
export function getPlansData(params){
    // dispatch 用来通知数据修改
    return dispatch => {
        dispatch(showLoading())
        axios.get('/maintenance/plans',{params})
            .then(res=>{
                let organizationIds = res.data.map(ele=>ele.organizationId);
                Promise.all([getOrganizationsByIDs(organizationIds)]).then(res2=>{
                    let _data = res.data.map(ele=>{
                        return {
                            ...ele,
                            organization: res2[0].data.find(origanization=>origanization.id === ele.organizationId)
                        }
                    })
                    let payload = {
                        ...res,
                        data: generateFolderInfo(_data)
                    }
                    dispatch(plansData(payload))

                })               
            }).finally(()=>dispatch(hideLoading()))
    }
}

//获取某个计划下的维保内容， 并与当前巡查点下的维保内容做交集和并集
//最终得到当前巡查点下的维保内容，以及其是否已经加入到维保计划
export function getMaintenanceItemsInPlan(inspectionPointId, planId, type){
    return dispatch => {
    //1. 获取当前计划contractId,
    //2. 根据contractId获取当前合同所在的所有计划
    //3. 获取当前巡查点下的当前类型的所有维保内容
        axios.get(`/maintenance/plans/${planId}`).then(res=>{
             // 获取当前计划的contractId
             const {contractId} = res;
             const allPlanParams = {
                 contractId,
                 type,
                 size: 2000,
             }
            dispatch(contentIdsInPlan(res.contents.map(ele=>ele.id)));  // 当前计划下的contentsIds
            Promise.all([axios.get(`/maintenance/plans`, {params: allPlanParams}),
                    axios.get('/maintenance/items',{
                        params:{
                            type,
                            inspectionPointId,
                            size: 2000
                        }})])
            .then(res=>{
                
                let contentsCandidates=generatePlanContentCandidate(res[0].data, planId, res[1].data);
                dispatch(contentsCandidatesData(contentsCandidates));
            })
         })
    }
}

// 点击全选后更新check信息
export function onClickSelectAll(maintenanceItems, itemIndex){
    maintenanceItems = maintenanceItems.map((ele,index)=>{
        // 判断是全选还是全不选
        let checkAll = index === itemIndex? !ele.checkAll : ele.checkAll;  
        let contents =  ele.contents.map(content=>{
            if(index === itemIndex){
               content ={
                    ...content,
                    checked: checkAll
                }
            }
            return content
        });
        
        return{
            ...ele,
            contents,
            checkAll,
            checked: contents.filter(ele=>ele.checked).length
        }
    });
    return dispatch=>{
        dispatch(contentsCandidatesData(maintenanceItems));
    }
}

// 选择维保内容后更新check信息
export function checkMaintenanceContent(items, itemId, contentId){
    return dispatch=>{
        let newItems = items.map(ele=>{
            if(ele.name === itemId){    // 将预定义项目添加到巡查点后id改变，但是content的itemId为改变，所以要通过name来筛选
                let contents = ele.contents.map(content=>{
                    if(content.id === contentId){
                        content = {
                            ...content,
                            checked: !content.checked
                        }
                    }
                    return content
                })
                ele = {
                    ...ele,
                    contents
                }
            }

            // 需重新判断checkAll的状态
            ele={
                ...ele,
                checkAll: ele.contents.filter(content=>content.checked).length === ele.contents.length,
                checked: ele.contents.filter(content=>content.checked).length
            }

            return ele;
        })   

        dispatch(contentsCandidatesData(newItems))
    }
}

// 删除计划
export function deletePlan(id){
    return axios.delete(`/maintenance/plans/${id}`)
}


export function plansData(data) {
    return {type: PLAN_DATA, payload: data}
}

export function contentIdsInPlan(data){
    return {type: CONTENTS_IDS_IN_CURRENT_PLAN, payload: data}
}

export function contentsCandidatesData(data){
    return {type: CONTENTS_CANDIDATES, payload: data}
}

export function switchFolder(items, id) {
    return dispatch => {


        let newItems = items.map(ele => {
            if (ele.id === id) {
                ele = {
                    ...ele,
                    foldered: !ele.foldered
                }
            }

            return ele;
        })
        dispatch(plansData({data: newItems}))
    }
}

/** 
 * 非redux管理的function
*/

export  function getCurrentPlan(id){
    return axios.get(`/maintenance/plans/${id}`)
}


export function updatePlanRequest(data,edit=false, id){
    if(edit){
        return axios.put(`/maintenance/plans/${id}`,data)
    }else {
        return axios.post('/maintenance/plans',data)
    }
}

function generateFolderInfo(data){
    return data.map(ele=>({
        ...ele,
        foldered:true
    }))
}


function generatePlanContentCandidate(plansInContract,planId, itemsInInspetionPoint){
    // 属于其他计划的contents
    let otherContents=[];
    plansInContract.filter(ele=>Number(ele.id) !== Number(planId)).forEach(ele=>otherContents = otherContents.concat(ele.contents));
    const contentInPlan = plansInContract.find(ele=>Number(ele.id) === Number(planId)).contents;
    let contentsIds = contentInPlan.map(ele=>ele.id);
    let otherContentsIds = otherContents.map(ele=>ele.id);
    return itemsInInspetionPoint.map(ele=>{
        let newContents = ele.contents.map(content=>({
            ...content,
            checked: contentsIds.indexOf(content.id) > -1,
            disableChecked: otherContentsIds.indexOf(content.id) > -1
        }))
        
        // 判断维保项目的选中状态
        // 选中状态
        return {
            ...ele,
            contents: newContents,
            checkAll: newContents.filter(ele=>ele.checked).length === ele.contents.length,
            disableCheckAll: newContents.filter(ele=>ele.disableChecked).length === ele.contents.length,
            checked: contentsIds.filter(id=>newContents.map(ele=>ele.id).indexOf(id) > -1).length,
            disableChecked: otherContentsIds.filter(id=>newContents.map(ele=>ele.id).indexOf(id) > -1).length,
        }
    })
}
