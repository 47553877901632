import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import zhCN from 'antd/es/locale-provider/zh_CN';
import { LocaleProvider } from 'antd';

import Auth from './pages/auth/auth'
import PasswordReset from './pages/auth/password_reset'
import ProjectRouter from './pages/01_project/project.router'
import BuildingsRouter from './pages/02_building/buildings.router'
import RegionRouter from './pages/07_region/region.router'
import UserRouter from './pages/06_user/user.router'
import MaintainerRouter from './pages/08_maintainer/maintainers.router'
import PlanRouter from './pages/05_plan/plan.router'
import InspectionRouter from './pages/03_inspection/inspection_point.router'
import ContractRouter from './pages/04_contract/contract.router'
import RegistryRouter from './pages/10_registry/registry.router'
import LicenseRouter from './pages/11_license/license.router'

import DrawingReviewRouter from './pages/12_drawing_review/drawing_review.router'
import NewProjectRouter from './pages/13_new_project/new_project.router'
import AcceptanceRouter from './pages/14_acceptance/acceptance.router'
import DrawingReviewPRouter from './pages/15_drawing_review_p/drawing_review_p.router'
import AcceptancePRouter from './pages/16_acceptance_p/acceptance_p.router'
import ProjectMapRouter from './pages/17_project_map/project_map.router'
import CollectionRouter from './pages/18_collection/collection.router'
import MessageRouter from './pages/19_message/message.router'

import ErrorPage from './pages/error-page/404'
import './index.css';

import { PersistGate } from 'redux-persist/lib/integration/react';
import configStore from './store';
import { persistor } from './store';
import{ getStorage } from './utils/utils'

import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

require('promise.prototype.finally').shim();
export const store = configStore;
// login
// 没有登录信息，统一跳转login

// 普通页面  导航、显示、注销
//router+redux

ReactDOM.render(
    <LocaleProvider locale={zhCN}>
        <Provider store={configStore}>
            <PersistGate loading={null} persistor={persistor}>
                <HashRouter>
                    <Switch>
                        <Redirect exact from='/' to='/orgs-management' />
                        <Route path='/login' component={Auth} />
                        <Route path='/password/reset' component={PasswordReset} />
                        <Route path='/orgs-management' component={ProjectRouter} />

                        <Route path='/users' component={UserRouter} />
                        
                        <Route path='/new_project' component={NewProjectRouter} />
                        <Route path='/drawing_review' component={DrawingReviewRouter} />
                        <Route path='/acceptance' component={AcceptanceRouter} />
                        <Route path='/drawing_review_p' component={DrawingReviewPRouter} />
                        <Route path='/acceptance_p' component={AcceptancePRouter} />
                        <Route path='/project_map' component={ProjectMapRouter} />
                        <Route path='/collection' component={CollectionRouter} />
                        <Route path='/message' component={MessageRouter} />


                        <Route path='/regions' component={RegionRouter} />
                        <Route path='/maintainers' component={MaintainerRouter} />
                        <Route path='/buildings' component={BuildingsRouter} />
                        <Route path='/plans' component={PlanRouter} />
                        <Route path='/inspections' component={InspectionRouter} />
                        <Route path='/contracts' component={ContractRouter} />
                        <Route path='/registries' component={RegistryRouter} />
                        <Route path='/licenses' component={LicenseRouter} />
                        <Route component={ErrorPage} />
                    </Switch>
                </HashRouter>
            </PersistGate>
        </Provider>
    </LocaleProvider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
