import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {Form,Input,Select, message,DatePicker,Icon,Button, Modal} from 'antd';
import moment from 'moment';
import back2 from '../../assets/images/back2.png'
import { getCurrentDrawingReviewP,dispatchCurrentDrawingReviewP, dispatchWork } from '../../redux/15_drawing_review_p.redux'
import { getUsersData} from '../../redux/06_users.redux'
import {formItemLayout} from '../../components/layout/formlayout'
import{ getHeaders, _baseUrl} from '../../axios.config'
import{  timeFormatSeconds, fmtTimes} from '../../utils/utils'
import {  addMarker} from '../../redux/00_common.action'
import Enclosure  from '../../components/enclosure/index'
import TimeAxis from "../../components/timeAxis";

import mapJson from '../../assets/json/custom_map_config.json'

const BMap = window.BMap;
const Search = Input.Search;
let map;

const Option = Select.Option;
@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.maintainers,
            ...state.users
        }
    },
    {getCurrentDrawingReviewP,dispatchCurrentDrawingReviewP, getUsersData}
)
class DrawingReviewDetailP extends Component{
    constructor(props){
        super(props);
        this.state = {
            tab : 0,
            edit: true,
            roleId: 0,
            avatar: null,
            loading: false,
            headers:{},
            iconLoading: false,
            paynum: 0,
            key: Date.now(),
            payNode: [], //收款节点

            startDef: '',
            endDef: '',
            receiver: []
        };
    }
    componentWillMount(){
        this.updateHeaders();
    }

    updateHeaders = async()=>{
        let headers = await getHeaders(true);
        this.setState({
            headers
        })
    }

    componentDidMount(){
        let {id} = this.props.match.params;
        getCurrentDrawingReviewP(id).then(res=>{
            //this.setState(res);
            this.setState({
                ...res,
                key: Date.now()
            })
            this.props.dispatchCurrentDrawingReviewP(res);
            this.addMap(res.startLongitude,res.startLatitude)
        });
        let params = {
            size: 99,
            rolesIds: 9 //这里需要将当前所有审图员读取出来，取值为9，当前测试取值为2
        }
        this.props.getUsersData(params);
    }

    addMap = (lng,lat,type)=>{
        map = new BMap.Map("l-map");  
        map.centerAndZoom(new BMap.Point(lng,lat), this.state.edit ? 13 : 13);
        map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
        map.addControl(new BMap.NavigationControl());    
        map.addControl(new BMap.ScaleControl());    
        map.addControl(new BMap.OverviewMapControl());   
        //map.setMapStyle({styleJson:mapJson});
        addMarker(map,lng,lat,this.state.name)
    }

    onChangeDate = (date, dateString) => {
        console.log(date, dateString);
    }

    handleSubmit = e => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let paynum = this.state.paynum,
                    paymentReturns = [];
                for(let i = 0;i<=paynum;i++){
                    let item = new Object();
                        item = {
                            dateTime: values[`dateTime${i}`] ? moment(values[`dateTime${i}`]).format('YYYY-MM-DD') : '',
                            price: values[`price${i}`] ? parseFloat(values[`price${i}`]) : '',
                            contacts: values[`contacts${i}`] ? values[`contacts${i}`] : '',
                            contactPhone: values[`contactPhone${i}`] ? values[`contactPhone${i}`] : ''

                            // dateTime: moment(values[`dateTime${i}`]).format('YYYY-MM-DD'),
                            // price: parseFloat(values[`price${i}`]),
                            // contacts: values[`contacts${i}`],
                            // contactPhone: values[`contactPhone${i}`]
                        };
                    paymentReturns.push(item);
                }

                if(this.state.receiver.length==0){
                    message.warn("请选择派单人员");return;
                }

                let paymentReturns2 = Array.from(paymentReturns);
                let all_p = 0;
                for(var i = 0;i<paymentReturns2.length;i++){
                    var item = paymentReturns2[i];
                        if(item.dateTime!='' && item.price!='' && item.contacts!='' && item.contactPhone!=''){
                            all_p += 1;
                        }
                }
                if(all_p==0){
                    paymentReturns = "";
                }
                
                let data = {
                    payer: values.payer,
                    payerPhone: values.payerPhone,
                    payCondition: values.payCondition,
                    normalDate: fmtTimes(this.state.startDef),
                    warningDate: fmtTimes(this.state.endDef),
                    receiver: this.state.receiver,
                    payDate: values.payDate ? moment(values.payDate).format('YYYY-MM-DD') : ''
                }
                
                //console.log(123,data);return;
                if(paymentReturns){
                    data.paymentReturns = paymentReturns;
                }
              
                let _this = this;
                Modal.confirm({
                    title: '提示',
                    content: '确定派单？',
                    okText: '确认',
                    cancelText: '取消',
                    onOk(){
                        _this.setState({iconLoading: true});
                        dispatchWork(data, _this.state.id).then(res=>{
                            message.success("派单成功");
                            setTimeout(function(){
                                _this.props.history.push(`/drawing_review_p`);
                            },1800)
                        }).finally(()=>_this.setState({iconLoading: false}));
                    }
                });
            }
        });
    };

    
    render(){  
        const { getFieldDecorator } = this.props.form;
        let {name,address, floorNum, height, areaMeasure, constructionCompany, constructionPerson, constructionPhone, designCompany, designPerson, designPhone, supervisorCompany, supervisorPerson, supervisorPhone, testingCompany, testingPerson, testingPhone, drawingReviewCompany, drawingReviewPerson, drawingReviewPhone, useNature, projectDrawings, drawingReviewComments, selfCheckReport, builtDrawings, fireDetectionRepor, startLongitude, startLatitude, startDate, endDate,normalDate,warningDate, status, updateRemark} = this.state;

        const config = {
            rules: [{ type: 'object', required: false, message: '请选择日期' }],
        };

        const passwordValidator = (rule, value, callback) => { 
            var regExp = new RegExp("^1[3456789]\\d{9}$");
            if (!regExp.test(value)) {
                callback('手机号格式错误')
            }
        
            // 必须总是返回一个 callback，否则 validateFields 无法响应
            callback();
        }
       
        return (
            <div className = 'content content_abs'>
                {/* <div className='itabs itabs2'>
                    <span className={["tab_span ",this.state.tab==0 ? 'on' :'' ].join('')} onClick={e => this.setTabs(0)}>全部</span>
                    <span className={["tab_span ",this.state.tab==1 ? 'on' :'' ].join('')} onClick={e => this.setTabs(1)}>初审</span>
                    <span className={["tab_span ",this.state.tab==2 ? 'on' :'' ].join('')} onClick={e => this.setTabs(2)}>复审</span>
                    <span className={["tab_span ",this.state.tab==3 ? 'on' :'' ].join('')} onClick={e => this.setTabs(3)}>已完成</span>
                </div> */}
                <div className="comm_detail">
                    <div className="itops flex-between">
                        <div className="itop_back">
                            返回 <img src={back2} alt="" />
                        </div>
                    </div>
                    <div className="examcons"><br/><br/>
                        <div className="examcon_title">项目详情</div>
                        <div className="examcon flex-between-top">
                            <div className="examcon_left">
                            <div className='oneexam'>
                                    <span className='sp1'>项目名称：</span>
                                    <span className='sp2'>{name}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>项目地址：</span>
                                    <span className='sp2'>{address}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>层数：</span>
                                    <span className='sp2'>{floorNum}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>高度：</span>
                                    <span className='sp2'>{height}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>面积：</span>
                                    <span className='sp2'>{areaMeasure}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>施工单位：</span>
                                    <span className='sp2'>{constructionCompany}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>施工单位联系人：</span>
                                    <span className='sp2'>{constructionPerson}</span>
                                    <span className='sp3'>联系方式：{constructionPhone}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>设计单位：</span>
                                    <span className='sp2'>{designCompany}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>设计单位联系人：</span>
                                    <span className='sp2'>{designPerson}</span>
                                    <span className='sp3'>联系方式：{designPhone}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>监理单位：</span>
                                    <span className='sp2'>{supervisorCompany}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>监理单位联系人：</span>
                                    <span className='sp2'>{supervisorPerson}</span>
                                    <span className='sp3'>联系方式：{supervisorPhone}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>审图单位：</span>
                                    <span className='sp2'>{drawingReviewCompany}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>审图单位联系人：</span>
                                    <span className='sp2'>{drawingReviewPerson}</span>
                                    <span className='sp3'>联系方式：{drawingReviewPhone}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>使用性质：</span>
                                    <span className='sp2'>{useNature}</span>
                                </div>
                                
                            </div>
                            <div className="examcon_right">
                                <div id="l-map" className='l-map'></div>
                                <div className='iwarn'>
                                    <div>项目告警设置：</div>
                                    <TimeAxis key={this.state.key} startDate={timeFormatSeconds(startDate)} endDate={timeFormatSeconds(endDate)} normalDate={timeFormatSeconds(normalDate)} warningDate={timeFormatSeconds(warningDate)} handeChangeSlider={this.changeSlider.bind(this)}></TimeAxis>
                                </div>
                                <div className="tsonedems tsonedems2">
                                    {updateRemark ? (
                                        <div className="tsonedem" style={{"margin-bottom": "30px"}}>
                                            <div className="onedem_left">补充说明：</div>
                                            <div className="onedem_right">{updateRemark}</div>
                                        </div>
                                    ) : ''}
                                    <div className="tsonedem">
                                        <div className="onedem_left">项目图纸：</div>
                                        <div className="onedem_right">
                                            <Enclosure enclosure={projectDrawings}></Enclosure>
                                        </div>
                                    </div>
                                    <div className="tsonedem">
                                        <div className="onedem_left">审图意见：</div>
                                        <div className="onedem_right">
                                            <Enclosure enclosure={drawingReviewComments}></Enclosure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="supps edit-form-container edit-form-container-half">
                            <div className="tsexam">
                                <div className="tsexam_left">补充资料：</div>
                                <div className="tsexam_right">
                                    <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                                        <div className=' flex-between'>
                                            <Form.Item label='　付款方'>
                                                {getFieldDecorator('payer', {
                                                    //initialValue: this.state.payer,
                                                    rules: [
                                                    {
                                                        required: false,
                                                        message: '付款方不能为空',
                                                    },
                                                    ],
                                                })(<Input placeholder=""/>)}
                                            </Form.Item>
                                            <Form.Item label='联系方式'>
                                                {getFieldDecorator('payerPhone', {
                                                    //initialValue: this.state.payerPhone,
                                                    rules: [
                                                    {
                                                        required: false,
                                                        message: '联系方式不能为空',
                                                    }, 
                                                    // {
                                                    //     required: false,
                                                    //     validator: passwordValidator
                                                    // }
                                                    ],
                                                })(<Input placeholder=""/>)}
                                            </Form.Item> 
                                        </div>
                                        <div className=' flex-between'>
                                            <Form.Item label='付款时间'>
                                                {getFieldDecorator('payDate', config)(<DatePicker />)}
                                            </Form.Item>
                                            <Form.Item label='付款条件'>
                                                {getFieldDecorator('payCondition', {
                                                    //initialValue: this.state.payCondition,
                                                    rules: [
                                                    {
                                                        required: false,
                                                        message: '付款条件不能为空',
                                                    },
                                                    ],
                                                })(<Input placeholder=""/>)}
                                            </Form.Item> 
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>

                        <div className="supps edit-form-container edit-form-container-half">
                            <div className="tsexam tsexam2">
                                <div className="tsexam_left">回款节点：</div>
                                <div className="tsexam_right">
                                    <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                                        <div className='flex-between'>
                                            <Form.Item label="">
                                                {getFieldDecorator('dateTime0', config)(<DatePicker />)}
                                            </Form.Item>
                                            <Form.Item label=''>
                                                {getFieldDecorator('price0', {
                                                    rules: [
                                                    {
                                                        required: false,
                                                        message: '金额不能为空',
                                                    },
                                                    ],
                                                })(<Input placeholder="请输入金额"/>)}
                                            </Form.Item> 
                                            <Form.Item label=''>
                                                {getFieldDecorator('contacts0', {
                                                    rules: [
                                                    {
                                                        required: false,
                                                        message: '联系人不能为空',
                                                    },
                                                    ],
                                                })(<Input placeholder="联系人"/>)}
                                            </Form.Item> 
                                            <Form.Item label=''>
                                                {getFieldDecorator('contactPhone0', {
                                                    rules: [
                                                    {
                                                        required: false,
                                                        message: '联系方式不能为空',
                                                    }, 
                                                    // {
                                                    //     required: false,
                                                    //     validator: passwordValidator
                                                    // }
                                                    ],
                                                })(<Input placeholder="联系方式"/>)}
                                            </Form.Item> 
                                        </div>
                                        { this.state.payNode.map((item, i) => {
                                            return <div className='add_form_item flex-between' key={i}>
                                                        <Form.Item label="">
                                                            {getFieldDecorator(`dateTime${item}`, config)(<DatePicker />)}
                                                        </Form.Item>
                                                        <Form.Item label=''>
                                                            {getFieldDecorator(`price${item}`, {
                                                                rules: [
                                                                {
                                                                    required: false,
                                                                    message: '金额不能为空',
                                                                },
                                                                ],
                                                            })(<Input placeholder="请输入金额"/>)}
                                                        </Form.Item> 
                                                        <Form.Item label=''>
                                                            {getFieldDecorator(`contacts${item}`, {
                                                                rules: [
                                                                {
                                                                    required: false,
                                                                    message: '联系人不能为空',
                                                                },
                                                                ],
                                                            })(<Input placeholder="联系人"/>)}
                                                        </Form.Item> 
                                                        <Form.Item label=''>
                                                            {getFieldDecorator(`contactPhone${item}`, {
                                                                rules: [
                                                                {
                                                                    required: false,
                                                                    message: '联系方式不能为空',
                                                                }, 
                                                                // {
                                                                //     required: false,
                                                                //     validator: passwordValidator
                                                                // }
                                                                ],
                                                            })(<Input placeholder="联系方式"/>)}
                                                        </Form.Item> 
                                                        <div className="form_close"  onClick={e => this.removePayNode(i)}>
                                                            <Icon type='close' style={{'fontSize': '20px', "color": '#65C0F4'}} />
                                                        </div>
                                                    </div>
                                        }) }

                                        
                                    </Form>
                                </div>
                                <div className="tsexam_add" onClick={e => this.addPayNode()}>
                                    <Icon type='plus-circle' theme="filled" style={{'fontSize': '30px', "color": '#65C0F4'}} />
                                </div>
                            </div>
                        </div>

                        <div className="supps edit-form-container edit-form-container-half">
                            <div className="tsexam tsexam_pd">
                                <div className="tsexam_left">派单：</div>
                                <div className="tsexam_right">
                                    <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                                        <Form.Item label=''>
                                            <Select
                                                mode="multiple"
                                                style={{ width: '100%' }}
                                                placeholder="请选择派单"
                                                onChange={this.handleChangeDist}
                                            >
                                                {
                                                    this.props.users.map(ele=> <Option key={ele.id} value={ele.id}>{ele.userOrgMap?
                                                        (ele.userOrgMap.length&&ele.userOrgMap[0].name?ele.userOrgMap[0].name:(ele.name||'未知'))
                                                        :'未知'}</Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>
                        </div>

                        <Button className="done_btn" loading={this.state.iconLoading} type="primary" size='large' onClick={this.handleSubmit}>派单</Button>

                    </div>
                </div>
            </div>
        )

    }

    setTabs(i){
        this.setState({
            tab : i
        })
    }

    //新增节点
    addPayNode(){
        let paynum = this.state.paynum;
        let payNode = this.state.payNode;
        let newpaynum = paynum + 1;
        let end = payNode.length>0 ? payNode[payNode.length-1] : 0; 
    	payNode.push(end+1);
        this.setState({
            paynum: newpaynum,
            payNode: payNode
        })
        
    }

    //删除节点
    removePayNode(i){ 
        let paynum = this.state.paynum;
        let payNode = this.state.payNode;
        let newpaynum = paynum - 1;
        payNode.splice(i, 1);
        console.log(newpaynum,payNode);
        this.setState({
            paynum: newpaynum,
            payNode: payNode
        })
    }

    changeSlider(e){
        //console.log("子组件传值：",e);
        this.setState({
            startDef: e[0],
            endDef: e[1]
        })
    }

    //派单人员选择
    handleChangeDist = (val) => {
        //console.log(`selected ${val}`);
        this.setState({
            receiver: val
        })
    }

}

export default  Form.create()(DrawingReviewDetailP)


  