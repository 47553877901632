import React, {Component}  from 'react'
import { withRouter} from 'react-router-dom';

import { Row, Col,  Button, message,Form,Input } from 'antd';
import{ isPhoneAvailable} from '../../utils/utils'
import {sendValidateCode, resetPassword} from '../../redux/auth.redux'

import './styles.css'
import logo from '../../assets/images/logo.jpg'
import loginWeb from '../../assets/images/login-web.png'


function inject_unount (target){
    // 改装componentWillUnmount，销毁的时候记录一下
    let next = target.prototype.componentWillUnmount
    target.prototype.componentWillUnmount = function () {
        if (next) next.call(this, ...arguments);
        this.unmount = true
     }
     // 对setState的改装，setState查看目前是否已经销毁
    let setState = target.prototype.setState
    target.prototype.setState = function () {
        if ( this.unmount ) return ;
        setState.call(this, ...arguments)
    }
}
@inject_unount
class BaseComponent extends Component {

}


@withRouter
class PasswordReset extends BaseComponent {
    state = {
        confirmDirty: false,
        autoCompleteResult: [],
        time: "发送验证码",
        currentTime: 61,
        sendDisabled: false,
        suffix: '',
      };

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
          if (!err) {
            const { phone, password, validateCode} = values;
            let data ={
                password,
                validateCode,
                username: phone,
                classType: 0 
            }
            resetPassword(data).then(()=>{
                 message.success('重置密码成功，请重新登录');
                 this.props.history.push('/login');
            })
          }
        });
      };

    handleConfirmBlur = e => {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };
    // checkPhoneInput= (rule, value, callback) => {
    //     const form = this.props.form;
    //     if (isPhoneAvailable(form.getFieldValue('phone'))){
    //         callback();
    //     } else {
    //         callback('手机号码格式错误!');
    //     }
    // };

    onPhoneChange=(e)=>{
        this.setState({
            phone: e.target.value
        })
    }
    
     // 获取验证码
    getVerificationCode=()=>{
        if (!this.state.sendDisabled){
            this.getCode();
        }
    }

    getCode=()=> {
        let _this = this;
        let phone = this.state.phone;
    // 设置发送验证码按钮样式
    let interval = null;
    let currentTime = this.state.currentTime;
        if (isPhoneAvailable(phone)) {
            sendValidateCode(phone).then(res=>{
                message.success('已发送')
                this.setState({
                    sendDisabled: true
                });
                interval = setInterval(function() {
                    currentTime--;
                    _this.setState({
                        time: currentTime,
                        suffix: '秒后可重新获取',
                        sendDisabled: true
                    })
                    if (currentTime <= 0) {
                        clearInterval(interval)
                        _this.setState({
                                time: '重新发送',
                                suffix: '',
                                currentTime: 61,
                                sendDisabled: false
                            })
                        }
                }, 1000)
            },()=>{
                this.setState({
                    sendDisabled: false,
                    time: '重新发送',
                    suffix: '',
                    currentTime: 61,
                })
            });
        }else{
            message.warn('请输入正确的手机号码')
        }
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        return (
            <div className='body'>
                <Form onSubmit={this.handleSubmit} className="login-form reset-form">
                    <div className='logo-container'>
                        <img src={logo} alt='logo' className='logo-login'></img>
                        <div>智慧维保管理系统</div>
                    </div>
                    {/* <Form.Item>
                    {getFieldDecorator('username', {
                        rules: [{ required: true, message: '请输入用户名!' }],
                    })(
                        <Input placeholder="用户名" />,
                    )}
                    </Form.Item> */}
                    <Form.Item >
                    {getFieldDecorator('phone', {
                        rules: [{ required: true, message: '请输入你的手机号码!' }],
                    })(<Input placeholder="手机号码" onChange={this.onPhoneChange}/>)}
                    </Form.Item>
                    <Form.Item>
                    <Row gutter={8}>
                        <Col span={12}>
                        {getFieldDecorator('validateCode', {
                            rules: [{ required: true, message: '请输入验证码!' }],
                        })(<Input placeholder="验证码"/>)}
                        </Col>
                        <Col span={12}>
                            <Button className='send-validateCode' onClick={this.getVerificationCode}>{`${this.state.time} ${this.state.suffix}`}</Button>
                        </Col>
                    </Row>
                    </Form.Item>
                    <Form.Item  hasFeedback>
                    {getFieldDecorator('password', {
                        rules: [
                        {
                            required: true,
                            message: '请输入新密码!',
                        },
                        {
                            validator: this.validateToNextPassword,
                        },
                        ],
                    })(<Input.Password placeholder="新密码"/>)}
                    </Form.Item>
                    <Form.Item  hasFeedback>
                        {getFieldDecorator('confirm', {
                            rules: [
                            {
                                required: true,
                                message: '请再次输入新密码!',
                            },
                            {
                                validator: this.compareToFirstPassword,
                            },
                            ],
                        })(<Input.Password onBlur={this.handleConfirmBlur} placeholder="确认新密码"/>)}
                    </Form.Item>

                    <div className='btns-flex'>
                        <Button  ghost className="login-form-button two-btn" onClick={this.props.history.goBack}>
                            返回
                        </Button>
                        <Button type="primary" htmlType="submit" className="login-form-button two-btn">
                            确认重置
                        </Button>
                    </div>
                </Form>
                <img className='footer' alt='web' src={loginWeb}></img>
            </div>
        );
    }
}

export default Form.create({ name: 'normal_reset' })(PasswordReset)