
import axios from '../axios.config'
import {HIDE_LOADING, SHOW_LOADING, hideLoading, showLoading} from './00_common.action'

const LICENSES_DATA = 'LICENSES_DATA';
//reducer
const initState = {
    licenses : [],
    total:0,
    start:0,
    loading: true
}


export function licenses(state = initState, action) { 
    switch(action.type){
        case LICENSES_DATA:
            return { ...state, 
                licenses: action.payload.data,
                total: action.payload.total,
                start: action.payload.start
             }
        case SHOW_LOADING:
            return {
                ...state,
                loading: true,
            }
        case HIDE_LOADING:
             return {
                 ...state,
                 loading: false
             }
        default:
            return state
    }
}

//action creator
export function getLicenseData(params){
    // dispatch 用来通知数据修改
    return dispatch => {
        dispatch(showLoading())
        axios.get('/account/licenses',{params})
            .then(res=>{
                dispatch(LicenseData(res));
            })
            .finally(()=>{
                dispatch(hideLoading());
            })
    }
}
export function LicenseData(data) {
    return {type: LICENSES_DATA, payload: data}
}

/** 
 * 非redux管理的function
*/

export function getCurrentLicense(id){
    return axios.get(`/account/licenses/${id}`)
}


export function updateLicenseRequest(data,edit=false, id){
    if(edit){
        return axios.put(`/account/licenses/${id}`,data)
    }else {
        return axios.post('/account/licenses',data)
    }
}

