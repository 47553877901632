import React, {Component}  from 'react';
import { Icon , Modal } from 'antd';
import './styles.css';

class Enclosure extends Component{
    constructor(props){
        super(props)

        this.state = {
            previewVisible: false,
            previewImage: ''
        }
    }

    //获取文件后缀
    getSuffix(filename){
        var spl = filename.split(".");
        return spl[spl.length-1];
    }

    //查看图片
    prevImage = (img) => { 
        this.setState({
            previewImage: img,
            previewVisible: true
        })
    }

    //关闭预览
    handleCancelImage = () => { 
        this.setState({
            previewVisible: false
        })
    }

    //下载文件
    downLoad = (file) => {
        const URL = 'download url'
        // 创建a标签
        let a= document.createElement("a");
        // 设置下载文件的文件名
        a.download = file.name;
        a.target = '_blank'
        // 设置下载文件url
        a.href = file.url;
        // 设置点击事件
        a.click();
    }

    render(){
        let { enclosure } = this.props;
        const { previewVisible, previewImage } = this.state;

        return (
            <div className='sufxs'>
                {enclosure ? enclosure.map((item, index) => {
                    let fx = this.getSuffix(item.url);
                    if(fx=='jpg' || fx=='jpeg' || fx=='png'){
                        return  <div className="sufx" key={index}>
                                    <img className='sufx_img' src={item.url} alt="" />
                                    <div className="shadefx flex-around">
                                        <div className="shadefxm" onClick={e => this.prevImage(item.url)}><Icon style={{fontSize: '25px',color:'#f5f5f5'}} type="eye" /></div>
                                        <div className="shadefxm" onClick={e => this.downLoad(item)}><Icon style={{fontSize: '25px',color:'#f5f5f5'}} type="download" /></div>
                                    </div>
                                </div>
                    }else if(fx=='pdf'){
                        return  <div className="sufx" key={index}>
                                    <Icon style={{fontSize: '50px',margin: '30px auto 15px'}} type="file-pdf" theme="twoTone" twoToneColor="#DA2729" />
                                    <span className='sutxt'>{item.name}</span>
                                    <div className="shadefx flex-around">
                                        <div className="shadefxm" onClick={e => this.downLoad(item)}><Icon style={{fontSize: '25px',color:'#f5f5f5'}} type="download" /></div>
                                    </div>
                                    {/* <a href={item.url} download={item.name}>11111111</a> */}
                                </div>
                    }else if(fx=='doc' || fx=='docx'){
                        return  <div className="sufx" key={index}>
                                    <Icon style={{fontSize: '50px',margin: '30px auto 15px'}} type="file-word" theme="twoTone" />
                                    <span className='sutxt'>{item.name}</span>
                                    <div className="shadefx flex-around">
                                        <div className="shadefxm" onClick={e => this.downLoad(item)}><Icon style={{fontSize: '25px',color:'#f5f5f5'}} type="download" /></div>
                                    </div>
                                </div>
                    }else if(fx=='mp4'){

                    }else if(fx=='txt'){
                        return  <div className="sufx" key={index}>
                                    <Icon style={{fontSize: '50px',margin: '30px auto 15px'}} type="file-text" theme="twoTone" />
                                    <span className='sutxt'>{item.name}</span>
                                    <div className="shadefx flex-around">
                                        <div className="shadefxm" onClick={e => this.downLoad(item)}><Icon style={{fontSize: '25px',color:'#f5f5f5'}} type="download" /></div>
                                    </div>
                                </div>
                    }
                }) : ''}

                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancelImage}>
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </div>

        )
    }
}

export default Enclosure