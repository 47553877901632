/**
 * td 溢出隐藏 组件
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import './index.css';
 
export default class LineWrap extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    lineClampNum: PropTypes.number,
  };
 
  render() {
    const { title, lineClampNum } = this.props;
    return (
      <Tooltip placement="bottomRight" title={title} >
        <span className='lineEllipsis' style={{WebkitLineClamp:lineClampNum}}>{title}</span>
      </Tooltip>
    );
  }
}