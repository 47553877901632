import React, {Component}  from 'react';
import { connect} from 'react-redux'
import { withRouter,Link } from 'react-router-dom'
import moment from 'moment'

import Header from '../../components/header';
import Table from '../../components/table'
import add from '../../assets/images/add.svg';
import editImg  from '../../assets/images/edit_white.svg'
import defaultAvatar from '../../assets/images/logo.jpg'
import { getMaintainerData} from '../../redux/08_maintainer.redux'
import CustomPagination from '../../components/pagination/pagination'
import './styles.css';
@withRouter
@connect(
    state=>{
        return {...state.auth,...state.maintainers}
    },
    {getMaintainerData}
)
class Maintainer extends Component {

    componentDidMount(){
        this.props.getMaintainerData()
    }

    pageChangeHandler(page) {
        this.props.getMaintainerData({start: page-1})
    }

    onSearch = (params)=>{
        this.props.getMaintainerData(params);
    }

    addMaintainer = ()=>{
        this.props.history.push('/maintainers/add')
    }


    render(){
        // console.log(this.props)
        const filters = [{
            key:'nameLike',
            placeholder:'单位名称'
        },{
            key:'detailAddrLike',
            placeholder:'地址'
        }];

        const columns = [
            {
              title: '单位图片',
              key: 'picture',
              width: 180,
              render: (text, record) => (
                <img className='picture_maintainerlist' src={record.picture || defaultAvatar} alt='单位图片'></img>
              ),
            },
            {
              title: '单位名称',
              dataIndex: 'name',
              key: 'name',
              width: "10%",
            },
            {
              title: '详细地址',
              dataIndex: 'detailAddr',
              key: 'detailAddr',
            },
            {
                title: '联系方式',
                dataIndex: 'phone',
                key: 'phone',
                width: "10%",
            },
            {
                title: '所属区域',
                dataIndex: 'region.fullName',
                key: 'region.fullName',
                width: "15%",
            
            },
            {
                title: '最大用户数',
                dataIndex: 'maxUserCount',
                key: 'maxUserCount',
                width: "10%",
            
            },
            {
                title: '有效期',
                render: (text, record)=>(
                    <span>{record.endTime ? moment(record.endTime).format('YYYY-MM-DD HH:mm') : ''}</span>
                ),
                width: 200,
            },
            {
              title: '',
              key: 'action',
              width: 180,
              render: (text, record) => (
                <Link className='edit-action' to={`/maintainers/edit/${record.id}`}>
                  <img src={editImg} alt='edit'></img>
                  查看修改
                </Link>
              ),
            },
          ];
          

        const app = (
            <div className = 'content'>
                <Header title = '维保单位列表' filters ={filters} onSearch={this.onSearch}>
                    <div className='new_org new' onClick={this.addMaintainer} >
                        <img src={add} alt='add' className='add_img  new'></img>新建维保单位
                    </div>
                </Header>
                <div className='main-table-container'>
                    <Table  scroll={{ y: 640 }}
                            columns={columns} 
                            dataSource={this.props.maintainers.map(ele=>{return {...ele, key:ele.id}})} 
                            loading={this.props.loading}/>
                    <CustomPagination
                        total={this.props.total}
                        onChange={(e)=>{this.pageChangeHandler(e)}}/>
                </div>
            </div>
        );
        return app
    }
}

export default Maintainer