import React, {Component}  from 'react';
import { Icon , Modal, Slider } from 'antd';
import{ timeFormatSeconds} from '../../utils/utils'
import './styles.css';

class Progressc extends Component{
    constructor(props){
        super(props)

        this.state = {
            min: '',
            max: '',
            day: 0,
            reclist: []
        }
    }

    componentWillMount(){
        let { startDate, endDate, recordList } = this.props, 
            min = this.timeFmt(startDate),
            max = this.timeFmt(endDate),
            nowDef = Date.parse(new Date());
        if(nowDef>max){
            max = nowDef;
        }
        //模拟一个recordList
        // recordList = [{
        //     title: "到达现场",
        //     date: 1653790897000
        // },{
        //     title: "到达现场",
        //     date: 1655000497000
        // }];

        this.setState({
            min: min,
            max: max
        })
        this.changeSlider(recordList);
    }


    render(){
        let { startDate, endDate } = this.props; 
        let step = 86400,
            min = this.state.min,
            max = this.state.max, 
            nowDef = Date.parse(new Date());
        
        return (
            <div className='timeaxis timeaxise'>
                <div className="timeaxis_re">
                    <Slider 
                        range
                        min={min}
                        max={max}
                        step={step}
                        disabled={true}
                        tipFormatter={this.doTipFormatter}
                        defaultValue={this.state.reclist} 
                        onChange={this.changeSlider} 
                        tooltipVisible={true} 
                        getTooltipPopupContainer={()=>document.querySelector('.timeaxis_re')}  
                    />
                </div>
                <div className="timeaxis_ab">
                    <div className="tibb tibb1">{this.fmtTime(min)}</div>
                    <div className="tibb tibb2">{this.fmtTime(max)}</div>
                </div>
            </div>

        )
    }

    //时间格式转时间戳
    timeFmt(date){
        let dates = new Date(date); 
        return (dates.getTime());
    }

    //时间戳转时间格式
    fmtTime(str){ 
        let date = new Date(str);
        let year = date.getFullYear();
        let month= date.getMonth() + 1;
        month= month< 10 ? ('0' + month) : month;
        let day = date.getDate();
        day = day < 10 ? ('0' + day ) : day ;
        
        return year + '' + month + '-' + day ;

    }

    //tip格式化
    doTipFormatter = (val) => { 
        return `到达现场${timeFormatSeconds(val)}`;
    }


    //值改变时触发
    changeSlider = (e) => {
        let { endDate } = this.props; 
        let max = this.timeFmt(endDate),
            nowDef = Date.parse(new Date());
        if(nowDef>max){
            max = nowDef;
        }
        let alldays = max - nowDef;
        let day = parseInt((alldays/1000)/86400);

        //将今天和历史组合
        var last_record = [];
        let now_record = {
            title: "今天",
            date: nowDef 
        };
        if(e){
            for(var i = 0;i<e.length;i++){
                var item = e[i];
                last_record.push(item.date);
            }
        }
        // last_record = last_record.concat(nowDef); 
        this.setState({
            day: day,
            reclist: last_record
        })
    }
}

export default Progressc