import React, {Component}  from 'react';
import { connect } from 'react-redux'
import { withRouter, Link} from 'react-router-dom'

import CustomPagination from '../../components/pagination/pagination'
import Header from '../../components/header';
import Table from '../../components/table'
import add from '../../assets/images/add.svg';
import editImg  from '../../assets/images/edit_white.svg'
import { getInspectionPointData } from '../../redux/03_inspection.redux'
import './styles.css';


@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.inspections
        }
    },
    {getInspectionPointData}
)
class InspectionPointList extends Component {

    componentDidMount(){
        this.props.getInspectionPointData({start: 0})
    }
    pageChangeHandler(page) {
        this.props.getInspectionPointData({start: page-1})
    }
    onSearch = (params)=>{
        this.props.getInspectionPointData(params);
    }

    addNewInspectionPoint = ()=>{
        this.props.history.push('/inspections/add')
    }

    render(){
        const filters = [{
            key:'nameLike',
            placeholder:'巡查点名称'
        }];

        const columns = [
            {
              title: '巡查点名称',
              dataIndex: 'name',
              key: 'name',
              width: "15%",
            },
            {
              title: '所属项目',
              dataIndex: 'organization.name',
              key: 'organization.name',
              width: "15%",
            },
            {
              title: '楼栋',
              dataIndex: 'building.name',
              key: 'building.name',
              width: "15%",
            },
            {
                title: '楼层',
                dataIndex: 'floor.name',
                key: 'floor.name',
                width: "15%",
            },
            {
                title: '详细地址',
                dataIndex: 'location',
                key: 'location',
                
            },
            {
              title: '',
              key: 'action',
              width: 180,
              render: (text, record) => (
                <Link className='edit-action' to={`/inspections/edit/${record.organizationId}/${record.id}`}>
                  <img src={editImg} alt='edit'></img>
                  查看修改
                </Link>
              ),
            },
          ];
          
        // console.log(this.props)
        return (
            <div className = 'content'>
                <Header title = '巡查点列表' filters ={filters} remoteSelectFilters={['organizationId','buildingId','floorId']} onSearch={this.onSearch}>
                    <div className='new_org new' onClick={this.addNewInspectionPoint}>
                        <img src={add} alt='add' className='add_img  new'></img>新建巡查点
                    </div>
                </Header>
                <div className='main-table-container'>
                    <Table  columns={columns} 
                            scroll={{ y: 640 }}
                            dataSource={this.props.inspectionPoints.map((ele,index)=> {return {...ele, key:index}})} 
                            loading={this.props.loading}/>
                    <CustomPagination
                        total={this.props.total}
                        onChange={(e)=>{this.pageChangeHandler(e)}}/>
                </div>
            </div>
        )
    }
}

export default InspectionPointList