import React , { Component} from 'react'
import { Popconfirm, message} from 'antd';
import { withRouter} from 'react-router-dom'
import { connect} from 'react-redux'

import EditBar from '../../components/edit-bar/edit-bar';
import RemoteSelect from '../../components/remote-select/remote-select'

import deleteImg from '../../assets/images/delete_red.svg'
import './styles.css'

import Table from '../../components/table'
import { getUsersData, deleteUserFromOrg, updateUserRequest} from '../../redux/06_users.redux'
import {getCurrentProject, dispatchCurrentProject }  from '../../redux/01_project.redux'

@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.users,
            ...state.projects
        }
    },
    {getUsersData,  dispatchCurrentProject}
)
class OwnerBind extends Component{
    state={

    }


    componentDidMount(){

        let {organizationId} = this.props.match.params;
        getCurrentProject(organizationId).then(res=>{
            this.props.dispatchCurrentProject(res)
        })
        this.setState({
            organizationId
        },()=>this.getOwners())
        
    }

    getOwners = (data)=>{
        const organizationId = this.state.organizationId;
        const rolesId = 4;
        const params = {
            'userOrgMap.organizationId':organizationId,
            'userOrgMap.roleId':rolesId,
            size: 2000,
            ...data
        }
        this.props.getUsersData(params, false);
    }

    generateList = (raw_dataSource)=>{
        if(raw_dataSource.length < 13){
            raw_dataSource.push(
                {
                    id: 'key'+raw_dataSource.length,
                    name: null,
                    phone: null,
                },
            )
            return this.generateList(raw_dataSource)
        }else {
            return raw_dataSource;
        }  
    }

    bindOwner=(user)=>{
        let data={
            name: user.name,
            username: user.username,
            phone: user.phone,
            roleId: 4,
            organizationId: this.state.organizationId
        }
        updateUserRequest(data).then(()=>{
            message.success('添加成功');
            //重新加载数据
            this.getOwners();
        },()=>message.error('添加失败'))
    }
    deleteUserFromOrg=(username)=>{
        let organizationId = this.state.organizationId;
        deleteUserFromOrg(username, [organizationId]).then(()=>{
            message.success('删除成功');
            //重新加载数据
            this.getOwners();
        },()=>message.error('删除失败'))
    }
    onNewOwner=()=>{
        this.props.history.push(`/orgs-management/owners/register/${this.state.organizationId}`)
    }

    render(){
        let originUsers = this.props.users.map(ele=>ele);
        let raw_dataSource = this.props.users;
        const dataSource = this.generateList(raw_dataSource);
          
          const columns = [
            {
              title: '业主姓名',
              dataIndex: 'name',
              key: 'name',
            },
            {
              title: '业主电话',
              dataIndex: 'phone',
              key: 'phone',
            },
            {
                title: '',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Popconfirm
                            title="确定移除当前业主吗?"
                            onCancel = {()=>console.log('cancel')}
                            onConfirm={()=>this.deleteUserFromOrg(record.username)}
                            okText="是"
                            cancelText="否"
                        >
                        {
                            record.rolesIds? 
                            <span className='delete-btn'>
                                <img alt ='delete' src={deleteImg}/>
                                <span className='detete-user'>删除</span>
                            </span>:null
                        }
                        </Popconfirm>

                    </span>
                ),
              },
          ];

        let searchParams ={
            'userOrgMap.roleId':4, // 业主的roleId
            idNotIn: JSON.stringify(originUsers.map(ele=>ele.id))
        }
        // console.log(originUsers)
        if(!originUsers.length){
            searchParams={
                'userOrgMap.roleId':4, // 业主的roleId
            }
        }

        return (
            <div className='owner-list-content owner-list-content-bind'>
                <EditBar back={true}>
                    <div className='bind-owner-select-container '>
                        <RemoteSelect 
                            placeholder='搜索业主账户(手机号)添加到项目'
                            url='/account/users'
                            additionalParams={searchParams}
                            searchKey='phoneLike'
                            onNewOwner={this.onNewOwner}
                            onRemoteSelect={(user)=>this.bindOwner(user)}/>
                    </div>
                </EditBar>
                <div className='main-table-container bind-owner-table'>
                    <Table dataSource={dataSource.map(ele=>({...ele, key:ele.id}))} columns={columns} />
                </div>
            </div>
        )
    }
}

export default OwnerBind