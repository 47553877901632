
import axios from '../axios_new.config'
import {HIDE_LOADING, SHOW_LOADING, hideLoading, showLoading, getRegionsByIDs, getOrganizationsByIDs} from './00_common.action'

const COLLECTIONS_DATA = 'COLLECTIONS_DATA';
const CURRENT_COLLECTION = 'CURRENT_COLLECTION'

//reducer
const initState = {
    message : [],
    total:0,
    start:1,
    status: 1,
    sort: 'id',
    order: 'DESC',
    loading: true,
    currentMessage:{}
}


export function message(state = initState, action) { 
    switch(action.type){
        case COLLECTIONS_DATA:
            return { ...state, 
                message: action.payload.content,
                total: action.payload.totalElements,
                start: action.payload.start,
                status: action.payload.status,
                sort: action.payload.sort,
                order: action.payload.order,
                // start: 1,
                errorMsg:null
             }
        case CURRENT_COLLECTION:
             return {
                 ...state,
                 currentMessage: action.payload
             }
        case SHOW_LOADING:
             return {
                 ...state,
                 loading: true,
             }
        case HIDE_LOADING:
             return {
                 ...state,
                 loading: false
             }
        default:
            return state
    }
}

//action creator
export function getMessageList(params, access_token=undefined, needDetail=true){

    // dispatch 用来通知数据修改
    return dispatch => {
        dispatch(showLoading())
        let promise;
        if(access_token){
            promise = axios.request({
                method:'get',
                url:'/api/maintenance/fireControls/timeOut',
                params: params,
                headers:{
                  Authorization: `Bearer ${access_token}`,
                  "Content-Type": 'application/json',
                },
            })
        }else{
            promise = axios.get('/api/maintenance/fireControls/timeOut',{params});
        }
        
        promise.then(res=>{
            dispatch(messageData(res))
        }).finally(()=>{
            dispatch(hideLoading())
        })
    }
}

// export function updateProjectSuccess() { 
//     return dispatch=>dispatch(getDrawingList())
// }

export function dispatchCurrentMessage(data){
    return dispatch=>dispatch(currentMessage(data))
}

function messageData(data) {
    return {type: COLLECTIONS_DATA, payload: data}
}
function currentMessage(data){
    return {type: CURRENT_COLLECTION, payload: data}
}

//设为已读
export function doRead(id){
    return axios.post(`/api/maintenance/fireControls/timeOut/read/${id}`)
}

