import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {Form,Input,DatePicker, message,Spin,Select} from 'antd';
import moment from 'moment'

import EditBar from '../../components/edit-bar/edit-bar';

import { getCurrentLicense,updateLicenseRequest } from '../../redux/11_license.redux'
import { getMaintainerData } from '../../redux/08_maintainer.redux'
import {formItemLayout} from '../../components/layout/formlayout'

import { isPhoneAvailable } from '../../utils/utils'

const Option = Select.Option;

@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.maintainers
        }
    },
    {getMaintainerData}
)
class LicenseEdit extends Component{
    constructor(props){
        super(props);
        this.state = {
            regionIds:[],
            edit: false,
            picture:null,
            loading: false,
            iconLoading: false
        };
    }

    componentDidMount(){
        this.props.getMaintainerData({size: 99})
        if(Object.keys(this.props.match.params).length){
            let id = this.props.match.params.id;
            this.setState({
                loading: true,
                edit: true,
            });

            getCurrentLicense(id).then(res=>{
                this.setState({
                    ...res,
                    id,
                    loading: false
                });
            })
        }
    }    
    handleSubmit = e => {
        
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let data={
                    ...values,
                    maxUserCount: Number(values.maxUserCount),
                    name: this.props.maintainers.find(ele=>ele.id === values.organizationId).name,
                    endTime: new Date(values.endTime).toISOString(),
                }
                if(isPhoneAvailable(values.buyerPhone)){
                    this.setState({iconLoading: true});
                    updateLicenseRequest(data).then(res=>{
                        message.success('创建许可成功');
                        this.props.history.goBack();
                    }).finally(()=>this.setState({iconLoading: false}));
                }else{
                    message.warn('请输入正确的电话号码')
                }
            }
        });
    };    
    render(){
        const { getFieldDecorator } = this.props.form; 

        return (
            <div className='edit-content'>
                <EditBar  back={true} onSave = {this.handleSubmit} save={!this.state.edit} iconLoading={this.state.iconLoading}/>
                <div className='edit-form-container2 edit-form-container2-half'>
                    {
                        this.state.loading?
                        <div className="spin-container-half">
                            <Spin  size="large" tip="正在加载..."/>
                        </div>
                        :   
                        <div>
                           <Form {...formItemLayout}  onSubmit={this.handleSubmit}>
                                <Form.Item label='维保单位'>
                                    {getFieldDecorator('organizationId', {
                                        initialValue: this.state.organizationId ||undefined,
                                        rules: [{ required:true, message: '请选择维保单位!' }],
                                    })(    <Select placeholder="请选择维保单位" disabled={this.state.edit}>
                                                {
                                                    this.props.maintainers.map(ele=> <Option key={ele.id} value={ele.id}>{ele.name}</Option>)
                                                }
                                            </Select>)}
                                </Form.Item> 
                                
                                <Form.Item label='购买人姓名'>
                                {getFieldDecorator('buyerName', {
                                    initialValue: this.state.buyerName,
                                    rules: [{ required: true, message: '请输入购买人姓名!' }],
                                })(<Input  placeholder="购买人姓名" disabled={this.state.edit}/>)}
                                </Form.Item>
                                <Form.Item label='购买人电话'>
                                {getFieldDecorator('buyerPhone', {
                                    initialValue: this.state.buyerPhone,
                                    rules: [{ required: true, message: '请输入购买人电话!' }],
                                })(<Input placeholder="购买人电话" disabled={this.state.edit}/>)}
                                </Form.Item>
                                <Form.Item label='最大用户数'>
                                {getFieldDecorator('maxUserCount', {
                                    initialValue: this.state.maxUserCount,
                                    rules: [{ 
                                        required: true,
                                        pattern: new RegExp(/^[1-9]\d*$/, "g"),
                                        message: '请输入最大用户数，仅支持数字!' }],
                                    getValueFromEvent: (event) => {
                                        return event.target.value.replace(/\D/g,'')
                                    },
                                })(<Input  placeholder="最大用户数" disabled={this.state.edit}/>)}
                                </Form.Item>
                                
                                <Form.Item label='有效期'>
                                {getFieldDecorator('endTime', {
                                    initialValue: this.state.endTime ? moment(this.state.endTime):null,
                                    rules: [{ required: true, message: '请选择有效期!' }],
                                })(<DatePicker showTime placeholder="有效期" disabled={this.state.edit}/>)}
                                </Form.Item>
                            </Form>
                        </div>             
                    }
                </div>
            </div>
        )
    }
}

export default  Form.create()(LicenseEdit)
