import React, { Component } from 'react';
import { Switch, Route} from 'react-router-dom';
import DrawingReviewPList from './drawing_review_p';
import DrawingReviewPEdit from './drawing_review_p.edit'
import DrawingReviewPExamine from './drawing_review_p.examine'
import DrawingReviewPDetail from './drawing_review_p.detail'
import HomeLayout from '../../components/layout/home.layout'
import NoneSideBarLayout from '../../components/layout/non-sidebar.layout'

class DrawingReviewPRouter extends Component {
  render() {
    const drawingReviewPList = <HomeLayout>
                        <DrawingReviewPList></DrawingReviewPList>
                    </HomeLayout>
    const drawingReviewPeditView  = <HomeLayout hideProject={true}>
                        <Route  path='/drawing_review_p/register' component={DrawingReviewPEdit}/>
                        <Route  path='/drawing_review_p/edit/:id' component={DrawingReviewPEdit}/>
                        <Route  path='/drawing_review_p/drawing_examine_p/:id' component={DrawingReviewPExamine}/>  
                        <Route  path='/drawing_review_p/drawing_detail_p/:id' component={DrawingReviewPDetail}/>  
                    </HomeLayout>
    // const drawingReviewExamine = <HomeLayout>
    //           <Route  path='/drawing_review/drawing_examine/:id' component={DrawingReviewExamine}/> 
    //       </HomeLayout>

    return (
      <Switch>
        <Route exact path='/drawing_review_p' render={()=>drawingReviewPList}/>
        <Route path='/drawing_review_p' render={()=>drawingReviewPeditView}/>
      </Switch>
    );
  }
}

export default DrawingReviewPRouter;


// import React,{Component} from "react";

// export default class App extends Component{
//     constructor(props){
//         super(props)

//         this.state = {
//             counter : 0,
//         }
//     }
    

//     render(){
//         const {title,name,age} = this.props;
//         return (
//             <div>
//                 <div>{title},{name},{age}</div>
//                 <p>当前计数：{this.state.counter}</p>
//                 <button onClick={e => this.setNums(1)}>+</button>
//                 <button onClick={e => this.setNums(2)}>-</button>
//             </div>
//         )   
//     }

//     setNums(type) {
//         if(type==1){
//             this.setState({
//                 counter : this.state.counter + 1
//             })
//         }else{
//             this.setState({
//                 counter : this.state.counter - 1
//             })
//         }
//     }
// }

