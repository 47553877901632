import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'
import { message} from 'antd'

import EditBar from '../../components/edit-bar/edit-bar'
import successImg from '../../assets/images/success.svg'
import unCheckImg from '../../assets/images/select_normal.svg'
import disableCheckedImg from '../../assets/images/disableChecked.svg'

import {getMaintenanceItemsByIDs} from '../../redux/00_common.action'
import {getCurrentProject, dispatchCurrentProject} from '../../redux/01_project.redux'
import {getMaintenanceItemsInPlan, onClickSelectAll, checkMaintenanceContent, updatePlanRequest, getCurrentPlan} from '../../redux/05_plan.redux'

// import {MAINTAINCE_TYPES} from '../../constants/constants'
//维保类型
export const MAINTAINCE_TYPES = [{
        key: 'MAINTAINCE_TYPE_INSPECTION',
        value: 1,
        label: '巡查内容',
        item_label: '巡查类别',
        item_label2:'巡查项目'
    },
    {
        key: 'MAINTAINCE_TYPE_TEST',
        value: 2,
        label: '检测内容',
        item_label: '检测类别',
        item_label2:'检测项目'
    },
    {
        key: 'MAINTAINCE_TYPE_MAINTENANCE',
        value: 3,
        label: '保养内容',
        item_label: '保养类别',
        item_label2:'保养项目'
    },
    // {
    //     key: 'MAINTAINCE_TYPE_FIX',
    //     value: 4,
    //     label: '维修内容',
    //     item_label: '维修类别',
    //     item_label2:'维修项目'
    // }
]

@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.plans
            
        }
    },
    {getMaintenanceItemsInPlan, onClickSelectAll,  checkMaintenanceContent, dispatchCurrentProject}
)
class PlanInspectionContent extends Component{

    state={
        currentItemIndex:0,
        contentsIdsInOtherInspections:[],
        iconLoading: false
    }

    componentDidMount(){
        let organizationId = this.props.match.params.organizationId;

        // 获取当前项目信息
        getCurrentProject(organizationId).then(res=>{
            this.props.dispatchCurrentProject(res);
        })
        this.getDatas()
    }

    getDatas(){
        const {type, planId, inspectionPointId} = this.props.match.params;
        this.props.getMaintenanceItemsInPlan(inspectionPointId,planId, type,);

        //获取当前维保计划
        getCurrentPlan(planId).then(res=>{
            let originContents = res.contents;
            this.setState(res);
            this.setState({
                id:planId, 
                edit: true,
                contentsIds: originContents.map(ele=>ele.id)    // 当前plan中已经存在的维保内容
            });
            let itemIds = originContents.map(ele=>ele.itemId)   
            if(itemIds.length){
                // 当前页面为维保计划中某个巡查点的维保内容，需同时保存其他巡查点的维保内容最后一期发请求
                return getMaintenanceItemsByIDs(itemIds).then(resp=>{
                    let contentsIdsInOtherInspections =originContents.filter(content=>{
                        let contentItem=resp.data.find(ele=>ele.id === content.itemId);
                      return contentItem.inspectionPointId !== Number(inspectionPointId); 
                    }).map(ele=>ele.id);
                    this.setState({
                        contentsIdsInOtherInspections
                    })
                })
            }
        })
    }
    //选择类别下属内容
    switchMaintenanceItemContent(itemName, contentId, disableChecked){
        // if(disableChecked){
        //     message.warn('已列入其他维保计划')
        // }else{
            this.props.checkMaintenanceContent(this.props.contentsCandidates, itemName, contentId)
        // }
    }

    // 点击类别，仅用于改变右侧栏内容的显示
    clickMaintenanceItem(index){
        this.setState({
            currentItemIndex: index
        })
    }

    // 勾选全部
    /**
     *  点击勾选全部，
     * 1. 改变点击后的状态
     * 2. 当前所属的项目
     * 3. 原maintenanceItems
     * @memberof PlanInspectionContent
     */
    selectAllContents=(disableCheckAll)=>{
        // if(disableCheckAll){
        //     message.warn('已列入其他维保计划')
        // }else {
            this.props.onClickSelectAll(this.props.contentsCandidates, this.state.currentItemIndex)
        // }
    }

    // 注意：！！！！！！！！！！！
    //这边只是编辑了当前巡查点的contents,其他巡查点的contents也应该加进去
    // 即要保留原维保计划中的其他数据，不能直接用当前的contentsId覆盖。
    updateContentsToPlan = ()=>{
        
        const {planId} = this.props.match.params;
        const contentsIds = this.state.contentsIdsInOtherInspections;
        this.props.contentsCandidates.forEach(ele=>ele.contents.forEach(content=>{
            if(content.checked){
                contentsIds.push(content.id);
            }
        }))
        let data ={
            contentsIds:JSON.stringify(contentsIds),
            name: this.state.name,
        }
        this.setState({iconLoading: true});
        updatePlanRequest(data, true, planId).then((res)=>{
            message.success('添加维保内容成功');
            this.props.history.goBack();
        }).finally(()=>this.setState({iconLoading: false}));
    }
    deleteCurrentContents = ()=>{
        const {planId} = this.props.match.params;
        const contentsIds = this.state.contentsIdsInOtherInspections;
        let data ={
            contentsIds:JSON.stringify(contentsIds),
            name: this.state.name,
        }
        updatePlanRequest(data, true, planId).then((res)=>{
            message.success('删除维保内容成功');
            this.props.history.goBack();
        })
    }
    
    render(){
        return (
            <div className='inspection-content-page'>
                <div className='inspection-content-edit'>
                    <div className='items'>
                        <div className='items-title'>
                            {
                                MAINTAINCE_TYPES.find(ele=>Number(ele.value) === Number(this.props.match.params.type)).item_label2
                            }
                        </div>
                        <div className='items-flow-wrapper'>
                            <div className='flow'>
                                {   this.props.contentsCandidates&&
                                    this.props.contentsCandidates.map((ele,index)=><div key={ele.id} className={this.state.currentItemIndex === index?'item2 item2-active':'item2'}
                                                                    onClick = {()=>{this.clickMaintenanceItem(index)}}>
                                                                    <img className='item-success' 
                                                                        alt='checked'
                                                                        src={ele.checked? successImg: (ele.disableChecked? disableCheckedImg: unCheckImg)}/>
                                                                    <span>{ele.name}</span>
                                                                </div>)
                                }  
                            </div> 
                        </div>                     
                    </div>
                    <div className='contents'>
                        <div className='items-title'>{
                            MAINTAINCE_TYPES.find(ele=>Number(ele.value) === Number(this.props.match.params.type)).label
                        }</div>
                        <div className='contents-flow-wrapper'>
                            <div className='flow'>
                                {    this.props.contentsCandidates&&
                                    this.props.contentsCandidates.length&&this.props.contentsCandidates[this.state.currentItemIndex].contents.length?
                                    <div className={'item3'}>
                                        <img className='item-success' 
                                            src={this.props.contentsCandidates[this.state.currentItemIndex].checkAll?successImg:(this.props.contentsCandidates[this.state.currentItemIndex].disableCheckAll
                                                ? disableCheckedImg: unCheckImg)}
                                            alt='unchecked'
                                            onClick = {()=>{this.selectAllContents(this.props.contentsCandidates[this.state.currentItemIndex].disableCheckAll)}}/>
                                        <span className='select-all'>勾选全部</span>
                                    </div> : null
                                }
                                {   this.props.contentsCandidates&&
                                    this.props.contentsCandidates.length?this.props.contentsCandidates[this.state.currentItemIndex].contents.map((ele,index)=>
                                    <div key={ele.id} 
                                        className={'item3'}>
                                        <img className='item-success' 
                                            alt='checked'
                                            src={ele.checked? successImg: (ele.disableChecked? disableCheckedImg: unCheckImg)}
                                            onClick = {()=>{this.switchMaintenanceItemContent(ele.itemName, ele.id, ele.disableChecked)}}/>
                                        <span>{ele.content}</span>
                                    </div>) : null
                                }  
                            </div> 
                        </div>                 
                    </div>
                </div>
                <EditBar back={true} save={true} iconLoading={this.state.iconLoading} onSave={this.updateContentsToPlan} delete={true} onDelete={this.deleteCurrentContents}/>
            </div>
        )
    }
}

export default PlanInspectionContent