import React, {Component}  from 'react';
import { connect } from 'react-redux'
import { withRouter} from 'react-router-dom'
import { getCurrentProject, dispatchCurrentProject}from '../../redux/01_project.redux' 
import { getInspectionPointDataOfPlan } from '../../redux/03_inspection.redux'
import './styles.css';

import EditBar from '../../components/edit-bar/edit-bar'


@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.inspections,
            ...state.projects
        }
    },
    {   dispatchCurrentProject,
        getInspectionPointDataOfPlan
    }
)
class PlanMaintenanceContent extends Component {
    state={
        buildingIndex: 0,
        floorIndex:0
    }

    componentDidMount(){
        let {organizationId, planId, type} = this.props.match.params;

        // 获取当前项目信息
        getCurrentProject(organizationId).then(res=>{
            this.props.dispatchCurrentProject(res);
        })
        this.props.getInspectionPointDataOfPlan({
            organizationId,
            size: 2000},planId, type)
    }

    clickBuilding = (key, index)=>{
        if(key === 'buildingIndex'){
            this.setState({
                [key]: index,
                floorIndex:0
            })
        }else{
            this.setState({
                [key]: index
            })
        }
        
    }

    toAddContentToPlanPage=(inspectionPointId)=>{
        let {organizationId, planId, type} = this.props.match.params;
        const isFromGuidePage = this.props.history.location.pathname.indexOf('orgs-management') > -1;
        const path = isFromGuidePage?
                     `/orgs-management/plans/inspection-point/${inspectionPointId}/contents/${type}/${organizationId}/${planId}`
                     : `/plans/inspection-point/${inspectionPointId}/contents/${type}/${organizationId}/${planId}`
        this.props.history.push(path)
    }

    render(){
        // console.log(this.props)
        return (
            <div>
                <div className='buildings-floors'>
                    <div className = 'buildings'>
                        <div className='title'>楼栋</div>
                        <div className='building-scroll-view'>
                            {  this.props.inspectionPointsOfPlan.length === 0 ?
                                <div className='left-item-project-active'>暂无内容</div>
                                : this.props.inspectionPointsOfPlan.map((ele, index)=>
                                        <div  key={ele.id} 
                                            className={index === this.state.buildingIndex? 
                                                'left-item-project-active flex':'left-item-project flex'}
                                                onClick={()=>this.clickBuilding('buildingIndex',index)}>
                                            <div>{ele.name}</div>
                                            <div className='plan-tag'>{ele.buildingPlanStatus}</div>
                                        </div>
                                )
                            }
                        </div>
                    </div>
                    <div className = 'floors'>
                        <div className='title'>楼层</div>
                        <div className='building-scroll-view'>
                            {
                                this.props.inspectionPointsOfPlan.length ?
                                this.props.inspectionPointsOfPlan[this.state.buildingIndex].floors.map((ele,index)=>
                                    <div className={index === this.state.floorIndex ? 'floor-item2-active flex':'floor-item2 flex'} key={ele.id}
                                        onClick={()=>this.clickBuilding('floorIndex',index)}>
                                        <div>{ele.name}</div>
                                        <div className='plan-tag'>{ele.floorPlanStatus}</div>                                  
                                    </div>
                                ): null
                            }
                        </div>
                    </div>
                    <div className = 'inspections'>
                        <div className='title2'>巡查点</div>
                        <div className='building-scroll-view'>
                            {
                                this.props.inspectionPointsOfPlan.length 
                                && this.props.inspectionPointsOfPlan[this.state.buildingIndex].floors.length
                                ? this.props.inspectionPointsOfPlan[this.state.buildingIndex].floors[this.state.floorIndex].inspectionsInCurrentFloor.map(ele=>
                                    <div className='floor-item2 flex item-inspectionPoint'
                                        onClick={()=>{this.toAddContentToPlanPage(ele.id)}}
                                        key={ele.id}>
                                        <div>{ele.name}</div> 
                                        <div className='plan-tag plan-tag-inspectionPoint' >{ele.inspectionPlanStatus}</div>                                 
                                    </div>
                                
                                ): null
                            }
                        </div>
                    </div>
                </div>
                <div className='edit-bar-container-content'>
                    <EditBar back={true}/>
                </div>
            </div>
        )
    }
}

export default PlanMaintenanceContent