import React, {Component}  from 'react';
import { connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import {Form,Input,Select, message,Upload, Spin, Button, Modal} from 'antd';


import Table from '../../components/table'
import moment from 'moment';
import CustomPagination from '../../components/pagination/pagination'
import {formItemLayout} from '../../components/layout/formlayout'
import{ getHeaders, _baseUrl} from '../../axios.config'
import { getMessageList, doRead } from '../../redux/19_message.redux'
import {  addMarker} from '../../redux/00_common.action'
import mapJson from '../../assets/json/custom_map_config.json'
import './styles.css';


const BMap = window.BMap;
const Search = Input.Search;
let map;


const Option = Select.Option;
@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.region,
            ...state.message
        }
    },
    {  getMessageList}
)
class Message extends Component {


    constructor(props){
        super(props)

        this.state = {
            tab: 0,
            loading: false,
            headers:{},
            iconLoading: false,
            nowproject: []
        }
    } 

    componentWillMount(){
        this.updateHeaders();
    }

    updateHeaders = async()=>{
        let headers = await getHeaders(true);
        this.setState({
            headers
        })
    }

    componentDidMount(){
        if(this.props.isAuth){
            this.props.getMessageList({start:1, type: 1}, this.props.authUser.access_token)
        }
    }

    pageChangeHandler(page) {
        let st = parseInt(this.state.tab) + 1;
        this.props.getMessageList({start:page,type: st})
    }

    //设置已读
    doReadMessage(id){ 
        let _this = this;
        let st = parseInt(this.state.tab) + 1;
        Modal.confirm({
            title: '提示',
            content: '确定消息已读？',
            okText: '确认',
            cancelText: '取消',
            onOk(){
                _this.setState({iconLoading: true});
                doRead(id).then(res=>{
                    message.success("设置成功");
                    _this.props.getMessageList({start:1, type: st}, _this.props.authUser.access_token)
                }).finally(()=>_this.setState({iconLoading: false}));
            }
        });
    }

    
    render(){   

          

        const columns = [
            {
                title: '告警类型',
                dataIndex: 'type',
                key: 'type',
                width: "8%",
                render: (text, record) => {
                    if(text==1){
                        return "项目超期"
                    }else if(text==2){
                        return "回款超期"
                    }
                }
            },
            {
                title: '项目名称',
                dataIndex: 'name',
                key: 'name',
                width: "10%",
            },
            {
                title: '联系人',
                dataIndex: 'constructionPerson',
                key: 'constructionPerson',
                width: "8%",
            },
            {
                title: '联系方式',
                dataIndex: 'constructionPhone',
                key: 'constructionPhone',
                width: "10%",
            },
            {
                title: '期限范围',
                dataIndex: 'range',
                key: 'range',
                //width: "20%",
                render: (text, record) => {
                    //return record.startDate+"-"+record.endDate
                    return moment(record.startDate).format('YYYY-MM-DD')+"-"+moment(record.endDate).format('YYYY-MM-DD')
                }
            },
            {
                title: '逾期时长',
                dataIndex: 'timeOutDate',
                key: 'timeOutDate',
                width: "8%",
            },
            {
                title: '操作',
                key: 'action',
                align: 'center',
                width: 180,
                render: (text, record) => {
                    if(record.hasRead){
                        return ""
                    }else{
                        //审核
                        return <Button type='danger' onClick={e => this.doReadMessage(record.id)}>设为已读</Button>
                    }
                },
            }
        ];



        const app = (
            <div className = 'content content_abs'>
                <div className='itabs'>
                    <span className={["tab_span ",this.state.tab==0 ? 'on' :'' ].join('')} onClick={e => this.setTabs(0)}>项目超期</span>
                    <span className={["tab_span ",this.state.tab==1 ? 'on' :'' ].join('')} onClick={e => this.setTabs(1)}>回款超期</span>
                </div>
                <div className='main-table-container ts_table la_ts_table2 col_table' style={{"marginTop": "70px"}}>
                    <Table columns={columns} dataSource={this.props.message && this.props.message.map(item=>{ return {...item, key:item.id}})}  rowKey={record => record.id} bordered={true} />
                    <CustomPagination
                        total={this.props.total}
                        onChange={(e)=>{this.pageChangeHandler(e)}}/>
                </div>
            </div>
        );
        return app
    }

    setTabs(i){
        this.setState({
            tab : i
        })
        let st = parseInt(i) + 1;
        this.props.getMessageList({start:1,type: st})
    }

}



export default Message

