import React, { Component } from 'react';
import { Switch, Route} from 'react-router-dom';
import DrawingReviewList from './drawing_review';
import DrawingReviewEdit from '../13_new_project/new_project.edit'
import DrawingReviewExamine from './drawing_review.examine'
import HomeLayout from '../../components/layout/home.layout'
import NoneSideBarLayout from '../../components/layout/non-sidebar.layout'

class DrawingReviewRouter extends Component {
  render() {
    const drawingReviewList = <HomeLayout>
                        <DrawingReviewList></DrawingReviewList>
                    </HomeLayout>
    const drawingRevieweditView  = <HomeLayout hideProject={true}>
                        <Route  path='/drawing_review/register' component={DrawingReviewEdit}/>
                        <Route  path='/drawing_review/edit/:id' component={DrawingReviewEdit}/>
                        <Route  path='/drawing_review/drawing_examine/:id' component={DrawingReviewExamine}/>  
                    </HomeLayout>
    // const drawingReviewExamine = <HomeLayout>
    //           <Route  path='/drawing_review/drawing_examine/:id' component={DrawingReviewExamine}/> 
    //       </HomeLayout>

    return (
      <Switch>
        <Route exact path='/drawing_review' render={()=>drawingReviewList}/>
        <Route path='/drawing_review' render={()=>drawingRevieweditView}/>
      </Switch>
    );
  }
}

export default DrawingReviewRouter;


// import React,{Component} from "react";

// export default class App extends Component{
//     constructor(props){
//         super(props)

//         this.state = {
//             counter : 0,
//         }
//     }
    

//     render(){
//         const {title,name,age} = this.props;
//         return (
//             <div>
//                 <div>{title},{name},{age}</div>
//                 <p>当前计数：{this.state.counter}</p>
//                 <button onClick={e => this.setNums(1)}>+</button>
//                 <button onClick={e => this.setNums(2)}>-</button>
//             </div>
//         )   
//     }

//     setNums(type) {
//         if(type==1){
//             this.setState({
//                 counter : this.state.counter + 1
//             })
//         }else{
//             this.setState({
//                 counter : this.state.counter - 1
//             })
//         }
//     }
// }

