import React, { Component } from 'react';
import { Switch, Route} from 'react-router-dom';
import Message from './message';
import HomeLayout from '../../components/layout/home.layout'

class MessageRouter extends Component {
  render() {
    const message = <HomeLayout>
                        <Message></Message>
                    </HomeLayout>


    return (
      <Switch>
        <Route exact path='/message' render={()=>message}/>
      </Switch>
    );
  }
}

export default MessageRouter;
