import React, {Component}  from 'react';
import './styles.css';
class NextBar extends Component {

    render(){

        return (
            <div className='next-bar'>
                <div className='next-step' onClick={this.props.onNextStep}>
                    {this.props.nextBtn || '下一步'}
                </div> 
            </div>
        )
    }
}

export default NextBar