import React, {Component}  from 'react';
import { connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import {Input,Select} from 'antd';


import marker1 from '../../assets/images/marker1.png'
import marker2 from '../../assets/images/marker2.png'
import marker3 from '../../assets/images/marker3.png'

import { getMaintainerData } from '../../redux/08_maintainer.redux'
import { getProjectsData } from '../../redux/01_project.redux'
import { getAcceptanceList} from '../../redux/14_acceptance.redux'
import { getDrawingList} from '../../redux/12_drawing_review.redux'

import { getCurrentAuthUser } from '../../redux/auth.redux'


import{ getHeaders, _baseUrl} from '../../axios.config'

import mapJson from '../../assets/json/custom_map_config.json'
import './styles.css';


const BMap = window.BMap;
const Search = Input.Search;
let map;


const Option = Select.Option;
@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.maintainers,
            ...state.projects,
            ...state.acceptance,
            ...state.drawingReview
        }
    },
    {getMaintainerData, getProjectsData, getCurrentAuthUser, getAcceptanceList, getDrawingList}
)
class ProjectMap extends Component {


    constructor(props){
        super(props)

        this.state = {
            loading: false,
            headers:{},
            iconLoading: false,
            nowproject: [],//当前展示的信息
            lasList: [],

            mk1: true,
            mk2: true,
            mk3: true
        }
    } 

    componentWillMount(){
        this.updateHeaders();
    }

    updateHeaders = async()=>{
        let headers = await getHeaders(true);
        this.setState({
            headers
        })
    }

    componentDidMount(){
        if(this.props.isAuth){
            this.props.getAcceptanceList({start:1,size: 999,type:2,searchType:1}, this.props.authUser.access_token);
            this.props.getDrawingList({start:1,size: 999,type:1,searchType:1}, this.props.authUser.access_token);
            this.props.getProjectsData({start:0, size: 999}, this.props.authUser.access_token);

            let projectList = this.props.projects,
                acceptList = this.props.acceptance,
                drawingList = this.props.drawingReview,
                lasList = [];
            if(projectList){
                for(var i = 0;i<projectList.length;i++){
                    var item = projectList[i];
                        item['startLatitude'] = item['latitude'];
                        item['startLongitude'] = item['longitude'];
                    projectList[i] = item;
                }
            }

            lasList = projectList.concat(acceptList,drawingList);
            // this.setState({
            //     lasList: lasList
            // })
            this.addProMap(104.080439,30.655875,lasList)
            //console.log(1234,lasList);
        }
        
    }


    addProMap = (lng,lat,datalist)=>{
        map = new BMap.Map("p_map");  
        map.centerAndZoom(new BMap.Point(lng,lat), this.state.edit ? 13:13);
        map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
        map.addControl(new BMap.NavigationControl());    
        map.addControl(new BMap.ScaleControl());    
        map.addControl(new BMap.OverviewMapControl());   
        //map.setMapStyle({styleJson:mapJson});
        // if(this.state.edit){
        //     addMarker(map,lng,lat,this.state.name)
        // } 

        var bPoints = new Array();

        // 函数 创建多个标注
        //var datalist = this.state.lasList; console.log(978,datalist);
        datalist.forEach(function(item){   
            var points = new BMap.Point(item['startLongitude'],item['startLatitude']);//创建坐标点
                bPoints.push(points); // 添加到百度坐标数组 用于自动调整缩放级别
                var view = map.getViewport(eval(bPoints));
                var mapZoom = view.zoom;
                var centerPoint = view.center;
                map.centerAndZoom(centerPoint, mapZoom);

            if(item.type==1){
                var myIcon = new BMap.Icon(marker2, new BMap.Size(50, 50));
            }else if(item.type==2){
                var myIcon = new BMap.Icon(marker3, new BMap.Size(50, 50));
            }else{
                var myIcon = new BMap.Icon(marker1, new BMap.Size(50, 50));
            }
        
            var markers = new BMap.Marker(points,{icon: myIcon});
            map.addOverlay(markers);
            
            
            let opt_height = 0;
            //不同的数据显示不同的窗口
            var html = "";
            if(item.type==1 || item.type==2){
                html +=     "<div class='onemcon onemcon1'><span>项目地址：</span><em>"+item.address+"</em></div>";
                html +=     "<div class='onemcon onemcon1'><span>层　　数：</span><em>"+item.floorNum+"</em></div>";
                html +=     "<div class='onemcon onemcon1'><span>面　　积：</span><em>"+item.areaMeasure+"㎡</em></div>";
                //html +=     "<div class='onemcon onemcon1'><span>高　　度：</span><em>"+item.height+"</em></div>";
                html +=     "<div class='onemcon onemcon1'><span>施工单位：</span><em>"+item.constructionCompany+"</em></div>";
                // html +=     "<div class='onemcon onemcon2 flex-between'>";
                // html +=         "<div class='con1'><span>施工单位联系人：</span><em>"+item.constructionPerson+"</em></div>";
                // html +=         "<div class='con2'><span>联系方式：</span><em>"+item.constructionPhone+"</em></div>";
                // html +=     "</div>";
                html +=     "<div class='onemcon onemcon1'><span>设计单位：</span><em>"+item.designCompany+"</em></div>";
                // html +=     "<div class='onemcon onemcon2 flex-between'>";
                // html +=         "<div class='con1'><span>设计单位联系人：</span><em>"+item.designPerson+"</em></div>";
                // html +=         "<div class='con2'><span>联系方式：</span><em>"+item.designPhone+"</em></div>";
                // html +=     "</div>";
                html +=     "<div class='onemcon onemcon1'><span>监理单位：</span><em>"+item.supervisorCompany+"</em></div>";
                // html +=     "<div class='onemcon onemcon2 flex-between'>";
                // html +=         "<div class='con1'><span>监理单位联系人：</span><em>"+item.supervisorPerson+"</em></div>";
                // html +=         "<div class='con2'><span>联系方式：</span><em>"+item.supervisorPhone+"</em></div>";
                // html +=     "</div>";
                if(item.type==1){
                    html +=     "<div class='onemcon onemcon1'><span>审图单位：</span><em>"+item.drawingReviewCompany+"</em></div>";
                }else if(item.type==2){
                // html +=     "<div class='onemcon onemcon2 flex-between'>";
                // html +=         "<div class='con1'><span>审图单位联系人：</span><em>"+item.drawingReviewPerson+"</em></div>";
                // html +=         "<div class='con2'><span>联系方式：</span><em>"+item.drawingReviewPhone+"</em></div>";
                // html +=     "</div>";
                    html +=     "<div class='onemcon onemcon1'><span>检测单位：</span><em>"+item.testingCompany+"</em></div>";
                }
                // html +=     "<div class='onemcon onemcon2 flex-between'>";
                // html +=         "<div class='con1'><span>检测单位联系人：</span><em>"+item.testingPerson+"</em></div>";
                // html +=         "<div class='con2'><span>联系方式：</span><em>"+item.testingPhone+"</em></div>";
                // html +=     "</div>";
                html +=     "<div class='onemcon onemcon1'><span>使用性质：</span><em>"+item.useNature+"</em></div>";

                opt_height = 350;
            }else{
                html +=     "<div class='onemcon onemcon1'><span>项目地址：</span><em>"+item.detailAddr+"</em></div>";
                html +=     "<div class='onemcon onemcon1'><span>联系电话：</span><em>"+item.phone+"</em></div>";
                opt_height = 140;
            }

            var opts = {
                width : 450,     // 信息窗口宽度
                height: opt_height,     // 信息窗口高度
                title : "详情"
            }

            var content = "";
                content += "<div class='mcon'>";
                content +=     "<div class='onemcon onemcon1'><span>项目名称：</span><em>"+item.name+"</em></div>";
                content +=      html;
                content += "</div>"
            var infoWindow = new BMap.InfoWindow(content, opts);  // 创建信息窗口对象 
            markers.addEventListener("click", function(){      
                map.openInfoWindow(infoWindow, points); //开启信息窗口
            }); 
        })
    }

    
    render(){   

        //后期增加点击图例筛选功能
        const app = (
            <div className = 'content content_abs content_map'>
                
                <div className="promap">
                    <div className="pm_right">
                        <div id="p_map"></div>

                        <div className='mlegend'>
                            <div className={["oneleg flex-between ",this.state.mk3==true ? 'on' :'' ].join('')} onClick={e => this.changeMaker(3)}>
                                <img src={marker1} alt="" />
                                <font>消防维保</font>
                            </div>
                            <div className={["oneleg flex-between ",this.state.mk1==true ? 'on' :'' ].join('')} onClick={e => this.changeMaker(1)}>
                                <img src={marker2} alt="" />
                                <font>消防审图</font>
                            </div>
                            <div className={["oneleg flex-between ",this.state.mk2==true ? 'on' :'' ].join('')} onClick={e => this.changeMaker(2)}>
                                <img src={marker3} alt="" />
                                <font>验收评定</font>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        );
        return app
    }

    //切换maker
    changeMaker(e){
        this.setState({
            [`mk${e}`] : !this.state[`mk${e}`]
        },() => {
            let projectList = this.state.mk3 ? this.props.projects : [],
                acceptList = this.state.mk2 ? this.props.acceptance : [],
                drawingList = this.state.mk1 ? this.props.drawingReview : [],
                lasList = [];
            if(projectList){
                for(var i = 0;i<projectList.length;i++){
                    var item = projectList[i];
                        item['startLatitude'] = item['latitude'];
                        item['startLongitude'] = item['longitude'];
                    projectList[i] = item;
                }
            }

            lasList = projectList.concat(acceptList,drawingList);
            this.addProMap(104.080439,30.655875,lasList)
        })

        
    }

}

export default ProjectMap

