
import axios from '../axios_new.config'
import {HIDE_LOADING, SHOW_LOADING, hideLoading, showLoading, getRoles, getOrganizationsByIDs} from './00_common.action'

const USERS_DATA = 'USERS_DATA';

//reducer
const initState = {
    users : [],
    total:0,
    start:0,
    loading: true
}



// 新增项目或编辑项目
// https://api.m.t.zlya.net/api/maintenance/fireControls
export function doAddNewProject(data,edit=false, id){
    if(edit){
        return axios.put(`/api/maintenance/fireControls/${id}`,data)
    }else {
        return axios.post('/api/maintenance/fireControls',data)
    }
}

// //从单位移除用户
// export function deleteUserFromOrg(username, organizationIds){
//     return axios.delete('/account/users/organization',{
//         params:{
//             username,
//             organizationIds: JSON.stringify(organizationIds)
//         }
//     })
// }