import React, {Component}  from 'react';
import { connect } from 'react-redux'
import { withRouter, Link} from 'react-router-dom'

import CustomPagination from '../../components/pagination/pagination'
import Header from '../../components/header';
import Table from '../../components/table'

import add from '../../assets/images/add.svg';
import editImg  from '../../assets/images/edit_white.svg'

import { getFloorsData } from '../../redux/02_buildings.redux'

import './styles.css';

@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.buildings
        }
    },
    {getFloorsData}
)
class Building extends Component {

    componentDidMount(){
        this.props.getFloorsData({start: 0})
    }
    pageChangeHandler(page) {
        this.props.getFloorsData({start: page-1})
    }
    onSearch = (params)=>{
        this.props.getFloorsData(params);
    }

    addNewBuildings = ()=>{
        this.props.history.push('/buildings/add')
    }

    render(){
        const filters = [{
            key:'nameLike',
            placeholder:'楼层名称'
        }];

        const columns = [
            {
              title: '所属项目',
              dataIndex: 'organization.name',
              key: 'organization.name',
              width: "25%",
            },
            {
              title: '楼栋',
              dataIndex: 'building.name',
              key: 'building.name',
              width: "25%",
            },
            {
              title: '楼层',
              dataIndex: 'name',
              key: 'name',
            },
            {
              title: '',
              key: 'action',
              width: 180,
              render: (text, record) => (
                <Link className='edit-action' to={`/buildings/edit/${record.organizationId}/${record.id}`}>
                  <img src={editImg} alt='edit'></img>
                  查看修改
                </Link>
              ),
            },
          ];
          
        // console.log(this.props)
        return (
            <div className = 'content'>
                <Header title = '楼栋/楼层列表' filters ={filters} remoteSelectFilters={['organizationId','buildingId']}  onSearch={this.onSearch}>
                    <div className='new_org new' onClick={this.addNewBuildings}>
                        <img src={add} alt='add' className='add_img  new'></img>新建楼层
                    </div>
                </Header>
                <div className='main-table-container'>
                    <Table  columns={columns}
                    scroll={{ y: 640 }}
                            dataSource={this.props.floors.map((ele,index)=> {return {...ele, key:index}})} 
                            loading={this.props.loading}/>
                    <CustomPagination
                        total={this.props.total}
                        onChange={(e)=>{this.pageChangeHandler(e)}}/>
                </div>
            </div>
        )
    }
}

export default Building