import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {Form,Input,Select,Radio, message,Upload,  Modal, Button} from 'antd';

import picUpload from '../../assets/images/pic_update.svg'
import back2 from '../../assets/images/back2.png'
import Progress from '../../components/progress/index'


import {formItemLayout} from '../../components/layout/formlayout'
import{ getHeaders, _baseUrl} from '../../axios.config'
import{  timeFormatSeconds ,getStorage} from '../../utils/utils'
import {  addMarker} from '../../redux/00_common.action'

import { getCurrentDrawingReviewP,dispatchCurrentDrawingReviewP,receiveWork, fireControlsAudit } from '../../redux/15_drawing_review_p.redux'
import { getCurrentUser} from '../../redux/06_users.redux'
import Enclosure  from '../../components/enclosure/index'

import mapJson from '../../assets/json/custom_map_config.json'

const BMap = window.BMap;
const Search = Input.Search;
let map;

const Option = Select.Option;
@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.maintainers
        }
    },
    {getCurrentDrawingReviewP,dispatchCurrentDrawingReviewP, getCurrentUser}
)
class DrawingReviewExamineP extends Component{
    constructor(props){
        super(props);
        this.state = {
            tab : 0,
            edit: true,
            roleId: 0,
            avatar: null,
            loading: false,
            headers:{},
            iconLoading: false,
            qualified: 3,
            remark: '',
            inspectionList: [],
            inspection: [],//凭证
            status: 0,//当前状态

            previewVisible: false,
            previewImage: '',
            status: 0,
            last_qualified: [],
            noqualified: [],

            userRoid: '',
            userId: ''
        };
    }
    componentWillMount(){
        this.updateHeaders();

        let authUserRole = this.props.authUser.rolesIds;
        const isrole = authUserRole.indexOf(10) > -1; 
        let userRoid = authUserRole[0] ? authUserRole[0] : '';
        let userId = getStorage('userId');
        this.setState({
            userRoid: userRoid,
            userId: userId
        })
    }

    updateHeaders = async()=>{
        let headers = await getHeaders(true);
        this.setState({
            headers
        })
    }

    componentDidMount(){
        let {id} = this.props.match.params;
        let userId = getStorage('userId');
        getCurrentDrawingReviewP(id).then(res=>{
            //解析审核历史及合格意见
            let auditHis = res.auditHis;
            let last_audit,last_qualified,noqualified;
            if(auditHis.length>0){
                last_audit = auditHis[auditHis.length-1];
                if(last_audit.status==3){
                    last_qualified = last_audit;//合格
                    auditHis.pop();
                    noqualified = auditHis;//不合格
                }else if(last_audit.status==4){
                    last_qualified = [];
                    noqualified = auditHis;
                }else{
                    last_qualified = [];
                    auditHis.pop();
                    noqualified = auditHis;
                }
            }
            this.setState(res);
            this.setState({
                status: last_audit.status,
                last_qualified: last_qualified,
                noqualified: noqualified
            })
            this.props.dispatchCurrentDrawingReviewP(res);
            this.addMap(res.startLongitude,res.startLatitude)
            
        });
        getCurrentUser(userId).then(res=>{
            this.setState({
                username: res.userOrgMap[0]?res.userOrgMap[0].name:(res.name || null),
                userroleId: res.userOrgMap[0]?res.userOrgMap[0].roleId : res.rolesIds[0],
                userrolesIds: res.userOrgMap&&res.userOrgMap.length?res.userOrgMap.map(ele=>ele.roleId):res.rolesIds
            })
        })
    }

    addMap = (lng,lat,type)=>{
        map = new BMap.Map("l-map");  
        map.centerAndZoom(new BMap.Point(lng,lat), this.state.edit ? 13 : 13);
        map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
        map.addControl(new BMap.NavigationControl());    
        map.addControl(new BMap.ScaleControl());    
        map.addControl(new BMap.OverviewMapControl());   
        //map.setMapStyle({styleJson:mapJson});
        addMarker(map,lng,lat,this.state.name)
    }

    //设置上传按钮
    setUpdTxt(txt){
        return <div>
                  <img src={picUpload} alt="" />
                  <div className="ant-upload-text">{txt}</div>
               </div>;
    }

    //接收
    doAudit = () => {
        let _this = this;
        let userId = getStorage('userId');
        Modal.confirm({
            title: '提示',
            content: '确定接收？',
            okText: '确认',
            cancelText: '取消',
            onOk(){
                _this.setState({iconLoading: true});
                receiveWork( _this.state.id,userId).then(res=>{
                    message.success("接收成功");
                    setTimeout(function(){
                        _this.props.history.push(`/drawing_review_p`);
                    },1800)
                }).finally(()=>_this.setState({iconLoading: false}));
            }
        });
    }

    handleSubmitAuditing = e => {
        let {remark, qualified, inspection} = this.state;
        let {id} = this.props.match.params;
        let _this = this;
        let data = {
            fireControlId: id,
            status: qualified,
            remark: remark,
            files: inspection
        };
        Modal.confirm({
            title: '提示',
            content: '请确认审核结果？',
            okText: '确认',
            cancelText: '取消',
            onOk(){
                _this.setState({iconLoading: true});
                fireControlsAudit(data).then(res=>{
                    message.success("审核成功");
                    setTimeout(function(){
                        _this.props.history.push(`/drawing_review_p`);
                    },1800)
                }).finally(()=>_this.setState({iconLoading: false}));
            }
        });
    }
    
    render(){  
        const _this = this;
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;
        let {name,address,auditHis, floorNum, height, areaMeasure, constructionCompany, constructionPerson, constructionPhone, designCompany, designPerson, designPhone, supervisorCompany, supervisorPerson, supervisorPhone, testingCompany, testingPerson, testingPhone, drawingReviewCompany, drawingReviewPerson, drawingReviewPhone, useNature, projectDrawings, drawingReviewComments, selfCheckReport, builtDrawings, fireDetectionRepor, startLongitude, startLatitude, startDate,normalDate,warningDate,endDate,qualified,last_qualified,noqualified, status, updateRemark} = this.state;
        const { previewVisible, previewImage, inspectionList } = this.state;
        const props = {
            name: 'file',
            showUploadList: true,
            listType: "picture-card",
            onPreview: _this.handlePreview,
            beforeUpload: _this.beforeUpload,
            multiple:true,
            action: `${_baseUrl}/resource/resources/put-oss`,
            headers: _this.state.headers
        };


        //上传凭证
        let uploadButton = this.setUpdTxt("上传凭证");
        let updBtns =   (<div>
            <Upload {...props} onChange={this.handleChangeInspection} fileList={inspectionList}>
                {inspectionList.length >= 99 ? null : uploadButton}
            </Upload>
        </div>
        )

        //审核
        let audit = '';
        if(status==1 && this.state.userRoid==9){
            audit = (<Button className="done_btn" loading={this.state.iconLoading} type="primary" size='large' onClick={this.doAudit}>接收</Button>);
        }else if(status==2 && this.state.userRoid==9){
            audit = (
                <div className="supps supps_bs edit-form-container edit-form-container-half">
                    <Form {...formItemLayout} onSubmit={this.handleSubmitAuditing}>
                        <div className="onedem exm_radio">
                            <div className="onedem_left"></div>
                            <div className="onedem_right">
                                <Radio.Group name="status"  onChange={this.onChangeQualified.bind(this)} value={this.state.qualified}>
                                    <Radio value={3}>通过</Radio>
                                    <Radio value={4}>不通过</Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className="onedem">
                            <div className="onedem_left">审核意见：</div>
                            <div className="onedem_right">
                                <div className="exm_top">审核员：{this.state.username}</div>
                                <div className="exm_txt">
                                    <TextArea onChange={this.onChangeRemark.bind(this)} className='textarea' placeholder="请输入审核意见" />
                                </div>
                                <div className="exm_img upd_img clearfix">
                                    {updBtns}

                                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                        <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </Form>

                    <Button className="done_btn" loading={this.state.iconLoading} type="primary" size='large' onClick={this.handleSubmitAuditing}>审核</Button>
                </div>
            );
        }
    
        return (
            <div className = 'content content_abs'>
                {/* <div className='itabs itabs4'>
                    <span className={["tab_span ",this.state.tab==0 ? 'on' :'' ].join('')} onClick={e => this.setTabs(0)}>全部</span>
                    <span className={["tab_span ",this.state.tab==1 ? 'on' :'' ].join('')} onClick={e => this.setTabs(1)}>初审</span>
                    <span className={["tab_span ",this.state.tab==2 ? 'on' :'' ].join('')} onClick={e => this.setTabs(2)}>复审</span>
                    <span className={["tab_span ",this.state.tab==3 ? 'on' :'' ].join('')} onClick={e => this.setTabs(3)}>已完成</span>
                </div> */}
                <div className="comm_detail">
                    <div className="itops flex-between">
                        <div className="itop_back">
                            返回 <img src={back2} alt="" />
                        </div>
                    </div>
                    <div className="examcons"><br/><br/>
                        <div className="examcon_title">项目详情</div>
                        <div>
                            <Progress 
                            startDate={timeFormatSeconds(startDate)} 
                            endDate={timeFormatSeconds(endDate)} 
                            startWarn={timeFormatSeconds(normalDate)} 
                            endWarn={timeFormatSeconds(warningDate)}></Progress>
                        </div>
                        <div className="examcon flex-between-top">
                            <div className="examcon_left">
                                <div className='oneexam'>
                                    <span className='sp1'>项目名称：</span>
                                    <span className='sp2'>{name}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>项目地址：</span>
                                    <span className='sp2'>{address}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>层数：</span>
                                    <span className='sp2'>{floorNum}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>高度：</span>
                                    <span className='sp2'>{height}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>面积：</span>
                                    <span className='sp2'>{areaMeasure}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>施工单位：</span>
                                    <span className='sp2'>{constructionCompany}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>施工单位联系人：</span>
                                    <span className='sp2'>{constructionPerson}</span>
                                    <span className='sp3'>联系方式：{constructionPhone}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>设计单位：</span>
                                    <span className='sp2'>{designCompany}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>设计单位联系人：</span>
                                    <span className='sp2'>{designPerson}</span>
                                    <span className='sp3'>联系方式：{designPhone}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>监理单位：</span>
                                    <span className='sp2'>{supervisorCompany}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>监理单位联系人：</span>
                                    <span className='sp2'>{supervisorPerson}</span>
                                    <span className='sp3'>联系方式：{supervisorPhone}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>审图单位：</span>
                                    <span className='sp2'>{drawingReviewCompany}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>审图单位联系人：</span>
                                    <span className='sp2'>{drawingReviewPerson}</span>
                                    <span className='sp3'>联系方式：{drawingReviewPhone}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>使用性质：</span>
                                    <span className='sp2'>{useNature}</span>
                                </div>
                            </div>
                            <div className="examcon_right">
                                <div id="l-map" className='l-map'></div>
                            </div>
                        </div>

                        <div className="tsonedems">
                            {updateRemark ? (
                                <div className="tsonedem" style={{"margin-bottom": "30px"}}>
                                    <div className="onedem_left">补充说明：</div>
                                    <div className="onedem_right">{updateRemark}</div>
                                </div>
                            ) : ''}
                            <div className="tsonedem">
                                <div className="onedem_left">项目图纸：</div>
                                <div className="onedem_right">
                                    <Enclosure enclosure={projectDrawings}></Enclosure>
                                </div>
                            </div>
                            <div className="tsonedem">
                                <div className="onedem_left">审图意见：</div>
                                <div className="onedem_right">
                                    <Enclosure enclosure={drawingReviewComments}></Enclosure>
                                </div>
                            </div>
                        </div>

                        {noqualified.length>0 ? noqualified.map((item, index) => {
                            return <div className="onerecord" key={index}>
                                        <div className="onerecord_sort">{index+1}</div>
                                        {item.projectDrawings.length>0 ? (
                                            <div className="onedem onedem_small">
                                                <div className="onedem_left">历史图纸：</div>
                                                <div className="onedem_right">
                                                    <div className="oimg">
                                                        <Enclosure enclosure={item.projectDrawings}></Enclosure>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : ''}
                                        {item.drawingReviewComments.length>0 ? (
                                            <div className="onedem onedem_small">
                                                <div className="onedem_left">历史审图意见：</div>
                                                <div className="onedem_right">
                                                    <div className="oimg">
                                                        <Enclosure enclosure={item.drawingReviewComments}></Enclosure>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : ''}
                                        
                                        <div className="onedem onedem_small">
                                            <div className="onedem_left">历史意见：</div>
                                            <div className="onedem_right">
                                                <div className="onedem_right_top">
                                                    审图员：{item.auditors.map(ele=> <span key={ele.id}>{ele.userOrgMap?
                                                        (ele.userOrgMap.length&&ele.userOrgMap[0].name?ele.userOrgMap[0].name:(ele.name||'未知'))
                                                        :'未知'}</span>)}
                                                </div>
                                                <div className="onedem_right_img">
                                                    <div className="otxt"><span className='txt'>{item.remark}</span></div>
                                                    <div className="oimg">
                                                        <Enclosure enclosure={item.files}></Enclosure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        }) : ''}

                        {status==3 ? (
                            <div className="onerecord">
                                <div className="onedem onedem_small">
                                    <div className="onedem_left">合格意见：</div>
                                    <div className="onedem_right">
                                        <div className="onedem_right_top">
                                            审图员：{last_qualified.auditors ? last_qualified.auditors.map(ele=> <span key={ele.id}>{ele.userOrgMap?
                                                        (ele.userOrgMap.length&&ele.userOrgMap[0].name?ele.userOrgMap[0].name:(ele.name||'未知'))
                                                        :'未知'}</span>) : '-'}
                                        </div>
                                        <div className="onedem_right_img">
                                            <div className="otxt"><span className='txt'>{last_qualified.remark}</span></div>
                                            <div className="oimg">
                                                <Enclosure enclosure={last_qualified.files}></Enclosure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : ''}

                        
                        {audit}
                     

                    </div>
                </div>
            </div>
        )

    }

    setTabs(i){
        this.setState({
            tab : i
        })
    }

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await file.originFileObj
        }
       
        this.setState({
            // previewImage: file.url || file.preview,
            previewImage: file.response.url,
            previewVisible: true,
        });
    };

    //自检意见
    handleChangeInspection = ({ fileList }) => {
        this.setState({ 
            inspectionList: fileList,
            inspection: this.setImgList(fileList)
         })
    };

    //上传的图片处理
    setImgList(files){ 
        var picture = [];
        if(files.length>0){
            for(let i = 0;i<files.length;i++){
                let item = files[i];
                if(item.response){
                    let pic = new Object;
                        pic.name = item.name;
                        pic.url = item['response']['url'];
                        picture.push(pic);
                }
            }
        }
        //console.log(1234,picture);
        return picture;
    }

    onChangeQualified = (e) => {
        this.setState({
            qualified: e.target.value
        });
    }

    onChangeRemark({ target: { value } }){
        this.setState({
            remark: value
        })
    }

}

export default  Form.create()(DrawingReviewExamineP)


  