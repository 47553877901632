
import axios from '../axios.config'
import {HIDE_LOADING, SHOW_LOADING, hideLoading, showLoading} from './00_common.action'

const REGISTRIES_DATA = 'REGISTRIES_DATA';
//reducer
const initState = {
    registries : [],
    total:0,
    start:0,
    loading: true
}


export function registries(state = initState, action) { 
    switch(action.type){
        case REGISTRIES_DATA:
            return { ...state, 
                registries: action.payload.data,
                total: action.payload.total,
                start: action.payload.start
             }
        case SHOW_LOADING:
            return {
                ...state,
                loading: true,
            }
        case HIDE_LOADING:
             return {
                 ...state,
                 loading: false
             }
        default:
            return state
    }
}

//action creator
/**
 * 获取维保单位列表后，需再用Idin请求区域列表，并重新组合maintainer Data
 */
export function getRegistryData(params){
    // dispatch 用来通知数据修改
    return dispatch => {
        dispatch(showLoading())
        axios.get('/account/registry-requests',{params})
            .then(res=>{
                dispatch(RegistryData(res));
            })
            .finally(()=>{
                dispatch(hideLoading());
            })
    }
}
export function RegistryData(data) {
    return {type: REGISTRIES_DATA, payload: data}
}

export function updateRegistrySuccess() { 
    return dispatch=>dispatch(getRegistryData({start:0}))
}

/** 
 * 非redux管理的function
*/

export  function getCurrentRegistry(id){
    return axios.get(`/account/registry-requests/${id}`)
}


export function updateRegistryRequest(data,edit=false, id){
    if(edit){
        return axios.put(`/account/registry-requests/${id}`,data)
    }else {
        return axios.post('/account/registry-requests',data)
    }
}

export  function deleteCurrentRegistry(id){
    return axios.delete(`/account/registry-requests/${id}`)
}

