import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import axios from '../../axios.config'
import './styles.css'

const { Option } = Select;

@withRouter
class RemoteSelectFilter extends Component {
  constructor(props) {
    super(props);
    this.lastFetchId = 0;
    this.fetchOptions = debounce(this.fetchOptions, 800);
  }

  state = {
    data: [],
    value: undefined,
    fetching: false,
  };

  fetchOptions = value => {
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    this.setState({ data: [], fetching: true });
    let params={
        [this.props.searchKey]:value,
        size: 2000
    }
    if(this.props.additionalParams){
        params={
            ...params,
            ...this.props.additionalParams
        }
    }
    axios.get(this.props.url,{params}).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        const data = res.data;
        this.setState({ data,fetching: false });
      });
  };

  handleChange = value => {
      if(value){
        let currentOption = this.state.data.find(ele=>ele.id === value);
        let currentValue = currentOption.name;
        this.props.onRemoteSelectFilter(currentOption.id);
        this.setState({
          value: currentValue,
          data: [],
          fetching: false,
        });
      }else{
        this.props.onRemoteSelectFilter(undefined);
        this.setState({
            value: undefined
        })
      }
  };

  render() {
    const { fetching, data, value } = this.state;

    return (
      <Select
        showSearch
        showArrow={false}
        value={value}
        placeholder={this.props.placeholder || "Select users"}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        filterOption={false}
        onFocus={this.fetchOptions}
        onSearch={this.fetchOptions}
        onChange={this.handleChange}
        className='remote-select'
        allowClear={true}
      >
        {data.map(d => (
          <Option key={d.id} value={d.id}>{d.name}</Option>
        ))}
      </Select>
    );
  }
}

export default RemoteSelectFilter