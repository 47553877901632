import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {Icon, Input, Form, Radio,Modal, Button, message} from 'antd';
import debounce from 'lodash/debounce';

import EditBar from '../../components/edit-bar/edit-bar'
import addImg from '../../assets/images/add.svg'

import { getBuildingsData,checkBuilding,  updateFloor, updateBuilding, batchAddBuildingRequest, batchAddFloorRequest} from '../../redux/02_buildings.redux'
import { dispatchCurrentProject} from '../../redux/01_project.redux'
import {formItemLayout} from '../../components/layout/formlayout'
const Search = Input.Search;

@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.buildings,
            ...state.projects
        }
    },
    {getBuildingsData,checkBuilding, dispatchCurrentProject}
)
class BuildingNew extends Component{
    constructor(props){
        super(props);
        this.state={
            addType: 'single',
            visible: false
        
        };
        this.saveBuilding = debounce(this.saveBuilding, 1000);
        this.saveFloor = debounce(this.saveFloor, 1000);
        this.batchAddBuilding = debounce(this.batchAddBuilding, 1000);
        this.batchAddFloor = debounce(this.batchAddFloor, 1000);
    }
 
    componentDidMount(){
        this.getDatas();
        this.props.dispatchCurrentProject({});
    }
    // 获取楼栋楼层信息
    getDatas=(buildingId)=>{
        this.props.getBuildingsData({
            size: 2000,
            organizationId: this.props.currentProject.id
        }, buildingId);
    }

    //选择楼栋
    switchBuilding(buildingId){
        this.setState({
            buildingId
        },()=>{
            this.props.checkBuilding(this.props.buildings, buildingId)
        }) 
    }

    // 保存新增的建筑物
    saveBuilding = (data)=>{     
        updateBuilding(data,false).then(res=>{
            this.getDatas(res.id);
            this.handleCancel();
            message.success('创建楼栋成功');
        }).finally(()=>this.setState({iconLoading: false}));
    }
    // 保存新添加的楼层
    saveFloor = (data)=>{     
        updateFloor(data,false).then(res=>{
            this.getDatas(this.state.buildingId);
            this.handleCancel();
            message.success('创建楼层成功')
        }).finally(()=>this.setState({iconLoading: false}));
    }
    // 批量添加建筑物
    batchAddBuilding = (data)=>{     
        batchAddBuildingRequest(data).then(res=>{
            const buildingId = res[0].id;
            this.getDatas(buildingId);
            this.handleCancel();
            message.success('批量创建楼栋成功');
        }).finally(()=>this.setState({iconLoading: false}));
    }
    // 批量添加楼层
    batchAddFloor = (data)=>{
        batchAddFloorRequest(data).then(res=>{
            this.getDatas(this.state.buildingId);
            this.handleCancel();
            message.success('批量创建楼层成功')
        }).finally(()=>this.setState({iconLoading: false}));
    }
    onAddTypeChange = e => {
        this.setState({
          addType: e.target.value,
        });
      };
    onBatchInputChange = inputLabel =>{
        const form = this.props.form;
        const _this = this;
        setTimeout(()=>{
            const value = form.getFieldValue(inputLabel);
            _this.setState({
                [inputLabel]: value
            })
        }, 0)
    }
    handleAdd = e =>{
        e.preventDefault();
        const organizationId = this.props.currentProject.id;
        if(!organizationId){
            message.warn('请先选择所属项目');
        } else {
            let body;
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    const {addName, addType} = this.state;
                    this.setState({iconLoading: true});
                    if (addType === 'single') {
                        if (addName === 'building') {
                            body= {
                                name: values.name,   
                                organizationId                 
                            };
                            this.saveBuilding(body);
                        } else {
                            const buildingId = this.props.buildings.find(ele=>ele.checked).id;
                            body= {
                                name: values.name,
                                buildingId, 
                                organizationId             
                            };
                            this.saveFloor(body);
                        }
                    } else {
                        // 批量添加
                        // 首先要判断结束数字必须大于起始数字
                        // 去除掉0
                        const { suffix, startNum, endNum} = values;
                        const prefix = values.prefix || '';
                        const isValidNum = endNum - startNum > 0
                        if(isValidNum){
                            let arr = [];
                            for (let i=Number(startNum); i<=Number(endNum); i++) {
                                i !== 0 && arr.push(i);
                            };
                            if (addName === 'building') {
                                body = arr.map(num=>`name=${prefix}${num}${suffix}&organizationId=${organizationId}`);
                                this.batchAddBuilding(body);
                            } else {
                                const buildingId = this.props.buildings.find(ele=>ele.checked).id;
                                body = arr.map(num=>`name=${prefix}${num}${suffix}&buildingId=${buildingId}&organizationId=${organizationId}`);
                                this.batchAddFloor(body);
                            }
                        } else {
                            message.warn('结束数字需大于起始数字！')
                        }
                    }
                }
            });
        }

    }
    showModal = (addName) => {
        const modalTitle = addName === 'building' ? '创建楼栋':'创建楼层';
        const modalName = addName === 'building' ? '楼栋名称':'楼层名称'
        this.setState({
          visible: true,
          modalTitle,
          modalName,
          addName
        });
      };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };
    render(){
        const { getFieldDecorator } = this.props.form; 
        const noLabelFormItemLayout = {
            wrapperCol: {
              xs: { span: 24, offset: 24 },
              sm: { span: 16, offset: 8 },
            },
        }; 
        return (
            <div>
                <div className='buildings-floors'>
                    <div className = 'buildings'>
                        <div className='title'>楼栋</div>
                        <div className='building-scroll-view'>
                        {
                            this.props.buildings.map(building=>
                                <div className={building.checked?'building-item item-active':'building-item'} 
                                    key={building.id}
                                    onClick={()=>this.switchBuilding(building.id)}>
                                    <div className='name'>{building.name}</div>
                                </div>
                                 )
                        }
                        <img alt='add' 
                                className='add-building' 
                                src={addImg}
                                onClick={()=>this.showModal('building')}/>              
                        </div>
                    </div>
                    <div className = 'floors'>
                        <div className='title'>楼层</div>
                        <div className='building-scroll-view'>
                            {
                                this.props.floors.map(floor=>
                                    <div 
                                        className='floor-item'
                                        key={floor.id}>
                                        <div className='name'>{floor.name}</div>                                  
                                    </div>
                                )
                            }
                            <img alt='add' 
                                    className='add-building' 
                                    src={addImg}
                                    onClick={()=>this.showModal('floor')}/>
                        </div>
                    </div>
                </div>
                <div className='edit-bar-container'>
                    <EditBar back={true}/>
                </div>
                <Modal
                    title={this.state.modalTitle}
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    footer={null}
                    width={650}
                    className='add-modal'
                    >
                    <div >
                        <div className='edit-form-container-batch-add'>
                           <Form {...formItemLayout}  onSubmit={this.handleAdd}>
                                <Form.Item label='创建类型'>
                                    {getFieldDecorator('addType', {
                                        initialValue:this.state.addType
                                    })( <Radio.Group className='batch-type' onChange={this.onAddTypeChange}>
                                        <Radio value='single'>创建单个</Radio>
                                        <Radio value='batch'>批量创建</Radio>
                                      </Radio.Group>)}
                                </Form.Item>                     
                                {
                                    this.state.addType === 'single' ?
                                    <Form.Item label='名称'>
                                            {getFieldDecorator('name', {
                                                rules: [{ required: true, message: `请输入${this.state.modalName}!` }],
                                            })(<Input  placeholder={`请输入${this.state.modalName}`}/>)}
                                    </Form.Item>
                                    :
                                    <div>
                                        <Form.Item label='名称前缀'>
                                            {getFieldDecorator('prefix', {
                                                // rules: [{ required: true, message: '请输入前缀!' }],
                                            })(<Input  placeholder="请输入名称前缀" onChange={()=>this.onBatchInputChange('prefix')}/>)}
                                        </Form.Item>
                                        <Form.Item label='名称后缀'>
                                            {getFieldDecorator('suffix', {
                                                rules: [{ required: true, message: '请输入名称后缀!' }],
                                            })(<Input  placeholder="请输入名称后缀" onChange={()=>this.onBatchInputChange('suffix')}/>)}
                                        </Form.Item>
                                        <Form.Item label='开始数字'>
                                            {getFieldDecorator('startNum', {
                                                rules: [{ 
                                                    required: true,
                                                    pattern: new RegExp(/^[-]?[1-9]\d*$/, "g"),
                                                    message: '请输入开始数字!' }],
                                                getValueFromEvent: (event) => {
                                                    return event.target.value.replace(/!-\D/g,'')
                                                },
                                            })(<Input  placeholder="请输入开始数字" onChange={()=>this.onBatchInputChange('startNum')}/>)}
                                        </Form.Item>
                                        <Form.Item label='结束数字'>
                                            {getFieldDecorator('endNum', {
                                                rules: [{ 
                                                    required: true,
                                                    pattern: new RegExp(/^[-]?[1-9]\d*$/, "g"),
                                                    message: '请输入结束数字!' }],
                                                getValueFromEvent: (event) => {
                                                    return event.target.value.replace(/!-\D/g,'')
                                                },
                                            })(<Input  placeholder="请输入结束数字" onChange={()=>this.onBatchInputChange('endNum')}/>)}
                                        </Form.Item>
                                        <Form.Item label='示例'>
                                            {getFieldDecorator('example', {
                                                initialValue: `${this.state.prefix||''}${this.state.startNum||''}${this.state.suffix||''}`,
                                            })(<Input disabled placeholder=""/>)}
                                        </Form.Item>
                                        <Form.Item label='' {...noLabelFormItemLayout}>
                                            {getFieldDecorator('tip', {
                                            })(<div className='format-tip'>生成格式为：名称前缀+数字+后缀</div>)}
                                        </Form.Item>
                                    </div> 

                                }
                                <div className='btns-flex flex-end'>
                                    <Button onClick={this.handleCancel} className="modal-btn">
                                        取消
                                    </Button>
                                    <Button type="primary" htmlType="submit" className="modal-btn">
                                        确认
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default  Form.create()(BuildingNew)
