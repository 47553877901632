import React, { Component } from 'react';
import { Switch, Route} from 'react-router-dom';
import ContractList from './contract';
import ContractEdit from './contract.edit'
// import BuildingListGuide from './plan.guide.list'
import HomeLayout from '../../components/layout/home.layout'
import NoneSideBarLayout from '../../components/layout/non-sidebar.layout'

class PlanRouter extends Component {


  render() {
    const listView = <HomeLayout>
                                <ContractList/>
                            </HomeLayout>
    const editView  = <NoneSideBarLayout>
                            <Route exact path='/contracts/add' component={ContractEdit}/>
                            <Route exact path='/contracts/add/:organizationId' component={ContractEdit}/>
                            <Route path='/contracts/edit/:organizationId/:id' component={ContractEdit}/>
                    </NoneSideBarLayout>
    return (
      <Switch>
        <Route exact path='/contracts' render={()=>listView}/>
        <Route path='/contracts' render={()=>editView}/>
      </Switch>
    );
  }
}

export default PlanRouter