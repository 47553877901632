import React, {Component}  from 'react';
import { connect} from 'react-redux'
import { withRouter,Link } from 'react-router-dom'
import moment from 'moment'
import { Badge } from 'antd'

import Header from '../../components/header';
import Table from '../../components/table'
import editImg  from '../../assets/images/edit_white.svg'
import { getRegistryData} from '../../redux/10_registry.redux'
import CustomPagination from '../../components/pagination/pagination'
import {RegistryStatus} from '../../constants/constants'

import './styles.css';
@withRouter
@connect(
    state=>{
        return {...state.auth,...state.registries}
    },
    {getRegistryData}
)
class Registry extends Component {

    componentDidMount(){
        this.props.getRegistryData({
            sort: 'status',
            order: 'asc'
        })
    }

    pageChangeHandler(page) {
        this.props.getRegistryData({
            start: page-1,
            sort: 'status',
            order: 'asc'
        })
    }

    onSearch = (params)=>{
        this.props.getRegistryData({
            ...params,
            sort: 'status',
            order: 'asc'
        });
    }


    render(){
        const filters = [{
            key:'userNameLike',
            placeholder:'姓名'
        },{
            key:'userPhoneLike',
            placeholder:'手机号码'
        },{
            key:'companyNameLike',
            placeholder:'公司名称'
        }];

        const columns = [
            {
              title: '姓名',
              dataIndex: 'userName',
              key: 'userName',
              width: "10%",
            },
            {
              title: '手机号码',
              dataIndex: 'userPhone',
              key: 'userPhone',
              width: "10%",
            },
            {
                title: '公司名称',
                dataIndex: 'companyName',
                key: 'companyName',
                width: "15%",
            },
            {
                title: '公司统一社会信用码',
                dataIndex: 'companyUniCode',
                key: 'companyUniCode',
                width: 215,
            
            },
            {
                title: '申请状态',
                key: 'status',
                dataIndex:'status',
                width: "10%",
                render: (text, record)=>(
                    <span>
                       <Badge status={RegistryStatus.find(ele=>ele.value === record.status).status}/>
                       {RegistryStatus.find(ele=>ele.value === record.status).label}
                    </span>
                ) 
            
            },
            {
                title: '申请时间',
                key: 'requestTime',
                width: "10%",
                render: (text, record)=>(
                    <span>{moment(record.requestTime).format('YYYY-MM-DD HH:mm')}</span>
                )  
            },
            {
                title: '处理时间',
                key: 'proccessTime',
                width: "10%",
                render: (text, record)=>(
                    record.proccessTime && <span>{moment(record.proccessTime).format('YYYY-MM-DD HH:mm')}</span>
                )
            },
            {
                title: '备注',
                dataIndex: 'note',
                key: 'note',
            },
            {
              title: '',
              key: 'action',
              width: 180,
              render: (text, record) => (
                <Link className='edit-action' to={`/registries/edit/${record.id}`}>
                  <img src={editImg} alt='edit'></img>
                   查看修改
                </Link>
              ),
            },
          ];
          

        const app = (
            <div className = 'content'>
                <Header title = '注册申请列表' filters ={filters} onSearch={this.onSearch} statusFilter={true}/>
                <div className='main-table-container'>
                    <Table  scroll={{ y: 640 }}
                            columns={columns} 
                            dataSource={this.props.registries.map(ele=>{return {...ele, key:ele.id}})} 
                            loading={this.props.loading}/>
                    <CustomPagination
                        total={this.props.total}
                        onChange={(e)=>{this.pageChangeHandler(e)}}/>
                </div>
            </div>
        );
        return app
    }
}

export default Registry