import React, {Component}  from 'react';
import TopBarInside from './topbar-inside/topbar-inside';
import SideBarArrow from './sidebar-arrow/sidebar-arrow';
import './layout.css';

class ArrowLayout extends Component {

    componentDidMount(){
        // console.log(this.props)
    }

    render(){
        return (
            <div className=''>
                <TopBarInside projectSelectHide={this.props.projectSelectHide}/>
                <div >
                    <SideBarArrow className='sidebar' path={this.props.path}/>
                    <div className = 'arrow-right'>
                            {this.props.children}
                    </div>
                    
                </div>
            </div>
        )
    }
}

export default ArrowLayout