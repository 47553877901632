import React, { Component } from 'react';
import { Switch, Route} from 'react-router-dom';
import RegionList from './region';
import HomeLayout from '../../components/layout/home.layout'

class RegionRouter extends Component {


  render() {
    const RegionListView = <HomeLayout>
                        <RegionList></RegionList>
                       </HomeLayout>
    return (
      <Switch>
        <Route exact path='/Regions' render={()=>RegionListView}/>
      </Switch>
    );
  }
}

export default RegionRouter;