import axios from 'axios'
import { setStorage, getStorage} from './utils/utils'
import {  notification } from 'antd'
import {logout} from './redux/auth.redux'
import moment from 'moment'

let callLogin = false;

let baseUrl = 'https://api.m.t.zlya.net';


const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

if (!isLocalhost) {
    baseUrl = `https://api${window.location.hostname.substring(window.location.hostname.indexOf('.'))}`
}

export const getHeaders = async (file) => {
    let access_token = getStorage('access_token_maintenance');
    const expireTime = getStorage('expireTime_maintenance');
    if(access_token){
        let isValidToken = moment(new Date()).isBefore(expireTime);
        if(isValidToken){
    
        }else {
           try{
            let res = await getRefreshToken();
            access_token = res.data.access_token;
            setStorage('access_token_maintenance', res.data.access_token);
             let expireTime = moment(new Date()).add(res.data.expires_in,'seconds');
             setStorage('expireTime_maintenance', expireTime);
             setStorage('refresh_token_maintenance', res.data.refresh_token);
           } catch(err) {
                if(!callLogin){
                    warning();
                    callLogin = true;
                } 
           }
        }

        if(file){
            return {
                Authorization: `Bearer ${access_token}`
            }
        }else{
            return {
                "Content-Type": 'application/x-www-form-urlencoded',
                Authorization: `Bearer ${access_token}`
            }
        }
    }else {
        return undefined
    }
}

export const _baseUrl = baseUrl;
//创建axios实例
const service = axios.create({
    baseURL: baseUrl, // api的base_url
    timeout: 60 * 1000, // 请求超时时间
    withCredentials: false, // 选项表明了是否是跨域请求
    transformRequest: [function (data) {
        let ret = ''
        for (let it in data) {
            ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
        }
        return ret
    }],
})

service.interceptors.request.use(async config => {
    config.headers = await getHeaders();//用来处理刷新token后重新请求的自定义变量
    config.isRetryRequest = false;

    return config;
}, err => {
    return Promise.reject(err)
})
// respone拦截器

service.interceptors.response.use(
  response => {
    let statusCode = response.status;
    if (statusCode >= 400) {       
      return Promise.reject('error');
    } else {
        callLogin = false;
      return response.data;
      
    }
  },
  err => {
    let statusCode = err.response && err.response.status;
    // 22 : 未授权
    if(statusCode === 401 || (statusCode === 400 && err.response.data.statusCode === 22)){
        if(!callLogin){
            warning();
            callLogin = true;
        }    
    }

    let error_description = err.response && err.response.data ?( err.response.data.message || err.response.data.error_description) : '';
    if(error_description.indexOf('Access token expired')  !== -1){
        error_description = '登录已过期，请重新登录';
    }
    const args = {
        message: '请求失败',
        description: error_description,
        // duration: 0,
      };
      notification.open(args); 
    return Promise.reject(err)
  }
)
export default service

function warning() {
    let store = require('./index').store;
    store.dispatch(logout())
}

//刷新token的请求方法
function getRefreshToken() {
    //refresh_token存在本地的localstorage
    const refresh_token = getStorage('refresh_token_maintenance')
    let data = {
        grant_type: 'refresh_token',
        refresh_token
    };
    let ret = ''
    for (let it in data) {
        ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
    }
    return axios.request({
        method:'post',
        url: `${baseUrl}/api/account/oauth/token`,
        headers:{
          "Content-Type": 'application/x-www-form-urlencoded',
          Authorization: 'Basic YnJvd3Nlcjo='
        },
        data:ret
      })
}