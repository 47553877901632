import React, {Component}  from 'react';
import { connect} from 'react-redux'
import { withRouter,Link } from 'react-router-dom'
import moment from 'moment'

import add from '../../assets/images/add.svg';

import Header from '../../components/header';
import Table from '../../components/table'
import editImg  from '../../assets/images/edit_white.svg'
import { getLicenseData} from '../../redux/11_license.redux'
import CustomPagination from '../../components/pagination/pagination'
import './styles.css';

@withRouter
@connect(
    state=>{
        return {...state.auth,...state.licenses}
    },
    {getLicenseData}
)
class License extends Component {

    componentDidMount(){
        this.props.getLicenseData(
            {
                sort: 'id',
                order: 'desc'
            }
        )
    }

    pageChangeHandler(page) {
        this.props.getLicenseData({
            start: page-1,
            sort: 'id',
            order: 'desc'
        })
    }

    onSearch = (params)=>{
        this.props.getLicenseData({
            ...params,
            sort: 'id',
            order: 'desc'
        });
    }

    addLicense = ()=>{
        this.props.history.push('/licenses/add')
    }
    render(){
        const filters = [];

        const columns = [
            {
              title: '维保单位',
              dataIndex: 'name',
              key: 'name',
              width: "15%",
            },
            {
                title: '购买人姓名',
                dataIndex: 'buyerName',
                key: 'buyerName',
                width: "12%",
            },
            {
              title: '购买人电话',
              dataIndex: 'buyerPhone',
              key: 'buyerPhone',
              width: "12%",
            },
            {
                title: '购买时间',
                key: 'buyTime',
                width: 250, 
                render: (text, record)=>(
                    <span>{moment(record.buyTime).format('YYYY-MM-DD HH:mm')}</span>
                )  
            },
            {
                title: '有效期',
                key: 'endTime',
                width: 250, 
                render: (text, record)=>(
                    record.endTime && <span>{moment(record.endTime).format('YYYY-MM-DD HH:mm')}</span>
                )
            },
            {
                title: '最大用户数',
                dataIndex: 'maxUserCount',
                key: 'maxUserCount',
            },
            {
              title: '',
              key: 'action',
              width: 180,
              render: (text, record) => (
                <Link className='edit-action' to={`/licenses/detail/${record.id}`}>
                  <img src={editImg} alt='edit'></img>
                   查看详情
                </Link>
              ),
            },
          ];
          

        const app = (
            <div className = 'content'>
                <Header title = '许可列表' filters ={filters} remoteSelectFilters={['organizationId']} flag='licensePage' onSearch={this.onSearch}>
                    <div className='new_org new' onClick={this.addLicense} >
                        <img src={add} alt='add' className='add_img  new'></img>新建许可
                    </div>
                </Header>
                <div className='main-table-container'>
                    <Table  scroll={{ y: 640 }}
                            columns={columns} 
                            dataSource={this.props.licenses.map(ele=>{return {...ele, key:ele.id}})} 
                            loading={this.props.loading}/>
                    <CustomPagination
                        total={this.props.total}
                        onChange={(e)=>{this.pageChangeHandler(e)}}/>
                </div>
            </div>
        );
        return app
    }
}

export default License