/** 
 * 合并所有reducers,并返回
*/

import { combineReducers}  from 'redux'
import { auth } from './redux/auth.redux'
import { region } from './redux/07_region.redux'
import { users } from './redux/06_users.redux'
import {maintainers} from './redux/08_maintainer.redux'
import {projects} from './redux/01_project.redux'
import { buildings } from './redux/02_buildings.redux'
import {plans } from './redux/05_plan.redux'
import {inspections} from './redux/03_inspection.redux'
import {contracts}  from './redux/04_contract.redux'
import { registries } from './redux/10_registry.redux'
import { licenses } from './redux/11_license.redux'
import { drawingReview } from './redux/12_drawing_review.redux'
import { acceptance } from './redux/14_acceptance.redux'
import { drawingReviewP } from './redux/15_drawing_review_p.redux'
import { acceptanceP } from './redux/16_acceptance_p.redux'
import { collection } from './redux/18_collection.redux'
import { message } from './redux/19_message.redux'


export default combineReducers({ auth, region, users, maintainers, projects, buildings, plans, inspections, contracts, registries, licenses, drawingReview, drawingReviewP, acceptance, acceptanceP, collection, message })
