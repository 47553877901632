// 设置本地存储
export function setStorage(name, data) {
	let dataType = typeof data;
	// JSON对象
	if (dataType === 'object') {
		window.localStorage.setItem(name, JSON.stringify(data));
	} else if (['number', 'string', 'boolean'].indexOf(dataType) >= 0) {
		window.localStorage.setItem(name, data);
	} else {
		console.log('该类型不能用于本地存储');
		//   console.log(data)
	}
}
// 取出本地存储内容
export function getStorage(name) {
	let data = window.localStorage.getItem(name);
	if (data) {
		try {
			return JSON.parse(data);
		} catch (err) {
			return data
		}
	} else {
		return '';
	}
}
// 删除本地存储内容
export function removeStorage(name) {
	window.localStorage.removeItem(name);
}

export function getRedirectPath() {

}

//时间戳转时间格式
export function fmtTimes(str) {
	let date = new Date(str);
	let year = date.getFullYear();
	let month = date.getMonth() + 1;
	month = month < 10 ? ('0' + month) : month;
	let day = date.getDate();
	day = day < 10 ? ('0' + day) : day;

	return year + '-' + month + '-' + day;
}

//计算使用时间天数
export function seTimeArea(startDate, endDate, normalDate, warningDate) {
	let now = Date.parse(new Date());
	let start = +new Date(timeFormatSeconds(startDate));
	let end = +new Date(timeFormatSeconds(endDate));
	let nomal = +new Date(timeFormatSeconds(normalDate));
	let warning = +new Date(timeFormatSeconds(warningDate));
	let all_second = parseInt(end) - parseInt(start),
		use_second = now - start,
		all_day = 0,
		use_day = 0,
		now_status = 1;
	if(now>nomal && now<=warning){
		now_status = 2;
	}else if(now>end){
		now_status = 3;
	}
	if(all_second>0){
		all_day = parseInt((all_second/1000)/86400);
	}
	if(use_second>0){
		use_day = parseInt((use_second/1000)/86400);
	}
	let data = {
		now_status: now_status,
		all_day: all_day,
		use_day: use_day
	};
	return data;
}

//状态值
//spa bpa
//sem bem
export function setStatusTxt(status, auditNum, type = 1, from = 1) {
	let txt1 = '',color1 = 1,type1,
		txt2 = '',color2 = 1,type2,
		text;
	if(from==1){
		text = "审";
	}else{
		text = "评";
	}
	if(status==0){
		txt1 = "新";
	}else if(status==1){
		txt1 = "待接收";
		color1 = 2;
	}else if(status==2){
		if(auditNum==1){
			txt1 = `初${text}中`;
		}else{
			txt1 = `复${text}中`;
		}
		color1 = 2;
	}else if(status==3){
		txt1 = "合格";
		color1 = 3;
	}else if(status==4){
		txt1 = "不合格";
		color1 = 4;
	}
	if(auditNum>=1){
		txt2 = `${changeNumToHan(auditNum)}次`;
		color2 = auditNum;
	}
	if(type==1){
		type1 = 'spa';
		type2 = "sem";
	}else if(type==2){
		type1 = 'bpa';
		type2 = "bem";
	}
	let name1 = `${type1} ${type1}${color1}`,
		name2 = `${type2} ${type2}${color2}`;
	let data = {
		txt1: txt1,
		txt2: txt2,
		name1: name1,
		name2: name2
	};
	return data;
	//console.log(7890,status, auditNum,txt1,txt2,name1,name2);	
}

//带T的时间字符格式化
export function timeFormatSeconds(time, type = 1) {
	// var d = time ? new Date(time) : new Date();
	if(time){
		var d = new Date(time);
		var year = d.getFullYear();
		var month = d.getMonth() + 1;
		var day = d.getDate();
		var hours = d.getHours();
		var min = d.getMinutes();
		var seconds = d.getSeconds();

		if (month < 10) month = '0' + month;
		if (day < 10) day = '0' + day;
		if (hours < 0) hours = '0' + hours;
		if (min < 10) min = '0' + min;
		if (seconds < 10) seconds = '0' + seconds;
		if (type == 1) {
			return (year + '-' + month + '-' + day);
		} else {
			return (year + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + seconds);
		}
	}else{
		return false;
	}
}


export function changeNumToHan(num) {
	var arr1 = ['零', '首', '二', '三', '四', '五', '六', '七', '八', '九']
	var arr2 = ['', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千', '万', '十', '百', '千', '亿']
	if (!num || isNaN(num)) return '零'
	var english = num.toString().split('')
	var result = ''
	for (var i = 0; i < english.length; i++) {
		var des_i = english.length - 1 - i// 倒序排列设值
		result = arr2[i] + result
		var arr1_index = english[des_i]
		result = arr1[arr1_index] + result
	}
	result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十') // 将【零千、零百】换成【零】 【十零】换成【十】
	result = result.replace(/零+/g, '零') // 合并中间多个零为一个零
	result = result.replace(/零亿/g, '亿').replace(/零万/g, '万') // 将【零亿】换成【亿】【零万】换成【万】
	result = result.replace(/亿万/g, '亿') // 将【亿万】换成【亿】
	result = result.replace(/零+$/, '') // 移除末尾的零
	// 将【一十】换成【十】
	result = result.replace(/^一十/g, '十')
	return result
}

// 验证手机号码是否有效
export function isPhoneAvailable(phone) {
	let myreg = /^1[0-9]{10}$/;
	if (!myreg.test(phone)) {
		return false;
	} else {
		return true;
	}
}

export function isPhoneAndMobileAvailable(phone) {
	let myreg = /^1[0-9]{10}$/;
	let reg2 = /^([0-9]|[-])+$/g;
	if (!myreg.test(phone) && !reg2.test(phone)) {
		return false;
	} else {
		return true;
	}
}