import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {Form,Input,DatePicker, message,Spin,Select, Badge} from 'antd';
import moment from 'moment'

import EditBar from '../../components/edit-bar/edit-bar';

import { getCurrentRegistry, updateRegistryRequest, deleteCurrentRegistry} from '../../redux/10_registry.redux'
import {formItemLayout} from '../../components/layout/formlayout'
import {RegistryStatus} from '../../constants/constants'

const Option = Select.Option;

@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.region
        }
    },
)
class RegistryEdit extends Component{
    constructor(props){
        super(props);
        this.state = {
            regionIds:[],
            edit: false,
            picture:null,
            loading: false,
            iconLoading: false
        };
    }

    componentDidMount(){

        if(Object.keys(this.props.match.params).length){
            let id = this.props.match.params.id;
            this.setState({loading: true});


            getCurrentRegistry(id).then(res=>{
                this.setState({
                    ...res,
                    id, edit: true,
                    loading: false
                });
            })
        }
    }

    handleSubmit = e => {
        
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let body = {};
                Object.keys(values).forEach(key=>{
                    if(values[key]){
                        if(key.indexOf('Time') > -1 ){
                            body[key] = new Date(values[key]).toISOString();
                        } else {
                            body[key] = values[key];
                        }
                    }else{
                        body[key] = '';
                    }
                })
                delete body.proccessTime;
                this.setState({  iconLoading: true });
                updateRegistryRequest(body,this.state.edit, this.state.id).then(()=>{
                    this.props.history.goBack();
                }).finally(()=>{
                    this.setState({
                        iconLoading: false
                    })
                })
            }
        });
    };


    deleteCurrentRegistry = ()=>{
        deleteCurrentRegistry(this.state.id).then(()=>{
            message.success('删除注册申请成功');
            this.props.history.goBack();
        })
    }
    
    
    render(){
        const { getFieldDecorator } = this.props.form; 

        return (
            <div className='edit-content'>
                <EditBar onSave = {this.handleSubmit} save={true} iconLoading={this.state.iconLoading} back={true} delete={true} onDelete={this.deleteCurrentRegistry}/>
                <div className='edit-form-container2 edit-form-container2-half'>
                    {
                        this.state.loading?
                        <div className="spin-container-half">
                            <Spin  size="large" tip="正在加载..."/>
                        </div>
                        :   
                        <div>
                           <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                                <Form.Item label='姓名'>
                                {getFieldDecorator('userName', {
                                    initialValue: this.state.userName,
                                    // rules: [{ required: true, message: '请输入姓名', }],
                                })(<Input  placeholder="姓名"/>)}
                                </Form.Item>
                                <Form.Item label='手机号码'>
                                {getFieldDecorator('userPhone', {
                                    initialValue: this.state.userPhone,
                                    // rules: [{ required: true, message: '请输入手机号码!' }],
                                })(<Input placeholder="手机号码"/>)}
                                </Form.Item>
                                <Form.Item label='公司名称'>
                                {getFieldDecorator('companyName', {
                                    initialValue: this.state.companyName,
                                    // rules: [{ required: true, message: '请输入公司名称!' }],
                                })(<Input  placeholder="公司名称"/>)}
                                </Form.Item>
                                <Form.Item label='统一社会信用码'>
                                {getFieldDecorator('companyUniCode', {
                                    initialValue: this.state.companyUniCode,
                                    // rules: [{ required: true, message: '请输入统一社会信用码!' }],
                                })(<Input  placeholder="统一社会信用码"/>)}
                                </Form.Item>
                                <Form.Item label='备注'>
                                {getFieldDecorator('note', {
                                    initialValue: this.state.note,
                                    // rules: [{ required: true, message: '备注!' }],
                                })(<Input  placeholder="备注信息"/>)}
                                </Form.Item>
                                
                                <Form.Item label='申请时间'>
                                {getFieldDecorator('requestTime', {
                                    initialValue: this.state.requestTime ? moment(this.state.requestTime):null,
                                    // rules: [{ required: true, message: '请选择申请时间!' }],
                                })(<DatePicker showTime placeholder="申请时间" disabled/>)}
                                </Form.Item>
                                <Form.Item label='处理时间'>
                                {getFieldDecorator('proccessTime', {
                                    initialValue: this.state.proccessTime?moment(this.state.proccessTime):null,
                                    // rules: [{ required: true, message: '请选择处理时间!' }],
                                })(<DatePicker showTime placeholder="处理时间" disabled/>)}
                                </Form.Item>
                                <Form.Item label='申请状态'>
                                    {getFieldDecorator('status', {
                                        initialValue: this.state.status,
                                        // rules: [{ required: true, message: '请选择申请状态!' }],
                                    })(
                                        <Select>
                                        {
                                            RegistryStatus.map(ele=>
                                            <Option value={ele.value} key={ele.key}>
                                              <Badge status={ele.status}/>{ele.label}
                                            </Option>)
                                        }
                                    </Select>
                                    )}
                                </Form.Item>
                            </Form>
                        </div>             
                    }
                </div>
            </div>
        )
    }
}

export default  Form.create()(RegistryEdit)
