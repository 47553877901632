import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {Form,Input,Cascader, Upload, message,Spin,DatePicker} from 'antd';
import moment from 'moment'

import EditBar from '../../components/edit-bar/edit-bar';
import picUpload from '../../assets/images/pic_update.svg'

import { getCurrentMaintainer, updateMaintainerRequest, deleteCurrentMaintainer} from '../../redux/08_maintainer.redux'
import { getRegionData } from '../../redux/07_region.redux'
import { getRegionById, addMarker} from '../../redux/00_common.action'

import {formItemLayout} from '../../components/layout/formlayout'
import{ getHeaders, _baseUrl} from '../../axios.config'

const BMap = window.BMap;
const Search = Input.Search;
let map;

@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.region
        }
    },
    {getRegionData}
)
class MaintainerEdit extends Component{
    constructor(props){
        super(props);
        this.state = {
            regionIds:[],
            edit: false,
            picture:null,
            loading: false,
            headers:{},
            addrValue:'',
            iconLoading: false
        };
    }

    componentWillMount(){
        this.updateHeaders();
    }

    updateHeaders = async()=>{
        let headers = await getHeaders(true);
        this.setState({
            headers
        })
    }

    componentDidMount(){
        this.props.getRegionData({
            size: 5000
        });

        if(Object.keys(this.props.match.params).length){
            let id = this.props.match.params.id;
            this.setState({loading: true});


            getCurrentMaintainer(id).then(res=>{
                this.setState({
                    ...res,
                    id, edit: true
                },()=>this.addMap(res.longitude,res.latitude));
                
                // 获取三级region信息,用户回填region
                let regionIds = [res.regionId]
                getRegionById(res.regionId).then(resp=>{
                    regionIds.unshift(resp.superiorId);
                    getRegionById(resp.superiorId).then(region2=>{
                        if(region2.superiorId){
                            regionIds.unshift(region2.superiorId);
                        }
                        this.setState({regionIds})
                    })
                }).finally(()=>{
                    this.setState({loading: false})
                })
            })
        }else {
            this.addMap(104.080439,30.655875)
        }
    }

    handleSubmit = e => {
        
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const regionId = values.regionId[values.regionId.length -1];
                let body = {
                    ...values,
                    regionId,
                    
                }
                if(this.state.picture){
                    body={
                        ...body,
                        picture: this.state.picture
                    }
                }
                delete body._img;
                this.setState({iconLoading: true})
                updateMaintainerRequest(body,this.state.edit, this.state.id ).then(()=>{
                    this.props.history.goBack();
                }).finally(()=>this.setState({iconLoading: false}));
            }
        });
    };

    addMap = (lng,lat)=>{
        map = new BMap.Map("l-map");  
        map.centerAndZoom(new BMap.Point(lng,lat), this.state.edit?13:11);
        map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
        map.addControl(new BMap.NavigationControl());    
        map.addControl(new BMap.ScaleControl());    
        map.addControl(new BMap.OverviewMapControl());   
        if(this.state.edit){
            addMarker(map,lng,lat,this.state.name)
        } 
    }

    onAddrInputChange=(value)=>{
        this.searchLocation(value)
    }


    searchLocation=(key)=>{
        let _this = this;
        this.setState({
            addrValue:key
        });

        let local = new BMap.LocalSearch(map, {
            renderOptions:{map: map, panel:"r-result"},
            pageCapacity:5,
            onInfoHtmlSet: function(poi, HTMLElement){
                const trs = HTMLElement.querySelectorAll('tr');
                if(trs[1]) trs[1].remove();

                let td = document.createElement('td');
                td.setAttribute('width', '20%')
                let seclectBtn = document.createElement('button');
                seclectBtn.innerHTML = '选择';
		        seclectBtn.className = 'map-select-button';
		        td.appendChild(seclectBtn);
                HTMLElement.querySelector('tr') && HTMLElement.querySelector('tr').appendChild(td);

                seclectBtn.addEventListener('click',()=>{
                    map.closeInfoWindow();
                    _this.setState({
                       latitude: poi.point.lat,
                       longitude: poi.point.lng,
                   });
                    _this.props.form.setFieldsValue({
                      latitude: poi.point.lat,
                      longitude: poi.point.lng,
                      detailAddr: poi.address
                   });
                //    map.clearOverlays();
                //    local.clearResults();
              });       

            }
        });
        
        if (key.trim().length) {
            local.search(key);
        }else{
            
            map.clearOverlays();
            local.clearResults();
        }

    }

    onRegionChange=(regionIds)=>{
        const regionId = regionIds[regionIds.length -1];
        const currentRegion = this.props.raw_regions.find(ele=>ele.id === regionId);
        map.centerAndZoom(new BMap.Point(currentRegion.longitude,currentRegion.latitude), 13);
        this.setState({
            longitude:null,
            latitude:null
        })
    }

    deleteCurrentMaintainer = ()=>{
        deleteCurrentMaintainer(this.state.id).then(()=>{
            message.success('删除维保单位成功');
            this.props.history.goBack();
        })
    }
    
    
    render(){
        const { getFieldDecorator } = this.props.form; 
        const _this = this;  
        const props = {
            name: 'file',
            showUploadList: false,
            action: `${_baseUrl}/resource/resources/put-oss`,
            headers: _this.state.headers,
            onChange(info) {
              if (info.file.status === 'done') {
                message.success(`${info.file.name} 上传成功`);
                _this.setState({
                    picture: info.fileList[info.fileList.length-1].response.url
                })
              } else if (info.file.status === 'error') {
                message.error(`${info.file.name} 上传失败`);
              }
            },
            beforeUpload:beforeUpload
          };

        return (
            <div className='edit-content'>
                <EditBar onSave = {this.handleSubmit} save={true} iconLoading={this.state.iconLoading} back={true} delete={this.state.edit} onDelete={this.deleteCurrentMaintainer}/>
                <div className='edit-form-container edit-form-container-half'>
                    {
                        this.state.loading?
                        <div className="spin-container-half">
                            <Spin  size="large" tip="正在加载..."/>
                        </div>
                        :   
                        <div>
                            <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                                
                            <Form.Item label='单位图片'>
                                {getFieldDecorator('_img', {})(
                                    <div>                              
                                     <Upload {...props}>
                                         <img src={this.state.picture || picUpload} alt='upload' className='upload-img'/>
                                     </Upload>                           
                                 </div>
                                )}
                                </Form.Item>
                                <Form.Item label='单位名称'>
                                {getFieldDecorator('name', {
                                    initialValue: this.state.name,
                                    rules: [
                                    {
                                        required: true,
                                        message: '单位名称不能为空',
                                    },
                                    ],
                                })(<Input  placeholder="请输入单位名称"/>)}
                                </Form.Item>
                                <Form.Item  label='所属区域'>
                                {getFieldDecorator('regionId', {
                                    initialValue: this.state.regionIds.length? this.state.regionIds : [1, 2],
                                    rules: [
                                    { type: 'array', required: true, message: '请选择所属区域!' },
                                    ],
                                })(<Cascader options={this.props.regions} onChange={this.onRegionChange} placeholder="请选择所属区域" expandTrigger="hover"/>)}
                                </Form.Item>
                                <Form.Item label='详细地址'>
                                {getFieldDecorator('detailAddr', {
                                    initialValue: this.state.detailAddr,
                                    rules: [{ required: true, message: '详细地址不能为空!' }],
                                })(<Input placeholder="请输入详细地址"  onChange={e=>this.onAddrInputChange(e.target.value)}/>)}
                                </Form.Item>
                                <Form.Item label='联系电话'>
                                {getFieldDecorator('phone', {
                                    initialValue: this.state.phone,
                                    rules: [{ required: true, message: '联系电话不能为空!' }],
                                })(<Input  placeholder="请输入联系电话"/>)}
                                </Form.Item>
                                <Form.Item label="经度">
                                {getFieldDecorator('longitude', {
                                    initialValue: this.state.longitude,
                                    rules: [{ required: true, message: '请在右侧地图选择正确的经纬度坐标，不正确的坐标将无法进行工单处理！' }],
                                })(<Input  placeholder="经度"/>)}
                                </Form.Item>
                                <Form.Item label='纬度'>
                                {getFieldDecorator('latitude', {
                                    initialValue: this.state.latitude,
                                    rules: [{ required: true, message: '请在右侧地图选择正确的经纬度坐标，不正确的坐标将无法进行工单处理！' }],
                                })(<Input  placeholder="纬度"/>)}
                                </Form.Item>
                                <Form.Item label='统一社会信用码'>
                                {getFieldDecorator('uniCode', {
                                    initialValue: this.state.uniCode,
                                    rules: [{ required: true, message: '统一社会信用码不能为空!' }],
                                })(<Input  placeholder="请输入统一社会信用码"/>)}
                                </Form.Item>
                                {
                                    this.state.edit?
                                    <div>
                                        <Form.Item label='最大用户数'>
                                            {getFieldDecorator('maxUserCount', {
                                                initialValue: this.state.maxUserCount,
                                            })(<Input disabled/>)}
                                        </Form.Item>
                                        <Form.Item label='有效期'>
                                        {getFieldDecorator('endTime', {
                                            initialValue: this.state.endTime ? moment(this.state.endTime):null,
                                        })(<DatePicker showTime disabled placeholder/>)}
                                        </Form.Item>
                                    </div>
                                    :null
                                }
                            </Form>
                        </div>             
                    }
                </div>
                <div className='mapContainer'>
                    <div className='search-container'>
                        <Search
                            placeholder="输入地址获取坐标"
                            enterButton="搜索"
                            size="large"
                            value={this.state.addrValue}
                            onChange={e => this.searchLocation(e.target.value)}
                            onSearch={value => this.searchLocation(value)}
                            />
                        <div id="r-result"></div>
                    </div>
                    <div id="l-map"></div>
                </div>
            </div>
        )
    }
}

export default  Form.create()(MaintainerEdit)


function beforeUpload(file) {
  const isJPG = file.type === 'image/jpeg';
  const isPNG = file.type === 'image/png';
  if (!isJPG&&!isPNG){
    message.error('只能上传JPG/PNG类型的文件!');
  }
  return isJPG || isPNG;
}

