import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'
import {Input, message, Icon, Checkbox} from 'antd'
import debounce from 'lodash/debounce';

import EditBar from '../../components/edit-bar/edit-bar'
import successImg from '../../assets/images/success.svg'
import unCheckImg from '../../assets/images/select_normal.svg'
import addImg from '../../assets/images/add.svg'

import {getMaintenanceItems,onClickSelectAll, checkMaintenanceItem, 
    batchAddMaintenanceItemRequest, batchAddMaintenanceContentRequest,
    batchDeleteMaintenanceItemRequest, batchDeleteMaintenanceContentRequest,
    updateItemAndContentState
} from '../../redux/03_inspection.redux'
import {getCurrentProject, dispatchCurrentProject} from '../../redux/01_project.redux'

import { setStorage, getStorage } from '../../utils/utils'

// import {MAINTAINCE_TYPES} from '../../constants/constants'
//维保类型
export const MAINTAINCE_TYPES = [{
        key: 'MAINTAINCE_TYPE_INSPECTION',
        value: 1,
        label: '巡查内容',
        item_label: '巡查类别',
        item_label2:'巡查项目'
    },
    {
        key: 'MAINTAINCE_TYPE_TEST',
        value: 2,
        label: '检测内容',
        item_label: '检测类别',
        item_label2:'检测项目'
    },
    {
        key: 'MAINTAINCE_TYPE_MAINTENANCE',
        value: 3,
        label: '保养内容',
        item_label: '保养类别',
        item_label2:'保养项目'
    }
]

const Search = Input.Search;

@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.inspections,
            ...state.projects
        }
    },
    {getMaintenanceItems,onClickSelectAll,  checkMaintenanceItem, dispatchCurrentProject, updateItemAndContentState}
)
class InspectionContent extends Component{
    constructor(){
        super();
        this.state={
            currentItemIndex:0,
            enterButtonType: 'check',
            iconLoading: false,
            showAllItems: false
        };
        this.saveItem = debounce(this.saveItem, 2000);
        this.saveContent = debounce(this.saveContent, 2000);
    }

    componentDidMount(){
        const {type, organizationId} = this.props.match.params;

        // 获取当前项目信息
        getCurrentProject(organizationId).then(res=>{
            this.props.dispatchCurrentProject(res);
        })
        const showAllItems = getStorage(`SHOW_ALL_ORG_MAINTENANCE_ITEMS_${type}`) ? true : false;
        this.setState({
            showAllItems
        })
        this.getDatas(showAllItems);
    }

    getDatas(showAllItems){
        //当“显示全部”勾选时，获取预定义的那个接口调用参数改为：predefine_OR_organizationIdIn=[true,[1,3,4]]。其中 organizationId是可以获取到的所有联网用户的id
        const {type, organizationId, inspectionPointId} = this.props.match.params;
        const organizationIds = this.props.projects.map(ele=>ele.id)
        this.props.getMaintenanceItems({type,inspectionPointId, organizationId,size: 500, showAllItems, organizationIds});
    }

    //选择类别
    switchMaintenanceItem(e, itemName){
        e.stopPropagation();
        this.props.checkMaintenanceItem(this.props.maintenanceItems, itemName)
    }
    //选择类别下属内容
    switchMaintenanceItemContent(itemName, content){
        this.props.checkMaintenanceItem(this.props.maintenanceItems, itemName, content, 'content')
    }

    // 点击类别，仅用于改变右侧栏内容的显示
    clickMaintenanceItem(index){
        this.setState({
            currentItemIndex: index
        })
    }
    showAddNewItem=(status)=>{
        this.setState({
            showAddNewItem: status
        })
    }

    showAddNewContent=(status)=>{
        this.setState({
            showAddNewContent: status
        })
    }

    //保存新的维保项目
    saveItem =  name =>{
        //维保项目必须先保存
        if(name.trim().length){
            this.setState({
                enterButtonType: 'loading'
            },()=>{
                let originItemName = this.props.originMaintenanceItems.map(ele=>ele.name);
                if(originItemName.indexOf(name) === -1){
                    const {organizationId,inspectionPointId, type} = this.props.match.params;
                    let data = {
                        name,
                        type,
                        inspectionPointId,
                        organizationId
                    }
                    this.props.updateItemAndContentState(data, 'item', this.props.maintenanceItems, this.props.originMaintenanceItems);
                    this.showAddNewItem(false);
                } else {
                    message.warn('已存在相同名称的维保项目');
                }

                this.setState({
                    enterButtonType: 'check'
                });
            });
            
        }
    }

    //保存新的维保内容
    saveContent= content =>{
        //维保内容只是临时更改状态，可在点击保存时再更新
        if(content.trim().length){


            //TODO 当前未起到loading效果
            this.setState({
                enterButtonType: 'loading'
            },()=>{
                let originContentsContent = [];
                this.props.originMaintenanceItems.forEach(ele=>originContentsContent = originContentsContent.concat(ele.contents.map(ele=>ele.content)));
                if(originContentsContent.indexOf(content) === -1){
                    let data = {
                        content,
                        itemName: this.props.maintenanceItems[this.state.currentItemIndex].name
                    }
                    this.props.updateItemAndContentState(data, 'content', this.props.maintenanceItems, this.props.originMaintenanceItems);
                    this.showAddNewContent(false);
                    
                }else {
                    message.warn('已存在相同的维保内容');
                }
                this.setState({
                    enterButtonType: 'check'
                });
            });

                       
        } 
    }

    // 勾选全部
    /**
     *  点击勾选全部，
     * 1. 改变点击后的状态
     * 2. 当前所属的项目
     * 3. 原maintenanceItems
     * @memberof InspectionContent
     */
    selectAllContents=()=>{
        this.props.onClickSelectAll(this.props.maintenanceItems, this.state.currentItemIndex)
    }
    //更新巡查点的维保项目和维保内容信息
    //
    onUpdateItemsAndContents = ()=>{
        // 构建队列
        // function queue(arr) {
        //     let sequence = Promise.resolve();
        //     arr.forEach(function (item) {
        //         sequence = sequence.then(item)
        //     })
        //     return sequence
        // }

        let {originMaintenanceItems, maintenanceItems} = this.props;
        const {type, organizationId, inspectionPointId} = this.props.match.params;
        let originItemName = originMaintenanceItems.map(ele=>ele.name);
        //1. 获取新添加的项目
        let newItems = maintenanceItems.filter(ele=>ele.checked)
                                        .map(ele=>ele.name)
                                        .filter(ele=> originItemName.indexOf(ele) === -1)
                                        .map(ele=>`name=${ele}&type=${type}&organizationId=${organizationId}&inspectionPointId=${inspectionPointId}`);
        
        if(newItems.length){
            this.setState({iconLoading: true});
            batchAddMaintenanceItemRequest(newItems).then(res=>{
                let items = originMaintenanceItems.concat(res);
                let originContentsContent = [], newContentsContent = [];
                originMaintenanceItems.forEach(ele=>originContentsContent=originContentsContent.concat(ele.contents.map(ele=>ele.content)));
                maintenanceItems.filter(ele=>ele.checked)
                                .forEach(ele=>newContentsContent = newContentsContent.concat(ele.contents.filter(ele=>ele.checked)));
                newContentsContent = newContentsContent.filter(ele=>originContentsContent.indexOf(ele.content) === -1)
                                                        .map(ele=>{
                                                            let itemId = items.find(item=>item.name === ele.itemName).id;
                                                            return `content=${ele.content}&itemId=${itemId}`
                                                        })
                

                if(newContentsContent.length){
                    return batchAddMaintenanceContentRequest(newContentsContent)
                }else{
                    return Promise.resolve()
                }
            }).then(()=>{
                // 3. 最后判断是否有需要删除的项目和内容
                let deletePromises = this.deleteMaintenancePromise(originMaintenanceItems, maintenanceItems);
                if(Object.keys(deletePromises).length){
                    if(deletePromises.deprecatedContentIds){
                        // 既有维保内容，又有维保项目，先删除维保内容，再删除维保项目
                        batchDeleteMaintenanceContentRequest(deletePromises.deprecatedContentIds).then(()=>{
                            if(deletePromises.deprecatedItemIds){
                                batchDeleteMaintenanceItemRequest(deletePromises.deprecatedItemIds)
                                    .then(()=>this.onUpdateItemsAndContentsSuccess())
                                    .finally(()=>this.setState({iconLoading: false}));
                            } else {
                                this.onUpdateItemsAndContentsSuccess();
                                this.setState({iconLoading: false})
                            }
                        })
                    }else if(deletePromises.deprecatedItemIds){
                        // 只有维保项目
                        batchDeleteMaintenanceItemRequest(deletePromises.deprecatedItemIds)
                            .then(()=>this.onUpdateItemsAndContentsSuccess())
                            .finally(()=>this.setState({iconLoading: false}));
                    }
                }else{
                    this.onUpdateItemsAndContentsSuccess();
                }
            })
        }else{

            // 2. 没有新项目，但是可能有新内容  
            let originContentsContent = [], newContentsContent = [];
            originMaintenanceItems.forEach(ele=>originContentsContent = originContentsContent.concat(ele.contents.map(ele=>ele.content)));
            maintenanceItems.filter(ele=>ele.checked)
                            .forEach(ele=>newContentsContent = newContentsContent.concat(ele.contents.filter(ele=>ele.checked)));

            // 当前只根据维保内容的字符串进行判断，存在漏洞
            newContentsContent = newContentsContent.filter(ele=>originContentsContent.indexOf(ele.content) === -1)
                                                    .map(ele=>{
                                                        let itemId = originMaintenanceItems.find(item=>item.name === ele.itemName).id;
                                                        return `content=${ele.content}&itemId=${itemId}`
                                                    })
   

            let deletePromises = this.deleteMaintenancePromise(originMaintenanceItems, maintenanceItems);
            this.setState({iconLoading: true});
            Promise.resolve().then(()=>{
                if(newContentsContent.length){
                    return batchAddMaintenanceContentRequest(newContentsContent)
                }
            })
            .then(()=>{
                if(Object.keys(deletePromises).length){
                    if(deletePromises.deprecatedContentIds){
                        batchDeleteMaintenanceContentRequest(deletePromises.deprecatedContentIds).then(()=>{
                            if(deletePromises.deprecatedItemIds){
                                batchDeleteMaintenanceItemRequest(deletePromises.deprecatedItemIds)
                                 .then(()=>this.onUpdateItemsAndContentsSuccess())
                                 .finally(()=>this.setState({iconLoading: false}))
                            } else {
                                this.onUpdateItemsAndContentsSuccess();
                                this.setState({iconLoading: false})
                            }
                        })
                    }else if(deletePromises.deprecatedItemIds){
                        batchDeleteMaintenanceItemRequest(deletePromises.deprecatedItemIds)
                            .then(()=>this.onUpdateItemsAndContentsSuccess())
                            .finally(()=>this.setState({iconLoading: false}));
                    }
                }else{
                    this.onUpdateItemsAndContentsSuccess();
                }
            })


        }
    }

    onUpdateItemsAndContentsSuccess=()=>{
        message.success('更新维保内容成功');
        this.getDatas(this.state.showAllItems);
        this.props.history.goBack();
    }

    // 删除维保项目和维保内容
    deleteMaintenancePromise=(originMaintenanceItems, maintenanceItems)=>{
        let deprecatedItemIds = [], deprecatedContentIds =[];
        let originContents =[];
        // 原先被checked的所有contents
        originMaintenanceItems.forEach(ele=>originContents = originContents.concat(ele.contents));
        maintenanceItems.forEach(newItem =>{
            if(!newItem.checked && originMaintenanceItems.find(ele=>ele.id === newItem.id)) {
                deprecatedItemIds.push(newItem.id)
            }

            //获取需要删除的巡查内容
            newItem.contents.forEach(newContent =>{
                if(!newContent.checked && originContents.find(ele=>ele.id === newContent.id)){
                    deprecatedContentIds.push(newContent.id)
                }
            })
        });
        let deletePromises ={};
        // deprecatedContentIds.length && deletePromises.push(
        //     new Promise((resolve, reject)=>{
        //         batchDeleteMaintenanceContentRequest(deprecatedContentIds).then(()=>resolve(),err=>reject())
        //     })
        // );
        // deprecatedItemIds.length && deletePromises.push(
        //     new Promise((resolve, reject)=>{
        //         batchDeleteMaintenanceItemRequest(deprecatedItemIds).then(res=>resolve(),err=>reject())
        //     })
        // );
        if(deprecatedContentIds.length){
            deletePromises['deprecatedContentIds'] = deprecatedContentIds;
        }
        if(deprecatedItemIds.length){
            deletePromises['deprecatedItemIds'] = deprecatedItemIds;
        }
        return deletePromises;
    }

    // 20200407
    onShowAllOrgMaintenanceItemsChange = e => {
        const {type} = this.props.match.params;
        const showAllItems = e.target.checked;
        setStorage(`SHOW_ALL_ORG_MAINTENANCE_ITEMS_${type}`, showAllItems);
        this.setState({
            showAllItems
        });
        this.getDatas(showAllItems)
    }
    
    render(){
        // console.log(this.props)
        return (
            <div className='inspection-content-page'>
                <div className='inspection-content-edit'>
                    <div className='items'>
                        <div className='items-title'>
                            <Checkbox
                                checked={this.state.showAllItems} 
                                className='items-title item-title-with-showAll' 
                                onChange={this.onShowAllOrgMaintenanceItemsChange}>显示全部
                            </Checkbox>
                            {
                                MAINTAINCE_TYPES.find(ele=>Number(ele.value) === Number(this.props.match.params.type)).item_label2
                            }
                        </div>
                        <div className='items-flow-wrapper'>
                            <div className='flow'>
                                {
                                    this.props.maintenanceItems.map((ele,index)=><div key={ele.name} className={this.state.currentItemIndex === index?'item2 item2-active':'item2'}
                                                                    onClick = {()=>{this.clickMaintenanceItem(index)}}>
                                                                    <img className='item-success' 
                                                                        alt='checked'
                                                                        src={ele.checked? successImg: unCheckImg}
                                                                        // onClick = {(e)=>{this.switchMaintenanceItem(e,ele.name)}}
                                                                        />
                                                                    <span>{ele.name}</span>
                                                                </div>)
                                }
                                {
                                    this.state.showAddNewItem? 
                                    <div className='input-confirm input-confirm-item'>
                                        <Search
                                            enterButton={<Icon type={this.state.enterButtonType}/>}
                                            size="large"
                                            onSearch={(value)=>{this.saveItem(value)}}
                                        />
                                    </div> : null
                                }
                                <img alt='add' 
                                    className='add-content' 
                                    src={addImg} 
                                    onClick={()=>this.showAddNewItem(true)}/>  
                            </div>  
                        </div>                    
                    </div>
                    <div className='contents'>
                        <div className='items-title'>{
                            MAINTAINCE_TYPES.find(ele=>Number(ele.value) === Number(this.props.match.params.type)).label
                        }</div>
                        <div className='contents-flow-wrapper'>
                            <div className='flow'>
                                {
                                    this.props.maintenanceItems.length&&this.props.maintenanceItems[this.state.currentItemIndex].contents.length?
                                    <div className={'item3'}>
                                        <img className='item-success' 
                                            src={this.props.maintenanceItems[this.state.currentItemIndex].checkAll?successImg:unCheckImg}
                                            alt='unchecked'
                                            onClick = {()=>{this.selectAllContents()}}/>
                                        <span className='select-all'>勾选全部</span>
                                    </div> : null
                                }
                                {
                                    this.props.maintenanceItems.length?this.props.maintenanceItems[this.state.currentItemIndex].contents.map((ele,index)=>
                                    <div key={ele.content} 
                                        className={'item3'}>
                                        <img className='item-success' 
                                            alt='checked'
                                            src={ele.checked? successImg: unCheckImg}
                                            onClick = {()=>{this.switchMaintenanceItemContent(ele.itemName, ele.content)}}/>
                                        <span>{ele.content}</span>
                                    </div>) : null
                                }  
                                {this.state.showAddNewContent? 
                                
                                    <div className='input-confirm input-confirm-content'>
                                        <Search
                                            enterButton={<Icon type={this.state.enterButtonType}/>}
                                            size="large"
                                            onSearch={value=>{this.saveContent(value)}}
                                        />
                                    </div> : null}
                                <img alt='add' 
                                    className='add-content2' 
                                    src={addImg} 
                                    onClick={()=>this.showAddNewContent(true)}/>
                            </div> 
                        </div>                 
                    </div>
                </div>
                <EditBar back={true} save={true} iconLoading={this.state.iconLoading} onSave={this.onUpdateItemsAndContents}/>
            </div>
        )
    }
}

export default InspectionContent
