import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {Form,Input,Select, message,Upload} from 'antd';
import EditBar from '../../components/edit-bar/edit-bar';
import picUpload from '../../assets/images/pic_update.svg'
import { getCurrentProject, dispatchCurrentProject } from '../../redux/01_project.redux'
import {  updateUserRequest} from '../../redux/06_users.redux'
import {ROLE_TYPES} from '../../constants/constants'

import {formItemLayout} from '../../components/layout/formlayout'
import{ getHeaders, _baseUrl} from '../../axios.config'
import{ isPhoneAvailable} from '../../utils/utils'

const Option = Select.Option;
@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.projects
        }
    },
    {dispatchCurrentProject}
)
class UserRegister extends Component{
    constructor(props){
        super(props);
        this.state = {
            edit: false,
            roleId: 4,
            headers:{},
            iconLoading: false
        };
    }

    componentWillMount(){
        this.updateHeaders();
    }

    updateHeaders = async()=>{
        let headers = await getHeaders(true);
        this.setState({
            headers
        })
    }
    
    componentDidMount(){
        let {organizationId} = this.props.match.params;
        getCurrentProject(organizationId).then(res=>{
            this.props.dispatchCurrentProject(res)
        })
        this.setState({
            organizationId
        })
    }

    handleSubmit = e => {
        
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let data={
                    ...values,
                    username: values.phone,
                    roleId: this.state.roleId,
                    organizationId: this.state.organizationId,
                }
                if(this.state.avatar){
                    data={
                        ...data,
                        avatar: this.state.avatar
                    }
                }
                delete data._img;
                if(isPhoneAvailable(values.phone)){
                    this.setState({iconLoading: true});
                    updateUserRequest(data).then(()=>{
                        message.success('添加业主成功');
                        this.props.history.push(`/orgs-management/guide/owners/${this.state.organizationId}`);
                    }).finally(()=>{
                        this.setState({
                            iconLoading: false
                        })
                    })
                }else{
                    message.warn('请输入正确的手机号码')
                }
            }
        });
    };
    
    render(){
        const { getFieldDecorator } = this.props.form;
        const _this = this;

        const props = {
            name: 'file',
            showUploadList: false,
            action: `${_baseUrl}/resource/resources/put-oss`,
            headers: _this.state.headers,
            onChange(info) {
              if (info.file.status === 'done') {
                message.success(`${info.file.name} 上传成功`);
                _this.setState({
                    avatar: info.fileList[info.fileList.length-1].response.url
                })
              } else if (info.file.status === 'error') {
                message.error(`${info.file.name} 上传失败`);
              }
            },
            beforeUpload:beforeUpload
          };
    
        return (
            <div>
                <div className='edit-content'>
                    <EditBar onSave = {this.handleSubmit} save={true} back={true} iconLoading={this.state.iconLoading}/>
                    <div className='edit-form-container edit-form-container-half'>
                        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                            <Form.Item label='用户头像'>
                                {getFieldDecorator('_img', {})(
                                    <Upload {...props}>
                                        <img src={this.state.avatar || picUpload} alt='upload' className='upload-img'/>
                                    </Upload> 
                                )}
                            </Form.Item>
                            <Form.Item label='手机号码'>
                            {getFieldDecorator('phone', {
                                initialValue: this.state.phone,
                                rules: [{ required: true, message: '手机号码不能为空!' }],
                            })(<Input disabled={this.state.edit}  placeholder="请输入手机号码"/>)}
                            </Form.Item>
                            <Form.Item label='用户姓名'>
                            {getFieldDecorator('name', {
                                initialValue: this.state.name,
                                rules: [
                                {
                                    required: true,
                                    message: '用户姓名不能为空',
                                },
                                ],
                            })(<Input disabled={this.state.edit} placeholder="请输入用户姓名"/>)}
                            </Form.Item>
                            <Form.Item label='角色类型'>
                                {getFieldDecorator('roleId', {
                                    initialValue: this.state.roleId,
                                    rules: [{ required: true, message: '请选择角色类型!' }],
                                })(    <Select placeholder="角色类型" disabled>
                                            {
                                                ROLE_TYPES.map(ele=> <Option key={ele.key} value={ele.value}>{ele.label}</Option>)
                                            }
                                        </Select>)}
                            </Form.Item>
                            {/* <Form.Item>
                                {getFieldDecorator('organizationId', {
                                    initialValue: this.state.organizationId,
                                    rules: [{ required: true, message: '请选择所属项目!' }],
                                })( <Select placeholder="所属项目" >
                                        {
                                            this.props.projects.map(ele=> <Option key={ele.id} value={ele.id}>{ele.name}</Option>)
                                        }
                                    </Select>)}
                            </Form.Item>   */}
                        </Form>
                    </div>
                </div>
            </div>
        )
    }
}

export default  Form.create()(UserRegister)

function beforeUpload(file) {
    const isJPG = file.type === 'image/jpeg';
    const isPNG = file.type === 'image/png';
    if (!isJPG&&!isPNG){
      message.error('只能上传JPG/PNG类型的文件!');
    }
    return isJPG || isPNG;
  }
  