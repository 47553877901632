
import axios from '../axios.config'
import {HIDE_LOADING, SHOW_LOADING, hideLoading, showLoading, getRegionsByIDs} from './00_common.action'

const MAINTAINERS_DATA = 'maintainers_DATA';
//reducer
const initState = {
    maintainers : [],
    total:0,
    start:0,
    loading: true
}


export function maintainers(state = initState, action) { 
    switch(action.type){
        case MAINTAINERS_DATA:
            return { ...state, 
                maintainers: action.payload.data,
                total: action.payload.total,
                start: action.payload.start
             }
        case SHOW_LOADING:
            return {
                ...state,
                loading: true,
            }
        case HIDE_LOADING:
             return {
                 ...state,
                 loading: false
             }
        default:
            return state
    }
}

//action creator
/**
 * 获取维保单位列表后，需再用Idin请求区域列表，并重新组合maintainer Data
 */
export function getMaintainerData(params){
    // dispatch 用来通知数据修改
    return dispatch => {
        dispatch(showLoading())
        axios.get('/account/maintainers',{params})
            .then(res=>{
                let ids = res.data.map(ele=>ele.regionId)
                return getRegionsByIDs(ids).then(res2=>{
                    let newData=res.data.map(ele=>{
                        return {
                            ...ele,
                            region: res2.data.find(region=>region.id === ele.regionId)
                        }
                    })


                    dispatch(MaintainerData({
                        ...res,
                        data: newData
                    }))
                })
            })
            .finally(()=>{
                dispatch(hideLoading())
            })
    }
}
export function MaintainerData(data) {
    return {type: MAINTAINERS_DATA, payload: data}
}

export function updateMaintainerSuccess() { 
    return dispatch=>dispatch(getMaintainerData({start:0}))
}

/** 
 * 非redux管理的function
*/

export  function getCurrentMaintainer(id){
    return axios.get(`/account/maintainers/${id}`)
}


export function updateMaintainerRequest(data,edit=false, id){
    if(edit){
        return axios.put(`/account/maintainers/${id}`,data)
    }else {
        return axios.post('/account/maintainers',data)
    }
}

export  function deleteCurrentMaintainer(id){
    return axios.delete(`/account/maintainers/${id}`)
}

