
import axios from '../axios_new.config'
import {HIDE_LOADING, SHOW_LOADING, hideLoading, showLoading, getRegionsByIDs, getOrganizationsByIDs} from './00_common.action'

const DRWAINGPS_DATA = 'DRWAINGPS_DATA';
const CURRENT_DRWAINGP = 'CURRENT_DRWAINGP'

//reducer
const initState = {
    drawingReviewP : [],
    total:0,
    start:1,
    type: 1,
    searchType: 1,
    loading: true,
    currentDrawingReviewP:{}
}


export function drawingReviewP(state = initState, action) { 
    switch(action.type){
        case DRWAINGPS_DATA:
            return { ...state, 
                drawingReviewP: action.payload.content,
                total: action.payload.totalElements,
                start: action.payload.start,
                type: action.payload.type,
                searchType: action.payload.searchType,
                // start: 1,
                errorMsg:null
             }
        case CURRENT_DRWAINGP:
             return {
                 ...state,
                 currentDrawingReviewP: action.payload
             }
        case SHOW_LOADING:
             return {
                 ...state,
                 loading: true,
             }
        case HIDE_LOADING:
             return {
                 ...state,
                 loading: false
             }
        default:
            return state
    }
}

//action creator
export function getDrawingPList(params, access_token=undefined, needDetail=true){ 

    // dispatch 用来通知数据修改
    return dispatch => {
        dispatch(showLoading())
        let promise;
        if(access_token){
            promise = axios.request({
                method:'get',
                url:'/api/maintenance/fireControls',
                params: params,
                headers:{
                  Authorization: `Bearer ${access_token}`,
                  "Content-Type": 'application/json',
                },
            })
        }else{
            promise = axios.get('/api/maintenance/fireControls',{params});
        }
        
        promise.then(res=>{
            dispatch(drawingReviewPData(res));
        }).finally(()=>{
            dispatch(hideLoading())
        })
    }
}

// export function updateProjectSuccess() { 
//     return dispatch=>dispatch(getDrawingList())
// }


export function dispatchCurrentDrawingReviewP(data){
    return dispatch=>dispatch(currentDrawingReviewP(data))
}

function drawingReviewPData(data) {
    return {type: DRWAINGPS_DATA, payload: data}
}
function currentDrawingReviewP(data){
    return {type: CURRENT_DRWAINGP, payload: data}
}


// // 非redux管理的方法
// export function updateProjectRequest(data,edit=false, id){
//     if(edit){
//         return axios.put(`/account/network-users/${id}`,data)
//     }else{
//         return axios.post('/account/network-users',data)
//     }
// }

//派单
export function dispatchWork(data, id){
    return axios.post(`/api/maintenance/fireControls/dispatchWork/${id}`,data)
}

//接单
export function receiveWork(id,userId){
    return axios.post(`/api/maintenance/fireControls/receiveWork/${id}/${userId}`)
}

//审核
export function fireControlsAudit(data){
    return axios.post(`/api/maintenance/fireControls/audit`,data)
}

/**
 * 根据project ID 获取当前的project信息，主要用于填写topBarInside中的项目信息
 * @param {*project id} id 
 */
export function getCurrentDrawingReviewP(id){
    return axios.get(`/api/maintenance/fireControls/${id}`)
}

// // 删除项目
// export function deleteProject(id){
//     return axios.delete(`/account/network-users/${id}`)
// }