import React, {Component}  from 'react';
import { Table} from 'antd';
import './styles.css';

class MainTable extends Component{
    render(){
       return (
        <Table 
            scroll={this.props.scroll}
            rowClassName = 'main-table-row'
            pagination={false}
            className= 'main-table'
            loading={this.props.loading}
            columns={this.props.columns} 
            dataSource={this.props.dataSource}
            bordered={this.props.bordered}
        />
       )
    }
}

export default MainTable