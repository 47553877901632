import React, {Component}  from 'react';
import { connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import {Form,Input,Select, message,Upload, Spin,Modal, Button, DatePicker } from 'antd';
import moment from 'moment';

import EditBar from '../../components/edit-bar/edit-bar';
import picUpload from '../../assets/images/pic_update.svg'

import { doAddNewProject } from '../../redux/13_new_project.redux'
import { getCurrentDrawingReview,dispatchCurrentDrawingReview, ReFireControlsAudit } from '../../redux/12_drawing_review.redux'
import { getProjectsData } from '../../redux/01_project.redux'

import { getCurrentAuthUser } from '../../redux/auth.redux'


import {formItemLayout} from '../../components/layout/formlayout'
import{ getHeaders, _baseUrl} from '../../axios_new.config'

import {  addMarker} from '../../redux/00_common.action'
import mapJson from '../../assets/json/custom_map_config.json'
import './styles.css';



const BMap = window.BMap;
const Search = Input.Search;
let map;

const Option = Select.Option;
@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.maintainers,
            ...state.projects,
            ...state.region
        }
    },
    {doAddNewProject,getCurrentDrawingReview,dispatchCurrentDrawingReview}
)
class NewProject extends Component {


    constructor(props){
        super(props)

        this.state = {
            tab : 0,
            edit: false,
            id: '',
            picture:null,
            roleId: 0,
            avatar: null,
            loading: false,
            headers:{},
            iconLoading: false,

            drawList: [],
            drawing: [],//项目图纸
            opinionList: [],
            opinion: [],//审图意见
            inspectionList: [],
            inspection: [],//自检报告
            completedList: [],
            completed: [],//竣工图纸
            firesList: [],
            fires: [],//消防检测报告
            previewVisible: false,
            previewImage: '',
            
            latitude: '',//纬度
            longitude: ''//精度
        }
    } 

    componentWillMount(){
        this.updateHeaders();
    }

    updateHeaders = async()=>{
        let headers = await getHeaders(true);
        this.setState({
            headers
        })
    }

    componentDidMount(){
        let {id} = this.props.match.params;
        getCurrentDrawingReview(id).then(res=>{ 
            let useNature = res.useNature;
            let arr_useNature = useNature.split("/");
            
            this.setState({
                ...res,
                nature : arr_useNature[0],
                latitude: res.startLatitude,//纬度
                longitude: res.startLongitude,//精度
                drawing: res.projectDrawings,
                drawList: this.setImgUid(res.projectDrawings),
                opinionList: this.setImgUid(res.drawingReviewComments),
                opinion: res.drawingReviewComments,//审图意见
                inspectionList: this.setImgUid(res.selfCheckReport),
                inspection: res.selfCheckReport,//自检报告
                completedList: this.setImgUid(res.builtDrawings),
                completed: res.builtDrawings,//竣工图纸
                firesList: this.setImgUid(res.fireDetectionReport),
                fires: res.fireDetectionReport,//消防检测报告
            });
            if(arr_useNature[0]=="民用"){
                this.setState({
                    type1: arr_useNature[1],
                    type2: arr_useNature[2],
                });
            }else{
                this.setState({
                    type3: arr_useNature[1]
                });
            }
            this.props.dispatchCurrentDrawingReview(res);
            this.addMap(res.startLongitude,res.startLatitude)
        });
    }

    //图片格式处理
    setImgUid(imglist){
        let dList = [];
        for(var i = 0;i<imglist.length;i++){
            var obj = new Object(),
                item = imglist[i];
                obj.name = item.name;
                obj.url = item.url;
                obj.uid = i;
            dList.push(obj);
        }
        return dList;
    }

    handleSubmit = e => {
        this.props.form.validateFields((err, values) => { 
            let latitude = this.state.latitude;
            let longitude = this.state.longitude;
            if(!latitude || !longitude){
                message.warn('请在右侧地图选择详细地址坐标')
            }
            if (!err) {
                let data = {
                    ...values,
                    type: this.state.type,
                    startLongitude: longitude,
                    startLatitude: latitude,
                    useNature: values.nature=="民用" ? values.nature+"/"+values.type1+"/"+values.type2 : values.nature+"/"+values.type3,
                    startDate: moment(values['startDate']).format('YYYY-MM-DD'),
                    endDate: moment(values['endDate']).format('YYYY-MM-DD')
                }
                if(this.state.type==1){
                    if(this.state.drawing){
                        data={
                            ...data,
                            projectDrawings: this.state.drawing
                        }
                    }
                    if(this.state.opinion){
                        data={
                            ...data,
                            drawingReviewComments: this.state.opinion
                        }
                    }
                }else if(this.state.type==2){
                    if(this.state.inspection){
                        data={
                            ...data,
                            selfCheckReport: this.state.inspection
                        }
                    }
                    if(this.state.completed){
                        data={
                            ...data,
                            builtDrawings: this.state.completed
                        }
                    }
                    if(this.state.fires){
                        data={
                            ...data,
                            fireDetectionReport: this.state.fires
                        }
                    }
                }
                delete data.nature;
                delete data.type1;
                delete data.type2;
                //console.log(123,data);return;
              
                this.setState({iconLoading: true});
                doAddNewProject(data,true, this.state.id).then(res=>{ 
                    let _this = this;
                    if(!isNaN(res)){
                        message.success("修改成功");
                        if(this.state.type==1){
                            setTimeout(function(){
                                _this.props.history.push(`/drawing_review`);
                            },1800)
                        }else if(this.state.type==2){
                            setTimeout(function(){
                                _this.props.history.push(`/acceptance`);
                            },1800)
                        }
                    }
                    
                }).finally(()=>this.setState({iconLoading: false}));

            }else{
                message.warn('请填写完整信息')
            }
        });
    };

    

    addMap = (lng,lat,type)=>{
        map = new BMap.Map("l-map");  
        map.centerAndZoom(new BMap.Point(lng,lat), this.state.edit?13:13);
        map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
        map.addControl(new BMap.NavigationControl());    
        map.addControl(new BMap.ScaleControl());    
        map.addControl(new BMap.OverviewMapControl());   
        //map.setMapStyle({styleJson:mapJson});
        addMarker(map,lng,lat,this.state.name)
    }


    onAddrInputChange=(value)=>{
        this.searchLocation(value)
    }

    searchLocation=(key)=>{
        let _this = this;
        this.setState({
            addrValue:key
        });

        let local = new BMap.LocalSearch(map, {
            renderOptions:{map: map, panel:"r-result"},
            pageCapacity:5,
            onInfoHtmlSet: function(poi, HTMLElement){
                const trs = HTMLElement.querySelectorAll('tr');
                if(trs[1]) trs[1].remove();

                let td = document.createElement('td');
                td.setAttribute('width', '20%')
                let seclectBtn = document.createElement('button');
                seclectBtn.innerHTML = '选择';
		        seclectBtn.className = 'map-select-button';
		        td.appendChild(seclectBtn);
                HTMLElement.querySelector('tr') && HTMLElement.querySelector('tr').appendChild(td);

                seclectBtn.addEventListener('click',()=>{ 
                    map.closeInfoWindow();
                    _this.setState({
                       latitude: poi.point.lat,
                       longitude: poi.point.lng,
                   });
                   message.success("选择成功");
                //     _this.props.form.setFieldsValue({
                //       latitude: poi.point.lat,
                //       longitude: poi.point.lng,
                //       detailAddr: poi.address
                //    });
                //    map.clearOverlays();
                //    local.clearResults();
              });       

            }
        });
        
        if (key.trim().length) {
            local.search(key);
        }else{
            map.clearOverlays();
            local.clearResults();
        }

    }
    
    onRoleChange = (value)=>{
        this.setState({
            roleId: value
        })
    }

    onDeleteUserFromOrg = () =>{
        
    }

    //设置上传按钮
    setUpdTxt(txt){
        return <div>
                  <img src={picUpload} alt="" />
                  <div className="ant-upload-text">{txt}</div>
               </div>;
    }
    
    render(){
        const { getFieldDecorator } = this.props.form;   
        const _this = this;

        const { previewVisible, previewImage, drawList,opinionList, inspectionList, completedList, firesList } = this.state;
        const props = {
            name: 'file',
            showUploadList: true,
            listType: "picture-card",
            onPreview: _this.handlePreview,
            beforeUpload: _this.beforeUpload,
            multiple:true,
            action: `${_baseUrl}/resource/resources/put-oss`,
            headers: _this.state.headers
        };

        const config = {
            rules: [{ type: 'object', required: true, message: '请选择日期' }],
        };
        
        let uploadButton1 = this.setUpdTxt("项目图纸");
        let uploadButton2 = this.setUpdTxt("审图意见");

        let uploadButton3 = this.setUpdTxt("自检报告");
        let uploadButton4 = this.setUpdTxt("竣工图纸");
        let uploadButton5 = this.setUpdTxt("消防检测报告");

        let updBtns,drtd;
        if(this.state.type==1){
            updBtns =   (<div>
                                <Upload {...props} onChange={this.handleChangeDrawing} fileList={drawList}>
                                    {drawList.length >= 99 ? null : uploadButton1}
                                </Upload>
                                <br/> <br/> <br/>
                                <Upload {...props} onChange={this.handleChangeOpinion} fileList={opinionList}>
                                    {opinionList.length >= 99 ? null : uploadButton2}
                                </Upload>
                            </div>)
            drtd = (
                <label>
                    <Form.Item label='审图单位'>
                        {getFieldDecorator('drawingReviewCompany', {
                            initialValue: this.state.drawingReviewCompany,
                            rules: [
                            {
                                required: true,
                                message: '审图单位不能为空',
                            },
                            ],
                        })(<Input placeholder="请输入审图单位"/>)}
                    </Form.Item>

                    <div className='tsform flex-between'>
                        <Form.Item label='审图单位联系人'>
                            {getFieldDecorator('drawingReviewPerson', {
                                initialValue: this.state.drawingReviewPerson,
                                rules: [
                                {
                                    required: true,
                                    message: '审图单位联系人不能为空',
                                },
                                ],
                            })(<Input placeholder="请输入审图单位联系人"/>)}
                        </Form.Item>
                        <Form.Item label='联系方式'>
                            {getFieldDecorator('drawingReviewPhone', {
                                initialValue: this.state.drawingReviewPhone,
                                rules: [
                                {
                                    required: true,
                                    message: '联系方式不能为空',
                                },
                                ],
                            })(<Input placeholder="请输入联系方式"/>)}
                        </Form.Item>
                    </div>
                </label>
            )
        }else{
            updBtns =   (<div>
                <Upload {...props} onChange={this.handleChangeInspection} fileList={inspectionList}>
                    {inspectionList.length >= 99 ? null : uploadButton3}
                </Upload>
                <br/> <br/> <br/>
                <Upload {...props} onChange={this.handleChangeCompleted} fileList={completedList}>
                    {completedList.length >= 99 ? null : uploadButton4}
                </Upload>
                <br/> <br/> <br/>
                <Upload {...props} onChange={this.handleChangeFires} fileList={firesList}>
                    {firesList.length >= 99 ? null : uploadButton5}
                </Upload>
            </div>
            )

            drtd = (
                <label>
                    <Form.Item label='检测单位'>
                        {getFieldDecorator('testingCompany', {
                            initialValue: this.state.testingCompany,
                            rules: [
                            {
                                required: true,
                                message: '检测单位不能为空',
                            },
                            ],
                        })(<Input placeholder="请输入检测单位"/>)}
                    </Form.Item>

                    <div className='tsform flex-between'>
                        <Form.Item label='检测单位联系人'>
                            {getFieldDecorator('testingPerson', {
                                initialValue: this.state.testingPerson,
                                rules: [
                                {
                                    required: true,
                                    message: '检测单位联系人不能为空',
                                },
                                ],
                            })(<Input placeholder="请输入检测单位联系人"/>)}
                        </Form.Item>
                        <Form.Item label='联系方式'>
                            {getFieldDecorator('testingPhone', {
                                initialValue: this.state.testingPhone,
                                rules: [
                                {
                                    required: true,
                                    message: '联系方式不能为空',
                                },
                                ],
                            })(<Input placeholder="请输入联系方式"/>)}
                        </Form.Item>
                    </div>
                </label>
            )
        }
          

        const app = (
            <div className = 'content content_abs'>
                <div className='itab show'>
                    <div className='btab flex-wrap flex-between-top'>
                        <div className='edit-form-container edit-form-container-half m_itab'>
                            <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                                <Form.Item label='项目名称'>
                                    {getFieldDecorator('name', {
                                        initialValue: this.state.name,
                                        rules: [
                                        {
                                            required: true,
                                            message: '项目名称不能为空',
                                        },
                                        ],
                                    })(<Input placeholder="请输入项目名称"/>)}
                                </Form.Item>

                                <Form.Item label='项目地址'>
                                    {getFieldDecorator('address', {
                                        initialValue: this.state.address,
                                        rules: [
                                        {
                                            required: true,
                                            message: '项目地址不能为空',
                                        },
                                        ],
                                    })(<Input placeholder="请输入项目地址"/>)}
                                </Form.Item>

                                <Form.Item label='层数'>
                                    {getFieldDecorator('floorNum', {
                                        initialValue: this.state.floorNum,
                                        rules: [
                                        {
                                            required: true,
                                            message: '层数不能为空',
                                        },
                                        ],
                                    })(<Input placeholder="请输入层数"/>)}
                                </Form.Item>

                                <Form.Item label='面积'>
                                    {getFieldDecorator('areaMeasure', {
                                        initialValue: this.state.areaMeasure,
                                        rules: [
                                        {
                                            required: true,
                                            message: '面积不能为空',
                                        },
                                        ],
                                    })(<Input placeholder="请输入面积"/>)}
                                </Form.Item>

                                <Form.Item label='高度'>
                                    {getFieldDecorator('height', {
                                        initialValue: this.state.height,
                                        rules: [
                                        {
                                            required: true,
                                            message: '高度不能为空',
                                        },
                                        ],
                                    })(<Input placeholder="请输入高度"/>)}
                                </Form.Item>

                                <Form.Item label='施工单位'>
                                    {getFieldDecorator('constructionCompany', {
                                        initialValue: this.state.constructionCompany,
                                        rules: [
                                        {
                                            required: true,
                                            message: '施工单位不能为空',
                                        },
                                        ],
                                    })(<Input placeholder="请输入施工单位"/>)}
                                </Form.Item>

                                <div className='tsform flex-between'>
                                    <Form.Item label='施工单位联系人'>
                                        {getFieldDecorator('constructionPerson', {
                                            initialValue: this.state.constructionPerson,
                                            rules: [
                                            {
                                                required: true,
                                                message: '施工单位联系人不能为空',
                                            },
                                            ],
                                        })(<Input placeholder="请输入施工单位联系人"/>)}
                                    </Form.Item>
                                    <Form.Item label='联系方式'>
                                        {getFieldDecorator('constructionPhone', {
                                            initialValue: this.state.constructionPhone,
                                            rules: [
                                            {
                                                required: true,
                                                message: '联系方式不能为空',
                                            },
                                            ],
                                        })(<Input placeholder="请输入联系方式"/>)}
                                    </Form.Item> 
                                </div>

                                <Form.Item label='设计单位'>
                                    {getFieldDecorator('designCompany', {
                                        initialValue: this.state.designCompany,
                                        rules: [
                                        {
                                            required: true,
                                            message: '设计单位不能为空',
                                        },
                                        ],
                                    })(<Input placeholder="请输入设计单位"/>)}
                                </Form.Item>

                                <div className='tsform flex-between'>
                                    <Form.Item label='设计单位联系人'>
                                        {getFieldDecorator('designPerson', {
                                            initialValue: this.state.designPerson,
                                            rules: [
                                            {
                                                required: true,
                                                message: '设计单位联系人不能为空',
                                            },
                                            ],
                                        })(<Input placeholder="请输入设计单位联系人"/>)}
                                    </Form.Item>
                                    <Form.Item label='联系方式'>
                                        {getFieldDecorator('designPhone', {
                                            initialValue: this.state.designPhone,
                                            rules: [
                                            {
                                                required: true,
                                                message: '联系方式不能为空',
                                            },
                                            ],
                                        })(<Input placeholder="请输入联系方式"/>)}
                                    </Form.Item>
                                </div>

                                <Form.Item label='监理单位'>
                                    {getFieldDecorator('supervisorCompany', {
                                        initialValue: this.state.supervisorCompany,
                                        rules: [
                                        {
                                            required: true,
                                            message: '监理单位不能为空',
                                        },
                                        ],
                                    })(<Input placeholder="请输入监理单位"/>)}
                                </Form.Item>

                                <div className='tsform flex-between'>
                                    <Form.Item label='监理单位联系人'>
                                        {getFieldDecorator('supervisorPerson', {
                                            initialValue: this.state.supervisorPerson,
                                            rules: [
                                            {
                                                required: true,
                                                message: '监理单位联系人不能为空',
                                            },
                                            ],
                                        })(<Input placeholder="请输入监理单位联系人"/>)}
                                    </Form.Item>
                                    <Form.Item label='联系方式'>
                                        {getFieldDecorator('supervisorPhone', {
                                            initialValue: this.state.supervisorPhone,
                                            rules: [
                                            {
                                                required: true,
                                                message: '联系方式不能为空',
                                            },
                                            ],
                                        })(<Input placeholder="请输入联系方式"/>)}
                                    </Form.Item>
                                </div>

                                {drtd}

                                <Form.Item label='开始时间'>
                                    {getFieldDecorator('startDate',{initialValue: moment(this.state.startDate),config})(<DatePicker />)}
                                </Form.Item>

                                <Form.Item label='截止时间'>
                                    {getFieldDecorator('endDate', {initialValue: moment(this.state.endDate),config})(<DatePicker />)}
                                </Form.Item>
                                
                                <Form.Item label='使用性质'>
                                    {getFieldDecorator('nature', {
                                        initialValue: this.state.nature,
                                        rules: [{ required: true, message: '使用性质!' }],
                                    })(<Select placeholder="使用性质" onChange={this.onNatureChange}>
                                            <option value="民用">民用</option>
                                            <option value="厂房">厂房</option>
                                            <option value="仓库">仓库</option>
                                        </Select>)}
                                </Form.Item>
                                {this.state.nature=="民用" ? 
                                    <span>
                                        <Form.Item label='类型'>
                                            {getFieldDecorator('type1', {
                                                initialValue: this.state.type1,
                                                rules: [{ required: true, message: '类型!' }],
                                            })(    <Select placeholder="类型">
                                                        <option value="住宅">住宅</option>
                                                        <option value="公共建筑">公共建筑</option>
                                                    </Select>)}
                                        </Form.Item>
                                        <Form.Item label='类型'>
                                            {getFieldDecorator('type2', {
                                                initialValue: this.state.type2,
                                                rules: [{ required: true, message: '类型!' }],
                                            })(    <Select placeholder="类型">
                                                        <option value="一类">一类</option>
                                                        <option value="二类">二类</option>
                                                        <option value="单多层">单多层</option>
                                                    </Select>)}
                                        </Form.Item>
                                    </span>
                                : 
                                <span>
                                    <Form.Item label='类型'>
                                        {getFieldDecorator('type3', {
                                            initialValue: this.state.type3,
                                            rules: [{ required: true, message: '等级!' }],
                                        })(    <Select placeholder="等级">
                                                    <option value="甲">甲</option>
                                                    <option value="乙">乙</option>
                                                    <option value="丙">丙</option>
                                                    <option value="丁">丁</option>
                                                    <option value="戊">戊</option>
                                                </Select>)}
                                    </Form.Item>
                                </span>
                                }
                                
                                
                                
                            </Form>
                        </div>
                        <div className="m_itab">
                            <Form {...formItemLayout}>
                                <div className='mapContainer'>
                                    <div className='search-container'>
                                        <Search
                                            placeholder="输入地址获取坐标"
                                            enterButton="搜索"
                                            size="large"
                                            value={this.state.addrValue}
                                            onChange={e => this.searchLocation(e.target.value)}
                                            onSearch={value => this.searchLocation(value)}
                                            />
                                        <div id="r-result" className='r-result'></div>
                                    </div>
                                    <div id="l-map" className='l-map'></div>
                                </div>
                                <div className="upd_img clearfix">
                                    
                                    {/* {getFieldDecorator('_img', {})(
                                        <div>                              
                                            <Upload {...props}>
                                                <img src={this.state.picture || picUpload} alt='upload' className='upload-img'/>
                                            </Upload>                           
                                        </div>
                                    )}*/}

                                    {updBtns}

                                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                        <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                    </Modal>
                                    
                                    
                                </div>
                            </Form>
                        </div>
                    </div>
                    {/* <div className="tab_btn" onClick={this.handleSubmit}>新建</div> */}
                    <Button className="tab_btn" loading={this.state.iconLoading} type="primary" size='large' onClick={this.handleSubmit}>修改</Button>
                </div>
            </div>
        );
        
        return app
    }

    setTabs(i){
        this.setState({
            tab : i
        })
    }

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => { console.log(file);
        if (!file.url && !file.preview) {
            file.preview = await file.originFileObj
        }
       
        this.setState({
            // previewImage: file.url || file.preview,
            previewImage: file.response ? file.response.url : file.url,
            previewVisible: true,
        });
    };

    //项目图纸
    handleChangeDrawing = ({ fileList }) => {
        this.setState({ 
            drawList: fileList,
            drawing: this.setImgList(fileList)
         })
    };


    //审图意见
    handleChangeOpinion = ({ fileList }) => {
        this.setState({ 
            opinionList: fileList,
            opinion: this.setImgList(fileList)
         })
    };

    //自检意见
    handleChangeInspection = ({ fileList }) => {
        this.setState({ 
            inspectionList: fileList,
            inspection: this.setImgList(fileList)
         })
    };

    //竣工图纸
    handleChangeCompleted = ({ fileList }) => {
        this.setState({ 
            completedList: fileList,
            completed: this.setImgList(fileList)
         })
    };

    //消防检测报告
    handleChangeFires = ({ fileList }) => {
        this.setState({ 
            firesList: fileList,
            fires: this.setImgList(fileList)
         })
    };

    //上传的图片处理
    setImgList(files){ 
        var picture = [];
        if(files.length>0){
            for(let i = 0;i<files.length;i++){
                let item = files[i];
                let pic = new Object;
                if(item.response){
                    pic.name = item.name;
                    pic.url = item['response']['url'];
                    picture.push(pic);
                }else{
                    pic.name = item.name;
                    pic.url = item.url;
                    picture.push(pic);
                }
            }
        }
        //console.log(1234,picture);
        return picture;
    }


}

export default Form.create()(NewProject)

function beforeUpload(file) {
    const isJPG = file.type === 'image/jpeg';
    const isPNG = file.type === 'image/png';
    if (!isJPG&&!isPNG){
      message.error('只能上传JPG/PNG类型的文件!');
    }
    return isJPG || isPNG;
  }