import React, {Component}  from 'react';
import { connect} from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { Input, Button, Modal, message } from 'antd';
import CustomPagination from '../../components/pagination/pagination'
import { seTimeArea,setStatusTxt } from '../../utils/utils'
import Table from '../../components/table'
import{ getStorage } from '../../utils/utils'
import { getDrawingList, delFireControls} from '../../redux/12_drawing_review.redux'
import './styles.css';
const Search = Input.Search;

@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.drawingReview
        }
    },
    {getDrawingList}
)
class DrawingReview extends Component {

    constructor(props){
        super(props)

        this.state = {
            tab : 0,
            edit: false,
            picture:null,
            roleId: 0,
            avatar: null,
            loading: false,
            headers:{},
            iconLoading: false,
            
        }
    } 

    componentDidMount(){ 
        if(this.props.isAuth){
            this.getDatas({start:1,type:1,searchType:1});
        }
    }
    pageChangeHandler(page) {
        let st = parseInt(this.state.tab) + 1;
        this.getDatas({start:page,type:1,searchType: st});
    }
    onSearch = (params)=>{
        this.getDatas(params);
    }

    getDatas = (params) =>{ 
        let authUserRole = this.props.authUser.rolesIds;
        const isrole = authUserRole.indexOf(7) > -1; 
        let userRoid = authUserRole[0] ? authUserRole[0] : '';
        let userId = getStorage('userId');
        params = {
            ...params
        }
        if(!isrole){ 
            delete params.userId
        }
        if(userRoid==7){
            params = {
                ...params,
                creatorId: userId
            }
        }
        this.props.getDrawingList(params);
    }

    userRegister = ()=>{
        this.props.history.push('/drawing_review/register')
    }

    //单个删除
    doDelete(id){
        let _this = this;
        Modal.confirm({
            title: '提示',
            content: '确定删除？删除后将不可恢复',
            okText: '确认',
            cancelText: '取消',
            onOk(){
                _this.setState({iconLoading: true});
                delFireControls(id).then(res=>{
                    message.success("删除成功");
                    _this.setTabs(_this.state.tab);
                }).finally(()=>_this.setState({iconLoading: false}));
            }
        });
    }

    render(){
        const filters = [{
            key:'nameLike',
            placeholder:'项目名称'
        }];

       
        const now_columns = [
            {
                title: '项目名称',
                dataIndex: 'name',
                key: 'name',
                width: "15%",
                render: (text, record) => {
                    let dat = setStatusTxt(record.status,record.auditNum);
                    var dom = <span className={dat.name1}>{dat.txt1}</span>;
                    var dom2 = <em className={dat.name2}>{dat.txt2}</em> ;
                    return <div>
                       {text}
                       <div className='spadiv'>
                            {dom}{dom2}
                       </div>
                    </div>
                },
            },{
                title: '详细地址',
                dataIndex: 'address',
                key: 'address',
                width: "15%",
            },{
                title: '层数',
                dataIndex: 'floorNum',
                key: 'floorNum',
                width: "8%",
            },{
                title: '面积',
                dataIndex: 'areaMeasure',
                key: 'areaMeasure',
                width: "8%",
            },{
                title: '监理单位信息',
                dataIndex: 'supervisorCompany',
                key: 'supervisorCompany',
                width: "15%",
            },{
                title: '审图单位信息',
                dataIndex: 'drawingReviewCompany',
                key: 'drawingReviewCompany',
                width: "15%",
            },{
                title: '施工单位信息',
                dataIndex: 'constructionCompany',
                key: 'constructionCompany',
                width: "15%",
            },{
                title: '设计单位信息',
                dataIndex: 'designCompany',
                key: 'designCompany',
                width: "15%",
            },{
                title: '使用性质',
                dataIndex: 'useNature',
                key: 'useNature',
                width: "15%",
            },{
                title: '状态',
                dataIndex: 'status',
                key: 'day',
                width: "10%",
                align: 'center',
                className: 'colnone',
                render: (text, record) => {
                    if(this.state.tab==1 || this.state.tab==2 || this.state.tab==4){
                        //这里计算当前的状态
                        if(record.startDate && record.endDate && record.normalDate && record.warningDate){
                            let dat = seTimeArea(record.startDate,record.endDate,record.normalDate,record.warningDate);
                            let dom = <span className={`iquan iquan${dat.now_status}`}></span>
                            return <div>{dom} <p className='sap'>{dat.use_day}/{dat.all_day}</p></div>
                        }else{
                            return "-";
                        }
                    }else{
                        return "-";
                    }
                }
            },{
                title: '操作',
                key: 'action',
                width: 180,
                align: 'center',
                render: (text, record) => {
                    let btn;
                    let btn_sea = <Link className='edit_action' to={`/drawing_review/drawing_examine/${record.id}`}>查看</Link>;
                    let btn_again = <Link className='edit_action' to={`/drawing_review/drawing_examine/${record.id}`}>重新提交</Link>
                    let btn_del = <Button type='danger' onClick={e => this.doDelete(record.id)}>删除</Button>;
                    let btn_edit = <Link className='edit_action' to={`/drawing_review/edit/${record.id}`}>编辑</Link>;
                    
                    if(record.status==4){
                        btn = (<div className='zubtns'>{btn_again}</div>)
                    }else if(record.status==3){//可删除
                        btn = (
                            <div className='zubtns'>
                                {btn_sea}{btn_del}
                            </div>
                        )
                    }else if(record.status==0 && record.auditNum==1){
                        btn = (
                            <div className='zubtns'>
                                {btn_sea}{btn_edit}{btn_del}
                            </div>
                        )
                    }else{
                        btn = (
                            <div className='zubtns'>
                                {btn_sea}
                            </div>
                        )
                    }
                    return btn;
                },
            },
        ];

        //当某状态时隐藏状态栏
        let columns;
        if(this.state.tab==0 || this.state.tab==3){
            columns = now_columns.filter(item => {
                if (item.dataIndex=='status'){
                  return false
                }
                return true
              })
        }else{
            columns = now_columns;
        }

        const app = (
            <div className = 'content content_abs'>
                <div className='itabs itabs2'>
                    <span className={["tab_span ",this.state.tab==0 ? 'on' :'' ].join('')} onClick={e => this.setTabs(0)}>全部</span>
                    <span className={["tab_span ",this.state.tab==1 ? 'on' :'' ].join('')} onClick={e => this.setTabs(1)}>初审</span>
                    <span className={["tab_span ",this.state.tab==2 ? 'on' :'' ].join('')} onClick={e => this.setTabs(2)}>复审</span>
                    <span className={["tab_span ",this.state.tab==3 ? 'on' :'' ].join('')} onClick={e => this.setTabs(3)}>已完成</span>
                    <span className={["tab_span ",this.state.tab==4 ? 'on' :'' ].join('')} onClick={e => this.setTabs(4)}>未通过</span>
                </div>
                <div className="isea">
                    <div className="misea">
                        <Search
                            placeholder="请输入关键字搜索"
                            enterButton="搜索"
                            size="large"
                            onSearch={value => this.onSearch({"searchKey": value})}
                        />
                    </div>
                </div>
                <div className={`main-table-container ts_table ts_table${this.state.tab}`}>
                    <Table  scroll={{ y: 640 }}
                            columns={columns} 
                            dataSource={this.props.drawingReview && this.props.drawingReview.map(item=>{ return {...item, key:item.id}})} 
                            loading={this.props.loading}/>
                            
                    <CustomPagination
                        total={this.props.total}
                        onChange={(e)=>{this.pageChangeHandler(e)}} />
                </div>
            </div>
        );
        return app
    }

    setTabs(i){
        this.setState({
            tab : i
        })
        // 消防审图列表(全部)：type=1,searchType=1
        // 消防审图列表(初审)：type=1,searchType=2
        // 消防审图列表(复审)：type=1,searchType=3
        // 消防审图列表(已完成)：type=1,searchType=1,auditStatus=3
        // 消防审图列表(未通过)：type=1,searchType=1,auditStatus=4
        if(this.props.isAuth){
            let st = parseInt(i) + 1;
            if(i<3){
                this.getDatas({start:1,type:1,searchType: st});
            }else{
                this.getDatas({start:1,type:1,searchType: 1,auditStatus: i})
            }
        }
    }
}

export default DrawingReview