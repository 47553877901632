import React, {Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {Form,Input,DatePicker,Select,Popconfirm, message, Spin} from 'antd';
import moment from 'moment'

import EditBar from '../../components/edit-bar/edit-bar';
import {formItemLayout} from '../../components/layout/formlayout'

import { PERIOD_TYPES, TICKET_TYPES, MAINTAINCE_TYPES } from '../../constants/constants';
import {getCurrentPlan, updatePlanRequest, deletePlan} from '../../redux/05_plan.redux'
import {getCurrentProject, dispatchCurrentProject} from '../../redux/01_project.redux'
import {getContractsData, switchContractFolder, deleteContract} from '../../redux/04_contract.redux'
import { getMaintenanceItemsByIDs} from '../../redux/00_common.action'
import { getInspectionPointDataOfGuidePage, getInspectionsDataInSelectedBuildings, switchFolder} from '../../redux/03_inspection.redux'

import TriangleImg from '../../assets/images/Triangle.svg'
import deleteImg from '../../assets/images/delete_red.svg'
import editImg from '../../assets/images/edit_white.svg'

const Option = Select.Option;

@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.contracts,
            ...state.projects,
            ...state.inspections
        }
    },
    {getCurrentProject, dispatchCurrentProject, getContractsData,switchContractFolder, switchFolder, getInspectionPointDataOfGuidePage,getInspectionsDataInSelectedBuildings}
)
class PlanEdit extends Component{

    constructor(props){
        super(props)
        this.state = {
            edit: false,
            tab1:true,
            contents:[],
            contentsIds:[],
            type: 1,
            buildingIndex: 0,
            loading: false,
            iconLoading: false
        };
    }

    componentDidMount(){
        if(Object.keys(this.props.match.params).length){
            let id = this.props.match.params.id;
            let organizationId = this.props.match.params.organizationId;

            this.setState({loading: true});

            // 获取当前项目信息
            getCurrentProject(organizationId).then(res=>{
                this.props.dispatchCurrentProject(res);
            });

            getCurrentPlan(id).then(res=>{
                this.setState(res);
                this.setState({
                    id, 
                    edit: true,
                    contentsIds: res.contents.map(ele=>ele.id)
                });
                let itemIds = res.contents.map(ele=>ele.itemId)
                if(itemIds.length){
                    return getMaintenanceItemsByIDs(itemIds)
                }else{
                    return Promise.resolve({data:[]})
                }
            }).then(resp=>{
                let inspectionPointIds = resp.data.map(ele=>ele.inspectionPointId);
                if(inspectionPointIds.length){
                    this.props.getInspectionPointDataOfGuidePage({
                            idIn:JSON.stringify(inspectionPointIds),
                            size: 2000
                        })
                }
            }).catch(()=>message.error('获取维保内容失败')).finally(()=>{
                this.setState({loading: false})
            });
            this.props.getContractsData({
                organizationId,
                size: 2000
            })
        }
    }

    handleSubmit = e => {
        
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if(this.state.contractId){
                    let data ={
                        ...values,
                        contentsIds:JSON.stringify(this.state.contentsIds),
                        startTime: new Date(values.startTime).toISOString(),
                        endTime: new Date(values.endTime).toISOString(),
                        organizationId: this.props.match.params.organizationId || this.props.currentProject.id
                    }
                    this.setState({iconLoading: true});
                    updatePlanRequest(data, this.state.edit, this.state.id).then((res)=>{
                        message.success(this.state.edit?'更新维保计划成功':'创建维保计划成功');
                        this.setState(res);
                        const isFromGuidePage = this.props.history.location.pathname.indexOf('orgs-management') > -1;
                        const path = isFromGuidePage?
                                     `/orgs-management/plans/edit/${this.props.currentProject.id}/${res.id}`
                                     : `/plans/edit/${this.props.currentProject.id}/${res.id}`;
                        this.props.history.push(path);
                    }).finally(()=>this.setState({iconLoading: false}));
                }else{
                    message.warn('请重新选择维保合同')
                }
            }
        });
    };

    switchTab = (tab)=>{
        if(this.state.edit){
            this.setState({
                tab1: tab === 'tab1'
            })
        }else if(tab === 'tab2'){
            message.warn('请先保存计划基本信息')
        }
    }

    onBindContract=(value)=>{
        this.setState({
            contractId: value
        })
    }
    // 展开/收起合同
    switchContractFolder=(id)=>{
        this.props.switchContractFolder(this.props.contracts, id)
    }
    //展开/收起巡查点
    switchFolder=(index)=>{
        this.props.switchFolder(this.props.inspectionPointsInSelectedBuilding, index)
    }
    // 编辑巡查点
    editInspection=(id)=>{
        const isFromGuidePage = this.props.history.location.pathname.indexOf('orgs-management') > -1;
        const path = isFromGuidePage?
                    `/orgs-management/plans/inspection-point/${id}/contents/${this.state.type}/${this.props.currentProject.id}/${this.state.id}`
                    :`/plans/inspection-point/${id}/contents/${this.state.type}/${this.props.currentProject.id}/${this.state.id}`
        this.props.history.push(path)
    }
    clickItems = (buildingId, buildingIndex)=>{
        this.props.getInspectionsDataInSelectedBuildings({
            buildingId,
            organizationId: this.props.currentProject.id,
            size: 2000})
        this.setState({buildingIndex})
    }

    addNewInspectionContent = ()=>{
        //添加维保内容
        const isFromGuidePage = this.props.history.location.pathname.indexOf('orgs-management') > -1;
        const path = isFromGuidePage?
                     `/orgs-management/plans/contents/${this.state.organizationId}/${this.state.id}/${this.state.type}`
                     : `/plans/contents/${this.state.organizationId}/${this.state.id}/${this.state.type}`
        this.props.history.push(path)
    }

    deleteContract = (id)=>{
        deleteContract(id).then(()=>{
            message.success('删除成功');
            this.setState({
                contractId: undefined
            });
            const organizationId = this.props.match.params.organizationId || this.props.currentProject.id;
            this.props.getContractsData({organizationId, size: 2000})
        })
    }

    // 删除计划
    deletePlan=()=>{
        deletePlan(this.state.id).then(()=>{
            message.success('删除成功');
            this.props.history.goBack();
        })
    }

    render(){
        // console.log(this.props)
        const { getFieldDecorator } = this.props.form; 

        return (
            <div className='edit-content'>
                <EditBar onSave = {this.handleSubmit} back={true} save={true} iconLoading={this.state.iconLoading} delete={this.state.edit} onDelete={this.deletePlan}/>
                <div className='plan-tabs'>
                    <div className={this.state.tab1?'tab-active':'tab'} onClick={()=>this.switchTab('tab1')}>基本信息</div>
                    <div className = {!this.state.tab1?'tab-active':'tab'} onClick={()=>this.switchTab('tab2')}>维保内容</div>
                </div>
         
                    
                   <div className='brief-content-wrapper' hidden={!this.state.tab1}>
                        <div className='brief-content brief-contect-scroll'>
                        {
                            this.state.loading?
                            <div className="spin-container-half">
                                <Spin  size="large" tip="正在加载..."/>
                            </div>
                            :
                            <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                                <Form.Item label='计划名称'>
                                {getFieldDecorator('name', {
                                    initialValue: this.state.name,
                                    rules: [
                                    {
                                        required: true,
                                        message: '计划名称不能为空',
                                    },
                                    ],
                                })(<Input  placeholder="请输入计划名称"/>)}
                                </Form.Item>
                                <Form.Item label='计划类型'>
                                {getFieldDecorator('type', {
                                    initialValue: this.state.type,
                                    rules: [{ required: true, message: '请选择计划类型!' }],
                                })(    <Select placeholder="请选择计划类型" disabled={this.state.edit}>
                                            {
                                                TICKET_TYPES.map(ele=> <Option key={ele.key} value={ele.value}>{ele.label}</Option>)
                                            }
                                        </Select>)}
                                </Form.Item>
                                <Form.Item label='文字描述'>
                                {getFieldDecorator('description', {
                                    initialValue: this.state.description,
                                    rules: [{ required: true, message: '文字描述不能为空!' }],
                                })(<Input.TextArea autosize={{ minRows: 4, maxRows: 6 }} placeholder="请输入文字描述"/>)}
                                </Form.Item>


                                <div className='flex-display flex-displayx'>
                                    <Form.Item label='重复周期'>
                                    {getFieldDecorator('periodInterval', {
                                        initialValue: this.state.periodInterval,
                                        rules: [{ required: true, message: '重复周期不能为空!' }],
                                    })(<Input className='addon-input' placeholder="请输入重复周期"/>
                                    )}
                                    </Form.Item>
                                    <Form.Item>
                                    {getFieldDecorator('periodType', {
                                        initialValue: this.state.periodType || 3,
                                        rules: [{ required: true, message: '请选择周期类型!' }],
                                    })(
                                        <Select style={{ width: 100 }}>
                                        {
                                            PERIOD_TYPES.map(ele=><Option value={ele.value} key={ele.key}>{ele.label}</Option>)
                                        }
                                    </Select>
                                    )}
                                    </Form.Item>
                                    <span className='periodInterval-text'>重复一次</span>
                                </div>

                                <Form.Item label='开始时间'>
                                {getFieldDecorator('startTime', {
                                    initialValue: this.state.startTime ? moment(this.state.startTime):null,
                                    rules: [{ required: true, message: '开始时间不能为空!' }],
                                })(<DatePicker showTime placeholder="请选择开始时间" />)}
                                </Form.Item>
                                <Form.Item label='结束时间'>
                                {getFieldDecorator('endTime', {
                                    initialValue: this.state.edit?moment(this.state.endTime):null,
                                    rules: [{ required: true, message: '结束时间不能为空!' }],
                                })(<DatePicker showTime placeholder="请选择结束时间" />)}
                                </Form.Item>
                                <Form.Item label='维保合同'>
                                {getFieldDecorator('contractId', {
                                    initialValue: this.state.contractId,
                                    rules: [{ required: true, message: '维保合同不能为空!' }],
                                })(    <Select placeholder="绑定维保合同" 
                                                onChange={this.onBindContract}>
                                            {
                                                this.props.contracts.map(ele=> <Option key={ele.id} value={ele.id}>{ele.name}</Option>)
                                            }
                                        </Select>)}
                                </Form.Item>
                                <div className='contracts-items-container'>
                                {  !this.props.contracts.length? null:
                                    this.props.contracts.filter(ele=>ele.id === this.state.contractId).map(ele=>
                                        <div className='items-456' key={ele.id}>
                                            <div className='header-456'>
                                                <div className='title'>{ele.name}</div>
                                                <div className='folder-control' onClick={()=>this.switchContractFolder(ele.id)}>
                                                    <span className='folder'>{ele.foldered?'展开':'收起'}</span>
                                                    <img alt = 'triangle' className={ele.foldered?'folder-img':'folder-img inverse'} src={TriangleImg}/>
                                                </div>
                                            </div>
                                            <div className={ele.foldered?'content-456-folder content-456-container' : 'content-456-container'}>
                                                <div className='content-header'>
                                                    <div className='period'>
                                                        <span>{moment(ele.startTime).format('YYYY年MM月DD日')}</span> 
                                                        <span>至</span>
                                                        <span>{moment(ele.endTime).format('YYYY年MM月DD日')}</span> 
                                                    </div>
                                                    <Popconfirm
                                                        title="确定删除当前合同吗?"
                                                        onCancel = {()=>console.log('cancel')}
                                                        onConfirm={()=>this.deleteContract(ele.id)}
                                                        okText="是"
                                                        cancelText="否">
                                                    <div className='edit'>
                                                        <img src={deleteImg} alt='delete' className='edit-img'/>
                                                        <span>删除</span>
                                                    </div>
                                                    </Popconfirm>
                                                    
                                                </div>
                                                <div className='contract-content'>{ele.content}</div>
                                                {
                                                    !ele.foldered && ele.files.map(file=>
                                                    <div key={file.url} className='file-item'>
                                                        <span>{file.name}</span>
                                                        <a href={file.url} target='_blank'  rel="noopener noreferrer">文件链接</a>
                                                    </div>)
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                </div>
                            </Form> 
                        }
                    </div>
                   </div>
                
                   <div className = 'maintaince-content-all' hidden={this.state.tab1}>
                    <div className='left-container-project-wrapper'>
                        <div className='left-container-project'>
                            {  !this.state.contents || this.state.contents.length === 0 ?
                                <div className='left-item-project-active'>暂无内容</div>
                                : this.props.buildingItems.map((building, index)=>
                                        building? <div  key={building.id} 
                                        className={index === this.state.buildingIndex? 'left-item-project-active':'left-item-project'}
                                        onClick  = {()=>this.clickItems(building.id, index)}
                                    >{building.name}</div> :null
                                )
                            }
                        </div>
                    </div>
                    <div className='right-container-plan'>
                        <EditBar new={true}
                                newBtn={`添加${MAINTAINCE_TYPES.find(ele=>ele.value === this.state.type).label}`} 
                                onNew = {this.addNewInspectionContent}/>

                        <div className='right-content-project-456-wrapper'>
                            <div className='right-content-project-456'>
                            {
                               !this.state.contents || this.state.contents.length === 0 || this.props.inspectionPointsInSelectedBuilding.length === 0?
                                <div className='items-456'>
                                    <div className='header-456'>
                                        <div className='title'>暂无内容</div>
                                        <div >
                                            <span className='folder'>展开</span>
                                            <img alt = 'triangle' className='folder-img' src={TriangleImg}/>
                                        </div>
                                    </div>
                                    <div className='content-456-folder content-456-container'>

                                    </div>
                                </div>
                                :
                                this.props.inspectionPointsInSelectedBuilding.map((ele,index)=>
                                    <div className='items-456' key={index}>
                                        <div className='header-456'>
                                            <div className='title'>{ele.inspectionInFloor[0].floor.name}</div>
                                            <div className='folder-control' onClick={()=>this.switchFolder(index)}>
                                                <span className='folder'>{ele.foldered?'展开':'收起'}</span>
                                                <img alt = 'triangle' className={ele.foldered?'folder-img':'folder-img inverse'} src={TriangleImg}/>
                                            </div>
                                        </div>
                                        <div className={ele.foldered?'content-456-folder content-456-container' : 'content-456-container'}>
                                            {
                                                
                                            ele.inspectionInFloor.map(ele=>ele).splice(0,4).map(inspection=>
                                                    <div className='inspection-card' key={inspection.id}>
                                                        <div className='left'>
                                                            <div className='name'>{inspection.name}</div>
                                                            <div className='location'>{inspection.location || '暂未填写位置信息'}</div>
                                                        </div>
                                                        <div className='right'>
                                                            <img alt='edit' className='editImg' src={editImg} onClick={()=>this.editInspection(inspection.id)}/>
                                                            <div className='edit' onClick={()=>this.editInspection(inspection.id)}>修改</div>
                                                        </div>
                                                    </div>)
                                            }
                                            { ele.foldered ? null:
                                                ele.inspectionInFloor.map(ele=>ele).splice(4).map(inspection=>
                                                    <div className='inspection-card' key={inspection.id}>
                                                        <div className='left'>
                                                            <div className='name'>{inspection.name}</div>
                                                            <div className='location'>{inspection.location || '暂未填写位置信息'}</div>
                                                        </div>
                                                        <div className='right'>
                                                            <img alt='edit' className='editImg' src={editImg} onClick={()=>this.editInspection(inspection.id)}/>
                                                            <div className='edit' onClick={()=>this.editInspection(inspection.id)}>修改</div>
                                                        </div>
                                                    </div>)
                                            }
                                        </div>
                                    </div>
                                )
                            }                        
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

        )
    }
}

export default Form.create()(PlanEdit)