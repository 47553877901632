import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import axios from '../../axios.config'
import './styles.css'

const { Option } = Select;

@withRouter
class RemoteSelect extends Component {
  constructor(props) {
    super(props);
    this.lastFetchId = 0;
    this.fetchOptions = debounce(this.fetchOptions, 800);
  }

  state = {
    data: [],
    value: undefined,
    fetching: false,
    notFoundContent: null
  };

  notFoundContent = <div className='notfound-container'>
        <div className='sorry-text'>抱歉，没有该业主的信息</div>
        <div className='new-btn'onClick={this.props.onNewOwner}>注册新业主</div>
    </div>

  fetchOptions = value => {
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    this.setState({ data: [], fetching: true });
    let params={
        [this.props.searchKey]:value,
        size: 2000
    }
    if(this.props.additionalParams){
        params={
            ...params,
            ...this.props.additionalParams
        }
    }
    axios.get(this.props.url,{params}).then(res => {
        if (fetchId !== this.lastFetchId) {
          // for fetch callback order
          return;
        }

        const data = res.data;
        this.setState({ 
            data,
            notFoundContent: data.length?null:this.notFoundContent,
            fetching: false });
      });
  };

  onFocus=()=>{
      this.setState({
        notFoundContent:null
      })
  }

  handleChange = value => {
    let currentOption = this.state.data.find(ele=>ele.id === value);
    // let currentValue = currentOption.name;
    this.props.onRemoteSelect(currentOption);
    this.setState({
    //   value: currentValue,
      data: [],
      fetching: false,
    });
  };

  render() {
    const { fetching, data, value } = this.state;

    return (
      <Select
        // labelInValue
        // mode="multiple"
        showSearch
        showArrow={false}
        value={value}
        placeholder={this.props.placeholder || "Select users"}
        notFoundContent={fetching ? <Spin size="small" /> : this.state.notFoundContent}
        filterOption={false}
        onSearch={this.fetchOptions}
        onChange={this.handleChange}
        onFocus={this.onFocus}
        className='remote-select'
      >
        {data.map(d => (
          <Option key={d.id} value={d.id}>{d.name}</Option>
        ))}
      </Select>
    );
  }
}

export default RemoteSelect