import React , { Component} from 'react'
import { Input, message, Popconfirm , Spin} from 'antd';
import { withRouter} from 'react-router-dom'
import { connect} from 'react-redux'

import './styles.css'
import addImg from '../../assets/images/add.svg'
import Table from '../../components/table'
import { getUsersData, deleteUserFromOrg, emptyOwnerList} from '../../redux/06_users.redux'
import deleteImg from '../../assets/images/delete_red.svg'
import NextBar from '../../components/next-bar/next-bar'
import {getCurrentProject, dispatchCurrentProject }  from '../../redux/01_project.redux'

const Search = Input.Search;
@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.users,
            ...state.projects
        }
    },
    {getUsersData, dispatchCurrentProject, emptyOwnerList}
)
class OwnerList extends Component{

    componentWillMount(){
        // 重置userlist
        this.props.emptyOwnerList()
    }


    componentDidMount(){
        let {organizationId} = this.props.match.params;
        getCurrentProject(organizationId).then(res=>{
            this.props.dispatchCurrentProject(res)
        })
        this.setState({
            organizationId
        },()=>this.getOwners())
        
    }

    getOwners = (data)=>{
        const organizationId = this.state.organizationId;
        const rolesId = 4;
        const params = {
            'userOrgMap.organizationId':organizationId,
            'userOrgMap.roleId':rolesId,
            size: 2000,
            ...data
        }
        this.props.getUsersData(params, false);
    }

    onSearch = (value)=>{
       if(value){
        this.getOwners({
            // phoneLike_OR_nameLike: JSON.stringify([value,value])
            phoneLike: value
        })
       }else{
           this.getOwners();
       }
    }



    toRegisterOwner = ()=>{
        this.props.history.push(`/orgs-management/owners/bind/${this.state.organizationId}`)
    }

    generateList = (raw_dataSource)=>{
        if(raw_dataSource.length < 13){
            raw_dataSource.push(
                {
                    id: 'key'+raw_dataSource.length,
                    name: null,
                    phone: null,
                },
            )
            return this.generateList(raw_dataSource)
        }else {
            return raw_dataSource;
        }
        
    }

    onNextStep = ()=>{
        this.props.history.push(`/orgs-management/guide/buildings/${this.state.organizationId}`)
    }

    deleteUserFromOrg=(username)=>{
        let organizationId = this.state.organizationId;
        deleteUserFromOrg(username, [organizationId]).then(()=>{
            message.success('删除成功');
            //重新加载数据
            this.getOwners();
        },()=>message.error('删除失败'))
    }

    render(){
        let raw_dataSource = this.props.users;
        const dataSource = this.generateList(raw_dataSource);
          
          const columns = [
            {
              title: '业主姓名',
              dataIndex: 'name',
              key: 'name',
            },
            {
              title: '业主电话',
              dataIndex: 'phone',
              key: 'phone',
            },
            {
                title: '',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Popconfirm
                            title="确定移除当前业主吗?"
                            onCancel = {()=>console.log('cancel')}
                            onConfirm={()=>this.deleteUserFromOrg(record.username)}
                            okText="是"
                            cancelText="否"
                        >
                        {
                            record.rolesIds? 
                            <span className='delete-btn'>
                                <img alt ='delete' src={deleteImg}/>
                                <span className='detete-user'>删除</span>
                            </span>:null
                        }
                        </Popconfirm>

                    </span>
                ),
              },
          ];


        return (
            <div className='owner-list-content'>
                <div className='header-bar-owner'>
                    <Search
                        key = 'phone'
                        placeholder= '搜索业主账户(手机号)'
                        onSearch={value => this.onSearch(value)}
                        style={{ width: 400, marginLeft: 40 }}
                        />
                    <div onClick={this.toRegisterOwner} className='add-owner'>
                        <img  alt='add' src={addImg}  className='add-img' />
                        <span>添加业主</span>
                    </div>
                </div>
                {
                    this.props.loading?
                    <div className="spin-container-half">
                        <Spin  size="large" tip="正在加载..."/>
                    </div>
                    :
                    <div className='main-table-container'>
                        <Table dataSource={dataSource.map(ele=>({...ele, key:ele.id}))} columns={columns}/>
                    </div>
                }
                <NextBar onNextStep={this.onNextStep}/>
            </div>
        )
    }
}

export default OwnerList