import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Form, Input,Select,message, Spin} from 'antd';

import EditBar from '../../components/edit-bar/edit-bar';
import {formItemLayout} from '../../components/layout/formlayout'
import {getBuildingsData,getFloorsData} from '../../redux/02_buildings.redux'

import { getCurrentInspectionPoint, updateInspectionPointRequest, deleteInspectionPoint }  from '../../redux/03_inspection.redux'
import { getCurrentProject, dispatchCurrentProject} from '../../redux/01_project.redux'
import {MAINTAINCE_TYPES} from '../../constants/constants'
import successImg from '../../assets/images/success.svg'
import addImg from '../../assets/images/add.svg'

const Option = Select.Option;
@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.projects,
            ...state.buildings
        }
    },
    {getBuildingsData,getFloorsData, dispatchCurrentProject}
)
class ProjectEdit extends Component{
    constructor(props){
        super(props);
        this.state = {
            edit: false,
            tab1: true,
            type:1,
            items:[],
            items2:[],
            contents:[],
            newContentPage: false,
            itemId:null,
            loading: false,
            iconLoading: false
        };
    }
    componentDidMount(){
        
        if(Object.keys(this.props.match.params).length){
            const {inspectionPointId,organizationId} = this.props.match.params;
            // 获取当前项目信息
            organizationId && this.onOrganizationUpdate(organizationId);
            inspectionPointId && this.setState({loading: true});
            inspectionPointId && getCurrentInspectionPoint(inspectionPointId).then(res=>{
                this.setState(res,()=>{
                    this.switchMaintainceType(1)
                });
                this.setState({
                    id: inspectionPointId, 
                    edit: true,
                    loading: false,
                })
            })
        }else {
            this.onOrganizationUpdate();
        }
    }

    // 根据是否有已选项目重置当前项目状态，拉取楼栋楼层
    onOrganizationUpdate = (organizationId)=>{
        if(organizationId){
            getCurrentProject(organizationId).then(res=>{
                this.props.dispatchCurrentProject(res);
            })
            this.props.getBuildingsData({
                size: 2000,
                organizationId
            });
        }else{
            //进入新建页面，且未选定project时，获取所有的楼栋楼层信息，并将当前项目重置为空
            this.props.getBuildingsData({
                size: 2000
            });
        }
    }

    handleSubmit = e => {
        
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let body = {
                    ...values,   
                    organizationId: this.props.currentProject.id                 
                }
                this.setState({iconLoading: true});
                updateInspectionPointRequest(body, this.state.edit,this.state.id).then(res=>{
                    message.success(this.state.edit?'更新巡查点成功':'创建巡查点成功');
                    const isFromGuidePage = this.props.history.location.pathname.indexOf('orgs-management') > -1;
                    const path = isFromGuidePage ? 
                                 `/orgs-management/inspections/edit/${this.props.currentProject.id}/${res.id}`
                                 :`/inspections/edit/${this.props.currentProject.id}/${res.id}`
                    this.props.history.push(path)

                }).finally(()=>this.setState({iconLoading: false}));
            }
        });
        
    };

    switchTab = (tab)=>{
        if(this.state.edit){
            this.setState({
                tab1: tab === 'tab1'
            })
        }else if(tab === 'tab2'){
            message.warn('请先保存巡查点基本信息')
        }
    }

    switchMaintainceType(value){
        // 生成当前类型下的类别
        let items2 = this.state.items.filter(ele=>ele.type === value);
        // console.log(items2)
        this.setState({
            items2,
            type: value
        },()=>{
            this.switchMaintainceItem(items2.length? items2[0].id: null)
        })
    }

    switchMaintainceItem(itemId){
        const currentTypeItem = this.state.items.find(ele=>ele.id === itemId);
        let contents =currentTypeItem? currentTypeItem.contents:[];
        this.setState({
            itemId,
            contents
        })

    }

    // TODO: 当为新建状态时，在没有保存之前，不能进行维保内容的编辑
    addMaintenaceContent=()=>{
        const isFromGuidePage = this.props.history.location.pathname.indexOf('orgs-management') > -1;
        const path = isFromGuidePage? 
                     `/orgs-management/inspections/edit/${this.props.currentProject.id}/${this.state.id}/${this.state.type}`
                     : `/inspections/edit/${this.props.currentProject.id}/${this.state.id}/${this.state.type}`;
        this.props.history.push(path)
    }

    deleteInspectionPoint=()=>{
        deleteInspectionPoint(this.state.id).then(()=>{
            message.success('删除成功');
            const isFromGuidePage = this.props.history.location.pathname.indexOf('orgs-management') > -1;
            const path = isFromGuidePage? `/orgs-management/guide/inspection/${this.props.currentProject.id}` : `/inspections`;
            this.props.history.push(path);
        })
    }

    // 改变building的选择后，需重新拉取楼层
    switchBuilding=(buildingId)=>{
        let params = {
            buildingId
        }
        if(this.props.currentProject.id){
            params={
                ...params,
                size: 2000, 
                organizationId: this.props.currentProject.id
            }
        }
        this.props.getFloorsData(params);
    }
    
    render(){
        // console.log(this.props)
        const { getFieldDecorator } = this.props.form;   
    
        return (
            <div>
                <div className='edit-content'>
                    <EditBar save={true} iconLoading={this.state.iconLoading} delete={this.state.edit} back={true} onSave={this.handleSubmit} onDelete={this.deleteInspectionPoint}/>
                    <div className='plan-tabs'>
                        <div className={this.state.tab1?'tab-active':'tab'} onClick={()=>this.switchTab('tab1')}>基本信息</div>
                        <div className = {!this.state.tab1?'tab-active':'tab'} onClick={()=>this.switchTab('tab2')}>维保内容</div>
                    </div>
                    <div className='edit-form-container  brief-content brief-content-contract' hidden={!this.state.tab1}>
                            {
                                this.state.loading?
                                <div className="spin-container-half">
                                    <Spin  size="large" tip="正在加载..."/>
                                </div>
                                :
                                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                                    <Form.Item label='巡查点名称'>
                                    {getFieldDecorator('name', {
                                        initialValue: this.state.name,
                                        rules: [
                                        {
                                            required: true,
                                            message: '巡查点名称不能为空',
                                        },
                                        ],
                                    })(<Input  placeholder="请输入巡查点名称"/>)}
                                    </Form.Item>
                                    <Form.Item label='楼栋'>
                                    {getFieldDecorator('buildingId', {
                                        initialValue: this.state.buildingId,
                                        rules: [{ required: true, message: '楼栋不能为空!' }],
                                    })(    <Select placeholder="请选择楼栋" onChange={this.switchBuilding}>
                                            {
                                                this.props.buildings.map(ele=> <Option key={ele.id} value={ele.id}>{ele.name}</Option>)
                                            }
                                            </Select>)}
                                    </Form.Item>
                                    <Form.Item label='楼层'>
                                    {getFieldDecorator('floorId', {
                                        initialValue: this.state.floorId,
                                        rules: [{ required: true, message: '楼层不能为空!' }],
                                    })(    <Select placeholder="请选择楼层" >
                                            {
                                                this.props.floors.map(ele=> <Option key={ele.id} value={ele.id}>{ele.name}</Option>)
                                            }
                                            </Select>)}
                                    </Form.Item>
                                    <Form.Item label='详细地址'>
                                    {getFieldDecorator('location', {
                                        initialValue: this.state.location,
                                        rules: [{ required: true, message: '详细地址不能为空!' }],
                                    })(<Input placeholder="请输入详细地址"/>)}
                                    </Form.Item>
                                </Form>
                            }
                        </div>
                        <div className='maintaince-content-all' hidden={this.state.tab1}>
                            <div className='maintaince-column1'>
                                {
                                    MAINTAINCE_TYPES.map(ele=>
                                                    <div key={ele.value} 
                                                        className={ele.value === this.state.type?'item1 item1-active':'item1'}
                                                        onClick = {()=>{this.switchMaintainceType(ele.value)}}>
                                                        {ele.label}</div>
                                    )
                                }
                                
                            </div>
                            <div className='maintaince-column2'>
                                <div className='title'>{MAINTAINCE_TYPES.find(ele=>ele.value === this.state.type).item_label}</div>
                                <div className='bottom-content'>
                                    {
                                        this.state.items2.map(ele=><div key={ele.id} className={ele.id === this.state.itemId ?'item2 item2-active':'item2'}
                                                                        onClick = {()=>{this.switchMaintainceItem(ele.id)}}>
                                                                        <img alt='success' className='item-success' src={successImg}/>
                                                                        <span>{ele.name}</span>
                                                                    </div>)
                                    }
                                </div>
                                
                            </div>
                            <div className='maintaince-column3'>
                                <div className='title'><div className='title'>{MAINTAINCE_TYPES.find(ele=>ele.value === this.state.type).label}</div></div>
                                <div className='bottom-content'>
                                {
                                    this.state.contents.map(ele=><div key={ele.id} className='item3'>
                                                                    <img alt='success' className='item-success' src={successImg}/>
                                                                    <span>{ele.content}</span>
                                                                </div>)
                                }
                                </div>
                            </div>
                            <div className='right-content'>
                                <div className ='add-flex' onClick={this.addMaintenaceContent}>
                                    <img src={addImg} alt='add' />
                                    <span>编辑{MAINTAINCE_TYPES.find(ele=>ele.value === this.state.type).label}</span>
                                </div>
                            </div>
                        </div> 
                </div>
            </div>
        )
    }
}

export default  Form.create()(ProjectEdit)