import React, { Component } from 'react';
import { Switch, Route} from 'react-router-dom';
import AcceptancePList from './acceptance_p';
import AcceptancePEdit from './acceptance_p.edit'
import AcceptancePExamine from './acceptance_p.examine'
import AcceptancePDetail from './acceptance_p.detail'
import HomeLayout from '../../components/layout/home.layout'
import NoneSideBarLayout from '../../components/layout/non-sidebar.layout'

class AcceptancePRouter extends Component {
  render() {
    const acceptancePList = <HomeLayout>
                        <AcceptancePList></AcceptancePList>
                    </HomeLayout>
    const acceptancePeditView  = <HomeLayout hideProject={true}>
                        <Route  path='/acceptance_p/register' component={AcceptancePEdit}/>
                        <Route  path='/acceptance_p/edit/:id' component={AcceptancePEdit}/>
                        <Route  path='/acceptance_p/acceptance_examine_p/:id' component={AcceptancePExamine}/>  
                        <Route  path='/acceptance_p/acceptance_detail_p/:id' component={AcceptancePDetail}/>  
                    </HomeLayout>
    // const acceptanceExamine = <HomeLayout>
    //           <Route  path='/acceptance/drawing_examine/:id' component={AcceptanceExamine}/> 
    //       </HomeLayout>

    return (
      <Switch>
        <Route exact path='/acceptance_p' render={()=>acceptancePList}/>
        <Route path='/acceptance_p' render={()=>acceptancePeditView}/>
      </Switch>
    );
  }
}

export default AcceptancePRouter;


// import React,{Component} from "react";

// export default class App extends Component{
//     constructor(props){
//         super(props)

//         this.state = {
//             counter : 0,
//         }
//     }
    

//     render(){
//         const {title,name,age} = this.props;
//         return (
//             <div>
//                 <div>{title},{name},{age}</div>
//                 <p>当前计数：{this.state.counter}</p>
//                 <button onClick={e => this.setNums(1)}>+</button>
//                 <button onClick={e => this.setNums(2)}>-</button>
//             </div>
//         )   
//     }

//     setNums(type) {
//         if(type==1){
//             this.setState({
//                 counter : this.state.counter + 1
//             })
//         }else{
//             this.setState({
//                 counter : this.state.counter - 1
//             })
//         }
//     }
// }

