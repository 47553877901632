
import axios from '../axios.config'
import {HIDE_LOADING, SHOW_LOADING, hideLoading, showLoading, getRoles, getOrganizationsByIDs} from './00_common.action'

const USERS_DATA = 'USERS_DATA';

//reducer
const initState = {
    users : [],
    total:0,
    start:0,
    loading: true
}


export function users(state = initState, action) { 
    switch(action.type){
        case USERS_DATA:
            return { ...state, 
                users: action.payload.data,
                total: action.payload.total,
                start: action.payload.start
             }
        case SHOW_LOADING:
             return {
                 ...state,
                 loading: true,
             }
        case HIDE_LOADING:
             return {
                 ...state,
                 loading: false
             }
        default:
            return state
    }
}

//action creator
export function getUsersData(params, needDetail = true){
    // dispatch 用来通知数据修改
    return dispatch => {
        dispatch(showLoading())
        axios.get('/account/users',{params})
            .then(res=>{
                if(needDetail){
                    let newData =[];
                    let organizationIds = [];
                    let organizations = res.data.map(ele=>{
                        return ele.userOrgMap.map(org=>org.organizationId)
                    });
                    organizations.forEach(ele=>{
                        organizationIds = organizationIds.concat(ele)
                    })
                    organizationIds = Array.from(new Set(organizationIds))
                    
                    Promise.all([getRoles(), getOrganizationsByIDs(organizationIds)]).then(resp=>{ 
                        newData=res.data.map(ele=>({
                            ...ele,
                            role: resp[0].data.find(role=>{
                                // 如果有userOrgMap就使用userOrgMap中的roleId,如果没有，则使用外层的rolesIds
                                const _roleIds = ele.userOrgMap&&ele.userOrgMap.length ?ele.userOrgMap.map(ele=>ele.roleId) : ele.rolesIds;
                                return role.id === _roleIds[0];
                            }),
                            organization: ele.userOrgMap.map(org=>resp[1].data.find(ele=>ele.id === org.organizationId)?
                                            resp[1].data.find(ele=>ele.id === org.organizationId).name:null)
                        }))

                        dispatch(usersData({
                            ...res,
                            data: newData
                        }))
                    })
                }else{
                    dispatch(usersData(res))
                }         
            }).finally(()=>dispatch(hideLoading()))
    }
}

// 重置业主列表为空
export function emptyOwnerList(){
    return dispatch=>{
        let data={
            data: [],
            start:0,
            total:0
        };
        dispatch(usersData(data))
    }
}


export function usersData(data) {
    return {type: USERS_DATA, payload: data}
}
/** 
 * 非redux管理的function
*/

export  function getCurrentUser(id){
    return axios.get(`/account/users/${id}`)
}

// 添加用户至单位，或更新用户
export function updateUserRequest(data,edit=false, id){
    if(edit){
        return axios.post(`/account/users/organization`,data)
    }else {
        return axios.post('/account/users/organization',data)
    }
}


export function addUser(data){
    return axios.post(`/account/users`,data)
}

//从单位移除用户
export function deleteUserFromOrg(username, organizationIds){
    return axios.delete('/account/users/organization',{
        params:{
            username,
            organizationIds: JSON.stringify(organizationIds)
        }
    })
}