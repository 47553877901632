
import axios from '../axios.config'
import {HIDE_LOADING, SHOW_LOADING, hideLoading, showLoading} from './00_common.action'

const REGION_DATA = 'REGION_DATA';
//reducer
const initState = {
    raw_regions:[],
    regions : [],
    start: 0,
    total:0,
    loading: true
}


export function region(state = initState, action) { 
    switch(action.type){
        case REGION_DATA:
            return { ...state,
                 raw_regions: action.payload.data, 
                 start: action.payload.start,
                 total: action.payload.total,
                 regions: regionsMapToCascader(action.payload.data)  }
        case SHOW_LOADING:
            return {
                ...state,
                loading: true,
            }
        case HIDE_LOADING:
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}

//action creator
export function getRegionData(params){
    // dispatch 用来通知数据修改
    return dispatch => {
        dispatch(showLoading())
        axios.get('/region/regions', {params})
            .then(res=>{
                dispatch(regionData(res))
            },err=>{
            }).finally(()=>dispatch(hideLoading()))
    }
}
export function regionData(data) {
    return {type: REGION_DATA, payload: data}
}



function regionsMapToCascader(regions = []){
    let provinces_all = regions.filter(ele=>ele.level === 0);     /// TODO: SomeError Here
    
    let result = provinces_all.map(province=>{
        let citys = getCitys(province.id);
        return {
            value: province.id,
            label: province.name,
            children: citys
        }
    })
    
    function getCitys(provinceId){
        let cities = regions.filter(city=>city.superiorId === provinceId);
        return cities.map(city=>{
            let countries = getCountries(city.id);
            return {
                value: city.id,
                label: city.name,
                children: countries
            }
        })
    }
    
    function getCountries(cityId){
        let countries =  regions.filter(country=>country.superiorId=== cityId);
        countries =  countries.map(country=>{
            return {
                value: country.id,
                label: country.name
            }
        });
        return countries.length? countries: null
    }
    return result
}
