import React, {Component}  from 'react';
import { connect } from 'react-redux'
import moment from 'moment'
import { withRouter, Link} from 'react-router-dom'

import CustomPagination from '../../components/pagination/pagination'
import Header from '../../components/header';
import Table from '../../components/table'
import add from '../../assets/images/add.svg';
import editImg  from '../../assets/images/edit_white.svg'
import './styles.css';
import { getContractsData } from '../../redux/04_contract.redux'
import LineWrap from '../../components/LineWrap';


@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.contracts
        }
    },
    {getContractsData}
)
class ContractList extends Component {

    componentDidMount(){
        this.props.getContractsData({start: 0})
    }
    pageChangeHandler(page) {
        this.props.getContractsData({start: page-1})
    }
    onSearch = (params)=>{
        this.props.getContractsData(params);
    }


    addNewContract = ()=>{
        this.props.history.push('/contracts/add')
    }

    render(){
        const filters = [{
            key:'nameLike',
            placeholder:'合同名称'
        }];

        const columns = [
            {
              title: '合同名称',
              dataIndex: 'name',
              key: 'name',
              width: '10%'
            },
            {
              title: '所属项目',
              dataIndex: 'organization.name',
              key: 'organization.name',
              width: "10%",
            },
            {
                title: '合同内容',
                key: 'content',
                width: "20%",
                render: (text, record)=>(
                     <LineWrap title={record.content} lineClampNum={2}/>
                )
            },
            {
            title: '开始时间',
            key: 'startTime',
            width: 150,
            render: (text, record)=>(
                    <span>{moment(record.startTime).format('YYYY-MM-DD')}</span>
                )
            },
            {
            title: '结束时间',
            key: 'endTime',
            width: 150,
            render: (text, record)=>(
                    <span>{moment(record.endTime).format('YYYY-MM-DD')}</span>
                )
            },
            {
                title: '文件链接',
                key: 'files',
                render: (text, record)=>(
                        <span>
                            {
                                record.files?record.files.map(file=>
                                <div key={file.url}>
                                    <a className='file_link'  target='_blank' rel="noopener noreferrer" href={file.url}>{file.name}</a>
                                </div>):'暂未配置'
                            }
                        </span>
                )
            },
            {
                title: '项目负责人',
                dataIndex: 'responsibleUser.name',
                key: 'responsibleUser.name',
                width: "10%",
            },
            {
              title: '',
              key: 'action',
              width: 180,
              render: (text, record) => (
                <Link className='edit-action' to={`/contracts/edit/${record.organizationId}/${record.id}`}>
                  <img src={editImg} alt='edit'></img>
                  查看修改
                </Link>
              ),
            },
          ];
          
        // console.log(this.props)
        return (
            <div className = 'content'>
                <Header title = '维保合同列表' filters ={filters} remoteSelectFilters={['organizationId']}  onSearch={this.onSearch}>
                    <div className='new_org new' onClick={this.addNewContract} >
                        <img src={add} alt='add' className='add_img  new'></img>新建合同
                    </div>
                </Header>
                <div className='main-table-container'>
                    <Table  columns={columns} 
                            scroll={{ y: 640 }}
                            dataSource={this.props.contracts.map((ele,index)=> {return {...ele, key:index}})} 
                            loading={this.props.loading}/>
                    <CustomPagination
                        total={this.props.total}
                        onChange={(e)=>{this.pageChangeHandler(e)}}/>
                </div>
            </div>
        )
    }
}

export default ContractList