import React, {Component}  from 'react';
import { connect }  from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import { Select } from 'antd';

import closeImg from '../../../assets/images/close.svg'

import {getProjectsData, dispatchCurrentProject,} from '../../../redux/01_project.redux'
import { getBuildingsData} from '../../../redux/02_buildings.redux'
import { getContractsData} from '../../../redux/04_contract.redux'
import './styles.css';

const { Option } = Select;
@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.projects
        }
    },
    { getProjectsData, dispatchCurrentProject, getBuildingsData, getContractsData}
)
class TopBarInside extends Component {
    componentDidMount(){
        // 获取所有项目以供选择
        this.props.getProjectsData({size:2000},null, false)
    }
 
    onClose = ()=>{
        const path = this.props.match.path;
        this.props.history.push(path);
        this.props.dispatchCurrentProject({});
    }

    onChange = (projectId) =>{
        const currentProject = this.props.projects.find(ele=>Number(ele.id) === Number(projectId));
        this.props.dispatchCurrentProject(currentProject);
        if(this.props.match.path.indexOf('building') > -1 || this.props.match.path.indexOf('inspections') > -1){
            // 如果是building，重新加载building信息
            this.props.getBuildingsData({
                size: 2000,
                organizationId: projectId
            })
        }else if(this.props.match.path.indexOf('plan') > -1){
            this.props.getContractsData({organizationId:projectId})
        }
      }
    render(){
        // console.log(this.props)
        return (
            <div className='header-container header-container-topbar-inside'>
                
                    {this.props.isAuth?  null: <Redirect to = '/login'></Redirect>}
                    <div className='header-left-topbar-inside'>
                    {  this.props.hideProject? null:
                    <div className='title'>
                            <span className='current-project'>当前项目 : </span>
                        {
                            Object.keys(this.props.currentProject).length?
                            <div>{this.props.currentProject.name}</div> : 
                            <div>
                                <span>未选择</span>
                                {
                                    this.props.projectSelectHide?null:
                                    <Select
                                            showSearch
                                            className='project-search'
                                            placeholder="选择项目"
                                            optionFilterProp="children"
                                            onChange={this.onChange}>
                                            {
                                                this.props.projects.map(ele=><Option key={ele.id} value={ele.id}>{ele.name}</Option>)
                                            }
                                    </Select>
                                }
                                
                            </div>
                        }
   
                    </div> }
                </div>
               
                
                <div className='header-right-topbar-inside' onClick={this.onClose}>
                    <img className='close' src={closeImg} alt='close'/>
                    <span>退出</span>
                </div>
            </div>
        )
    }
}

export default TopBarInside