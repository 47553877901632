import React, { Component } from 'react';
import { Switch, Route} from 'react-router-dom';
import Collection from './collection';
import HomeLayout from '../../components/layout/home.layout'

class CollectionRouter extends Component {
  render() {
    const collection = <HomeLayout>
                        <Collection></Collection>
                    </HomeLayout>


    return (
      <Switch>
        <Route exact path='/collection' render={()=>collection}/>
      </Switch>
    );
  }
}

export default CollectionRouter;
