import React, {Component}  from 'react';
import { Icon , Modal, Slider } from 'antd';
import './styles.css';

class timeAxis extends Component{
    constructor(props){
        super(props)

        this.state = {
            min: '',
            max: '',
            day: 0
        }
    }

    componentWillMount(){
        let { startDate, endDate } = this.props, 
            min = this.timeFmt(startDate),
            max = this.timeFmt(endDate),
            nowDef = Date.parse(new Date());
        if(nowDef>max){
            max = nowDef;
        }
        this.setState({
            min: min,
            max: max
        })
        this.changeSlider(nowDef);
    }


    render(){
        let { startDate, endDate } = this.props; 
        let step = 86400,
            min = this.state.min,
            max = this.state.max, 
            nowDef =Date.parse(new Date());
        
        return (
            <div className='timeaxis'>
                <div className="timeaxis_re">
                    <Slider 
                        min={min}
                        max={max}
                        step={step}
                        disabled={true}
                        tipFormatter={this.doTipFormatter}
                        defaultValue={nowDef} 
                        onChange={this.changeSlider} 
                        tooltipVisible={true} 
                        getTooltipPopupContainer={()=>document.querySelector('.timeaxis_re')}  
                    />
                </div>
                <div className="timeaxis_ab">
                    <div className="tibb tibb1">{this.fmtTime(min)}</div>
                    <div className="tibb tibb2">{this.fmtTime(max)}</div>
                </div>
            </div>

        )
    }

    //时间格式转时间戳
    timeFmt(date){
        let dates = new Date(date); 
        return (dates.getTime());
    }

    //时间戳转时间格式
    fmtTime(str){ 
        let date = new Date(str);
        let year = date.getFullYear();
        let month= date.getMonth() + 1;
        month= month< 10 ? ('0' + month) : month;
        let day = date.getDate();
        day = day < 10 ? ('0' + day ) : day ;
        
        return year + '' + month + '-' + day ;

    }

    //tip格式化
    doTipFormatter = (val) => {
        let day = this.state.day; 
        return `还剩${day}天`;
    }


    //值改变时触发
    changeSlider = (e) => {
        let { endDate } = this.props; 
        let max = this.timeFmt(endDate),
            nowDef = Date.parse(new Date());
        if(nowDef>max){
            max = nowDef;
        }
        let alldays = max - e;
        let day = parseInt((alldays/1000)/86400);
        this.setState({
            day: day
        })
        this.props.handeChangeSlider(day);
    }
}

export default timeAxis