import axios from '../axios.config'
import {
    HIDE_LOADING,
    SHOW_LOADING,
    hideLoading,
    showLoading,
    getBuildingsByIDs,
    getOrganizationsByIDs
} from './00_common.action'

const BUILDINGS_DATA = 'BUILDINGS_DATA'
const FLOORS_DATA = 'FLOORS_DATA';
const CURRENT_BUILDING = 'CURRENT_BUILDING'
const HIDE_BUILDING_LOADING = 'HIDE_BUILDING_LOADING';
const SHOW_BUILDING_LOADING  = 'SHOW_BUILDING_LOADING '

//reducer
const initState = {
    buildings: [],
    floors: [],
    total: 0,
    start: 0,
    loading: true,
    buildingLoading: true,
    currentBuildingId: null
}


export function buildings(state = initState, action) {
    switch (action.type) {
        case FLOORS_DATA:
            return {
                ...state,
                floors: action.payload.data,
                    total: action.payload.total,
                    start: action.payload.start,
            }
        case BUILDINGS_DATA:
            return {
                ...state,
                buildings: action.payload.data
            }
        case CURRENT_BUILDING: 
            return {
                ...state,
                currentBuildingId: action.payload
            }
        case SHOW_LOADING:
            return {
                ...state,
                loading: true,
            }
         case HIDE_LOADING:
            return {
                ...state,
                loading: false
            }
        case HIDE_BUILDING_LOADING:
            return {
                ...state,
                buildingLoading: false
            }
        case SHOW_BUILDING_LOADING:
            return {
                ...state,
                buildingLoading: true
            }
        default:
            return state
    }
}

//action creator
export function getFloorsData(params, needDetail =true) {
    // dispatch 用来通知数据修改
    return dispatch => {
        dispatch(showLoading())
        axios.get('/building/floors', {
                params
            })
            .then(res => {
                if(needDetail){
                    let organizationIds = res.data.map(ele=>ele.organizationId);
                    let buildingIds = res.data.map(ele=>ele.buildingId);
                    Promise.all([getBuildingsByIDs(buildingIds),getOrganizationsByIDs(organizationIds)]).then(res2=>{
                        let _data = res.data.map(ele=>{
                            return {
                                ...ele,
                                building: res2[0].data.find(building=>building.id === ele.buildingId),
                                organization: res2[1].data.find(origanization=>origanization.id === ele.organizationId)
                            }
                        })
                        let payload = {
                            ...res,
                            data: _data
                        }
                        dispatch(floorsData(payload))
                    })   
                }else{
                    dispatch(floorsData(res))
                }                            
            }).finally(() => dispatch(hideLoading()));
    }
}

export function getBuildingsData(params,buildingId = undefined) {
    // dispatch 用来通知数据修改
    return dispatch => {
        dispatch({type: SHOW_BUILDING_LOADING})
        axios.get('/building/buildings', {
                params
            })
            .then(res => {
                let buildingsWithCheck = generateBuildings(res.data, buildingId);
                let payload = {
                    ...res,
                    data: buildingsWithCheck
                }
                dispatch(buildingsData(payload));
                dispatch({type: HIDE_BUILDING_LOADING});
                if(res.data.length){
                    buildingId = buildingId || res.data[0].id
                    dispatch(currentBuildingId(buildingId)) && dispatch(getFloorsData({
                        size: 2000,
                        buildingId
                    })) 
                }else{
                    dispatch(floorsData({data: []}));
                }
            })
    }
}


// 选择楼栋后更新check信息

export function checkBuilding(buildings, buildingId) {
    return dispatch => {
        let newBuildings = buildings.map(ele => {
            return {
                ...ele,
                checked: ele.id === buildingId
            };
        })

        dispatch(getFloorsData({
            size: 2000,
            buildingId
        }));
        dispatch(currentBuildingId(buildingId));   // 保存当前所选建筑物ID
        dispatch(buildingsData({data: newBuildings}))
    }
}

// 改变建筑物的编辑状态
export function switchBuildingEditStatus(buildings, buildingId){
    return dispatch=>{
        let newBuildings = buildings.map(ele => {
            if(ele.id === buildingId){
                return {
                    ...ele,
                    editing: !ele.editing
                };
            }
            return ele;
        });
        dispatch(buildingsData({data: newBuildings}))      
    }
}

// 改变楼层的编辑状态
export function switchFloorEditStatus(buildings, buildingId){
    return dispatch=>{
        let newBuildings = buildings.map(ele => {
            if(ele.id === buildingId){
                return {
                    ...ele,
                    editing: !ele.editing
                };
            }
            return ele;
        });
        dispatch(floorsData({data: newBuildings}))      
    }
}


export function buildingsData(data) {
    return {
        type: BUILDINGS_DATA,
        payload: data
    }
}


export function floorsData(data) {
    return {
        type: FLOORS_DATA,
        payload: data
    }
}

//保存当前建筑物信息， 用于创建楼层
function currentBuildingId(id){
    return {
        type: CURRENT_BUILDING,
        payload: id
    }
}

//******************非redux管理的方法 *****************/

function generateBuildings(originData, buildingId) {
    return originData.map((ele, index) => {
        return {
            ...ele,
            checked: buildingId ? ele.id === buildingId : index === 0,
            editing: false
        }
    })
}


/** 
 * 获取当前楼层信息
*/
export function getCurrentFloor(id) {
    return axios.get(`/building/floors/${id}`)
}

/** 
 * 获取当前建筑物信息
*/
export function getCurrentBuilding(id) {
    return axios.get(`/building/buildings/${id}`)
}

export function addBuildingsRequest(data) {
    return axios.post('/building/buildings', data)
}

export function addFloorsRequest(data) {
    return axios.post('/building/floors', data)
}

// 更新建筑物
export function updateBuilding(data, edit=false, id) {
    if(edit){
        return axios.put(`/building/buildings/${id}`, data)
    }else {
        return axios.post(`/building/buildings`, data)
    }
}

//更新楼层
export function updateFloor(data,edit=false,id) {
    if(edit){
        return axios.put(`/building/floors/${id}`, data)
    }else{
        return axios.post(`/building/floors`, data)
    }
}


// 删除建筑物
export function deleteBuilding(id) {
    return axios.delete(`/building/buildings/${id}`)
}

// 删除楼层
export function deleteFloor(id) {
    return axios.delete(`/building/floors/${id}`)
}

// 批量添加建筑物
export function batchAddBuildingRequest(data){ 
    return axios.post('/building/buildings/batch',{
        paramStr: JSON.stringify(data)
    })
}
// 批量添加楼层
export function batchAddFloorRequest(data){ 
    return axios.post('/building/floors/batch',{
        paramStr: JSON.stringify(data)
    })
}