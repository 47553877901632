import React, {Component}  from 'react';
import { withRouter } from 'react-router-dom'
import { Popconfirm, Button} from 'antd';

import './styles.css';
import deleteImg from '../../assets/images/delete_red.svg'
import editImg from '../../assets/images/edit_white.svg'
import backImg from '../../assets/images/back.svg'
import addImg from '../../assets/images/add.svg'

@withRouter
class EditBar extends Component {

    onBack = ()=>{
        this.props.history.goBack();
    }

    render(){

        return (
            <div className='container-edit-bar'>
                {
                    this.props.children
                }
                {
                    this.props.back? <div className='save-delete-bar' onClick={this.onBack}>
                                        <img className='delete' src={backImg} alt='back'/>
                                        <span>返回</span>
                                    </div> : null
                }
                {/* {  this.props.delete?
                    <Popconfirm
                        title="确定删除吗?"
                        onCancel = {()=>console.log('cancel')}
                        onConfirm={this.props.onDelete}
                        okText="是"
                        cancelText="否"
                    >
                    <div className='save-delete-bar'>
                        <img className='delete' src={deleteImg} alt='delete'/>
                        <span>删除</span>
                    </div>
                    </Popconfirm>
                     : null */}
                {/* } */}
                {/* {  this.props.edit?
                    <div className='save-delete-bar' onClick={this.props.onEdit}>
                        <img className='delete' src={editImg} alt='edit'/>
                        <span>修改</span>
                    </div> : null
                } */}
                {  this.props.new?
                    <div className='save-delete-bar new-bar' onClick={this.props.onNew}>
                        <img className='delete' src={addImg} alt='add'/>
                        <span>{this.props.newBtn || '添加信息'}</span>
                    </div> : null
                }
                {   this.props.save?
                    // <div className='save-edit-bar' onClick={this.props.onSave}>
                    //     <img className='save' src={saveImg} alt='save'/>
                    //     <span>保存</span>
                    // </div> 
                    
                    <Button
                        type="primary"
                        icon="save"
                        className='save-edit-bar'
                        loading={this.props.iconLoading}
                        onClick={this.props.onSave}
                    >
                        保存
                    </Button>:null
                }

            </div>
        )
    }
}

export default EditBar