import React, {Component}  from 'react';
import { connect } from 'react-redux'
import moment from 'moment'
import { withRouter} from 'react-router-dom'
import {Spin} from 'antd'

import { getPlansData, switchFolder, plansData } from '../../redux/05_plan.redux'
import {getCurrentProject, dispatchCurrentProject }  from '../../redux/01_project.redux'
import {TICKET_TYPES, PERIOD_TYPES} from '../../constants/constants'

import './styles.css';
import NextBar from '../../components/next-bar/next-bar'
import EditBar from '../../components/edit-bar/edit-bar'
import TriangleImg from '../../assets/images/Triangle.svg'
import EditImg from '../../assets/images/edit_white.svg'


@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.plans,
            ...state.projects
        }
    },
    {getPlansData, switchFolder, dispatchCurrentProject}
)
class PlanGuideList extends Component {
    state={
        yearIndex: 0
    }

    componentWillMount(){
        // 重置plans为空
        let store = require('../../index').store;
        store.dispatch(plansData({data:[]}));
    }

    componentDidMount(){

        let {organizationId} = this.props.match.params;
        getCurrentProject(organizationId).then(res=>{
            this.props.dispatchCurrentProject(res)
        })
        this.props.getPlansData({
            organizationId,
            size: 2000})
    }

    addNewContract = ()=>{
        this.props.history.push('/orgs-management/plans/add')
    }

    editPlan=(id)=>{
        this.props.history.push(`/orgs-management/plans/edit/${this.props.currentProject.id}/${id}`)
    }
    switchFolder=(id)=>{
        this.props.switchFolder(this.props.plans, id)
    }
    onSaveAndQuit = ()=>{
        this.props.history.push('/orgs-management');
    }

    clickItems = (yearIndex)=>{
        this.setState({yearIndex})
    }

    render(){
        // console.log(this.props)
        // 
        const yearItems = this.props.plans? 
                        Array.from(new Set(this.props.plans.map(ele=>moment(ele.startTime).year()))).sort((a,b)=>b-a) : [];
        const plansInSelectedYear = this.props.plans? this.props.plans
                                                    .filter(ele=>moment(ele.startTime).year() === yearItems[this.state.yearIndex]):[]

        return (
            <div className = 'edit-content-project edit-content-project-456'>
                {
                    this.props.loading?
                    <div className="spin-container-half">
                        <Spin  size="large" tip="正在加载..."/>
                    </div>
                    :
                    <div>
                        <div className='left-container-project-wrapper'>
                            <div className='left-container-project'>
                                { this.props.plans && this.props.plans.length === 0 ?
                                    <div className='left-item-project-active'>暂无内容</div>
                                    : yearItems.map((year, index)=>
                                    <div key={year} 
                                        className={index === this.state.yearIndex? 'left-item-project-active':'left-item-project'}
                                        onClick={()=>this.clickItems(index)}
                                        >{year}</div>
                                    )
                                }
                            </div>
                        </div>
                        <div className='right-container-project'>
                            <EditBar new={true} newBtn='添加维保计划' onNew = {this.addNewContract}/>
                            <div className='right-content-project-456-wrapper'>
                                <div className='right-content-project-456'>
                                    {
                                        plansInSelectedYear.length === 0?
                                        <div className='items-456'>
                                            <div className='header-456'>
                                                <div className='title'>暂无内容</div>
                                                <div >
                                                    <span className='folder'>展开</span>
                                                    <img alt = 'triangle' className='folder-img' src={TriangleImg}/>
                                                </div>
                                            </div>
                                            <div className='content-456-folder content-456-container'>

                                            </div>
                                        </div>
                                        :
                                        plansInSelectedYear.map(ele=>
                                            <div className='items-456' key={ele.id}>
                                                <div className='header-456'>
                                                    <div className='title'>{ele.name}</div>
                                                    <div className='folder-control' onClick={()=>this.switchFolder(ele.id)}>
                                                        <span className='folder'>{ele.foldered?'展开':'收起'}</span>
                                                        <img alt = 'triangle' className={ele.foldered?'folder-img':'folder-img inverse'} src={TriangleImg}/>
                                                    </div>
                                                </div>
                                                <div className={ele.foldered?'content-456-folder content-456-container' : 'content-456-container'}>
                                                    <div className='content-header'>
                                                        <div className='period'>
                                                            <span>{moment(ele.startTime).format('YYYY年MM月DD日')}</span> 
                                                            <span>至</span>
                                                            <span>{moment(ele.endTime).format('YYYY年MM月DD日')}</span> 
                                                        </div>
                                                        <div className='edit'onClick={()=>this.editPlan(ele.id)}>
                                                            <img src={EditImg} alt='edit' className='edit-img'/>
                                                            <span>修改</span>
                                                        </div>
                                                    </div>
                                                    <div className='contract-content'>{ele.description}</div>
                                                    {
                                                        ele.foldered?null:
                                                        <div>
                                                            <div className='detail-item'>
                                                                <span>计划类型 : </span>
                                                                <span>{TICKET_TYPES[ele.type-1].label}</span>
                                                            </div>
                                                            <div className='detail-item'>
                                                                <span>重复周期 : </span>
                                                                <span>{ele.periodInterval}{PERIOD_TYPES.find(type=>type.value === ele.periodType)._label}</span>
                                                            </div>
                                                            <div className='detail-item'>
                                                                <span>维保合同 : </span>
                                                                <span>{ele.contractId}</span>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }                        
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <NextBar nextBtn='保存并退出' onNextStep={this.onSaveAndQuit}/>
            </div>
        )
    }
}

export default PlanGuideList