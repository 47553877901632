
import axios from '../axios_new.config'
import {HIDE_LOADING, SHOW_LOADING, hideLoading, showLoading, getRegionsByIDs, getOrganizationsByIDs} from './00_common.action'

const DRWAINGS_DATA = 'DRWAINGS_DATA';
const CURRENT_DRWAING = 'CURRENT_DRWAING'

//reducer
const initState = {
    drawingReview : [],
    total:0,
    start:1,
    type: 1,
    searchType: 1,
    loading: true,
    currentDrawingReview:{}
}


export function drawingReview(state = initState, action) { 
    switch(action.type){
        case DRWAINGS_DATA:
            return { ...state, 
                drawingReview: action.payload.content,
                total: action.payload.totalElements,
                start: action.payload.start,
                type: action.payload.type,
                searchType: action.payload.searchType,
                // start: 1,
                errorMsg:null
             }
        case CURRENT_DRWAING:
             return {
                 ...state,
                 currentDrawingReview: action.payload
             }
        case SHOW_LOADING:
             return {
                 ...state,
                 loading: true,
             }
        case HIDE_LOADING:
             return {
                 ...state,
                 loading: false
             }
        default:
            return state
    }
}

//action creator
export function getDrawingList(params, access_token=undefined, needDetail=true){ 
    
    // dispatch 用来通知数据修改
    return dispatch => {
        dispatch(showLoading())
        let promise;
        if(access_token){
            promise = axios.request({
                method:'get',
                url:'/api/maintenance/fireControls',
                params: params,
                headers:{
                  Authorization: `Bearer ${access_token}`,
                  "Content-Type": 'application/json',
                },
            })
        }else{
            promise = axios.get('/api/maintenance/fireControls',{params});
        }
        
        promise.then(res=>{
            dispatch(drawingReviewData(res));
        }).finally(()=>{
            dispatch(hideLoading())
        })
    }
}

// export function updateProjectSuccess() { 
//     return dispatch=>dispatch(getDrawingList())
// }

export function dispatchCurrentDrawingReview(data){
    return dispatch=>dispatch(currentDrawingReview(data))
}

function drawingReviewData(data) {
    return {type: DRWAINGS_DATA, payload: data}
}
function currentDrawingReview(data){
    return {type: CURRENT_DRWAING, payload: data}
}


// // 非redux管理的方法
//提交补充资料
export function ReFireControlsAudit(id,data){
    return axios.put(`/api/maintenance/fireControls/reCommit/${id}`,data)
}

//删除
export function delFireControls(id){
    return axios.delete(`/api/maintenance/fireControls/${id}`)
}

// export function updateProjectRequest(data,edit=false, id){
//     if(edit){
//         return axios.put(`/account/network-users/${id}`,data)
//     }else{
//         return axios.post('/account/network-users',data)
//     }
// }

/**
 * 根据project ID 获取当前的project信息，主要用于填写topBarInside中的项目信息
 * @param {*project id} id 
 */
export function getCurrentDrawingReview(id){
    return axios.get(`/api/maintenance/fireControls/${id}`)
}

