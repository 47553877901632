import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {Form,Input,Select, message,Upload, Spin} from 'antd';

import EditBar from '../../components/edit-bar/edit-bar';
import picUpload from '../../assets/images/pic_update.svg'

import { getMaintainerData } from '../../redux/08_maintainer.redux'
import { getProjectsData } from '../../redux/01_project.redux'
import { getCurrentUser, updateUserRequest, getUsersData} from '../../redux/06_users.redux'
import { getCurrentAuthUser } from '../../redux/auth.redux'
import {ROLE_TYPES} from '../../constants/constants'

import {formItemLayout} from '../../components/layout/formlayout'
import{ getHeaders, _baseUrl} from '../../axios.config'
import{ isPhoneAvailable} from '../../utils/utils'

const Option = Select.Option;
@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.maintainers,
            ...state.projects,
            ...state.users
        }
    },
    {getMaintainerData, getProjectsData, getCurrentAuthUser, getUsersData}
)
class DrawingReviewRegister extends Component{
    constructor(props){
        super(props);
        this.state = {
            edit: false,
            roleId: 0,
            avatar: null,
            loading: false,
            headers:{},
            iconLoading: false
        };
    }
    componentWillMount(){
        this.updateHeaders();
    }

    updateHeaders = async()=>{
        let headers = await getHeaders(true);
        this.setState({
            headers
        })
    }

    componentDidMount(){
        this.props.getProjectsData({size: 2000},null, false);
        this.props.getMaintainerData({size: 2000}); 
        
    }

    handleSubmit = e => {
        
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let data={
                    ...values,
                    roleId: this.state.roleId,
                    username: this.state.edit? this.state.username : values.phone,
                }
                if(this.state.avatar){
                    data={
                        ...data,
                        avatar: this.state.avatar
                    }
                }
                delete data.rolesIds;
                delete data._img
                if(isPhoneAvailable(values.phone)){
                    this.setState({iconLoading: true});
                    updateUserRequest(data,this.state.edit, this.state.id).then(res=>{
                        message.success(this.state.edit?'更新用户成功':'创建用户成功');
                        if (this.state.edit && this.props.authUser.id == this.state.id){
                            // 如果修改的信息恰好是当前用户，需要更新auth store
                            this.props.getCurrentAuthUser();
                        }
                        this.props.history.goBack();
                    }).finally(()=>this.setState({iconLoading: false}));
                }else{
                    message.warn('请输入正确的手机号码')
                }
            }
        });
    };
    
    onRoleChange = (value)=>{
        this.setState({
            roleId: value
        })
    }

    
    render(){  
        const { getFieldDecorator } = this.props.form;   
        const _this = this;
        const props = {
            name: 'file',
            showUploadList: false,
            action: `${_baseUrl}/resource/resources/put-oss`,
            headers: _this.state.headers,
            onChange(info) {
              if (info.file.status === 'done') {
                message.success(`${info.file.name} 上传成功`);
                _this.setState({
                    avatar: info.fileList[info.fileList.length-1].response.url
                })
              } else if (info.file.status === 'error') {
                message.error(`${info.file.name} 上传失败`);
              }
            },
            beforeUpload:beforeUpload
          };
         
        return (
            <div>
                <div className='edit-content'>
                    <EditBar onSave = {this.handleSubmit} save={true} iconLoading={this.state.iconLoading} delete={this.state.edit&& this.state.roleId !== 1} onDelete={this.onDeleteUserFromOrg} back={true}/>
                    <div className='edit-form-container edit-form-container-half'>
                        {
                            this.state.loading?
                            <div className="spin-container-half">
                                <Spin  size="large" tip="正在加载..."/>
                            </div>
                            :
                            <div>
                                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                                    <Form.Item label='用户头像'>
                                    {getFieldDecorator('_img', {})(
                                        <Upload {...props}>
                                            <img src={this.state.avatar || picUpload} alt='upload' className='upload-img'/>
                                        </Upload>
                                    )}
                                    </Form.Item>
                                    <Form.Item label='手机号码'>
                                    {getFieldDecorator('phone', {
                                        initialValue: this.state.phone,
                                        rules: [{ required: true, message: '手机号码不能为空!' }],
                                    })(<Input disabled={this.state.edit}  placeholder="请输入手机号码"/>)}
                                    </Form.Item>
                                    <Form.Item label='用户姓名'>
                                    {getFieldDecorator('name', {
                                        initialValue: this.state.name,
                                        rules: [
                                        {
                                            required: true,
                                            message: '用户姓名不能为空',
                                        },
                                        ],
                                    })(<Input placeholder="请输入用户姓名"/>)}
                                    </Form.Item>
                                    <Form.Item label='角色类型'>
                                        {getFieldDecorator('rolesIds', {
                                            initialValue: this.state.rolesIds,
                                            rules: [{ required: true, message: '请选择角色类型!' }],
                                        })(    <Select placeholder="请选择角色类型" onChange={this.onRoleChange}>
                                                    {
                                                        this.state.roleId === 1 ?
                                                        ROLE_TYPES.map(ele=> <Option key={ele.key} value={ele.value}>{ele.label}</Option>)
                                                        :
                                                        ROLE_TYPES.filter(ele=>ele.value !==1).map(ele=> <Option key={ele.key} value={ele.value}>{ele.label}</Option>)
                                                    }
                                                </Select>)}
                                    </Form.Item>
                                    {
                                        this.state.roleId > 3 ?
                                        <Form.Item label='所属项目'>
                                            {getFieldDecorator('organizationId', {
                                                initialValue: this.state.userOrgMap&&this.state.userOrgMap.length?this.state.userOrgMap.map(ele=>ele.organizationId)[0]:undefined,
                                                rules: [{ required: true, message: '请选择所属项目!' }],
                                            })(    <Select placeholder="请选择所属项目" >
                                                        {
                                                            this.props.projects.map(ele=> <Option key={ele.id} value={ele.id}>{ele.name}</Option>)
                                                        }
                                                    </Select>)}
                                        </Form.Item>  
                                        : null                             
                                    }
                                    {
                                        this.state.roleId>1 && this.state.roleId < 4 ? // 非超级管理员
                                        <Form.Item label='维保单位'>
                                            {getFieldDecorator('organizationId', {
                                                initialValue: this.state.userOrgMap&&this.state.userOrgMap.length?this.state.userOrgMap[0].organizationId:undefined,
                                                rules: [{ required:true, message: '请选择维保单位!' }],
                                            })(    <Select placeholder="请选择维保单位" >
                                                        {
                                                            this.props.maintainers.map(ele=> <Option key={ele.id} value={ele.id}>{ele.name}</Option>)
                                                        }
                                                    </Select>)}
                                        </Form.Item> 
                                        : null                           
                                    }
                                </Form>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default  Form.create()(DrawingReviewRegister)

function beforeUpload(file) {
    const isJPG = file.type === 'image/jpeg';
    const isPNG = file.type === 'image/png';
    if (!isJPG&&!isPNG){
      message.error('只能上传JPG/PNG类型的文件!');
    }
    return isJPG || isPNG;
  }
  