// 登录时使用的axios

import axios from 'axios'
import { notification} from 'antd'
import { ErrorMessages } from './constants/constants'

let baseUrl = 'https://api.m.t.zlya.net';

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

if (!isLocalhost) {
    baseUrl = `https://api${window.location.hostname.substring(window.location.hostname.indexOf('.'))}`
}

export const _baseUrl = baseUrl;
//创建axios实例
const service = axios.create({
    baseURL: baseUrl, // api的base_url
    timeout: 60 * 1000, // 请求超时时间
    withCredentials: false, // 选项表明了是否是跨域请求
    transformRequest: [function (data) {
        let ret = ''
        for (let it in data) {
            ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
        }
        return ret
    }],
})
service.interceptors.request.use(config => {
return config;
}, err => Promise.reject(err))

// // respone拦截器
service.interceptors.response.use(
  response => {
    let statusCode = response.status;
    if (statusCode >= 400) {       
        return Promise.reject('error');
    } else {
        return response.data; 
    }
  },
  err => {
    let error_description = err.response && err.response.data ?( err.response.data.message || err.response.data.error_description) : '';
    if(error_description === ErrorMessages.bad_credentials.en) error_description = ErrorMessages.bad_credentials.zh;
    if(error_description === ErrorMessages.account_locked.en) error_description = ErrorMessages.account_locked.zh;
    const args = {
        message: '请求失败',
        description:error_description,
      };
      notification.open(args); 
    return Promise.reject(err)
  }
)
export default service
