import React, {Component}  from 'react';
import { connect } from 'react-redux'
import moment from 'moment'
import { withRouter} from 'react-router-dom'
import {Spin} from 'antd'

import './styles.css';

import { getContractsData, switchContractFolder, contractsData } from '../../redux/04_contract.redux'
import {getCurrentProject, dispatchCurrentProject }  from '../../redux/01_project.redux'

import NextBar from '../../components/next-bar/next-bar'
import EditBar from '../../components/edit-bar/edit-bar'
import TriangleImg from '../../assets/images/Triangle.svg'
import EditImg from '../../assets/images/edit_white.svg'


@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.contracts,
            ...state.projects
        }
    },
    {getContractsData, switchContractFolder, dispatchCurrentProject}
)
class ContractGuideList extends Component {
    state={
        yearIndex: 0
    }

    componentDidMount(){

        // 重置contracts为空
        let store = require('../../index').store;
        store.dispatch(contractsData({data:[]}));

        let {organizationId} = this.props.match.params;
        getCurrentProject(organizationId).then(res=>{
            this.props.dispatchCurrentProject(res)
        })
        this.props.getContractsData({
            organizationId,
            size: 2000})
    }

    addNewContract = ()=>{
        this.props.history.push(`/orgs-management/contracts/add/${this.props.match.params.organizationId}`)
    }

    editContract=(id)=>{
        this.props.history.push(`/orgs-management/contracts/edit/${this.props.match.params.organizationId}/${id}`)
    }
    switchFolder=(id)=>{
        this.props.switchContractFolder(this.props.contracts, id)
    }
    onNextStep = ()=>{
        this.props.history.push(`/orgs-management/guide/plan/${this.props.currentProject.id}`)
    }

    render(){
        const yearItems = this.props.contracts? 
                        Array.from(new Set(this.props.contracts.map(ele=>moment(ele.startTime).year()))).sort((a,b)=>b-a) : [];
        const contractsInSelectedYear = this.props.contracts
                                                    .filter(ele=>moment(ele.startTime).year() === yearItems[this.state.yearIndex])

        return (
            <div className = 'edit-content-project edit-content-project-456'>
                {
                    this.props.loading?
                    <div className="spin-container-half">
                        <Spin  size="large" tip="正在加载..."/>
                    </div>
                    :
                    <div>
                        <div className='left-container-project-wrapper'>
                            <div className='left-container-project'>
                                {  this.props.contracts.length === 0 ?
                                    <div className='left-item-project-active'>暂无内容</div>
                                    : yearItems.map((year, index)=><div key={year} className={index === this.state.yearIndex? 'left-item-project-active':'left-item-project'}>{year}</div>
                                    )
                                }
                            </div>
                        </div>
                        <div className='right-container-project'>
                            <EditBar new={true} newBtn='添加维保合同' onNew = {this.addNewContract}/>
                            <div className='right-content-project-456-wrapper'>
                                <div className='right-content-project-456'>
                                    {
                                        contractsInSelectedYear.length === 0?
                                        <div className='items-456'>
                                            <div className='header-456'>
                                                <div className='title'>暂无内容</div>
                                                <div >
                                                    <span className='folder'>展开</span>
                                                    <img alt = 'triangle' className='folder-img' src={TriangleImg}/>
                                                </div>
                                            </div>
                                            <div className='content-456-folder content-456-container'>

                                            </div>
                                        </div>
                                        :
                                        contractsInSelectedYear.map(ele=>
                                            <div className='items-456' key={ele.id}>
                                                <div className='header-456'>
                                                    <div className='title'>{ele.name}</div>
                                                    <div className='folder-control' onClick={()=>this.switchFolder(ele.id)}>
                                                        <span className='folder'>{ele.foldered?'展开':'收起'}</span>
                                                        <img alt = 'triangle' className={ele.foldered?'folder-img':'folder-img inverse'} src={TriangleImg}/>
                                                    </div>
                                                </div>
                                                <div className={ele.foldered?'content-456-folder content-456-container' : 'content-456-container'}>
                                                    <div className='content-header'>
                                                        <div className='period'>
                                                            <span>{moment(ele.startTime).format('YYYY年MM月DD日')}</span> 
                                                            <span>至</span>
                                                            <span>{moment(ele.endTime).format('YYYY年MM月DD日')}</span> 
                                                        </div>
                                                        <div className='edit'onClick={()=>this.editContract(ele.id)}>
                                                            <img src={EditImg} alt='edit' className='edit-img'/>
                                                            <span>修改</span>
                                                        </div>
                                                    </div>
                                                    <div className='contract-content'>{ele.content}</div>
                                                    {
                                                        !ele.foldered && ele.files.map(file=>
                                                        <div key={file.url} className='file-item'>
                                                            <span>{file.name}</span>
                                                            <a href={file.url} target='_blank'  rel="noopener noreferrer">文件链接</a>
                                                        </div>)
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }                        
                                </div>                                
                            </div>
                        </div>
                    </div>
                }
                <NextBar onNextStep={this.onNextStep}/>
            </div>
        )
    }
}

export default ContractGuideList