
import axios from '../axios.config'
import {HIDE_LOADING, SHOW_LOADING, hideLoading, showLoading, getOrganizationsByIDs, getUsersByIDS} from './00_common.action'

const CONTRACT_DATA = 'CONTRACT_DATA';
//reducer
const initState = {
    contracts : [],
    total:0,
    start:0,
    loading: true
}


export function contracts(state = initState, action) { 
    switch(action.type){
        case CONTRACT_DATA:
            return { ...state, 
                contracts: action.payload.data,
                total: action.payload.total,
                start: action.payload.start,
             }
        case SHOW_LOADING:
             return {
                 ...state,
                 loading: true,
             }
        case HIDE_LOADING:
             return {
                 ...state,
                 loading: false
             }
        default:
            return state
    }
}

//action creator
/**
 * 获取合同列表
 * @param {*} params 
 */
export function getContractsData(params, needDetail=true){
    // dispatch 用来通知数据修改
    return dispatch => {
        dispatch(showLoading())
        axios.get('/maintenance/contracts',{params})
            .then(res=>{
                let organizationIds = res.data.map(ele=>ele.organizationId);
                let userIds = res.data.map(ele=>ele.responsibleUserId);
                Promise.all([getOrganizationsByIDs(organizationIds), getUsersByIDS(userIds)]).then(res2=>{
                    let _data = res.data.map(ele=>{
                        return {
                            ...ele,
                            organization: res2[0].data.find(origanization=>origanization.id === ele.organizationId),
                            responsibleUser: res2[1].data.find(user=>user.id === ele.responsibleUserId)
                        }
                    })
                    let payload = {
                        ...res,
                        data: generateFolderInfo(_data)
                    }
                    dispatch(contractsData(payload))
                })   
            }).finally(()=>dispatch(hideLoading()))
    }
}


export function contractsData(data) {
    return {type: CONTRACT_DATA, payload: data}
}

/**
 * 切换展开/收起
 * @param {*} items 
 * @param {*} id 
 */
export function switchContractFolder(items, id) {
    return dispatch => {
        let newItems = items.map(ele => {
            if (ele.id === id) {
                ele = {
                    ...ele,
                    foldered: !ele.foldered
                }
            }

            return ele;
        })
        dispatch(contractsData({data: newItems}))
    }
}

/** 
 * 非redux管理的function
*/

export function getCurrentContract(id){
    return axios.get(`/maintenance/contracts/${id}`)
}

export function updateContractRequest(data,edit=false, id){
    if(edit){
        return axios.put(`/maintenance/contracts/${id}`,data) 
    }else {
        return axios.post('/maintenance/contracts',data)
    }
}

export function deleteContract(id){
    return axios.delete(`/maintenance/contracts/${id}`)
}


// 支持guide列表中的展开/收起功能, 默认收起
function generateFolderInfo(data){
    return data.map(ele=>({
        ...ele,
        foldered:true
    }))
}

