import React, { Component } from 'react';
import { Switch, Route} from 'react-router-dom';
import PlanList from './plan';
import PlanEdit from './plan.edit'
import PlanMaintenanceStatus from './plan.maintenance.status'
import PlanInspectionContent from './inspection_content_plan'
import HomeLayout from '../../components/layout/home.layout'
import NoneSideBarLayout from '../../components/layout/non-sidebar.layout'

class PlanRouter extends Component {


  render() {
    const listView = <HomeLayout>
                        <PlanList/>
                    </HomeLayout>
    const editView  = <NoneSideBarLayout>
                        <Route exact path='/plans/add' component={PlanEdit}/>
                        <Route path='/plans/edit/:organizationId/:id' component={PlanEdit}/>
                        <Route exact path='/plans/contents/:organizationId/:planId/:type' component={PlanMaintenanceStatus}/>
                        <Route exact path='/plans/inspection-point/:inspectionPointId/contents/:type/:organizationId/:planId' 
                                        component={PlanInspectionContent}/>  
                    </NoneSideBarLayout>
    return (
      <Switch>
        <Route exact path='/plans' render={()=>listView}/>
        <Route path='/plans' render={()=>editView}/>
      </Switch>
    );
  }
}

export default PlanRouter