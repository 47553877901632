import React, {Component}  from 'react';
import { connect } from 'react-redux'
import { withRouter} from 'react-router-dom'
import { Spin } from 'antd'

import {getCurrentProject, dispatchCurrentProject }  from '../../redux/01_project.redux'
import { getInspectionPointDataOfGuidePage,switchFolder,getInspectionsDataInSelectedBuildings,
    inspectionsDataInSelectedBuilding,buildingItemsData } from '../../redux/03_inspection.redux'
import './styles.css';

import NextBar from '../../components/next-bar/next-bar'
import EditBar from '../../components/edit-bar/edit-bar'
import TriangleImg from '../../assets/images/Triangle.svg'
import EditImg from '../../assets/images/edit_white.svg'


@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.inspections,
            ...state.projects
        }
    },
    {getInspectionPointDataOfGuidePage, switchFolder, getInspectionsDataInSelectedBuildings, dispatchCurrentProject}
)
class InspectionGuideList extends Component {
    state={
        buildingIndex: 0
    }

    componentDidMount(){

        // 重置buildingItems和inspectionPointsInSelectedBuilding为空
        let store = require('../../index').store;
        store.dispatch(buildingItemsData([]));
        store.dispatch(inspectionsDataInSelectedBuilding([]));



        let {organizationId} = this.props.match.params;
        getCurrentProject(organizationId).then(res=>{
            this.props.dispatchCurrentProject(res)
        })
        this.props.getInspectionPointDataOfGuidePage({
            organizationId,
            size: 2000})
    }

    addNewInspection = ()=>{
        this.props.history.push(`/orgs-management/inspections/add/${this.props.currentProject.id}`)
    }

    editInspection=(id)=>{
        this.props.history.push(`/orgs-management/inspections/edit/${this.props.currentProject.id}/${id}`)
    }
    switchFolder=(index)=>{
        this.props.switchFolder(this.props.inspectionPointsInSelectedBuilding, index)
    }
    onNextStep = ()=>{
        this.props.history.push(`/orgs-management/guide/contract/${this.props.currentProject.id}`)
    }

    clickItems = (buildingId, buildingIndex)=>{
        this.props.getInspectionsDataInSelectedBuildings({
            buildingId,
            organizationId: this.props.currentProject.id,
            size: 2000})
        this.setState({buildingIndex})
    }

    render(){
        // console.log(this.props)
        return (
            <div className = 'edit-content-project edit-content-project-456'>
                {
                    this.props.inspectionGuideLoading?
                    <div className="spin-container-half">
                        <Spin  size="large" tip="正在加载..."/>
                    </div>
                    :
                    <div>

                        <div className='left-container-project-wrapper'>
                            <div className='left-container-project'>
                                {  this.props.buildingItems.length === 0 ?
                                    <div className='left-item-project-active'>暂无内容</div>
                                    : this.props.buildingItems.map((building, index)=>
                                            building? <div  key={building.id} 
                                            className={index === this.state.buildingIndex? 'left-item-project-active':'left-item-project'}
                                            onClick  = {()=>this.clickItems(building.id, index)}
                                        >{building.name}</div> :null
                                    )
                                }
                            </div>
                        </div>
                        <div className='right-container-project'>
                            <EditBar new={true} newBtn='添加巡查点' onNew = {this.addNewInspection}/>
                            <div className='right-content-project-456-wrapper'>
                                <div className='right-content-project-456'>
                                    {
                                        this.props.inspectionPointsInSelectedBuilding.length === 0?
                                        <div className='items-456'>
                                            <div className='header-456'>
                                                <div className='title'>暂无内容</div>
                                                <div >
                                                    <span className='folder'>展开</span>
                                                    <img alt = 'triangle' className='folder-img' src={TriangleImg}/>
                                                </div>
                                            </div>
                                            <div className='content-456-folder content-456-container'>

                                            </div>
                                        </div>
                                        :
                                        this.props.inspectionPointsInSelectedBuilding.map((ele,index)=>
                                            <div className='items-456' key={index}>
                                                <div className='header-456'>
                                                    <div className='title'>{ele.inspectionInFloor[0].floor?ele.inspectionInFloor[0].floor.name:null}</div>
                                                    <div className='folder-control' onClick={()=>this.switchFolder(index)}>
                                                        <span className='folder'>{ele.foldered?'展开':'收起'}</span>
                                                        <img alt = 'triangle' className={ele.foldered?'folder-img':'folder-img inverse'} src={TriangleImg}/>
                                                    </div>
                                                </div>
                                                <div className={ele.foldered?'content-456-folder content-456-container' : 'content-456-container'}>
                                                    {
                                                        
                                                    ele.inspectionInFloor.map(ele=>ele).splice(0,4).map(inspection=>
                                                            <div className='inspection-card' key={inspection.id}>
                                                                <div className='left'>
                                                                    <div className='name'>{inspection.name}</div>
                                                                    <div className='location'>{inspection.location || '暂未填写位置信息'}</div>
                                                                </div>
                                                                <div className='right'>
                                                                    <img alt='edit' className='editImg' src={EditImg} onClick={()=>this.editInspection(inspection.id)}/>
                                                                    <div className='edit' onClick={()=>this.editInspection(inspection.id)}>修改</div>
                                                                </div>
                                                            </div>)
                                                    }
                                                    { ele.foldered ? null:
                                                        ele.inspectionInFloor.map(ele=>ele).splice(4).map(inspection=>
                                                            <div className='inspection-card' key={inspection.id}>
                                                                <div className='left'>
                                                                    <div className='name'>{inspection.name}</div>
                                                                    <div className='location'>{inspection.location || '暂未填写位置信息'}</div>
                                                                </div>
                                                                <div className='right'>
                                                                    <img alt='edit' className='editImg' src={EditImg} onClick={()=>this.editInspection(inspection.id)}/>
                                                                    <div className='edit' onClick={()=>this.editInspection(inspection.id)}>修改</div>
                                                                </div>
                                                            </div>)
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }                        
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <NextBar onNextStep={this.onNextStep}/>
            </div>
        )
    }
}

export default InspectionGuideList