import React, {Component}  from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {message} from 'antd'

import './styles.css';


@withRouter
@connect(
    state=>(
        {
            ...state.path,
            ...state.projects
        }
    ),
    {}
)
class SideBarArrow extends Component {
    constructor(props){
        super(props)
        this.state = {
            pathname: '/orgs-management/guide/info'
        }
    }
    componentDidMount(){
        // console.log(this.props)
        this.setState({
            pathname: this.props.location.pathname
        })
    }
    onNavChange = (path)=>{
        // console.log(this.props.path)
        if(this.props.path === '/orgs-management/guide/new'){
            message.warn('请先创建项目')
        }else{
            this.props.history.push(path);
            this.setState({
                pathname: path
            })
        }
    }

    render(){
        

        const items = [
            {
                nav: '项目资料',
                path: this.props.currentProject.id? `/orgs-management/guide/info/${this.props.currentProject.id}`: '/orgs-management/guide/info',
                path2:'/orgs-management/guide/new'
            },
            {
                nav: '业主',
                path: `/orgs-management/guide/owners/${this.props.currentProject.id}`
            },  
            {
                nav: '楼栋/楼层',
                path: `/orgs-management/guide/buildings/${this.props.currentProject.id}`
            },
            {
                nav: '巡查点',
                path: `/orgs-management/guide/inspection/${this.props.currentProject.id}`
            },
            {
                nav: '维保合同',
                path:`/orgs-management/guide/contract/${this.props.currentProject.id}`
            },            
            {
                nav: '维保计划',
                path: `/orgs-management/guide/plan/${this.props.currentProject.id}`
            }          
        ]
        return (
            <div className='sidebar-arrow-container'>
                <div className='sidebar-arrow'>
                {
                    items.map((item,index)=>{
                        return <div className={this.props.path.indexOf(item.path) > -1 ||this.props.path.indexOf(item.path2) > -1? 'item-active item-position' :'item-arrow item-position' } key={item.path} onClick={()=>this.onNavChange(item.path)}>
                                    <div className='link' style={{color: '#A1BDCE'}}>
                                            <div>{index + 1}</div>
                                            <div>{item.nav}</div>
                                    </div>
                                </div>
                    })
                }
                </div>
            </div>
        )
    }
}

export default SideBarArrow