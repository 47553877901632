import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {Form,Input,Select, message,Upload, Spin,Modal,Button} from 'antd';

import EditBar from '../../components/edit-bar/edit-bar';
import picUpload from '../../assets/images/pic_update.svg'
import back2 from '../../assets/images/back2.png'
import t1 from '../../assets/images/t1.jpg'
import Progress from '../../components/progress/index'

import { getCurrentAuthUser } from '../../redux/auth.redux'
import {ROLE_TYPES} from '../../constants/constants'

import {formItemLayout} from '../../components/layout/formlayout'
import{ getHeaders, _baseUrl} from '../../axios.config'
import{ isPhoneAvailable, setStatusTxt} from '../../utils/utils'
import {  addMarker} from '../../redux/00_common.action'

import { getCurrentAcceptance , dispatchCurrentAcceptance,ReFireControlsAudit} from '../../redux/14_acceptance.redux'
import Enclosure  from '../../components/enclosure/index'

import mapJson from '../../assets/json/custom_map_config.json'

const BMap = window.BMap;
const Search = Input.Search;
let map;

const Option = Select.Option;
@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.maintainers,
            ...state.projects
        }
    },
    {getCurrentAcceptance, dispatchCurrentAcceptance}
)
class DrawingReviewExamine extends Component{
    constructor(props){
        super(props);
        this.state = {
            tab : 0,
            edit: true,
            roleId: 0,
            avatar: null,
            loading: false,
            headers:{},
            iconLoading: false,

            inspectionList: [],
            inspection: [],//自检报告
            completedList: [],
            completed: [],//竣工图纸
            firesList: [],
            fires: [],//消防检测报告
            status: 0,
            qualified: [],
            noqualified: [],
            previewVisible: false,
            previewImage: '',
            inspectionList: [],
            inspection: [],//凭证
        };
    }
    componentDidMount(){
        let {id} = this.props.match.params;
        getCurrentAcceptance(id).then(res=>{
            //解析审核历史及合格意见
            let auditHis = res.auditHis;
            let last_audit,qualified,noqualified;
            if(auditHis.length>0){
                last_audit = auditHis[auditHis.length-1];
                if(last_audit.status==3){
                    qualified = last_audit;//合格
                    auditHis.pop();
                    noqualified = auditHis;//不合格
                }else if(last_audit.status==4){
                    qualified = [];
                    noqualified = auditHis;
                }else{
                    qualified = [];
                    auditHis.pop();
                    noqualified = auditHis;
                }
            }
            this.setState(res);
            this.setState({
                status: last_audit.status,
                qualified: qualified,
                noqualified: noqualified
            })
            this.props.dispatchCurrentAcceptance(res);
            this.addMap(res.startLongitude,res.startLatitude)
        });
    }

    componentWillMount(){
        this.updateHeaders();
    }

    updateHeaders = async()=>{
        let headers = await getHeaders(true);
        this.setState({
            headers
        })
    }


    addMap = (lng,lat,type)=>{
        map = new BMap.Map("l-map");  
        map.centerAndZoom(new BMap.Point(lng,lat), this.state.edit ? 13 : 13);
        map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
        map.addControl(new BMap.NavigationControl());    
        map.addControl(new BMap.ScaleControl());    
        map.addControl(new BMap.OverviewMapControl());   
        //map.setMapStyle({styleJson:mapJson});
        addMarker(map,lng,lat,this.state.name)
    }

    //设置上传按钮
    setUpdTxt(txt){
        return <div>
                  <img src={picUpload} alt="" />
                  <div className="ant-upload-text">{txt}</div>
               </div>;
    }

    handleReSubmitAuditing = e => {
        let {remark, inspection} = this.state;
        let {id} = this.props.match.params;
        let _this = this;
        let data = {
            id: id,
            updateRemark: remark
        };
        if(this.state.inspection){
            data={
                ...data,
                selfCheckReport: this.state.inspection
            }
        }
        if(this.state.completed){
            data={
                ...data,
                builtDrawings: this.state.completed
            }
        }
        if(this.state.fires){
            data={
                ...data,
                fireDetectionReport: this.state.fires
            }
        }
        Modal.confirm({
            title: '提示',
            content: '请确认提交的补充资料？',
            okText: '确认',
            cancelText: '取消',
            onOk(){
                _this.setState({iconLoading: true});
                ReFireControlsAudit(id,data).then(res=>{
                    message.success("提交成功，请等待审核");
                    setTimeout(function(){
                        _this.props.history.push(`/acceptance`);
                    },1800)
                }).finally(()=>_this.setState({iconLoading: false}));
            }
        });
    }
    
    render(){  
        let _this = this;
        const { TextArea } = Input;
        let {name,address, floorNum, height, areaMeasure, constructionCompany, constructionPerson, constructionPhone, designCompany, designPerson, designPhone, supervisorCompany, supervisorPerson, supervisorPhone, testingCompany, testingPerson, testingPhone, drawingReviewCompany, drawingReviewPerson, drawingReviewPhone, useNature, projectDrawings, drawingReviewComments, selfCheckReport, builtDrawings, fireDetectionReport, startLongitude, startLatitude,qualified,noqualified,status,auditNum, updateRemark} = this.state;

        const { previewVisible, previewImage, inspectionList, completedList, firesList } = this.state;
        const props = {
            name: 'file',
            showUploadList: true,
            listType: "picture-card",
            onPreview: _this.handlePreview,
            beforeUpload: _this.beforeUpload,
            multiple:true,
            action: `${_baseUrl}/resource/resources/put-oss`,
            headers: _this.state.headers
        };

        
        //上传凭证
        let uploadButton3 = this.setUpdTxt("自检报告");
        let uploadButton4 = this.setUpdTxt("竣工图纸");
        let uploadButton5 = this.setUpdTxt("消防检测报告");
        let updBtns =   (<div>
            <Upload {...props} onChange={this.handleChangeInspection} fileList={inspectionList}>
                {inspectionList.length >= 99 ? null : uploadButton3}
            </Upload>
            <br/> <br/> <br/>
            <Upload {...props} onChange={this.handleChangeCompleted} fileList={completedList}>
                {completedList.length >= 99 ? null : uploadButton4}
            </Upload>
            <br/> <br/> <br/>
            <Upload {...props} onChange={this.handleChangeFires} fileList={firesList}>
                {firesList.length >= 99 ? null : uploadButton5}
            </Upload>
        </div>
        )
    
        let dat = setStatusTxt(status,auditNum,2);
        var dom = <div className={dat.name1}>{dat.txt1}</div>;
        var dom2 = <div className={dat.name2}>{dat.txt2}</div> ;

        return (
            <div className = 'content content_abs'>
                {/* <div className='itabs itabs2'>
                    <span className={["tab_span ",this.state.tab==0 ? 'on' :'' ].join('')} onClick={e => this.setTabs(0)}>全部</span>
                    <span className={["tab_span ",this.state.tab==1 ? 'on' :'' ].join('')} onClick={e => this.setTabs(1)}>初评</span>
                    <span className={["tab_span ",this.state.tab==2 ? 'on' :'' ].join('')} onClick={e => this.setTabs(2)}>复评</span>
                    <span className={["tab_span ",this.state.tab==3 ? 'on' :'' ].join('')} onClick={e => this.setTabs(3)}>已完成</span>
                </div> */}
                <div className="comm_detail">
                    <div className="itops flex-between">
                        <div className="itop_back">
                            返回 <img src={back2} alt="" />
                        </div>
                        <div className="itop_btns">
                            {dom}{dom2}
                        </div>
                    </div>
                    <div className="examcons">
                        <div className="examcon_title">审图详情</div>
                        <div>
                            <Progress startDate="2022-04-01" endDate="2022-08-15" startWarn="2022-05-20" endWarn="2022-08-01"></Progress>
                        </div>
                        <div className="examcon flex-between-top">
                            <div className="examcon_left">
                                <div className='oneexam'>
                                    <span className='sp1'>项目名称：</span>
                                    <span className='sp2'>{name}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>项目地址：</span>
                                    <span className='sp2'>{address}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>层数：</span>
                                    <span className='sp2'>{floorNum}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>高度：</span>
                                    <span className='sp2'>{height}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>面积：</span>
                                    <span className='sp2'>{areaMeasure}㎡</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>施工单位：</span>
                                    <span className='sp2'>{constructionCompany}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>施工单位联系人：</span>
                                    <span className='sp2'>{constructionPerson}</span>
                                    <span className='sp3'>联系方式：{constructionPhone}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>设计单位：</span>
                                    <span className='sp2'>{designCompany}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>设计单位联系人：</span>
                                    <span className='sp2'>{designPerson}</span>
                                    <span className='sp3'>联系方式：{designPhone}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>监理单位：</span>
                                    <span className='sp2'>{supervisorCompany}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>监理单位联系人：</span>
                                    <span className='sp2'>{supervisorPerson}</span>
                                    <span className='sp3'>联系方式：{supervisorPhone}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>检测单位：</span>
                                    <span className='sp2'>{testingCompany}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>检测单位联系人：</span>
                                    <span className='sp2'>{testingPerson}</span>
                                    <span className='sp3'>联系方式：{testingPhone}</span>
                                </div>
                                <div className='oneexam'>
                                    <span className='sp1'>使用性质：</span>
                                    <span className='sp2'>{useNature}</span>
                                </div>
                            </div>
                            <div className="examcon_right">
                                <div id="l-map" className='l-map'></div>
                            </div>
                        </div>

                        <div className="tsonedems">
                            {updateRemark ? (
                                <div className="tsonedem" style={{"margin-bottom": "30px"}}>
                                    <div className="onedem_left">补充说明：</div>
                                    <div className="onedem_right">{updateRemark}</div>
                                </div>
                            ) : ''}
                            <div className="tsonedem">
                                <div className="onedem_left">自检报告：</div>
                                <div className="onedem_right">
                                    <Enclosure enclosure={selfCheckReport}></Enclosure>
                                </div>
                            </div>
                            <div className="tsonedem">
                                <div className="onedem_left">竣工图纸：</div>
                                <div className="onedem_right">
                                    <Enclosure enclosure={builtDrawings}></Enclosure>
                                </div>
                            </div>
                            <div className="tsonedem">
                                <div className="onedem_left">消防检测报告：</div>
                                <div className="onedem_right">
                                    <Enclosure enclosure={fireDetectionReport}></Enclosure>
                                </div>
                            </div>
                        </div>

                        {noqualified.length>0 ? noqualified.map((item, index) => {
                            return <div className="onerecord" key={index}>
                                        <div className="onerecord_sort">{index+1}</div>
                                        {item.selfCheckReport.length>0 ? (
                                            <div className="onedem onedem_small">
                                                <div className="onedem_left">历史自检报告：</div>
                                                <div className="onedem_right">
                                                    <div className="oimg">
                                                        <Enclosure enclosure={item.selfCheckReport}></Enclosure>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : ''}
                                        {item.builtDrawings.length>0 ? (
                                            <div className="onedem onedem_small">
                                                <div className="onedem_left">历史竣工图纸：</div>
                                                <div className="onedem_right">
                                                    <div className="oimg">
                                                        <Enclosure enclosure={item.builtDrawings}></Enclosure>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : ''}
                                        {item.fireDetectionReport.length>0 ? (
                                            <div className="onedem onedem_small">
                                                <div className="onedem_left">历史消防验收报告：</div>
                                                <div className="onedem_right">
                                                    <div className="oimg">
                                                        <Enclosure enclosure={item.fireDetectionReport}></Enclosure>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : ''}

                                        <div className="onedem onedem_small">
                                            <div className="onedem_left">历史意见：</div>
                                            <div className="onedem_right">
                                                <div className="onedem_right_top">
                                                    验收员：{item.auditors.map(ele=> <span key={ele.id}>{ele.userOrgMap?
                                                        (ele.userOrgMap.length&&ele.userOrgMap[0].name?ele.userOrgMap[0].name:(ele.name||'未知'))
                                                        :'未知'}</span>)}
                                                </div>
                                                <div className="onedem_right_img">
                                                    <div className="otxt"><span className='txt'>{item.remark}</span></div>
                                                    <div className="oimg">
                                                        <Enclosure enclosure={item.files}></Enclosure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        }) : ''}

                        {status==3 ? (
                            <div className="onerecord">
                                <div className="onedem onedem_small">
                                    <div className="onedem_left">合格意见：</div>
                                    <div className="onedem_right">
                                        <div className="onedem_right_top">
                                            验收员：{qualified.auditors ? qualified.auditors.map(ele=> <span key={ele.id}>{ele.userOrgMap?
                                                        (ele.userOrgMap.length&&ele.userOrgMap[0].name?ele.userOrgMap[0].name:(ele.name||'未知'))
                                                        :'未知'}</span>) : '-'}
                                        </div>
                                        <div className="onedem_right_img">
                                            <div className="otxt"><span className='txt'>{qualified.remark}</span></div>
                                            <div className="oimg">
                                                <Enclosure enclosure={qualified.files}></Enclosure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : ''}

                        {status==4 ? 
                            <div className="supps supps_bc edit-form-container edit-form-container-half">
                                <Form {...formItemLayout} onSubmit={this.handleSubmitAuditing}>
                                    <div className="onedem onedem_small">
                                        <div className="onedem_left">补充资料：</div>
                                        <div className="onedem_right">
                                            <div className="exm_txt">
                                                <TextArea onChange={this.onChangeRemark.bind(this)} className='textarea' placeholder="请输入补充说明" />
                                            </div>
                                            <div className="exm_img upd_img clearfix">
                                                {updBtns}
            
                                                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                                </Modal>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
            
                                <Button className="done_btn" loading={this.state.iconLoading} type="primary" size='large' onClick={this.handleReSubmitAuditing}>提交</Button>
                            </div>
                        : ''}

                    </div>
                </div>
            </div>
        )

    }

    setTabs(i){
        this.setState({
            tab : i
        })
    }

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await file.originFileObj
        }
       
        this.setState({
            // previewImage: file.url || file.preview,
            previewImage: file.response.url,
            previewVisible: true,
        });
    };

     //自检意见
     handleChangeInspection = ({ fileList }) => {
        this.setState({ 
            inspectionList: fileList,
            inspection: this.setImgList(fileList)
         })
    };

    //竣工图纸
    handleChangeCompleted = ({ fileList }) => {
        this.setState({ 
            completedList: fileList,
            completed: this.setImgList(fileList)
         })
    };

    //消防检测报告
    handleChangeFires = ({ fileList }) => {
        this.setState({ 
            firesList: fileList,
            fires: this.setImgList(fileList)
         })
    };

    //上传的图片处理
    setImgList(files){ 
        var picture = [];
        if(files.length>0){
            for(let i = 0;i<files.length;i++){
                let item = files[i];
                if(item.response){
                    let pic = new Object;
                        pic.name = item.name;
                        pic.url = item['response']['url'];
                        picture.push(pic);
                }
            }
        }
        //console.log(1234,picture);
        return picture;
    }

    onChangeRemark({ target: { value } }){
        this.setState({
            remark: value
        })
    }

}

export default  Form.create()(DrawingReviewExamine)


  