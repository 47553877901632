import React, {Component}  from 'react';
import { Input, Select} from 'antd';

import RemoteSelectFilter from '../remote-select/remote-select-filter'
import {TICKET_TYPES, RegistryStatus} from '../../constants/constants'
import './header.css';

const Search = Input.Search;
const { Option } = Select;

class Header extends Component{
    constructor(props){
        super(props);
        this.state={
            searchFields:{}
        }
    }
    multiSearch(){
        let params = this.state.searchFields;
        Object.keys(params).forEach(key=>{
            if(!params[key]){
                delete params[key]
            }
        })
        params={
            ...params,
            start:0
        }
        this.props.onSearch(params);
    }
    getSearchKey(key, e){
        let value = e.target.value;
        this.setState({
            searchFields:{
                ...this.state.searchFields,
                [key]:value
            }
        })
    }
    addSearchKey(key,value){
        this.setState({
            searchFields:{
                ...this.state.searchFields,
                [key]:value
            }
        },()=>this.multiSearch())
    }

    onPlanTypeChange=(value)=> {
        this.addSearchKey('type',value)
    }

    onStatusChange = (value)=>{
        this.addSearchKey('status', value)
    }

    
      
    render(){
        const remoteSelectFilters = this.props.remoteSelectFilters||[];
        const flag = this.props.flag || '';
        const searchFields = this.state.searchFields;
        const remoteSearchFilterCandidates=[
            {
                key:'userOrgMap.organizationId',
                url:'/account/organizations',
                placeholder:'所属项目',
                searchKey:'nameLike',
                additionalParams: undefined
            },
            {
                key:'organizationId',
                url:'/account/organizations',
                placeholder:flag === 'licensePage' ? '维保单位' : '所属项目',
                searchKey:'nameLike',
                additionalParams: undefined
            },
            {
                key:'buildingId',
                url:'/building/buildings',
                placeholder:'楼栋',
                searchKey:'nameLike',
                additionalParams: undefined
            },
            {
                key:'floorId',
                url:'/building/floors',
                placeholder:'楼层',
                searchKey:'nameLike',
                additionalParams: 'buildingId' in searchFields ? {building: searchFields.buildingId}: undefined
            }
        ]
       return (
        <div className='headerContainer'>
            <div className='headerTitle'>{this.props.title}</div>
            <div>
                {
                    remoteSelectFilters && remoteSelectFilters.map(key=>{
                        let filter =  remoteSearchFilterCandidates.find(ele=>ele.key === key);
                        return <div className='remoterSelectFilter-container' key={key}>
                                <RemoteSelectFilter
                                    url={filter.url}
                                    placeholder={filter.placeholder}
                                    searchKey={filter.searchKey}
                                    additionalParams={filter.additionalParams}
                                    onRemoteSelectFilter={(organizationId)=>this.addSearchKey(key,organizationId)}/>
                            </div>
                    })
                }
  
                {
                    this.props.filters.map(ele=>{
                        return  <Search
                        key = {ele.key}
                        placeholder= {ele.placeholder}
                        onChange = {e=> this.getSearchKey(ele.key, e)}
                        onSearch={()=> this.multiSearch()}
                        style={{ width: 200, marginLeft: 40 }}
                        />
                    })
                }
                {
                    this.props.planTypeFilter?
                    <div className='plan-type-select'>
                        <Select  style={{ width: 200 }} 
                                    placeholder="计划类型" 
                                    allowClear={true}
                                    onChange={this.onPlanTypeChange}>
                            {
                                TICKET_TYPES.map(ele=><Option value={ele.value} key={ele.value}>{ele.label}</Option>)
                            }
                        </Select>
                    </div>
                    :null
                }
               {
                    this.props.statusFilter?
                    <div className='plan-type-select'>
                        <Select  style={{ width: 200 }} 
                                    placeholder="申请状态" 
                                    allowClear={true}
                                    onChange={this.onStatusChange}>
                            {
                                RegistryStatus.map(ele=><Option value={ele.value} key={ele.value}>{ele.label}</Option>)
                            }
                        </Select>
                    </div>
                    :null
                }
                {this.props.children}
            </div>
        </div>
       )
    }
}

export default Header