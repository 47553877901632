import React, { Component } from 'react';
import { Switch, Route} from 'react-router-dom';
import LicenseList from './license';
import LicenseEdit from './license.edit'
import HomeLayout from '../../components/layout/home.layout'
import NoneSideBarLayout from '../../components/layout/non-sidebar.layout'
class LicenseRouter extends Component {


  render() {
    const LicenseListView = <HomeLayout>
                                <LicenseList/>
                            </HomeLayout>

    const editView  = <NoneSideBarLayout hideProject={true}>
                        <Route exact  path='/licenses/add' component={LicenseEdit}/>
                        <Route  path='/licenses/detail/:id' component={LicenseEdit}/> 
                    </NoneSideBarLayout>

    return (
      <Switch>
        <Route exact path='/licenses' render={()=>LicenseListView}/>
        <Route path='/licenses' render={()=>editView}/>
      </Switch>
    );
  }
}

export default LicenseRouter;