
import axios from '../axios.config'
import {HIDE_LOADING, SHOW_LOADING, hideLoading, showLoading, getRegionsByIDs, getOrganizationsByIDs} from './00_common.action'
const PROJECTS_DATA = 'PROJECTS_DATA';
const CURRENT_PROJECT = 'CURRENT_PROJECT'


//reducer
const initState = {
    projects : [],
    total:0,
    start:0,
    loading: true,
    currentProject:{}
}


export function projects(state = initState, action) { 
    switch(action.type){
        case PROJECTS_DATA:
            return { ...state, 
                projects: action.payload.data,
                total: action.payload.total,
                start: action.payload.start,
                errorMsg:null
             }
        case CURRENT_PROJECT:
             return {
                 ...state,
                 currentProject: action.payload
             }
        case SHOW_LOADING:
             return {
                 ...state,
                 loading: true,
             }
        case HIDE_LOADING:
             return {
                 ...state,
                 loading: false
             }
        default:
            return state
    }
}

//action creator
export function getProjectsData(params, access_token=undefined, needDetail=true){

    // dispatch 用来通知数据修改
    return dispatch => {
        dispatch(showLoading())
        let promise;
        if(access_token){
            promise = axios.request({
                method:'get',
                url:'/account/network-users',
                headers:{
                  Authorization: `Bearer ${access_token}`,
                  "Content-Type": 'application/x-www-form-urlencoded',
                },
                params: params
            })
        }else{
            promise = axios.get('/account/network-users',{params});
        }
        
            promise.then(res=>{
                if(needDetail){
                    let regionIds = res.data.map(ele=>ele.regionId);
                    let maintainerIds = res.data.map(ele=>ele.maintainerId);
    
                    Promise.all([getRegionsByIDs(regionIds,access_token), getOrganizationsByIDs(maintainerIds, access_token)]).then(res2=>{
                        let newData=res.data.map(ele=>{
                            return {
                                ...ele,
                                region: res2[0].data.find(region=>region.id === ele.regionId),
                                maintainer: res2[1].data.find(maintainer=>maintainer.id === ele.maintainerId)
                            }
                        })
    
                        dispatch(projectsData({
                            ...res,
                            data: newData
                        }))
                    })
                }else{
                    dispatch(projectsData(res))
                }
            }).finally(()=>{
                dispatch(hideLoading())
            })
    }
}

export function updateProjectSuccess() { 
    return dispatch=>dispatch(getProjectsData())
}

export function dispatchCurrentProject(data){
    return dispatch=>dispatch(currentProject(data))
}

function projectsData(data) {
    return {type: PROJECTS_DATA, payload: data}
}
function currentProject(data){
    return {type: CURRENT_PROJECT, payload: data}
}


// 非redux管理的方法
export function updateProjectRequest(data,edit=false, id){
    if(edit){
        return axios.put(`/account/network-users/${id}`,data)
    }else{
        return axios.post('/account/network-users',data)
    }
}

/**
 * 根据project ID 获取当前的project信息，主要用于填写topBarInside中的项目信息
 * @param {*project id} id 
 */
export function getCurrentProject(id){
    return axios.get(`/account/network-users/${id}`)
}

// 删除项目
export function deleteProject(id){
    return axios.delete(`/account/network-users/${id}`)
}
