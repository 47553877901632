import React, { Component } from 'react';
import { Switch, Route} from 'react-router-dom';
import UserList from './user';
import UserEdit from './user.edit'
import UserAdd from './user.add'
import HomeLayout from '../../components/layout/home.layout'
import NoneSideBarLayout from '../../components/layout/non-sidebar.layout'
class UserRouter extends Component {


  render() {
    const userList = <HomeLayout>
                        <UserList></UserList>
                    </HomeLayout>
    const editView  = <NoneSideBarLayout hideProject={true}>
                        <Route  path='/users/register' component={UserEdit}/>
                        <Route  path='/users/add' component={UserAdd}/>
                        <Route  path='/users/edit/:id' component={UserEdit}/> 
                    </NoneSideBarLayout>
    return (
      <Switch>
        <Route exact path='/users' render={()=>userList}/>
        <Route path='/users' render={()=>editView}/>
      </Switch>
    );
  }
}

export default UserRouter;