import React, {Component}  from 'react';
import { connect} from 'react-redux'
import { withRouter, Link } from 'react-router-dom'

import CustomPagination from '../../components/pagination/pagination'
import Header from '../../components/header';
import Table from '../../components/table'
import add from '../../assets/images/add.svg';
import editImg  from '../../assets/images/edit_white.svg'
import defaultAvatar from '../../assets/images/avatar.jpg'
import { getUsersData} from '../../redux/06_users.redux'
import './styles.css';


@withRouter
@connect(
    state=>{
        return {...state.auth,...state.users}
    },
    {getUsersData}
)
class User extends Component {

    componentDidMount(){
        this.getDatas({start:0});
    }

    pageChangeHandler(page) {
        this.getDatas({start:page-1})
    }

    onSearch = (params)=>{
        this.props.getUsersData(params);
    }

    getDatas = (params) =>{
        const isAdmin = this.props.authUser.rolesIds.indexOf(1) > -1;
        params = {
            ...params,
            rolesIdNotEqual: 4
        }
        if(isAdmin) delete params.rolesIdNotEqual
        this.props.getUsersData(params)
    }

    userRegister = ()=>{
        this.props.history.push('/users/register')
    }

    render(){
        const filters = [{
            key:'nameLike',
            placeholder:'姓名'
        }];

        const columns = [
            {
              title: '头像',
              key: 'avatar',
              width: 180,
              render: (text, record) => (
                <img className='avatar_userlist' src={record.userOrgMap&&record.userOrgMap.length?
                    (record.userOrgMap[0].avatar?record.userOrgMap[0].avatar:defaultAvatar)
                    :(record.avatar || defaultAvatar)} alt='头像'></img>
              ),
            },
            {
              title: '姓名',
              render: (text, record) => (
                <div>{record.userOrgMap?
                    (record.userOrgMap.length&&record.userOrgMap[0].name?record.userOrgMap[0].name:(record.name||'未知'))
                    :'未知'}</div>
              ),
              width: "15%",
            },
            {
              title: '电话',
              dataIndex: 'phone',
              key: 'phone',
              width: "15%",
            },
            {
                title: '角色',
                render: (text, record) => (
                    <div>{record.userOrgMap?
                        (record.userOrgMap.length&&record.userOrgMap[0].roleName?record.userOrgMap[0].roleName:((record.role&&record.role.name) || '未知'))
                        :'未知'}</div>
                  ),
                width: "15%",
            },
            {
                title: '维保单位/所属项目',
                key: 'organization',
                render: (text, record) => (
                    <div>{record.organization&&record.organization.length ? record.organization.map(ele=><div key={ele}>{ele}</div>) : null}</div>
                  ),
            },
            {
              title: '',
              key: 'action',
              width: 180,
              render: (text, record) => (
                <Link className='edit-action' to={`/users/edit/${record.id}`}>
                  <img src={editImg} alt='edit'></img>
                  查看修改
                </Link>
              ),
            },
          ];
          

        const app = (
            <div className = 'content'>
                <Header title = '人员列表' filters ={filters} remoteSelectFilters={['userOrgMap.organizationId']} onSearch={this.onSearch}>
                    <div className='new_org new' onClick={this.userRegister} >
                        <img src={add} alt='add' className='add_img  new'></img>人员注册
                    </div>
                </Header>
                <div className='main-table-container'>
                    <Table  scroll={{ y: 640 }}
                            columns={columns} 
                            dataSource={this.props.users.map(user=>{return {...user, key:user.id}})} 
                            loading={this.props.loading}/>
                    <CustomPagination
                        total={this.props.total}
                        onChange={(e)=>{this.pageChangeHandler(e)}}/>
                </div>
            </div>
        );
        return app
    }
}

export default User