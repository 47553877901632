import React, { Component } from 'react';
import { Switch, Route} from 'react-router-dom';
import NewProjectList from './new_project';
import NewProjecteditView from './new_project.edit'
import HomeLayout from '../../components/layout/home.layout'
import NoneSideBarLayout from '../../components/layout/non-sidebar.layout'

class newProjectRouter extends Component {
  render() {
    const newProjectList = <HomeLayout>
                        <NewProjectList></NewProjectList>
                    </HomeLayout>
    const newProjecteditView  = <NoneSideBarLayout hideProject={true}>
                        <Route  path='/new_project/register' component={NewProjecteditView}/>
                        <Route  path='/new_project/edit/:id' component={NewProjecteditView}/> 
                    </NoneSideBarLayout>


    return (
      <Switch>
        <Route exact path='/new_project' render={()=>newProjectList}/>
        <Route path='/new_project' render={()=>newProjecteditView}/>
      </Switch>
    );
  }
}

export default newProjectRouter;


// import React,{Component} from "react";

// export default class App extends Component{
//     constructor(props){
//         super(props)

//         this.state = {
//             counter : 0,
//         }
//     }
    

//     render(){
//         const {title,name,age} = this.props;
//         return (
//             <div>
//                 <div>{title},{name},{age}</div>
//                 <p>当前计数：{this.state.counter}</p>
//                 <button onClick={e => this.setNums(1)}>+</button>
//                 <button onClick={e => this.setNums(2)}>-</button>
//             </div>
//         )   
//     }

//     setNums(type) {
//         if(type==1){
//             this.setState({
//                 counter : this.state.counter + 1
//             })
//         }else{
//             this.setState({
//                 counter : this.state.counter - 1
//             })
//         }
//     }
// }

