import React, {Component}  from 'react'
import { connect } from 'react-redux'
import { Redirect,Link }  from 'react-router-dom'
import { Form,  Input, Button} from 'antd'

import { login} from '../../redux/auth.redux'

import './styles.css'
import logo from '../../assets/images/logo.jpg'
import loginWeb from '../../assets/images/login-web.png'

// 两个reducers，每个reducer都有一个state
// 合并reducers
@connect(
    state=>state.auth,
    { login}
)
class Auth extends Component {
    
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
          if (!err) {
            this.props.login(values);
          }
        });
    };
    render(){
        const { getFieldDecorator } = this.props.form;
        return (
            <div className='body'>
                {this.props.isAuth? <Redirect to = '/'></Redirect> : null}
                <Form onSubmit={this.handleSubmit} className="login-form">
                    <div className='logo-container'>
                        <img src={logo} alt='logo' className='logo-login'></img>
                        <div>智慧维保管理系统</div>
                    </div>
                    <Form.Item>
                    {getFieldDecorator('username', {
                        rules: [{ required: true, message: '请输入用户名!' }],
                    })(
                        <Input
                        // prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="用户名"
                        />,
                    )}
                    </Form.Item>
                    <Form.Item>
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: '请输入密码!' }],
                    })(
                        <Input
                        // prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="password"
                        placeholder="密码"
                        />,
                    )}
                    </Form.Item>
                    <Form.Item>
                        <Link className="login-form-forgot" to="/password/reset">
                            忘记密码?
                        </Link>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            登录
                        </Button>
                    </Form.Item>
                </Form>
                <img className='footer' alt='web' src={loginWeb}></img>
            </div>
        );
    }
}

// const stateMapTo
export default Form.create({ name: 'normal_login' })(Auth)