import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Popconfirm, Input, message, Icon} from 'antd';



import editImg from '../../assets/images/edit_white.svg';
import deleteImg from '../../assets/images/delete_red.svg'
import EditBar from '../../components/edit-bar/edit-bar'

import {getCurrentFloor,getCurrentBuilding, updateFloor, updateBuilding, deleteBuilding, deleteFloor } from '../../redux/02_buildings.redux'
import { getCurrentProject, dispatchCurrentProject} from '../../redux/01_project.redux'

const Search = Input.Search;

@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.buildings,
            ...state.projects
        }
    },
    {dispatchCurrentProject}
)
class BuildingsEdit extends Component{

    constructor(props){
        super(props);
        this.state={
            editBuilding: false,
            editFloor:false,
            building:{},
            floor:{}
        }
    }
    componentDidMount(){
        if(Object.keys(this.props.match.params).length){
            let {id, organizationId} = this.props.match.params;
            getCurrentFloor(id).then(res=>{
                this.setState({
                    floor: res
                },()=>{
                    this.getBuildingData(res.buildingId);
                });
            });

            getCurrentProject(organizationId).then(res=>{
                this.props.dispatchCurrentProject(res);
            })
        }
    }

    deleteBuilding = ()=>{
        deleteBuilding(this.state.building.id).then(res=>{
            message.success('删除成功');
            this.props.history.goBack();
        })
    }

    deleteFloor = ()=>{
        deleteFloor(this.state.floor.id).then(res=>{
            message.success('删除成功');
            this.props.history.goBack();
        })
    }

    editBuilding = ()=>{
        this.setState({
            editBuilding: true
        })
    }

    editFloor = ()=>{
        this.setState({
            editFloor: true
        })
    }
    getBuildingData=(id)=>{
        getCurrentBuilding(id).then(res=>{
            this.setState({
                building:res
            })
        })
    }


    saveBuilding = (value)=>{
        updateBuilding({name: value}, true, this.state.building.id, ).then(res=>{
            this.getBuildingData(res.id);
        })
        this.setState({
            editBuilding:false
        })
    }

    saveFloor = (value)=>{
        updateFloor({name: value}, true, this.state.floor.id, ).then(res=>{
            getCurrentFloor(res.id).then(res=>{
                this.setState({
                    floor:res, 
                })
            })
        })
        this.setState({
            editFloor: false
        })
    }
    
    render(){
        
        return (
            <div>
                <div className='buildings-floors'>
                    <div className = 'buildings'>
                        <div className='title'>楼栋</div>
                        {
                                this.state.editBuilding?
                                <div className='input-confirm' key={this.state.building.id}>
                                    <Search
                                        enterButton={<Icon type='check'/>}
                                        size="large"
                                        defaultValue = {this.state.building.name}
                                        onSearch={this.saveBuilding}
                                    />
                                </div>
                                :
                                <div className='building-item'>
                                    <div className='name'>{this.state.building.name}</div>
                                    <div>
                                        <img alt='edit' className='edit-img' src={editImg} onClick={this.editBuilding}/>
                                        <Popconfirm
                                            title="确定要删除当前楼栋吗?"
                                            onCancel = {()=>console.log('cancel')}
                                            onConfirm={this.deleteBuilding}
                                            okText="是"
                                            cancelText="否"
                                        >
                                            <img alt='delete' className='delete-img' src={deleteImg} />
                                        </Popconfirm>
                                    </div>
                                </div>
                        }
                    </div>
                    <div className = 'floors'>
                        <div className='title'>楼层</div>
                        {
                                this.state.editFloor?
                                <div className='input-confirm' key={this.state.floor.id}>
                                    <Search
                                        enterButton={<Icon type='check'/>}
                                        size="large"
                                        defaultValue = {this.state.floor.name}
                                        onSearch={this.saveFloor}
                                    />
                                </div>
                                :
                                <div className='floor-item'>
                                    <div className='name'>{this.state.floor.name}</div>                               
                                    <div>
                                        <img alt='edit' className='edit-img' src={editImg}  onClick={this.editFloor}/>
                                        <Popconfirm
                                            title="确定要删除当前楼层吗?"
                                            onCancel = {()=>console.log('cancel')}
                                            onConfirm={this.deleteFloor}
                                            okText="是"
                                            cancelText="否"
                                        >
                                            <img alt='delete' className='delete-img' src={deleteImg} />
                                        </Popconfirm>
                                    </div>
                                </div>
                        }
                    </div>
                </div>

                <div className='edit-bar-container'>
                    <EditBar back={true}/>
                </div>
            </div>
        )
    }
}

export default  BuildingsEdit