import React, {Component} from 'react'
import './styles.css'

class ErrorPage extends Component{
    render(){
        return (
            <h1 className='error-title'>404 NOT FOUND</h1>
        )
    }
}
export default ErrorPage