import React, { Component } from 'react';
import { Switch, Route} from 'react-router-dom';
import ProjectMap from './project_map';
import HomeLayout from '../../components/layout/home.layout'

class ProjectMapRouter extends Component {
  render() {
    const projectMap = <HomeLayout>
                        <ProjectMap></ProjectMap>
                    </HomeLayout>


    return (
      <Switch>
        <Route exact path='/project_map' render={()=>projectMap}/>
      </Switch>
    );
  }
}

export default ProjectMapRouter;
