import React, {Component}  from 'react';
import { connect } from 'react-redux';
import { getRegionData} from '../../redux/07_region.redux'
import Header from '../../components/header';
import Table from '../../components/table';
import CustomPagination from '../../components/pagination/pagination'

import './styles.css';

@connect(
    state=> state.region,
    {getRegionData}
)
class Region extends Component {
    componentDidMount(){
        this.props.getRegionData({start:0});
    }
    
    pageChangeHandler(page) {
        this.props.getRegionData({start: page-1})
    }

    onSearch = (params)=>{
        this.props.getRegionData(params);
    }

    render(){
        // console.log(this.props)
        const filters = [{
            key:'nameLike',
            placeholder:'区域名称'
        },{
            key:'codeLike',
            placeholder:'区域代码'
        }];

        const columns = [
            {
              title: '简称',
              dataIndex: 'name',
              key: 'name',
              width: "10%",
            },
            {
              title: '全称',
              dataIndex: 'fullName',
              key: 'fullName',
            },
            {
              title: '编码',
              dataIndex: 'code',
              key: 'code',
              width: "15%",
            },
            {
                title: '级别',
                dataIndex: 'level',
                key: 'level',
                width: "10%",
            },
            {
                title: '纬度',
                dataIndex: 'latitude',
                key: 'latitude',
                width: "15%",
            },
            {
                title: '经度',
                dataIndex: 'longitude',
                key: 'longitude',
                width: "15%",
            },
            {
                title: '上级ID',
                dataIndex: 'superiorId',
                key: 'superiorId',
                width: "10%",
            },
          ];
          
        return (
            <div className = 'content'>
                <Header title = '区域列表' filters ={filters} onSearch={this.onSearch}></Header>
                <div className='main-table-container'>
                    <Table  scroll={{ y: 640 }}
                            columns={columns} 
                            dataSource={this.props.raw_regions.map(ele=>({...ele,key:ele.id}))} 
                            loading={this.props.loading}/>
                    <CustomPagination
                        total={this.props.total}   
                        onChange={(e)=>{this.pageChangeHandler(e)}}/>
                </div>
            </div>
        )
    }
}

export default Region