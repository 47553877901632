import React, {Component}  from 'react';
import { Icon , Modal, Slider } from 'antd';
import './styles.css';

class timeAxis extends Component{
    constructor(props){
        super(props)

        this.state = {
            startDef: '',
            endDef: '',
            min: '',
            max: '',

            w1: 0,
            w2: 0,
            w3: 0
        }
    }

    componentWillMount(){
        let { startDate, endDate, normalDate,warningDate } = this.props, 
            min = this.timeFmt(startDate),
            max = this.timeFmt(endDate),
            startDef = '',
            endDef = ''; console.log(990,startDate, endDate, normalDate,warningDate);
        if(normalDate!='' && warningDate!=''){
            startDef = this.timeFmt(normalDate);
            endDef = this.timeFmt(warningDate);
        }else{
            startDef = parseInt(min + (max - min)*0.3333);
            endDef = parseInt(min + (max - min)*0.6666);
        }
        
        this.setState({
            min: min,
            max: max,
            startDef: startDef,
            endDef: endDef
        })
        this.changeSlider([startDef,endDef]);
    }


    render(){
        let { startDate, endDate } = this.props; 
        let step = 86400,
            min = this.state.min,
            max = this.state.max, 
            startDef = this.state.startDef,
            endDef = this.state.endDef;
        
        return (
            <div className='timeaxis'>
                <div className="timeaxis_re">
                    <Slider 
                        range 
                        min={min}
                        max={max}
                        step={step}
                        tipFormatter={this.doTipFormatter}
                        defaultValue={[startDef, endDef]} 
                        onChange={this.changeSlider} 
                        tooltipVisible={true} 
                        getTooltipPopupContainer={()=>document.querySelector('.timeaxis_re')}  
                    />
                </div>
                <div className="timeaxis_ab">
                    <div className="tibb tibb1">{this.fmtTime(min)}</div>
                    <div className="tiab tiab1" style={{"width": `${this.state.w1}%`}}><p>常态</p></div>
                    <div className="tiab tiab2" style={{"width": `${this.state.w2}%`,left: `${this.state.w1}%`}}><p>预警</p></div>
                    <div className="tiab tiab3" style={{"width": `${this.state.w3}%`}}><p>紧急</p></div>
                    <div className="tibb tibb2">{this.fmtTime(max)}</div>
                </div>
            </div>

        )
    }

    //时间格式转时间戳
    timeFmt(date){
        let dates = new Date(date); 
        return (dates.getTime());
    }

    //时间戳转时间格式
    fmtTime(str){ 
        let date = new Date(str);
        let year = date.getFullYear();
        let month= date.getMonth() + 1;
        month= month< 10 ? ('0' + month) : month;
        let day = date.getDate();
        day = day < 10 ? ('0' + day ) : day ;
        
        return year + '' + month + '-' + day ;

    }

    //tip格式化
    doTipFormatter = (val) => {
        let data = this.fmtTime(val); 
        return data;
    }


    //值改变时触发
    changeSlider = (e) => {
        let { startDate, endDate } = this.props; 
        let min = this.timeFmt(startDate),
            max = this.timeFmt(endDate);
        let all = max - min,
            first = e[0] - min,
            second = e[1] - e[0],
            last = max - e[1];
        let w1 = (first/all)*100,
            w2 = (second/all)*100,
            w3 = (last/all)*100;
        this.setState({
            w1: w1,
            w2: w2,
            w3: w3
        })
        this.props.handeChangeSlider(e);
    }
}

export default timeAxis