import React, { Component } from 'react';
import { Switch, Route} from 'react-router-dom';
import BuildingList from './buildings';
import BuildingEdit from './buildings.edit'
import BuildingNew from './buildings.new'
// import BuildingListGuide from './buildings.guide.list'
import HomeLayout from '../../components/layout/home.layout'
import NoneSideBarLayout from '../../components/layout/non-sidebar.layout'

class BuildingsRouter extends Component {


  render() {
    const buildingListView = <HomeLayout>
                                <BuildingList/>
                            </HomeLayout>
    const editView  = <NoneSideBarLayout>
                            <Route path='/buildings/add' component={BuildingNew}/>
                            <Route path='/buildings/edit/:organizationId/:id' component={BuildingEdit}/>
                    </NoneSideBarLayout>
    return (
      <Switch>
        <Route exact path='/buildings' render={()=>buildingListView}/>
        <Route path='/buildings' render={()=>editView}/>
      </Switch>
    );
  }
}

export default BuildingsRouter;