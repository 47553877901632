import React, {Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {  Form, Input, DatePicker, Select, Upload, message, Spin} from 'antd';
import moment from 'moment'

import EditBar from '../../components/edit-bar/edit-bar';
import {formItemLayout} from '../../components/layout/formlayout'

import deleteImg  from '../../assets/images/delete_red.svg'
import {getCurrentContract, updateContractRequest, deleteContract} from '../../redux/04_contract.redux'
import { dispatchCurrentProject, getCurrentProject} from '../../redux/01_project.redux'
import {getUsersData} from '../../redux/06_users.redux'

import{ getHeaders, _baseUrl} from '../../axios.config'

const Option = Select.Option;

@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.projects,
            ...state.users
        }
    },
    {getUsersData,dispatchCurrentProject, getCurrentProject}
)
class ContractEdit extends Component{

    constructor(props){
        super(props);
        this.state={
            edit: false,
            files:[],
            loading: false,
            headers:{},
            iconLoading: false
        }
    }

    updateHeaders = async()=>{
        let headers = await getHeaders(true);
        this.setState({
            headers
        })
    }

    componentWillMount(){
        this.updateHeaders();
        this.props.getUsersData({
            size: 2000,
            'userOrgMap.roleId': 3
        }, false)
        if(Object.keys(this.props.match.params).length){
            let { organizationId, id} = this.props.match.params;
            getCurrentProject(organizationId).then(projectData=>{
                this.props.dispatchCurrentProject(projectData)
            })
            id&&this.setState({loading: true});
            id&&getCurrentContract(id).then(res=>{
                this.setState(res);
                this.setState({id, edit: true, loading: false});
            })
        }
    }

    handleSubmit = e => {
        
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let data={
                    ...values,
                    startTime: new Date(values.startTime).toISOString(),
                    endTime: new Date(values.endTime).toISOString(),
                    organizationId: this.props.currentProject.id,
                    files: JSON.stringify(this.state.files),
                }
                this.setState({iconLoading: true});
                updateContractRequest(data, this.state.edit, this.state.id).then(res=>{
                    this.props.history.goBack();
                }).finally(()=>this.setState({iconLoading: false}));
            }
        });
    };

    removeFile(index){
        let files = this.state.files;
        files.splice(index,1);
        this.setState({
            files
        })
    }

    deleteContract=()=>{
        deleteContract(this.state.id).then(()=>{
            message.success('删除成功');
            this.props.history.goBack();
        })
    }

    render(){
        // console.log(this.props)
        const { getFieldDecorator } = this.props.form; 
        const _this = this;  
        const props = {
            name: 'file',
            showUploadList: false,
            action: `${_baseUrl}/resource/resources/put-oss`,
            headers: _this.state.headers,
            onChange(info) {
              if (info.file.status === 'done') {
                message.success(`${info.file.name} 上传成功`);
                let newFile={
                    url: info.fileList[info.fileList.length-1].response.url,
                    name: info.file.name
                };
                let files = _this.state.files;
                files =files.concat([newFile]);
                _this.setState({
                    files
                })
              } else if (info.file.status === 'error') {
                message.error(`${info.file.name} 上传失败`);
              }
            },
            beforeUpload:beforeUpload
          };  

        return (
            <div className='edit-content'>
                <EditBar back={true} save={true} iconLoading={this.state.iconLoading} onSave = {this.handleSubmit} delete={this.state.edit} onDelete={this.deleteContract}/>
                <div className='edit-form-container-wrapper'>
                    <div className='brief-content edit-form-container brief-content-contract'>
                        {
                            this.state.loading?
                                <div className="spin-container-half">
                                            <Spin  size="large" tip="正在加载..."/>
                                </div>
                            :
                                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                                    <Form.Item label='合同名称'>
                                    {getFieldDecorator('name', {
                                        initialValue: this.state.name,
                                        rules: [
                                        {
                                            required: true,
                                            message: '合同名称不能为空',
                                        },
                                        ],
                                    })(<Input  placeholder="请输入合同名称"/>)}
                                    </Form.Item>
                                    <Form.Item label='合同描述'>
                                    {getFieldDecorator('content', {
                                        initialValue: this.state.content,
                                        rules: [{ required: true, message: '合同描述不能为空!' }],
                                    })(<Input.TextArea autosize={{ minRows: 4, maxRows: 6 }} placeholder="请输入合同描述"/>)}
                                    </Form.Item>
                                    <Form.Item label='开始时间'>
                                    {getFieldDecorator('startTime', {
                                        initialValue: this.state.startTime ? moment(this.state.startTime):null,
                                        rules: [{ required: true, message: '开始时间不能为空!' }],
                                    })(<DatePicker  placeholder="请选择开始时间" />)}
                                    </Form.Item>
                                    <Form.Item label='结束时间'>
                                    {getFieldDecorator('endTime', {
                                        initialValue: this.state.endTime ? moment(this.state.endTime):null,
                                        rules: [{ required: true, message: '结束时间不能为空!' }],
                                    })(<DatePicker placeholder="请选择结束时间" />)}
                                    </Form.Item>
                                    <Form.Item label='项目负责人'>
                                        {getFieldDecorator('responsibleUserId', {
                                            initialValue: this.state.responsibleUserId,  // 项目负责人只能选维保经理，默认当前登录用户
                                            rules: [{ required: true, message: '项目负责人不能为空!' }],
                                        })(    <Select placeholder="请选择项目负责人" >
                                                {
                                                    this.props.users.map(ele=> <Option key={ele.id} value={ele.id}>{ele.name}</Option>)
                                                }
                                                </Select>)}
                                        
                                    </Form.Item>
                                    <div>                              
                                        <Upload {...props}>
                                            <div id='contractUpdate'>上传维保合同</div>
                                        </Upload>                           
                                    </div>
                                    
                                    {
                                        this.state.files?
                                        <div className='file-list'>
                                            {
                                                this.state.files.map((file,index)=>(
                                                    <div key={file.url}>
                                                        <div className='file-title'>文件名</div>
                                                        <div className='file-flex'>
                                                            <div className='file-name'>{file.name}</div>
                                                            <img src={deleteImg} alt='delete' className='delete-file' onClick={()=>{this.removeFile(index)}}/>   
                                                        </div>
                                                        <a href={file.url} target='_blank'  rel="noopener noreferrer" className='file-link-edit'>文件链接</a>
                                                    </div>
                                                ))
                                            }
                                        </div>:null
                                    }
                                </Form> 
                        }                                    
                    </div>
                </div>
            </div>

        )
    }
}

export default Form.create()(ContractEdit)


function beforeUpload(file) {
    // const isPDF= file.type === 'application/pdf';
    // const isDoc= file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    // if (!isPDF&&!isDoc){
    //   message.error('只能上传pdf/docx类型的文件!');
    // }
    // return isPDF || isDoc;
    // console.log(file.type)
    return true
  }