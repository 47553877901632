import React, {Component}  from 'react';
import { connect} from 'react-redux'
import { Link, withRouter} from 'react-router-dom'


import CustomPagination from '../../components/pagination/pagination'
import Header from '../../components/header';
import Table from '../../components/table'
import add from '../../assets/images/add.svg';
import editImg from '../../assets/images/edit_white.svg'
import defaultAvatar from '../../assets/images/logo.jpg';
import{ getStorage } from '../../utils/utils'
import { getProjectsData} from '../../redux/01_project.redux'

import './styles.css';

@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.projects
        }
    },
    {getProjectsData}
)
class Project extends Component {
    //
    componentWillMount(){
        let userRoid = getStorage("userRoid");
        let url = '/orgs-management';
        if(userRoid==6){
            url = '/new_project'
        }else if(userRoid==7){
            url = '/drawing_review'
        }else if(userRoid==8){
            url = '/acceptance'
        }else if(userRoid==9){
            url = '/drawing_review_p'
        }else if(userRoid==10 || userRoid==2){
            url = '/acceptance_p'
        }else{
            url = '/orgs-management'
        }
        this.props.history.push(url);
    }

    componentDidMount(){
        if(this.props.isAuth){
            this.props.getProjectsData({start:0}, this.props.authUser.access_token)
        }
    }
    pageChangeHandler(page) {
        this.props.getProjectsData({start: page-1})
    }
    onSearch = (params)=>{
        this.props.getProjectsData(params);
    }
    addProject=()=>{
        this.props.history.push('/orgs-management/guide/new')
    }

    render(){
        // console.log(this.props)
        const filters = [{
            key:'nameLike',
            placeholder:'项目名称'
        }];

        const columns = [
            {
              title: '项目图片',
              key: 'picture',
              width: 180,
              render: (text, record) => (
                //   <span>{record.picture}</span>
                <img className='picture_maintainerlist' src={record.picture || defaultAvatar} alt='单位图片'></img>
              ),
            },
            {
              title: '项目名称',
              dataIndex: 'name',
              width: "20%",
              key: 'name',
            },
            // {
            //   title: '详细地址',
            //   dataIndex: 'detailAddr',
            //   key: 'detailAddr',
            //   width: "20%",
            // },
            {
                title: '联系方式',
                dataIndex: 'phone',
                key: 'phone',
                width: "15%",
            },
            // {
            //     title: '所属区域',
            //     dataIndex: 'region.fullName',
            //     key: 'region.fullName',
            //     width: "20%",
            
            // },
            {
                title: '维保单位',
                dataIndex: 'maintainer.name',
                key: 'maintainer.name',
                width: "15%",
            
            },
            {
              title: '',
              key: 'action',
              width: 180,
              render: (text, record) => (
                <Link className='edit-action' to={`/orgs-management/guide/info/${record.id}`}>
                <img src={editImg} alt='edit'></img>
                查看
              </Link>
              ),
            },
          ];  
        
        const app = (
            <div className = 'content'>
                <Header title = '项目列表' filters ={filters} onSearch={this.onSearch}>
                    <div className='new_org new' onClick={this.addProject}>
                        <img src={add} alt='add' className='add_img  new'></img>新建项目
                    </div>
                </Header>
                <div className='main-table-container'>
                    <Table  columns={columns}  
                            scroll={{ y: 640 }}
                            dataSource={this.props.projects.map(ele=>{return {...ele, key:ele.id}})}
                            loading={this.props.loading}/>
                    <CustomPagination
                        total={this.props.total}
                        onChange={(e)=>{this.pageChangeHandler(e)}}/>
                </div>
            </div>
        );
        // const redirectToLogin  = <Redirect to= '/login'></Redirect>
        return this.props.isAuth? app : app
    }
}

export default Project