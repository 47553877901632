
import axios from '../axios.config'
import {HIDE_LOADING, SHOW_LOADING, hideLoading, showLoading, getOrganizationsByIDs} from './00_common.action'

const INSPECTION_POINT_DATA = 'INSPECTION_POINT_DATA';
const MAINTENCE_ITEM_DATA = 'MAINTENCE_ITEM_DATA'
const MAINTENCE_ITEM_ORIGIN_DATA = 'MAINTENCE_ITEM_ORIGIN_DATA'

const INSPECTION_POINT_DATA_OF_PLAN = 'INSPECTION_POINT_DATA_OF_PLAN'
const INSPECTION_POINT_DATA_IN_SELECTED_BUILDING = 'INSPECTION_POINT_DATA_IN_SELECTED_BUILDING'
const BUILDING_ITEMS_DATA = 'BUILDING_ITEMS_DATA'
const HIDE_GUIDE_LOADING = 'HIDE_GUIDE_LOADING'
const SHOW_GUIDE_LOADING = 'SHOW_GUIDE_LOADING'

//reducer
const initState = {
    inspectionPoints : [],
    buildingItems:[],
    inspectionPointsInSelectedBuilding:[],
    maintenanceItems:[],
    inspectionPointsOfPlan:[],
    originMaintenanceItems:[],
    currentInspectionId:null,
    total:0,
    start:0,
    loading: true,
    inspectionGuideLoading: true
}


export function inspections(state = initState, action) { 
    switch(action.type){
        case INSPECTION_POINT_DATA:
            return { ...state, 
                inspectionPoints: action.payload.data,
                total: action.payload.total,
                start: action.payload.start,
             }
        case BUILDING_ITEMS_DATA:
            return {
                ...state,
                buildingItems: action.payload.data
            }
        case INSPECTION_POINT_DATA_IN_SELECTED_BUILDING:
            return {
                ...state,
                inspectionPointsInSelectedBuilding: action.payload.data,
            }
        case MAINTENCE_ITEM_DATA:
            return { ...state, 
                maintenanceItems: action.payload,
            } 
        case MAINTENCE_ITEM_ORIGIN_DATA :
            return { ...state, 
                originMaintenanceItems: action.payload,
            } 
        case INSPECTION_POINT_DATA_OF_PLAN:
            return { ...state, 
                inspectionPointsOfPlan: action.payload,
            } 

        case SHOW_LOADING:
             return {
                 ...state,
                 loading: true,
             }
        case HIDE_LOADING:
             return {
                 ...state,
                 loading: false
             }
        case SHOW_GUIDE_LOADING:
            return {
                ...state,
                inspectionGuideLoading: true
            }
        case HIDE_GUIDE_LOADING:
            return {
                ...state,
                inspectionGuideLoading: false
            }
        default:
            return state
    }
}

//action creator
export function getInspectionPointData(params){
    // dispatch 用来通知数据修改
    return dispatch => {
        dispatch(showLoading())
        axios.get('/maintenance/inspection-points',{params})
            .then(res=>{
                let organizationIds = res.data.map(ele=>ele.organizationId);
                Promise.all([getOrganizationsByIDs(organizationIds)]).then(res2=>{
                    let _data = res.data.map(ele=>{
                        return {
                            ...ele,
                            organization: res2[0].data.find(origanization=>origanization.id === ele.organizationId)
                        }
                    })
                    let payload = {
                        ...res,
                        data: _data
                    }
                    dispatch(inspectionPointData(payload))
                }) 
            }).finally(()=>dispatch(hideLoading()))
    }
}

export function getInspectionPointDataOfGuidePage(params){
    // dispatch 用来通知数据修改
    return dispatch => {
        dispatch({type: SHOW_GUIDE_LOADING});
        axios.get('/maintenance/inspection-points',{params})
            .then(res=>{
                let inspectionPoints = res.data;
                const buildingIdItems = Array.from(new Set(inspectionPoints.map(ele=>ele.buildingId)));
                let buildingItems = buildingIdItems.map(id=>{
                    return inspectionPoints.find(ele=>ele.buildingId === id).building
                });
                
                dispatch(inspectionPointData(res));
                dispatch(buildingItemsData({data:buildingItems}));
                dispatch(getInspectionsDataInSelectedBuildings({
                    ...params,
                    buildingId: buildingIdItems[0]
                }));
                dispatch({type:HIDE_GUIDE_LOADING})
            })
    }
}

export function getInspectionPointDataOfPlan(params, planId, type){
    // dispatch 用来通知数据修改
    return dispatch => {
         axios.get(`/maintenance/plans/${planId}`).then(res=>{
             // 获取当前计划的contractId
             const {contractId} = res;
             const allPlanParams = {
                 contractId,
                 type,
                 size: 2000,
             }

             Promise.all([axios.get('/maintenance/inspection-points',{params}),   // 获取当前项目的所有巡查点
                    axios.get(`/maintenance/plans`, {params: allPlanParams})])
            .then(res=>{
                let rawPlanContents = generatePlanContentAll(res, type);
                dispatch(inspectionPointsOfPlan(rawPlanContents));
            })
         })
        
    }
}



//获取维保项目列表
/**
 *当“显示全部”勾选时，获取预定义的那个接口调用参数改为：predefine_OR_organizationIdIn=[true,[1,3,4]]。其中 organizationId是可以获取到的所有联网用户的id
当“显示全部”不勾选时，跟原获取系统预定义的参数方式一致：predefine=true
 *
 * @export
 * @param {*} params
 * @returns
 */
export function getMaintenanceItems(params){
    // 获取当前巡查点下，当前类型的维保内容，
    //获取当前类型的预定的维保内容
    //两边做并集，并加入巡查点check信息
    
    const _params = {
        type: params.type,
        inspectionPointId: params.inspectionPointId,
        organizationId: params.organizationId,
        size: params.size
    }
    const showAllItems = params.showAllItems;
    if(!showAllItems){
        return dispatch => {
            Promise.all([axios.get('/maintenance/items',{
                    params:{
                        ..._params,
                        size: 2000
                    }
            }),axios.get('/maintenance/items',{
                    params:{
                        type: params.type,
                        predefine: true,
                    size: 2000
                    }})])
                .then(res=>{
                    dispatch(maintenanceItemsData(generateItems(res[0].data, res[1].data)));
                    dispatch(maintenanceItemsOriginData(generateOriginItems(res[0].data)));
                })
        }
    } else {
        const organizationIds = params.organizationIds;
        const predefine_OR_organizationIdIn = [true,organizationIds]
        return dispatch => {
            Promise.all([axios.get('/maintenance/items',{
                    params:{
                        ..._params,
                        size: 2000
                    }
            }),axios.get('/maintenance/items',{
                    params:{
                        type: params.type,
                        predefine_OR_organizationIdIn: JSON.stringify(predefine_OR_organizationIdIn),
                    size: 2000
                    }})])
                .then(res=>{
                    dispatch(maintenanceItemsData(generateItems(res[0].data, res[1].data)));
                    dispatch(maintenanceItemsOriginData(generateOriginItems(res[0].data)));
                })
        }
    }

}

//在勾选了默认的（国标的）项目和内容情况下，点击“+”按钮完成新增项目后，之前勾选的内容都被清除了。这个问题影响用户体验
// 在添加维保项目或者维保内容后，不再拉取新数据，直接更新originMaintenanceItem和maintenanceItem
export function updateItemAndContentState(newData, type, maintenanceItems, originMaintenanceItems){
    return dispatch =>{
        if(type ==='item'){
            newData = {
                ...newData,
                checkAll: false,
                checked: true,
                contents: []
            };
            maintenanceItems.push(newData);        
        }else if(type === 'content'){
            newData = {
                ...newData,
                checked: true
            };
            maintenanceItems = maintenanceItems.map(item=>{
                if(item.name === newData.itemName){
                    let contents = item.contents.concat([newData]);
                    item={
                        ...item,
                        checked: true,
                        checkAll: contents.length === contents.filter(content=>content.checked).length,
                        contents
                    };
                }
                return item;
            });
        }

        dispatch(maintenanceItemsData(maintenanceItems));
    }
}


// 点击全选后更新check信息
export function onClickSelectAll(maintenanceItems, itemIndex){
    maintenanceItems = maintenanceItems.map((ele,index)=>{
        // 判断是全选还是全不选
        let checkAll = index === itemIndex? !ele.checkAll : ele.checkAll;  
        let checked =  index === itemIndex? (checkAll): ele.checked;

        let contents =  ele.contents.map(content=>{
            if(index === itemIndex){
                content ={
                    ...content,
                    checked: checkAll
                }
            }
            return content
        });
        
        return{
            ...ele,
            contents,
            checkAll,
            checked
        }
    });
    return dispatch=>{
        dispatch(maintenanceItemsData(maintenanceItems));
    }
}

// 选择维保项目后更新check信息

export function checkMaintenanceItem(items, itemName, content, type='item'){
    return dispatch=>{
        let newItems = [];
        if(type === 'item'){
            newItems = items.map(ele=>{
                if(ele.name === itemName){
                    // 如果当前项目是由check状态变为非check状态，则其下属的content中的checked都应该变为false，
                    let newContents = ele.contents;
                    if(ele.checked){
                        newContents = ele.contents.map(ele=>({
                            ...ele,
                            checked: false
                        }))
                    }
                    ele = {
                        ...ele,
                        contents: newContents,
                        checked: !ele.checked,
                        checkAll: false
                    }
                }
                return ele;
            })
        }else if(type === 'content'){
            newItems = items.map(ele=>{
                if(ele.name === itemName){    // 将预定义项目添加到巡查点后id改变，但是content的itemId未改变，所以要通过name来筛选
                    let contents = ele.contents.map(_content=>{
                        if(_content.content === content){
                            _content = {
                                ..._content,
                                checked: !_content.checked
                            }
                        }
                        return _content;
                    })
                    ele = {
                        ...ele,
                        contents
                    }
                }

                // 需重新判断checkAll的状态
                ele={
                    ...ele,
                    checked: ele.checked || ele.contents.filter(content=>content.checked).length>0,
                    checkAll: ele.contents.filter(content=>content.checked).length === ele.contents.length
                }

                return ele;
            })            
        }

        dispatch(maintenanceItemsData(newItems))
    }
}

//获取当前所选building的巡查点
export function getInspectionsDataInSelectedBuildings(params) {
    // dispatch 用来通知数据修改
    return dispatch => {
        axios.get('/maintenance/inspection-points', {
                params
            })
            .then(res => {
                let data = generateFolderInfo(res);
                dispatch(inspectionsDataInSelectedBuilding(data));
            })
    }
}

export function maintenanceItemsData(data) {
    return {type: MAINTENCE_ITEM_DATA, payload: data}
}

export function maintenanceItemsOriginData(data){
    return {type: MAINTENCE_ITEM_ORIGIN_DATA, payload: data}
}

export function inspectionPointData(data) {
    return {type: INSPECTION_POINT_DATA, payload: data}
}

export function buildingItemsData(data) {
    return {type: BUILDING_ITEMS_DATA, payload: data}
}

export function inspectionsDataInSelectedBuilding(data){
    return {type: INSPECTION_POINT_DATA_IN_SELECTED_BUILDING, payload: {data}}
}

export function inspectionPointsOfPlan(data){
    return {type: INSPECTION_POINT_DATA_OF_PLAN, payload: data}
}

export function switchFolder(items, index) {

    return dispatch => {
        let newItems = items.map((ele,index2) => {
            if (index2 === index) {
                ele = {
                    ...ele,
                    foldered: !ele.foldered
                }
            }
            return ele;
        })
        dispatch(inspectionsDataInSelectedBuilding(newItems))
    }
}




// 非Redux管理的方法

//更新和创建巡查点
export function updateInspectionPointRequest(data, edit= false, id,){
    if(edit){
        return axios.put(`/maintenance/inspection-points/${id}`,data)
    }else{
        return axios.post(`/maintenance/inspection-points`,data)
    }
}

//获取当前巡查点
export function getCurrentInspectionPoint(id){
    return axios.get(`/maintenance/inspection-points/${id}`)
}

// 删除巡查点

export function deleteInspectionPoint(id){
    return axios.delete(`/maintenance/inspection-points/${id}`)
}

// 创建维保项目
export function updateMaintenanceItemRequest(data){
    return axios.post('/maintenance/items', data);
}

//创建维保内容
export function updateMaintenanceContentRequest(data){
    return axios.post('/maintenance/contents', data);
}

// 批量添加维保项目
export function batchAddMaintenanceItemRequest(data){ 
    return axios.post('/maintenance/items/batch',{
        paramStr: JSON.stringify(data)
    })
}

// 批量添加维保内容
export function batchAddMaintenanceContentRequest(data){ 
    return axios.post('/maintenance/contents/batch',{
        paramStr: JSON.stringify(data)
    })
}
// 批量删除维保项目
export function batchDeleteMaintenanceItemRequest(ids){ 
    return axios.delete('/maintenance/items',{
        params:{
            ids: JSON.stringify(ids)
        }
    })
}
// 批量删除维保内容
export function batchDeleteMaintenanceContentRequest(ids){ 
    return axios.delete('/maintenance/contents',{
        params:{
            ids: JSON.stringify(ids)
        }
    })
}


function generateItems(originData, predefineData){
    originData = originData.map(ele=>{
        let contents =  ele.contents.map(content=>({
            ...content,
            checked: true
        }));

        let checkedContent = ele.contents.map(ele=>ele.content);  //已选中的内容

        // 在当前已经选中的项目中，筛选predefindeData中也是属于当前项目的内容，
        //如果内容与已选中的内容相同，则忽略，
        //如果有新内容，则设checked为false
        let contentsInPredefineData = [];
        let contentsInPredefine = predefineData.find(predefineEle=>predefineEle.name ===ele.name);
        if(contentsInPredefine){
            contentsInPredefineData = contentsInPredefine.contents
                                            .filter(ele=>checkedContent.indexOf(ele.content) === -1)
                                            .map(content=>({
                                                ...content,
                                                checked: false
                                            }));
        }

        let newContents = contents.concat(contentsInPredefineData)
        let checkAll = newContents.filter(ele=>ele.checked === true).length === newContents.length;
        return{
            ...ele,
            contents: newContents,
            checked: true,
            checkAll
            
        }
    });

    let checkedItems = originData.map(ele=>ele.name);
    predefineData = predefineData.filter(ele=>checkedItems.indexOf(ele.name) === -1)
                                .map(ele=>{
                                    let contents =  ele.contents.map(content=>({
                                        ...content,
                                        checked: false
                                    }));
                                    return{
                                        ...ele,
                                        contents,
                                        checked: false,
                                        checkAll: false
                                    }
                                });

    const _predefineData = arrayUnique(predefineData, 'name');
    return originData.concat(_predefineData)
}

// js数组根据对象中的元素去重

function arrayUnique(arr1) {
    const res = new Map();
    return arr1.filter((a) => !res.has(a.name) && res.set(a.name, 1))
  }

function generateOriginItems(originData){
    return originData.map(ele=>{
        let contents =  ele.contents.map(content=>({
            ...content,
            checked: true
        }));
        return{
            ...ele,
            contents,
            checked: true
        }
    })
}

function generateFolderInfo(rawData){

    //原始数据为所选楼栋的所有巡查点，
    //将所有巡查点按照楼层分好
    //在每个楼层下面加foldered属性
    let floorIds = Array.from(new Set(rawData.data.map(ele=>ele.floorId)));

    return floorIds.map(id=>{
        let floorPlanStatus = ''
        let inspectionInFloor = rawData.data.filter(ele=>ele.floorId === id);
        return {
            floorPlanStatus,
            inspectionInFloor,
            foldered: true
        }
    })
}


/** 
 * 生成plan需要的content列表
*/

function generatePlanContentAll(data, type){
    type = Number(type);
    let inspectionPoints = data[0];
    let contents = [];
    data[1].data.forEach(ele=>contents=contents.concat(ele.contents));
    let planContentsIds = contents.map(ele=>ele.id);
    const buildingIds = Array.from(new Set(inspectionPoints.data.map(ele=>ele.buildingId)));
    return buildingIds.map((buildingId, index)=>{
        //获取当前building下的所有巡查点信息
        let inspectionsInCurrentBuilding = inspectionPoints.data.filter(ele=>ele.buildingId === buildingId);
        //获取building列表
        let building=inspectionsInCurrentBuilding[0].building;
        let buildingContents=[];
        inspectionsInCurrentBuilding.forEach(ele=>{
            ele.items.filter(item=>item.type === type).forEach(item=>{
                buildingContents  = buildingContents.concat(item.contents);
            })
        })
        let buildingContentsInPlan = buildingContents.filter(ele=>planContentsIds.indexOf(ele.id)>-1);
        let buildingPlanStatus=`${buildingContentsInPlan.length}/${buildingContents.length}`;

        //获取当前building下的floor列表
        let floorIds = Array.from(new Set(inspectionsInCurrentBuilding.map(ele=>ele.floorId)));

        let floors = floorIds.map((floorId,index)=>{
            //获取当前楼层下的所有巡查点信息
            let inspectionsInCurrentFloor = inspectionsInCurrentBuilding.filter(ele=>ele.floorId === floorId);
            let floor = inspectionsInCurrentFloor[0].floor;
            let floorContents = [];
            inspectionsInCurrentFloor.forEach(ele=>{
                ele.items.filter(item=>item.type === type).forEach(item=>{
                    floorContents = floorContents.concat(item.contents);
                })
            });
            let floorContentsInPlan = floorContents.filter(ele=> planContentsIds.indexOf(ele.id) > -1);
            let floorPlanStatus=`${floorContentsInPlan.length}/${floorContents.length}`;

            inspectionsInCurrentFloor=inspectionsInCurrentFloor.map(ele=>{
                let inspectionContents =[];
                ele.items.filter(item=>item.type === type).forEach(item=>{
                    inspectionContents = inspectionContents.concat(item.contents);
                })
                let inspectionContentsInPlan = inspectionContents.filter(ele=>planContentsIds.indexOf(ele.id)> -1);
                let inspectionPlanStatus=`${inspectionContentsInPlan.length}/${inspectionContents.length}`;
                return {
                    ...ele,
                    inspectionPlanStatus
                }
            })

            return {
                ...floor,
                floorPlanStatus,
                inspectionsInCurrentFloor,
                checked: index === 0
            }

        });

        building = {
            ...building,
            floors,
            buildingPlanStatus,
            checked: index === 0
        }

        return building
    })
}

