import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Popconfirm, Input, message, Spin, Icon,Form, Radio,Modal, Button} from 'antd';

import NextBar from '../../components/next-bar/next-bar'

import { getBuildingsData,getFloorsData,checkBuilding,updateFloor, 
        updateBuilding,switchBuildingEditStatus,switchFloorEditStatus,
        deleteBuilding,deleteFloor, buildingsData,floorsData,
        batchAddBuildingRequest, batchAddFloorRequest
    } from '../../redux/02_buildings.redux'
import {getCurrentProject, dispatchCurrentProject }  from '../../redux/01_project.redux'

import editImg from '../../assets/images/edit_white.svg';
import deleteImg from '../../assets/images/delete_red.svg'
import addImg from '../../assets/images/add.svg'
import {formItemLayout} from '../../components/layout/formlayout'
const Search = Input.Search;
@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.buildings,
            ...state.projects
        }
    },
    {getBuildingsData,getFloorsData, checkBuilding,switchBuildingEditStatus,
        switchFloorEditStatus,dispatchCurrentProject}
)
class BuildingsGuideList extends Component{
    constructor(props){
        super(props);
        this.state={
            editBuilding: false,
            editFloor:false,
            addType: 'single',
            visible: false
        }
    }
    componentDidMount(){

        // 重置buildings和floors为空
        let store = require('../../index').store;
        store.dispatch(buildingsData({data:[]}));
        store.dispatch(floorsData({data:[]}));
        
        let {organizationId} = this.props.match.params;
        getCurrentProject(organizationId).then(res=>{
            this.props.dispatchCurrentProject(res)
        })
        this.setState({
            organizationId
        },()=>this.getBuildings())
    }

    // 获取楼栋楼层信息
    getBuildings=(buildingId)=>{
        this.props.getBuildingsData({
            size: 2000,
            organizationId: this.state.organizationId
        }, buildingId);
    }

    //选择楼栋
    switchBuilding(buildingId){
        this.setState({
            buildingId
        },()=>{
            this.props.checkBuilding(this.props.buildings, buildingId)
        })
    }
    
    //编辑建筑物，并阻止事件冒泡
    editBuilding(e,buildingId){
        e.stopPropagation();
        this.props.switchBuildingEditStatus(this.props.buildings,buildingId)
    }

    // 改变楼层的编辑状态，并防止事件冒泡
    editFloor(e, floorId){
        e.stopPropagation();
        this.props.switchFloorEditStatus(this.props.floors,floorId)
    }

    // 删除建筑物，并阻止事件冒泡
    deleteBuilding(e, id){
        e.stopPropagation();
        deleteBuilding(id).then(()=>{
            this.getBuildings();
            message.success('删除成功');
        })
    }
    //删除楼层
    deleteFloor=(id)=>{
        deleteFloor(id).then(()=>{
            this.getBuildings(this.state.buildingId);
            message.success('删除成功');
        })
    }
    // // 显示添加建筑物的输入框
    // showAddNewBuilding=(status)=>{
    //     this.setState({
    //         showAddNewBuilding: status
    //     })
    // }

    // //显示添加楼层的输入框
    // showAddNewFloor=(status)=>{
    //     this.setState({
    //         showAddNewFloor: status
    //     })
    // }

    // 保存编辑后的建筑物
    saveEditedBuilding = (value, isEdit, buildingId)=>{
        let data = {
            organizationId: this.props.currentProject.id,
            name: value       
        }
        updateBuilding(data,isEdit, buildingId).then(()=>{
            this.getBuildings(buildingId);
            message.success('保存成功');
            !isEdit && this.showAddNewBuilding(false);
        })
    }
    // 保存新添加的楼层
    saveEditedFloor = (value, isEdit, floorId)=>{
        let data = {
            organizationId: this.props.currentProject.id,
            buildingId: this.props.currentBuildingId,
            name: value
        }
        updateFloor(data,isEdit, floorId).then(()=>{
            this.getBuildings(this.props.currentBuildingId);
            message.success('保存成功');
            !isEdit && this.showAddNewFloor(false);
        })
    }
    // 保存新增的建筑物
    saveNewBuilding = (data)=>{     
        updateBuilding(data,false).then(res=>{
            const buildingId = res.id;
            this.getBuildings(buildingId);
            this.handleCancel();
            message.success('创建楼栋成功');
        }).finally(()=>this.setState({iconLoading: false}));
    }
    // 保存新添加的楼层
    saveNewFloor = (data)=>{     
        updateFloor(data,false).then(res=>{
            this.getBuildings(this.props.currentBuildingId);
            this.handleCancel();
            message.success('创建楼层成功')
        }).finally(()=>this.setState({iconLoading: false}));
    }
    // 批量添加建筑物
    batchAddBuilding = (data)=>{     
        batchAddBuildingRequest(data).then(res=>{
            const buildingId = res[0].id;
            this.getBuildings(buildingId);
            this.handleCancel();
            message.success('批量创建楼栋成功');
        }).finally(()=>this.setState({iconLoading: false}));
    }
    // 批量添加楼层
    batchAddFloor = (data)=>{
        batchAddFloorRequest(data).then(res=>{
            this.getBuildings(this.props.currentBuildingId);
            this.handleCancel();
            message.success('批量创建楼层成功')
        }).finally(()=>this.setState({iconLoading: false}));
    }
    onAddTypeChange = e => {
        this.setState({
          addType: e.target.value,
        });
      };
    onBatchInputChange = inputLabel =>{
        const form = this.props.form;
        const _this = this;
        setTimeout(()=>{
            const value = form.getFieldValue(inputLabel);
            _this.setState({
                [inputLabel]: value
            })
        }, 0)
    }
    handleAdd = e =>{
        e.preventDefault();
        const organizationId = this.props.currentProject.id;
        if(!organizationId){
            message.warn('项目无效');
        } else {
            let body;
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    const {addName, addType} = this.state;
                    this.setState({iconLoading: true});
                    if (addType === 'single') {
                        if (addName === 'building') {
                            body= {
                                name: values.name,   
                                organizationId                 
                            };
                            this.saveNewBuilding(body);
                        } else {
                            const buildingId = this.props.buildings.find(ele=>ele.checked).id;
                            body= {
                                name: values.name,
                                buildingId, 
                                organizationId             
                            };
                            this.saveNewFloor(body);
                        }
                    } else {
                        // 批量添加
                        // 首先要判断结束数字必须大于起始数字
                        // 去除掉0
                        const { suffix, startNum, endNum} = values;
                        const prefix = values.prefix || '';
                        const isValidNum = endNum - startNum > 0
                        if(isValidNum){
                            let arr = [];
                            for (let i=Number(startNum); i<=Number(endNum); i++) {
                                i !== 0 && arr.push(i);
                            };
                            if (addName === 'building') {
                                body = arr.map(num=>`name=${prefix}${num}${suffix}&organizationId=${organizationId}`);
                                this.batchAddBuilding(body);
                            } else {
                                const buildingId = this.props.buildings.find(ele=>ele.checked).id;
                                body = arr.map(num=>`name=${prefix}${num}${suffix}&buildingId=${buildingId}&organizationId=${organizationId}`);
                                this.batchAddFloor(body);
                            }
                        } else {
                            message.warn('结束数字需大于起始数字！')
                        }
                    }
                }
            });
        }

    }
    showModal = (addName) => {
        const modalTitle = addName === 'building' ? '创建楼栋':'创建楼层';
        const modalName = addName === 'building' ? '楼栋名称':'楼层名称'
        this.setState({
          visible: true,
          modalTitle,
          modalName,
          addName
        });
      };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };
    onNextStep = ()=>{
        this.props.history.push(`/orgs-management/guide/inspection/${this.state.organizationId}`)
    }
    
    render(){
        const { getFieldDecorator } = this.props.form; 
        const noLabelFormItemLayout = {
            wrapperCol: {
              xs: { span: 24, offset: 24 },
              sm: { span: 16, offset: 8 },
            },
        }; 
        return (
            <div>
                <div className='buildings-floors'>
                    <div className = 'buildings'>
                        <div className='title'>楼栋</div>
                        {
                            this.props.buildingLoading?
                            <div className="spin-container-half">
                                    <Spin  size="large" />
                            </div>
                            :
                            <div className='building-scroll-view'>
                            {
                                this.props.buildings.map(building=>
                                    building.editing?
                                    <div className='input-confirm' key={building.id}>
                                        <Search
                                            enterButton={<Icon type='check'/>}
                                            size="large"
                                            defaultValue = {building.name}
                                            onSearch={(value)=>{this.saveEditedBuilding(value, true, building.id)}}
                                        />
                                    </div>
                                    :
                                    <div className={building.checked?'building-item item-active':'building-item'} 
                                            key={building.id}
                                            onClick={()=>this.switchBuilding(building.id)}>
                                        <div>{building.name}</div>
                                        <div>
                                            <img alt='edit' 
                                                className='edit-img' 
                                                src={editImg} 
                                                onClick={e=>{this.editBuilding(e, building.id)}}/>
                                            <Popconfirm
                                                title="确定要删除当前楼栋吗?"
                                                onCancel = {()=>console.log('cancel')}
                                                onConfirm={e=>{this.deleteBuilding(e,building.id)}}
                                                okText="是"
                                                cancelText="否">
                                                <img alt='delete' className='delete-img' src={deleteImg} />
                                            </Popconfirm>
                                        </div>
                                    </div>
                                    )
                            }
                            <img alt='add' 
                                className='add-building' 
                                src={addImg}
                                onClick={()=>this.showModal('building')}/> 
                        </div>

                        }
                    </div>
                    <div className = 'floors'>
                        <div className='title'>楼层</div>
                        {
                            this.props.loading?
                            <div className="spin-container-half">
                                    <Spin  size="large" />
                            </div>
                            :
                            <div className='building-scroll-view'>
                            {
                                this.props.floors.map(floor=>
                                    floor.editing?
                                    <div className='input-confirm' key={floor.id}>
                                        <Search
                                            enterButton={<Icon type='check'/>}
                                            size="large"
                                            defaultValue = {floor.name}
                                            onSearch={value=>{this.saveEditedFloor(value, true, floor.id)}}
                                        />
                                    </div>
                                    :
                                    <div 
                                        className={floor.checked? (this.state.edit?'floor-item item-active':'floor-item item-active'):(this.state.edit?'floor-item ':'floor-item')} 
                                        key={floor.id}>
                                        <div>{floor.name}</div>                                  
                                        <div>
                                            <img alt='edit' 
                                                className='edit-img' 
                                                src={editImg}  
                                                onClick={e=>{this.editFloor(e, floor.id)}}/>
                                            <Popconfirm
                                                title="确定要删除当前楼层吗?"
                                                onCancel = {()=>console.log('cancel')}
                                                onConfirm={()=>this.deleteFloor(floor.id)}
                                                okText="是"
                                                cancelText="否"
                                            >
                                                <img alt='delete' className='delete-img' src={deleteImg} />
                                            </Popconfirm>
                                        </div>
                                    </div>
                                )
                            }
                            <img alt='add' 
                                    className='add-building' 
                                    src={addImg}
                                    onClick={()=>this.showModal('floor')}/>
                        </div>
                        }
                    </div>
                </div>
                <Modal
                    title={this.state.modalTitle}
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    footer={null}
                    width={650}
                    className='add-modal'
                    >
                    <div >
                        <div className='edit-form-container-batch-add'>
                           <Form {...formItemLayout}  onSubmit={this.handleAdd}>
                                <Form.Item label='创建类型'>
                                    {getFieldDecorator('addType', {
                                        initialValue:this.state.addType
                                    })( <Radio.Group className='batch-type' onChange={this.onAddTypeChange}>
                                        <Radio value='single'>创建单个</Radio>
                                        <Radio value='batch'>批量创建</Radio>
                                      </Radio.Group>)}
                                </Form.Item>                     
                                {
                                    this.state.addType === 'single' ?
                                    <Form.Item label='名称'>
                                            {getFieldDecorator('name', {
                                                rules: [{ required: true, message: `请输入${this.state.modalName}!` }],
                                            })(<Input  placeholder={`请输入${this.state.modalName}`}/>)}
                                    </Form.Item>
                                    :
                                    <div>
                                        <Form.Item label='名称前缀'>
                                            {getFieldDecorator('prefix', {
                                                // rules: [{ required: true, message: '请输入前缀!' }],
                                            })(<Input  placeholder="请输入名称前缀" onChange={()=>this.onBatchInputChange('prefix')}/>)}
                                        </Form.Item>
                                        <Form.Item label='名称后缀'>
                                            {getFieldDecorator('suffix', {
                                                rules: [{ required: true, message: '请输入名称后缀!' }],
                                            })(<Input  placeholder="请输入名称后缀" onChange={()=>this.onBatchInputChange('suffix')}/>)}
                                        </Form.Item>
                                        <Form.Item label='开始数字'>
                                            {getFieldDecorator('startNum', {
                                                rules: [{ 
                                                    required: true,
                                                    pattern: new RegExp(/^[-]?[1-9]\d*$/, "g"),
                                                    message: '请输入开始数字!' }],
                                                getValueFromEvent: (event) => {
                                                    return event.target.value.replace(/!-\D/g,'')
                                                },
                                            })(<Input  placeholder="请输入开始数字" onChange={()=>this.onBatchInputChange('startNum')}/>)}
                                        </Form.Item>
                                        <Form.Item label='结束数字'>
                                            {getFieldDecorator('endNum', {
                                                rules: [{ 
                                                    required: true,
                                                    pattern: new RegExp(/^[-]?[1-9]\d*$/, "g"),
                                                    message: '请输入结束数字!' }],
                                                getValueFromEvent: (event) => {
                                                    return event.target.value.replace(/!-\D/g,'')
                                                },
                                            })(<Input  placeholder="请输入结束数字" onChange={()=>this.onBatchInputChange('endNum')}/>)}
                                        </Form.Item>
                                        <Form.Item label='示例'>
                                            {getFieldDecorator('example', {
                                                initialValue: `${this.state.prefix||''}${this.state.startNum||''}${this.state.suffix||''}`,
                                            })(<Input disabled placeholder=""/>)}
                                        </Form.Item>
                                        <Form.Item label='' {...noLabelFormItemLayout}>
                                            {getFieldDecorator('tip', {
                                            })(<div className='format-tip'>生成格式为：名称前缀+数字+后缀</div>)}
                                        </Form.Item>
                                    </div> 

                                }
                                <div className='btns-flex flex-end'>
                                    <Button onClick={this.handleCancel} className="modal-btn">
                                        取消
                                    </Button>
                                    <Button type="primary" htmlType="submit" className="modal-btn">
                                        确认
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Modal>
                <NextBar onNextStep={this.onNextStep}/>
            </div>
        )
    }
}

export default  Form.create()(BuildingsGuideList);
