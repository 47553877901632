import React, {Component}  from 'react';
import TopBarInside from './topbar-inside/topbar-inside';
import './layout.css';

class NoneSideBarLayout extends Component {

    render(){
        return (
            <div className=''>
                <TopBarInside 
                    hideProject={this.props.hideProject}
                    projectSelectHide={this.props.projectSelectHide}/>
                {this.props.children}
            </div>
        )
    }
}

export default NoneSideBarLayout