
import axios from '../axios_new.config'
import {HIDE_LOADING, SHOW_LOADING, hideLoading, showLoading, getRegionsByIDs, getOrganizationsByIDs} from './00_common.action'

const ACCEPTANCEPS_DATA = 'ACCEPTANCEPS_DATA';
const CURRENT_ACCEPTANCEP = 'CURRENT_ACCEPTANCEP'

//reducer
const initState = {
    acceptanceP : [],
    total:0,
    start:1,
    type: 1,
    searchType: 1,
    loading: true,
    currentAcceptancep:{}
}


export function acceptanceP(state = initState, action) { 
    switch(action.type){
        case ACCEPTANCEPS_DATA:
            return { ...state, 
                acceptanceP: action.payload.content,
                total: action.payload.totalElements,
                start: action.payload.start,
                type: action.payload.type,
                searchType: action.payload.searchType,
                // start: 1,
                errorMsg:null
             }
        case CURRENT_ACCEPTANCEP:
             return {
                 ...state,
                 currentAcceptancep: action.payload
             }
        case SHOW_LOADING:
             return {
                 ...state,
                 loading: true,
             }
        case HIDE_LOADING:
             return {
                 ...state,
                 loading: false
             }
        default:
            return state
    }
}

//action creator
export function getAcceptancePList(params, access_token=undefined, needDetail=true){

    // dispatch 用来通知数据修改
    return dispatch => {
        dispatch(showLoading())
        let promise;
        if(access_token){
            promise = axios.request({
                method:'get',
                url:'/api/maintenance/fireControls',
                params: params,
                headers:{
                  Authorization: `Bearer ${access_token}`,
                  "Content-Type": 'application/json',
                },
            })
        }else{
            promise = axios.get('/api/maintenance/fireControls',{params});
        }
        
        promise.then(res=>{
            dispatch(acceptancepData(res))
        }).finally(()=>{
            dispatch(hideLoading())
        })
    }
}

// export function updateProjectSuccess() { 
//     return dispatch=>dispatch(getDrawingList())
// }

export function dispatchCurrentAcceptanceP(data){
    return dispatch=>dispatch(currentAcceptancep(data))
}

function acceptancepData(data) {
    return {type: ACCEPTANCEPS_DATA, payload: data}
}
function currentAcceptancep(data){
    return {type: CURRENT_ACCEPTANCEP, payload: data}
}

//派单
export function dispatchWork(data, id){
    return axios.post(`/api/maintenance/fireControls/dispatchWork/${id}`,data)
}

//接单
export function receiveWork(id,userId){
    return axios.post(`/api/maintenance/fireControls/receiveWork/${id}/${userId}`)
}

//审核
export function fireControlsAudit(data){
    return axios.post(`/api/maintenance/fireControls/audit`,data)
}


// // 非redux管理的方法
// export function updateProjectRequest(data,edit=false, id){
//     if(edit){
//         return axios.put(`/account/network-users/${id}`,data)
//     }else{
//         return axios.post('/account/network-users',data)
//     }
// }

// /**
//  * 根据project ID 获取当前的project信息，主要用于填写topBarInside中的项目信息
//  * @param {*project id} id 
//  */
export function  getCurrentAcceptanceP(id){
    return axios.get(`/api/maintenance/fireControls/${id}`)
}

// // 删除项目
// export function deleteProject(id){
//     return axios.delete(`/account/network-users/${id}`)
// }