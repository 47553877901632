import React, {Component}  from 'react';
import { connect } from 'react-redux'
import moment from 'moment'
import { withRouter, Link} from 'react-router-dom'

import Header from '../../components/header';
import CustomPagination from '../../components/pagination/pagination'
import Table from '../../components/table'
import add from '../../assets/images/add.svg';
import editImg  from '../../assets/images/edit_white.svg'
import { getPlansData } from '../../redux/05_plan.redux'
import {TICKET_TYPES, PERIOD_TYPES} from '../../constants/constants'
import './styles.css';
import LineWrap from '../../components/LineWrap';

@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.plans
        }
    },
    {getPlansData}
)
class PlanList extends Component {

    componentDidMount(){
        this.props.getPlansData({ start: 0})
    }

    pageChangeHandler(page) {
        this.props.getPlansData({start: page-1})
    }

    onSearch = (params)=>{
        this.props.getPlansData(params);
    }


    addNewPlan = ()=>{
        this.props.history.push('/plans/add')
    }

    render(){
        // console.log(this.props)
        const filters = [{
            key:'nameLike',
            placeholder:'计划名称'
        }];

        const columns = [
            {
              title: '计划名称',
              dataIndex: 'name',
              key: 'name',
              width: "15%",
            },
            {
              title: '所属项目',
              dataIndex: 'organization.name',
              key: 'organization.name',
              width: "10%",
            },
            {
              title: '类型',
              key: 'type',
              width: 100, 
              render: (text,record)=>(
                <span>{TICKET_TYPES[record.type-1].label}</span>
              )
            },
            {
                title: '文字描述',
                width: "20%",
                key: 'description',
                render: (text, record)=>(
                     <LineWrap title={record.description} lineClampNum={2}/>
                )
            },
            {
            title: '开始时间',
            key: 'startTime',
            width: 200,
            render: (text, record)=>(
                <span>{moment(record.startTime).format('YYYY-MM-DD HH:mm')}</span>
            )
            },
            {
            title: '结束时间',
            key: 'endTime',
            width: 200, 
            render: (text, record)=>(
                    <span>{moment(record.endTime).format('YYYY-MM-DD HH:mm')}</span>
                )
            },
            {
                title: '重复周期',
                key: 'periodType',
                width: 150, 
                render: (text,record)=>(
                    <span>{record.periodInterval}{PERIOD_TYPES.find(ele=>ele.value === record.periodType)._label}</span>
                )
            },
            {
              title: '',
              key: 'action',
              width: 180,
              render: (text, record) => (
                <Link className='edit-action' to={`/plans/edit/${record.organizationId}/${record.id}`}>
                  <img src={editImg} alt='edit'></img>
                  查看修改
                </Link>
              ),
            },
          ];
          
        return (
            <div className = 'content'>
                <Header title = '维保计划列表' filters ={filters} remoteSelectFilters={['organizationId']} planTypeFilter={true} onSearch={this.onSearch}>
                    <div className='new_org new' onClick={this.addNewPlan} >
                        <img src={add} alt='add' className='add_img  new'></img>新建计划
                    </div>
                </Header>
                <div className='main-table-container'>
                    <Table  columns={columns} 
                            scroll={{ y: 640 }}
                            dataSource={this.props.plans&&this.props.plans.map((ele)=> {return {...ele, key:ele.id}})} 
                            loading={this.props.loading}/>
                    <CustomPagination
                        total={this.props.total}
                        onChange={(e)=>{this.pageChangeHandler(e)}}/>
                </div>
            </div>
        )
    }
}

export default PlanList