
import axios from '../axios.config0'
import { setStorage, removeStorage, getStorage} from '../utils/utils'
import {message} from 'antd';
import moment from 'moment';

const LOGIN  = 'LOGIN';
const LOGOUT = 'LOGOUT';
const USER_DATA = 'USER_DATA';
//reducer


const initState = {
    isAuth : false,
    access_token: null, 
    user: '管理员',
    roles: ['admin'],
    authUser:{
        rolesIds:[]
    }
    
}


export function auth(state = initState, action) { 
    switch(action.type){
        case LOGIN :
            return {...state, isAuth: true}
        case LOGOUT: 
            return {...state, isAuth: false}
        case USER_DATA:
            return { ...state, 
                        authUser:action.payload,
                        user: action.payload.name, 
                        roles: action.payload.roles.map(ele=>ele.name),
                     }
        default:
            return state
    }
}

//action creator
export function login({username, password}) { 
    let data = {
        username,
        password,
        grant_type: 'password',
        scope: 'ui',
        
    };
    let access_token = null;
    return dispatch=>{
        axios.request({
          method:'post',
          url:'/api/account/oauth/token',
          headers:{
            "Content-Type": 'application/x-www-form-urlencoded',
            Authorization: 'Basic YnJvd3Nlcjo='
          },
          data  
        }).then(res=>{
                setStorage('access_token_maintenance', res.access_token);
                setStorage('userId', res.user_id);
                global.access_token_maintenance = res.access_token
                let expireTime = moment(new Date()).add(res.expires_in,'seconds');
                setStorage('expireTime_maintenance', expireTime);
                setStorage('refresh_token_maintenance', res.refresh_token);
                access_token = res.access_token;
                return getUserData(res.access_token);
            }).then(res=>{
                setStorage('userRoid', res.rolesIds[0]);
                dispatch(userData({
                    ...res,
                    access_token,
                }));
                if(res.rolesIds[0] === 1 || res.rolesIds[0] === 3 || res.rolesIds[0] === 6 || res.rolesIds[0] === 7 || res.rolesIds[0] === 8 || res.rolesIds[0] === 9 || res.rolesIds[0] === 10 || res.rolesIds[0] === 2){
                // if(res.rolesIds[0] === 1 || res.rolesIds[0] === 3){
                    let interval = setInterval(()=>{
                       let token =  getStorage('access_token_maintenance');
                       if (token){
                            dispatch(loginSuccess());
                            clearInterval(interval);
                       }
                    },100)
                }else{
                    message.error('对不起，您没有权限访问该应用');
                    dispatch(logout())
                }
            })
    }
}
export function getUserData(access_token){
    // dispatch 用来通知数据修改
    return  axios.request({
        method:'get',
        url:'/account/users/current',
        headers:{
          Authorization: `Bearer ${access_token}`
        },
    })
}

function getHeaders(){
    let access_token = getStorage('access_token_maintenance')
    if(access_token){
        return {
            Authorization: `Bearer ${access_token}`
          }
    }else {
        return undefined
    }
}
export function getCurrentAuthUser(){
    return dispatch=>{
        axios.request({
            method:'get',
            url:'/account/users/current',
            headers:getHeaders(),
        }).then(res=>{
            dispatch(userData(res));
        })
    }
}

export function userData(data) {
    return {type: USER_DATA, payload: data}
}
export function loginSuccess() {
    return {type: LOGIN}
}
export function logout(){
    removeStorage('access_token_maintenance');
    global.access_token_maintenance = '';
    return {type: LOGOUT}
}

export function sendValidateCode(phone){
    return axios.request({
        method:'post',
        url:'/account/validate-codes',
        data: {phone}
    })
}

export function resetPassword(data){
    return axios.request({
        method:'post',
        url:'/account/accounts/reset-password',
        data
    })
}