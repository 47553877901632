//工单类型
export const TICKET_TYPES = [{
        key: 'TICKET_TYPE_INSPECTION',
        value: 1,
        label: '巡查'
    },
    {
        key: 'TICKET_TYPE_TEST',
        value: 2,
        label: '检测'
    },
    {
        key: 'TICKET_TYPE_MAINTENANCE',
        value: 3,
        label: '保养'
    },
    // {
    //     key: 'TICKET_TYPE_REPAIR',
    //     value: 4,
    //     label: '维修'
    // }
]

//周期类型
export const PERIOD_TYPES = [
//     {
//     key: 'PERIOD_TYPE_HOUR',
//     value: 1,
//     label: '小时',
//     _label: '小时'
// }, 
{
    key: 'PERIOD_TYPE_DAY',
    value: 2,
    label: '天',
    _label: '天'
}, {
    key: 'PERIOD_TYPE_WEEK',
    value: 3,
    label: '周',
    _label: '个周'
}, {
    key: 'PERIOD_TYPE_MONTH',
    value: 4,
    label: '月',
    _label: '个月'
}, {
    key: 'PERIOD_TYPE_QUARTER',
    value: 5,
    label: '季度',
    _label: '个季度'
}, {
    key: 'PERIOD_TYPE_YEAR',
    value: 6,
    label: '年',
    _label: '年'
}]

// 角色类型
export const ROLE_TYPES = [
    {
    key: 'ADMIN',
    value: 1,
    label: '超级管理员'
}, 
{
    key: 'MAINTAINERS',
    value: 2,
    label: '维保人员'
}, {
    key: 'MAINTAINERS_ADMIN',
    value: 3,
    label: '维保经理'
}, {
    key: 'OWNER',
    value: 4,
    label: '业主'
}, {
    key: 'HOUSE',
    value: 6,
    label: '住建项目管理'
}, {
    key: 'HOUSE_DREWING',
    value: 7,
    label: '住建审图部门'
}, {
    key: 'HOUSE_FIRE',
    value: 8,
    label: '住建消防验收评定部门'
}, {
    key: 'MAINTAINERS_DREWING',
    value: 9,
    label: '审图员'
}
// , {
//     key: 'MAINTAINERS_FIRE',
//     value: 10,
//     label: '消防验收评定员'
// }
]



//维保类型
export const MAINTAINCE_TYPES = [{
        key: 'MAINTAINCE_TYPE_INSPECTION',
        value: 1,
        label: '巡查内容',
        item_label: '巡查类别',
        item_label2:'巡查项目'
    },
    {
        key: 'MAINTAINCE_TYPE_TEST',
        value: 2,
        label: '检测内容',
        item_label: '检测类别',
        item_label2:'检测项目'
    },
    {
        key: 'MAINTAINCE_TYPE_MAINTENANCE',
        value: 3,
        label: '保养内容',
        item_label: '保养类别',
        item_label2:'保养项目'
    },
    // {
    //     key: 'MAINTAINCE_TYPE_FIX',
    //     value: 4,
    //     label: '维修内容',
    //     item_label: '维修类别',
    //     item_label2:'维修项目'
    // }
];

// 错误信息

export const ErrorMessages = {
    bad_credentials: {
        en: 'Bad credentials',
        zh: '用户名或密码错误'
    },
    account_locked:{
        en: 'User account is locked',
        zh: '账户已锁定，请15分钟后再试'
    }
}

// 注册申请

export const RegistryStatus = [
    {
        key: 'REGISTRY_REQUEST_STATUS_REQUESTING',
        value: 1,
        label: '申请中',
        status: 'processing'  // for Badge
    },
    {
        key: 'REGISTRY_REQUEST_STATUS_APPORVED',
        value: 2,
        label: '通过',
        status: 'success'
    },
    {
        key: 'REGISTRY_REQUEST_STATUS_DENIED',
        value: 3,
        label: '拒绝',
        status:'error'
    }
]