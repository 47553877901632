import React, { Component } from 'react';
import { Switch, Route} from 'react-router-dom';
import MaintainerList from './maintainer';
import MaintainerEdit from './maintainer.edit'
import HomeLayout from '../../components/layout/home.layout'
import NoneSideBarLayout from '../../components/layout/non-sidebar.layout'
class MaintainerRouter extends Component {


  render() {
    const MaintainerListView = <HomeLayout>
                                    <MaintainerList></MaintainerList>
                                </HomeLayout>

    const editView  = <NoneSideBarLayout hideProject={true}>
                        <Route  path='/maintainers/add' component={MaintainerEdit}/>
                        <Route  path='/maintainers/edit/:id' component={MaintainerEdit}/> 
                    </NoneSideBarLayout>

    return (
      <Switch>
        <Route exact path='/maintainers' render={()=>MaintainerListView}/>
        <Route path='/maintainers' render={()=>editView}/>
      </Switch>
    );
  }
}

export default MaintainerRouter;