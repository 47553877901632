import React, {Component} from 'react'
import {Pagination} from 'antd'
import './styles.css'

class CustomPagination extends Component{

    // itemRender = (current, type, originalElement)=> {
    //     const previous = '<上一页'
    //     if (type === 'prev') {
    //       return <a className='prev-next' >{previous}</a>;
    //     }
    //     if (type === 'next') {
    //       return <a className='prev-next'>下一页</a>;
    //     }
    //     return originalElement;
    //   }

    render(){
        return (
            <div>
                <Pagination className="ant-table-pagination" total={this.props.total} onChange={this.props.onChange} />
            </div>
            
        )
    }
}

export default CustomPagination