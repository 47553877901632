import React, {Component}  from 'react';
import{ fmtTimes } from '../../utils/utils'
import jian from '../../assets/images/jian.png'
import './index.css';

class Progress extends Component{
    constructor(props){
        super(props)

        this.state = {
            
        }
    }

    //计算时间差值
    setDiff(start,end){
        const {startDate,endDate} = this.props;
        let start_c = new Date(start);
        let end_c = new Date(end); 
        let alldiff = new Date(endDate) - new Date(startDate);
        if(end_c>start_c){
            let diff = end_c - start_c;
            let per = ((diff/alldiff)*100); 
            return per;
        }else{
            return 0;
        }
    }
    
      
    render(){
        const {startDate,endDate,startWarn,endWarn} = this.props;
        var date = new Date();
        let now = fmtTimes(date);

        return (
            <div className='progress'>
                <div className="prog">
                    <div className="mprog prog1" style={{"width": this.setDiff(startDate,startWarn)+'%'}}><span>常态</span></div>
                    <div className="mprog prog2" style={{"width": this.setDiff(startWarn,endWarn)+'%'}}><span>预警</span></div>
                    <div className="mprog prog3" style={{"width": this.setDiff(endWarn,endDate)+'%'}}><span>紧急</span></div>
                </div>
                <div className="node node1"><div className="txt">{startDate}</div></div>
                <div className="node node2"><div className="txt">{endDate}</div></div>
                <div className="node node3" style={{"left": this.setDiff(startDate,startWarn)+'%'}}>
                    <div className="img">
                        <img src={jian} alt="" />
                    </div>
                    <div className="txt">{startWarn}</div>
                </div>
                <div className="node node4" style={{"right": this.setDiff(endWarn,endDate)+'%'}}>
                    <div className="img">
                        <img src={jian} alt="" />
                    </div>
                    <div className="txt">{endWarn}</div>
                </div>

                <div className="nowprog" style={{"width": this.setDiff(now,endDate)+'%'}}>
                    <div className="node node5">
                        <div className="txt">今天：{now}</div>
                        <div className="img">
                            <img src={jian} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Progress