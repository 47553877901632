import React, {Component}  from 'react';
import { connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import {Form,Input,Select, message, Icon, Modal, DatePicker} from 'antd';
import moment from 'moment';
import Table from '../../components/table'

import { getCollectionList, addReturnNode, addReturnCollect } from '../../redux/18_collection.redux'
import CustomPagination from '../../components/pagination/pagination'

import {formItemLayout} from '../../components/layout/formlayout'
import{ getHeaders, _baseUrl} from '../../axios.config'
import{ timeFormatSeconds, fmtTimes} from '../../utils/utils'
import mapJson from '../../assets/json/custom_map_config.json'
import './styles.css';

const BMap = window.BMap;
const Search = Input.Search;
let map;




const Option = Select.Option;
@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.collection
        }
    },
    {getCollectionList}
)
class Collection extends Component {


    constructor(props){
        super(props)

        this.state = {
            tab: 0,
            loading: false,
            headers:{},
            iconLoading: false,
            nowproject: [], //当前展示的信息

            previewVisible: false,
            previewVisible2: false,
            paynum: 0,
            payNode: [], //收款节点
            now_id: '',
            now_id2: ''
        }
    } 

    componentWillMount(){
        this.updateHeaders();
    }

    updateHeaders = async()=>{
        let headers = await getHeaders(true);
        this.setState({
            headers
        })
    }

    componentDidMount(){
        if(this.props.isAuth){
            this.props.getCollectionList({start:1, status: 1}, this.props.authUser.access_token)
        }
    }

    pageChangeHandler(page) {
        let st = parseInt(this.state.tab) + 1;
        this.props.getCollectionList({start:page,status: st})
    }

    handleSubmit = e => {
        this.props.form.validateFields((err, values) => {
            //if (!err) {
                let paynum = this.state.paynum,
                    paymentReturns = [];
                for(let i = 0;i<=paynum;i++){
                    let item = new Object();
                        item = {
                            dateTime: moment(values[`dateTime${i}`]).format('YYYY-MM-DD'),
                            price: parseFloat(values[`price${i}`]),
                            contacts: values[`contacts${i}`],
                            contactPhone: values[`contactPhone${i}`]
                        };
                    paymentReturns.push(item);
                }
                
                let data = {
                    id: this.state.now_id,
                    paymentReturns: paymentReturns
                }

                for(let i = 0;i<=paynum;i++){
                    let item = paymentReturns[i];
                    if(!item['dateTime'] || !item['price'] || !item['contacts'] || !item['contactPhone']){
                        return false;
                    }
                }
                
                //console.log(123,paynum,paymentReturns);return;
              
                let _this = this;
                Modal.confirm({
                    title: '提示',
                    content: '确定添加收款节点？',
                    okText: '确认',
                    cancelText: '取消',
                    onOk(){
                        _this.setState({iconLoading: true});
                        addReturnNode(data, _this.state.now_id).then(res=>{
                            message.success("添加收款节点成功");
                            _this.setState({
                                previewVisible: false
                            })
                            _this.props.getCollectionList({start:1, status: 1}, _this.props.authUser.access_token)
                        }).finally(()=>_this.setState({iconLoading: false}));
                    }
                });
            //}
        });
    };

    handleSubmit2 = e => { 
        this.props.form.validateFields((err, values) => { 
            //if (!err) {
                let data = {
                    id: this.state.now_id2,
                    actualDate: moment(values['actualDate']).format('YYYY-MM-DD'),
                    actualPrice: values['actualPrice']
                }
                
                if(!values['actualDate'] || !values['actualPrice']){
                    return false;
                }
                    

                //console.log(123,data);return;
              
                let _this = this;
                Modal.confirm({
                    title: '提示',
                    content: '确定添加回款？',
                    okText: '确认',
                    cancelText: '取消',
                    onOk(){
                        _this.setState({iconLoading: true});
                        addReturnCollect(data, _this.state.now_id2).then(res=>{
                            message.success("添加回款成功");
                            _this.setState({
                                previewVisible2: false
                            })
                            _this.props.getCollectionList({start:1, status: 1}, _this.props.authUser.access_token)
                        }).finally(()=>_this.setState({iconLoading: false}));
                    }
                });
            //}
        });
    };
    
    render(){   
        const { getFieldDecorator } = this.props.form;
        const config = {
            rules: [{ type: 'object', required: true, message: '请选择日期' }],
        };

        let { previewVisible, previewVisible2 } = this.state;
        const list = this.props.collection;
          
        //定义获取求和方法
        const getSummaries = (columns, data) => {
            const sums = [];
            let sumsInfo = {};
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    sumsInfo = { total: '合计' }
                    return;
                }
                const values = data.map(item => item[column.dataIndex]);
                if (!values.every(value => isNaN(value)) && (index==4 || index==6)) {
                    let sum = values.reduce((prev, curr) => { 
                        if(curr==""){
                            curr = 0;
                        }
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        } else {
                            return prev;
                        }
                    }, 0);
                    sums[index] = sum
                    sumsInfo = { ...sumsInfo, [column.dataIndex]: sum }
                    sums[index] += ' ';
                } else {
                    sums[index] = 'N/A';
                }
            });
            return { sumsInfo, sums };
        }

        const getTotal = (columns, currentData) => {
            const sums = {
                index: '',
                name: '合计',
            }
            let sumObj = getSummaries(columns, currentData).sumsInfo
            return { ...sums, ...sumObj }
        }

        const columns = [
            {
                title: '项目名称',
                dataIndex: 'name',
                key: 'name',
                width: "15%",
            },
            {
                title: '联系人',
                dataIndex: 'contacts',
                key: 'contacts',
                width: "8%",
            },
            {
                title: '联系电话',
                dataIndex: 'contactPhone',
                key: 'contactPhone',
                width: "8%",
            },
            {
                title: '付款单位',
                dataIndex: 'payer',
                key: 'payer',
                width: "15%",
            },
            {
                title: '应收金额(元)',
                dataIndex: 'price',
                key: 'price',
                width: "8%",
            },
            {
                title: '应收时间',
                dataIndex: 'dateTime',
                key: 'dateTime',
                width: "8%",
                render: (text, record) => {
                    return timeFormatSeconds(text);
                }
            },
            {
                title: '实收金额(元)',
                dataIndex: 'actualPrice',
                key: 'actualPrice',
                width: "8%",
                render: (text, record) => {
                    if(text==""){
                        return "-"
                    }else{
                        return text;
                    }
                }
            },
            {
                title: '实收时间',
                dataIndex: 'actualDate',
                key: 'actualDate',
                width: "8%",
                render: (text, record) => {
                    if(text==""){
                        return "-"
                    }else{
                        return timeFormatSeconds(text);;
                    }
                }
            },{
                title: '添加收款/回款',
                key: 'action',
                width: '8%',
                align: 'center',
                dataIndex: "action",
                render: (text, record) => {
                    return (
                        <div>
                            <Icon type='plus-circle' theme="filled" title="添加收款" style={{'fontSize': '26px', "color": '#65C0F4', "cursor": "pointer"}} onClick={e => this.doAddCollect(record.fireControlId)} />
                            <Icon type='pay-circle' theme="filled" title="添加回款" style={{'fontSize': '26px', "color": '#65C0F4', "cursor": "pointer","marginLeft": "10px"}} onClick={e => this.doAddCollect2(record.id)} />
                        </div>
                        );
                },
            }
        ];
        let sums = getTotal(columns, list)
        const dataSource = list && list.length > 0 ? [...list, sums] : [];

        //当某状态时隐藏状态栏
        let last_columns;
        if(this.state.tab==2){
            last_columns = columns.filter(item => {
                if (item.dataIndex=='action'){
                  return false
                }
                return true
              })
        }else{
            last_columns = columns;
        }

        const app = (
            <div className = 'content content_abs'>
                <div className='itabs itabs3'>
                    <span className={["tab_span ",this.state.tab==0 ? 'on' :'' ].join('')} onClick={e => this.setTabs(0)}>正常</span>
                    <span className={["tab_span ",this.state.tab==1 ? 'on' :'' ].join('')} onClick={e => this.setTabs(1)}>逾期</span>
                    <span className={["tab_span ",this.state.tab==2 ? 'on' :'' ].join('')} onClick={e => this.setTabs(2)}>完成</span>
                </div>
                
                <div className='main-table-container ts_table la_ts_table col_table'>
                    <Table dataSource={dataSource} columns={last_columns} rowKey={record => record.id} bordered={true} />
                    {/* <Table  scroll={{ y: 640 }}
                            columns={columns} 
                            dataSource={list.map(item=>{ return {...item, key:item.id}})} 
                            loading={this.props.loading}/> */}
                    <CustomPagination
                        total={this.props.total}
                        onChange={(e)=>{this.pageChangeHandler(e)}}/>
                </div>

                <Modal title="新增回款" visible={previewVisible2} onCancel={this.handleCancel2} onOk={this.handleOk2} width={500} wrapClassName="ts_modal" bodyStyle={{"maxHeight": "500px","overflowY":"auto"}}>
                    <div className="edit-form-container modal_form2">
                        <Form  onSubmit={this.handleSubmit2}>
                            <Form.Item label="回款时间">
                                {getFieldDecorator('actualDate', config)(<DatePicker />)}
                            </Form.Item>
                            <Form.Item label='回款金额'>
                                {getFieldDecorator('actualPrice', {
                                    rules: [
                                    {
                                        required: true,
                                        message: '回款金额金额不能为空',
                                    },
                                    ],
                                })(<Input placeholder="请输入回款金额"/>)}
                            </Form.Item> 

                        </Form>
                    </div>
                </Modal>

                <Modal title="新增收款节点" visible={previewVisible} onCancel={this.handleCancel} onOk={this.handleOk} width={880} wrapClassName="ts_modal" bodyStyle={{"maxHeight": "500px","overflowY":"auto"}}>
                    <div className="edit-form-container modal_form">
                        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                            <div className='flex-between flex-wrap'>
                                <Form.Item label="">
                                    {getFieldDecorator('dateTime0', config)(<DatePicker />)}
                                </Form.Item>
                                <Form.Item label=''>
                                    {getFieldDecorator('price0', {
                                        rules: [
                                        {
                                            required: true,
                                            message: '金额不能为空',
                                        },
                                        ],
                                    })(<Input placeholder="请输入金额"/>)}
                                </Form.Item> 
                                <Form.Item label=''>
                                    {getFieldDecorator('contacts0', {
                                        rules: [
                                        {
                                            required: true,
                                            message: '联系人不能为空',
                                        },
                                        ],
                                    })(<Input placeholder="联系人"/>)}
                                </Form.Item> 
                                <Form.Item label=''>
                                    {getFieldDecorator('contactPhone0', {
                                        rules: [
                                        {
                                            required: true,
                                            message: '联系方式不能为空',
                                        }, 
                                        // {
                                        //     required: false,
                                        //     validator: passwordValidator
                                        // }
                                        ],
                                    })(<Input placeholder="联系方式"/>)}
                                </Form.Item> 
                                <div className="tsexam_add" onClick={e => this.addPayNode()}>
                                    <Icon type='plus-circle' theme="filled" style={{'fontSize': '26px', "color": '#65C0F4'}} />
                                </div>
                            </div>
                            { this.state.payNode.map((item, i) => {
                                return <div className='add_form_item flex-between' key={i}>
                                            <Form.Item label="">
                                                {getFieldDecorator(`dateTime${item}`, config)(<DatePicker />)}
                                            </Form.Item>
                                            <Form.Item label=''>
                                                {getFieldDecorator(`price${item}`, {
                                                    rules: [
                                                    {
                                                        required: true,
                                                        message: '金额不能为空',
                                                    },
                                                    ],
                                                })(<Input placeholder="请输入金额"/>)}
                                            </Form.Item> 
                                            <Form.Item label=''>
                                                {getFieldDecorator(`contacts${item}`, {
                                                    rules: [
                                                    {
                                                        required: true,
                                                        message: '联系人不能为空',
                                                    },
                                                    ],
                                                })(<Input placeholder="联系人"/>)}
                                            </Form.Item> 
                                            <Form.Item label=''>
                                                {getFieldDecorator(`contactPhone${item}`, {
                                                    rules: [
                                                    {
                                                        required: true,
                                                        message: '联系方式不能为空',
                                                    }, 
                                                    // {
                                                    //     required: false,
                                                    //     validator: passwordValidator
                                                    // }
                                                    ],
                                                })(<Input placeholder="联系方式"/>)}
                                            </Form.Item> 
                                            <div className="form_close"  onClick={e => this.removePayNode(i)}>
                                                <Icon type='close' style={{'fontSize': '26px', "color": '#65C0F4'}} />
                                            </div>
                                        </div>
                            }) }
                        </Form>
                    </div>
                    
                </Modal>
            </div>
        );
        return app
    }

    setTabs(i){
        this.setState({
            tab : i
        })
        if(this.props.isAuth){
            let st = parseInt(i) + 1;
            this.props.getCollectionList({start:1,status: st})
        }
    }

    //弹出新增
    doAddCollect(e){
        this.setState({
            now_id: e,
            previewVisible: true
        })
    }
    doAddCollect2(e){
        this.setState({
            now_id2: e,
            previewVisible2: true
        })
    }

    //关闭弹窗
    handleCancel = () => this.setState({ previewVisible: false });
    handleCancel2 = () => this.setState({ previewVisible2: false });

    handleOk = () => { this.handleSubmit(); }
    handleOk2 = () => { this.handleSubmit2(); }

    //新增节点
    addPayNode(){
        let paynum = this.state.paynum;
        let payNode = this.state.payNode;
        let newpaynum = paynum + 1;
        let end = payNode.length>0 ? payNode[payNode.length-1] : 0; 
    	payNode.push(end+1);
        this.setState({
            paynum: newpaynum,
            payNode: payNode
        })
    }

    //删除节点
    removePayNode(i){ 
        let paynum = this.state.paynum;
        let payNode = this.state.payNode;
        let newpaynum = paynum - 1;
        payNode.splice(i, 1);
        console.log(newpaynum,payNode);
        this.setState({
            paynum: newpaynum,
            payNode: payNode
        })
    }

}



export default Form.create()(Collection)

