import React, { Component } from 'react';
import { Switch, Route} from 'react-router-dom';
import RegistryList from './registry';
import RegistryEdit from './registry.edit'
import HomeLayout from '../../components/layout/home.layout'
import NoneSideBarLayout from '../../components/layout/non-sidebar.layout'
class RegistryRouter extends Component {


  render() {
    const RegistryListView = <HomeLayout>
                                    <RegistryList/>
                                </HomeLayout>

    const editView  = <NoneSideBarLayout hideProject={true}>
                        <Route  path='/registries/edit/:id' component={RegistryEdit}/> 
                    </NoneSideBarLayout>

    return (
      <Switch>
        <Route exact path='/registries' render={()=>RegistryListView}/>
        <Route path='/registries' render={()=>editView}/>
      </Switch>
    );
  }
}

export default RegistryRouter;