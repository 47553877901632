import React, { Component } from 'react';
import { Switch, Route} from 'react-router-dom';
import ProjectList from './project';
import ProjectEdit from './project.edit'
import ProjectInfo from './project.info'
import HomeLayout from '../../components/layout/home.layout'

import ArrowLayout from '../../components/layout/arrow.layout'
import ProjectGuide from './project.guide'

import OwnerList from '../09_owner/owner_list'
import OwnerBind from '../09_owner/owner_bind'
import OwnerRegister from '../09_owner/owner_register'

import BuildingsGuideList from '../02_building/buildings.guide.list'
import BuildingEdit from '../02_building/buildings.edit'

import InspectionGuideList from '../03_inspection/inspections.guide.list'
import InspectionPointEdit from '../03_inspection/inspection_point.edit'
import InspectionContent from '../03_inspection/inspection_content'

import ContractGuideList from '../04_contract/contract.guide.list'
import ContractEdit from '../04_contract/contract.edit'

import PlanGuideList from '../05_plan/plan.guide.list'
import PlanEdit from '../05_plan/plan.edit'
import PlanMaintenanceStatus from '../05_plan/plan.maintenance.status'
import PlanInspectionContent from '../05_plan/inspection_content_plan'

import NoneSideBarLayout from '../../components/layout/non-sidebar.layout'
class ProjectRouter extends Component {


  render() {
    const ProjectListView = <HomeLayout>
                                <ProjectList></ProjectList>
                            </HomeLayout>

    const ProjectItemsRouter = (e)=>{
        const editView  = <NoneSideBarLayout projectSelectHide={true}>
                                <Route exact path='/orgs-management/add' component={ProjectEdit}/>
                                <Route path='/orgs-management/edit/:id' component={ProjectEdit}/>
                                <Route path= '/orgs-management/owners/bind/:organizationId' component = {OwnerBind}/>
                                <Route path='/orgs-management/owners/register/:organizationId' component = {OwnerRegister}/>
                                <Route exact path='/orgs-management/building/edit' component={BuildingEdit}/>
                                  
                                <Route exact path='/orgs-management/inspections/add/:organizationId' component={InspectionPointEdit}/>
                                <Route exact path='/orgs-management/inspections/edit/:organizationId/:inspectionPointId' component={InspectionPointEdit}/>
                                <Route exact path='/orgs-management/inspections/edit/:organizationId/:inspectionPointId/:type' component={InspectionContent}/>

                                <Route exact path='/orgs-management/contracts/add/:organizationId' component={ContractEdit}/>
                                <Route path='/orgs-management/contracts/edit/:organizationId/:id' component={ContractEdit}/>

                                <Route exact path='/orgs-management/plans/add' component={PlanEdit}/>
                                <Route path='/orgs-management/plans/edit/:organizationId/:id' component={PlanEdit}/>
                                <Route exact path='/orgs-management/plans/contents/:organizationId/:planId/:type' component={PlanMaintenanceStatus}/>
                                <Route exact path='/orgs-management/plans/inspection-point/:inspectionPointId/contents/:type/:organizationId/:planId' 
                                        component={PlanInspectionContent}/>  
                                
                        </NoneSideBarLayout>
        const arrowView = <ArrowLayout path={e.location.pathname} projectSelectHide={true}>
                                <Switch>
                                    <Route exact path='/orgs-management/guide/new' component={ProjectGuide}/>
                                    <Route  path='/orgs-management/guide/info/:organizationId' component={ProjectInfo}/>
                                    <Route exact path='/orgs-management/guide/owners/:organizationId' component={OwnerList}/>
                                    <Route exact path='/orgs-management/guide/buildings/:organizationId' component={BuildingsGuideList}/>
                                    <Route exact path='/orgs-management/guide/inspection/:organizationId' component={InspectionGuideList}/>
                                    <Route exact path='/orgs-management/guide/contract/:organizationId' component={ContractGuideList}/>
                                    <Route exact path='/orgs-management/guide/plan/:organizationId' component={PlanGuideList}/>
                                </Switch>
                        </ArrowLayout>
        const guide = e.location.pathname.indexOf('guide');
        return guide > -1 ?arrowView: editView;
    }

    return (
      <Switch>
        <Route exact path='/orgs-management' render={()=>ProjectListView}/>
        <Route path='/orgs-management' render={(e)=>ProjectItemsRouter(e)}/>
      </Switch>
    );
  }
}

export default ProjectRouter;