import React, {Component}  from 'react';
import { Redirect }  from 'react-router-dom'
import moment from 'moment'
import { getStorage} from '../../../utils/utils'
import './styles.css';
import { connect }  from 'react-redux'
import { logout, loginSuccess } from '../../../redux/auth.redux'
import logo from '../../../assets/images/logo.jpg';
import logoutImg from '../../../assets/images/logout.svg';
import avatar from '../../../assets/images/avatar.jpg';  // TODO 从接口中获取
import {dispatchCurrentProject} from '../../../redux/01_project.redux'

@connect(
    state=>{
        return {
            ...state.auth
        }
    },
    {logout, loginSuccess, dispatchCurrentProject}
)
class TopBar extends Component {
    componentDidMount(){
        let access_token = getStorage('access_token_maintenance');
        let expireTime = getStorage('expireTime_maintenance');
        let isValidToken = access_token && moment(new Date()).isBefore(expireTime);
        if(isValidToken) this.props.loginSuccess()
        this.props.dispatchCurrentProject({});
    }
    onLogout = ()=>{
        this.props.logout()
    }
    render(){
        const _avatar = (this.props.authUser && this.props.authUser.userOrgMap && this.props.authUser.userOrgMap.length && this.props.authUser.userOrgMap[0].avatar) || this.props.authUser.avatar;
        const _user =  (this.props.authUser && this.props.authUser.userOrgMap && this.props.authUser.userOrgMap.length && this.props.authUser.userOrgMap[0].name) || this.props.authUser.name;
        return (
            <div className='header-container'>
            {this.props.isAuth?  null: <Redirect to = '/login'></Redirect>}
                <div className='header-left'>
                    <img className='logo' src={logo} alt='logo'/>
                    <div className='title'>消防数字远程监控系统</div>
                </div>
                <div className='header-right'>
                    <img className='avatar' src={_avatar || avatar} alt='avatar'/>
                    <div className='user'>{_user || '未知用户'}</div>
                    <img src={logoutImg} className='logout' alt='logout' onClick={this.onLogout}></img>
                </div>
            </div>
        )
    }
}

export default TopBar