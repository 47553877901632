import axios from '../axios.config'

export const HIDE_LOADING = 'HIDE_LOADING'
export const SHOW_LOADING = 'SHOW_LOADING'
export const ERROR_MSG = 'ERROR_MSG'

export function showLoading(){
    return{ type: SHOW_LOADING}
}

export  function hideLoading(){
    return{ type: HIDE_LOADING}
}

export function errorMsg(msg){
    return { msg, type: ERROR_MSG}
}

/** 
 * 获取region详细信息
*/
export function getRegionsByIDs(ids, access_token){
    if(access_token){
        return axios.request({
            method:'get',
            url:'/region/regions',
            headers:{
              Authorization: `Bearer ${access_token}`
            },
            params:{
                idIn:JSON.stringify(ids),
                size: 2000
            }
        })
    }
    return axios.get(`/region/regions`,{
        params:{
            idIn:JSON.stringify(ids),
            size: 2000
        }
    });
}
/** 
 * 获取region详细信息
*/
export function getRegionById(id){
    return axios.get(`/region/regions/${id}`);
}
/** 
 * 获取角色列表
*/
export function getRoles(){

    return axios.get(`/account/roles`);
}

/** 
 * 根据ids获取单位列表
*/

export function getOrganizationsByIDs(ids, access_token){
    if(access_token){
        return axios.request({
            method:'get',
            url:'/account/organizations',
            headers:{
              Authorization: `Bearer ${access_token}`
            },
            params:{
                idIn:JSON.stringify(ids),
                size: 2000
            }
        })
    }
    return axios.get(`/account/organizations`,{
        params:{
            idIn:JSON.stringify(ids),
            size: 2000
        }
    });
}

export function getCurrentProject(id) { 
    return axios.get(`/account/organizations/${id}`);  
 }
/*** 
 * 根据ids获取楼栋信息
*/
export function getBuildingsByIDs(ids){
    return axios.get(`/building/buildings`,{
        params:{
            idIn:JSON.stringify(ids),
            size: 2000
        }
    });
}

/** 
 * 根据id获取用户信息
*/

export function getUsersByIDS(ids){
    return axios.get(`/account/users`,{
        params:{
            idIn:JSON.stringify(ids),
            size: 2000
        }
    });
}

/** 
 * 根据ids获取维保项目信息
*/

export function getMaintenanceItemsByIDs(ids){
    return axios.get(`/maintenance/items`,{
        params:{
            idIn:JSON.stringify(ids),
            size: 2000
        }
    });
}

/** 
 * 根据巡查点ids获取巡查点信息
*/


export function addMarker(mp,lng,lat,text){
    const BMap = window.BMap;
    function ComplexCustomOverlay(point, text, mouseoverText){
        this._point = point;
        this._text = text;
        this._overText = mouseoverText;
      }
      ComplexCustomOverlay.prototype = new BMap.Overlay();
      ComplexCustomOverlay.prototype.initialize = function(map){
        this._map = map;
        var div = this._div = document.createElement("div");
        div.style.position = "absolute";
        div.style.zIndex = BMap.Overlay.getZIndex(this._point.lat);
        div.style.backgroundColor = "#EE5D5B";
        div.style.border = "1px solid #BC3B3A";
        div.style.color = "white";
        div.style.height = "28px";
        div.style.padding = "5px";
        div.style.lineHeight = "18px";
        div.style.whiteSpace = "nowrap";
        div.style.MozUserSelect = "none";
        div.style.fontSize = "18px"
        var span = this._span = document.createElement("span");
        div.appendChild(span);
        span.appendChild(document.createTextNode(this._text));      

  
        var arrow = this._arrow = document.createElement("div");
        arrow.style.background = "url(http://map.baidu.com/fwmap/upload/r/map/fwmap/static/house/images/label.png) no-repeat";
        arrow.style.position = "absolute";
        arrow.style.width = "11px";
        arrow.style.height = "10px";
        arrow.style.top = "27px";
        arrow.style.left = "10px";
        arrow.style.overflow = "hidden";
        div.appendChild(arrow);
  
        mp.getPanes().labelPane.appendChild(div);
        
        return div;
      }
      ComplexCustomOverlay.prototype.draw = function(){
        var map = this._map;
        var pixel = map.pointToOverlayPixel(this._point);
        this._div.style.left = pixel.x - parseInt(this._arrow.style.left) + "px";
        this._div.style.top  = pixel.y - 30 + "px";
      }

          
      var myCompOverlay = new ComplexCustomOverlay(new BMap.Point(lng,lat), text);
  
      mp.addOverlay(myCompOverlay);
}
