import React, {Component}  from 'react';
import TopBar from './topbar/topbar';
import SideBar from './sidebar-item/sidebar-item';
import './layout.css';

class HomeLayout extends Component {
    // constructor(props){
    //     super(props)
    // }

    render(){
        return (
            <div className=''>
                <TopBar/>
                <SideBar className='sidebar'/>
                {this.props.children}
            </div>
        )
    }
}

export default HomeLayout