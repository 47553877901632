import React, {Component}  from 'react';
import { Icon } from 'antd';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getCurrentAuthUser } from '../../../redux/auth.redux'

import './styles.css';
import { withRouter } from 'react-router-dom'

@withRouter
@connect(
    state=>({
        ...state.auth,
    }),{getCurrentAuthUser}
)

class SideBarItem extends Component {
    constructor(props){
        super(props)
        this.state = {
            pathname: '/orgs-management'
        }
    }

    componentDidMount(){
        this.setState({
            pathname: this.props.location.pathname
        });
        if(this.props.isAuth&& !this.props.authUser.rolesIds.length){
            this.props.getCurrentAuthUser()
        }
    }
    onNavChange = (path)=>{
        this.props.history.push(path);
        this.setState({
            pathname: path
        })
    }

    render(){
        // console.log(this.props)
        let allSideBar = [{
                icon: 'snippets',
                nav: '项目管理',
                path: '/orgs-management'
            },
            {
                icon: 'bank',
                nav: '楼栋/楼层',
                path: '/buildings'
            },
            {
                icon: 'environment',
                nav: '巡查点',
                path: '/inspections'
            },
            {
                icon: 'audit',
                nav: '维保合同',
                path:'/contracts'
            },            
            {
                icon: 'file-protect',
                nav: '维保计划',
                path: '/plans'
            },            
            {
                icon: 'usergroup-add',
                nav: '人员管理',
                path:'/users'
            },  
            {
                icon: 'global',
                nav: '区域',
                path: '/regions'
            }, 
            {
                icon: 'insurance',
                nav: '维保单位',
                path: '/maintainers'
            },
            {
                icon: 'solution',
                nav: '注册申请',
                path: '/registries'
            },
            {
                icon: 'file-text',
                nav: '许可管理',
                path: '/licenses'
            },  
            {
                icon: 'plus-circle',
                nav: '新建项目',
                path: '/new_project'
            },
            {
                icon: 'profile',
                nav: '消防审图',
                path: '/drawing_review'
            },
            {
                icon: 'fire',
                nav: '消防验收评定',
                path: '/acceptance'
            },  

            {
                icon: 'profile',
                nav: '消防审图',
                path: '/drawing_review_p'
            },
            {
                icon: 'fire',
                nav: '消防验收评定',
                path: '/acceptance_p'
            }, 
            {
                icon: 'heat-map',
                nav: '项目地图',
                path: '/project_map'
            },
            {
                icon: 'money-collect',
                nav: '项目回款',
                path: '/collection'
            },
            {
                icon: 'message',
                nav: '消息中心',
                path: '/message'
        }];

        var items = []
        if(this.props.authUser.rolesIds.indexOf(1) > -1){
            //超管
            items = allSideBar;
        }else if(this.props.authUser.rolesIds.indexOf(3) > -1 || this.props.authUser.rolesIds.indexOf(4) > -1){
            //原维保经理、业主
            items = [
                allSideBar[0],
                allSideBar[5],
                // allSideBar[13],
                // allSideBar[14]
            ];
        }else if(this.props.authUser.rolesIds.indexOf(6) > -1){
            //住建项目管理
            items = [
                allSideBar[10],
                allSideBar[11],
                allSideBar[12]
            ];
        }else if(this.props.authUser.rolesIds.indexOf(7) > -1){
            //住建审图部
            items = [
                allSideBar[10],
                allSideBar[11]
            ];
        }else if(this.props.authUser.rolesIds.indexOf(8) > -1){
            //住建消防验收部
            items = [
                allSideBar[10],
                allSideBar[12]
            ];
        }else if(this.props.authUser.rolesIds.indexOf(9) > -1){
            //正平审图员
            items = [
                allSideBar[13]
            ];
        }else if(this.props.authUser.rolesIds.indexOf(10) > -1 || this.props.authUser.rolesIds.indexOf(2) > -1){
            //正平消防验收员
            items = [
                allSideBar[14]
            ];
        }

        // let items = [
        //     {
        //         icon: 'snippets',
        //         nav: '项目管理',
        //         path: '/orgs-management'
        //     },          
        //     {
        //         icon: 'usergroup-add',
        //         nav: '人员管理',
        //         path:'/users'
        //     }        
        // ]

        

        // // 当为超级管理员登录时
        // if(this.props.authUser.rolesIds.indexOf(1) > -1){
        //     let adminItems =[{
        //             icon: 'snippets',
        //             nav: '项目管理',
        //             path: '/orgs-management'
        //         },
        //         {
        //             icon: 'bank',
        //             nav: '楼栋/楼层',
        //             path: '/buildings'
        //         },
        //         {
        //             icon: 'environment',
        //             nav: '巡查点',
        //             path: '/inspections'
        //         },
        //         {
        //             icon: 'audit',
        //             nav: '维保合同',
        //             path:'/contracts'
        //         },            
        //         {
        //             icon: 'file-protect',
        //             nav: '维保计划',
        //             path: '/plans'
        //         },            
        //         {
        //             icon: 'usergroup-add',
        //             nav: '人员管理',
        //             path:'/users'
        //         },  
        //         {
        //             icon: 'global',
        //             nav: '区域',
        //             path: '/regions'
        //         }, 
        //         {
        //             icon: 'insurance',
        //             nav: '维保单位',
        //             path: '/maintainers'
        //         },
        //         {
        //             icon: 'solution',
        //             nav: '注册申请',
        //             path: '/registries'
        //         },
        //         {
        //             icon: 'file-text',
        //             nav: '许可管理',
        //             path: '/licenses'
        //         },  
        //         {
        //             icon: 'plus-circle',
        //             nav: '新建项目',
        //             path: '/new_project'
        //         },
        //         {
        //             icon: 'profile',
        //             nav: '消防审图',
        //             path: '/drawing_review'
        //         },
        //         {
        //             icon: 'fire',
        //             nav: '消防验收评定',
        //             path: '/acceptance'
        //         },  
    
        //         {
        //             icon: 'profile',
        //             nav: '消防审图',
        //             path: '/drawing_review_p'
        //         },
        //         {
        //             icon: 'fire',
        //             nav: '消防验收评定',
        //             path: '/acceptance_p'
        //         }, 
        //         {
        //             icon: 'heat-map',
        //             nav: '项目地图',
        //             path: '/project_map'
        //         },
        //         {
        //             icon: 'money-collect',
        //             nav: '项目回款',
        //             path: '/collection'
        //         },
        //         {
        //             icon: 'message',
        //             nav: '消息中心',
        //             path: '/message'
        //         }   
        //     ]

        //     items = adminItems;
        // }
        return (
            <div className='sidebar'>
                <div className="msidebar">
                <div className="mnsidebar">
              {
                  items.map(item=>{
                      return <div className={this.state.pathname === item.path ? 'item-active' :'item' } key={item.path} onClick={()=>this.onNavChange(item.path)}>
                          <Icon className='icon' style={{ fontSize: '24px' }}  type={item.icon}></Icon>
                          <Link className='link' style={{color: '#A1BDCE'}} to={item.path}>{item.nav}</Link>
                      </div>
                  })
              }
              </div>
              </div>
            </div>
        )
    }
}

export default SideBarItem