import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {message, Spin} from 'antd'


import EditBar from '../../components/edit-bar/edit-bar';
import NextBar from '../../components/next-bar/next-bar'

import { getRegionData } from '../../redux/07_region.redux'
import { getMaintainerData } from '../../redux/08_maintainer.redux'
import {getCurrentProject, dispatchCurrentProject, deleteProject }  from '../../redux/01_project.redux'
import {getRegionById} from '../../redux/00_common.action'
import avatarImg from '../../assets/images/logo.jpg'


@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.region,
            ...state.maintainers
        }
    },
    {getRegionData, getMaintainerData, dispatchCurrentProject}
)
class ProjectInfo extends Component{
    constructor(props){
        super(props);
        this.state={
            loading: true
        }
    }

    componentDidMount(){
        this.props.getRegionData({size: 5000});
        let {organizationId} = this.props.match.params;
        getCurrentProject(organizationId).then(res=>{
            this.setState(res);
            this.setState({id: organizationId, edit: true});
            this.props.dispatchCurrentProject(res);
            // 获取三级区域信息，用户所属区域显示
            getRegionById(res.regionId).then(resp=>{
                this.setState({
                    region: resp.fullName,
                    loading: false
                })
            })
        })
    }
    onEdit = ()=>{
        this.props.history.push(`/orgs-management/edit/${this.state.id}`)
    }

    onNextStep = ()=>{
        let {organizationId} = this.props.match.params;
        this.props.history.push(`/orgs-management/guide/owners/${organizationId}`)
    }

        //删除项目
    deleteProject=()=>{
        deleteProject(this.state.id).then(()=>{
            message.success('删除成功');
            this.props.history.goBack();
        })
    }
 
    render(){ 
        const {name,detailAddr, region, phone, maintainer} = this.state;
        return (
            <div className='edit-content-project'>
            <EditBar edit={true} onEdit = {this.onEdit} delete={true} onDelete={this.deleteProject}/>
            <div className='edit-form-container'>
                {
                this.state.loading?
                    <div className="spin-container-half">
                        <Spin  size="large" tip="正在加载..."/>
                    </div>
                    :
                    <div>
                        <img src={this.state.picture || avatarImg} alt='logo' className='project-logo'/>
                        <div className='project-info-item'>
                            <div className='label'>项目名称 : </div>
                            <div className='label'>{name}</div>
                        </div>
                        <div className='project-info-item'>
                            <div className='label'>所属区域 : </div>
                            <div className='label'>四川省/成都市/金牛区</div>
                        </div>
                        <div className='project-info-item'>
                            <div className='label'>详细地址 : </div>
                            <div className='label'>成都市白马寺观景台</div>
                        </div>
                        <div className='project-info-item'>
                            <div className='label'>维保单位 : </div>
                            <div className='label'>{maintainer?maintainer.name:null}</div>
                        </div>
                        <div className='project-info-item'>
                            <div className='label'>联系方式 : </div>
                            <div className='label'>{phone}</div>
                        </div>
                    </div>
                }
            </div>
            <NextBar onNextStep={this.onNextStep}/>
        </div>
        )
    }
}

export default  ProjectInfo