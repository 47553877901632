import { createStore, applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk'
import reducer from './reducers';//引入deducer文件
import {persistStore, persistReducer} from 'redux-persist';
//  存储机制，可换成其他机制，当前使用sessionStorage机制
import storageSession from 'redux-persist/lib/storage/session'

// 数据对象
const storageConfig = {
    key: 'root', // 必须有的
    storage:storageSession, // 缓存机制
    blacklist: ['name','age'] // reducer 里不持久化的数据,除此外均为持久化数据
}
const myPersistReducer = persistReducer(storageConfig, reducer)
const store = createStore(myPersistReducer, compose( 
    applyMiddleware(thunk),
    // window.devToolsExtension? window.devToolsExtension():f=>f
))
export const persistor = persistStore(store)
export default store
