import React, {Component}  from 'react';

import { connect} from 'react-redux'
import { Input } from 'antd';
import { withRouter, Link } from 'react-router-dom'
import { seTimeArea,setStatusTxt, getStorage} from '../../utils/utils'
import CustomPagination from '../../components/pagination/pagination'
import Table from '../../components/table'
import { getDrawingPList} from '../../redux/15_drawing_review_p.redux'

import './styles.css';

const Search = Input.Search;



@withRouter
@connect(
    state=>{
        return {
            ...state.auth,
            ...state.drawingReviewP
        }
    },
    {getDrawingPList}
)
class DrawingReviewP extends Component {

    constructor(props){
        super(props)

        this.state = {
            tab : 0,
            edit: false,
            picture:null,
            roleId: 0,
            avatar: null,
            loading: false,
            headers:{},
            iconLoading: false,

            userRoid: '',
            userId: ''
        }
    } 

    componentDidMount(){ 
        if(this.props.isAuth){
            if(this.state.tab==5){
                var para = {start:1,type:1,searchType:4, userId: this.state.userId};
            }else{
                var para = {start:1,type:1,searchType:1};
            }
            this.getDatas(para);
        }
    }

    componentWillMount(){
        let authUserRole = this.props.authUser.rolesIds;
        const isrole = authUserRole.indexOf(10) > -1; 
        let userRoid = authUserRole[0] ? authUserRole[0] : '';
        let userId = getStorage('userId');
        this.setState({
            userRoid: userRoid,
            userId: userId,
            tab: userRoid==9 ? 5 : 0
        })
    }

    pageChangeHandler(page) {
        // let st = parseInt(this.state.tab) + 1;
        // this.getDatas({start:page,type:1,searchType: st});
        let i = this.state.tab;
        let st = parseInt(i) + 1;
        if(i<3){
            this.getDatas({start:page,type:1,searchType: st});
        }else if(i>=3 && i<5){
            this.getDatas({start:page,type:1,searchType: 1,auditStatus: i});
        }else{
            //待接单列表：type=?,auditStatus=1,userId=?
            // 待审核列表：type=?,auditStatus=2,auditorId=?
            // 已完成列表：type=?,searchType=1,auditStatus=3
            //审图员、消防验收员列表：type=?,searchType=4,userId=? 
            if(i==5){
                this.getDatas({start:page,type:1,searchType:4, userId: this.state.userId});
            }else if(i==6){ 
                this.getDatas({start:page,type:1,auditStatus:1, userId: this.state.userId});
            }else if(i==7){
                this.getDatas({start:page,type:1,auditStatus:2, auditorId: this.state.userId});
            }else if(i==8){
                this.getDatas({start:page,type:1,searchType:4,auditStatus:3, userId: this.state.userId});
            }
        }
    }
    onSearch = (params)=>{
        this.getDatas(params);
    }

    getDatas = (params) =>{
        // let authUserRole = this.props.authUser.rolesIds;
        // const isrole = authUserRole.indexOf(9) > -1; 
        // let userRoid = authUserRole[0] ? authUserRole[0] : '';
        // let userId = getStorage('userId');
        // params = {
        //     ...params,
        //     // auditStatus: 1, 
        //     // userId: userId
        // }
        // if(!isrole){ 
        //     delete params.userId
        // }
        // if(userRoid==9){
        //     params = {
        //         ...params,
        //         auditorId: userId
        //     }
        // }
        this.props.getDrawingPList(params);
    }


    render(){
        const filters = [{
            key:'nameLike',
            placeholder:'项目名称'
        }];

        let userRoid = this.state.userRoid;

        // "status": 1,   //审核状态 0：待派单 1：待接收 2：待审核 3：通过 4：未通过
        // "auditNum": 1,   //审核次数，1表示初审，>1表示复审；结合审核状态使用
        const now_columns = [
            {
                title: '项目名称',
                dataIndex: 'name',
                key: 'name',
                width: "15%",
                render: (text, record) => {
                    let dat = setStatusTxt(record.status,record.auditNum);
                    var dom = <span className={dat.name1}>{dat.txt1}</span>;
                    var dom2 = <em className={dat.name2}>{dat.txt2}</em> ;
                    return <div>
                       {text}
                       <div className='spadiv'>
                            {dom}{dom2}
                       </div>
                    </div>
                },
            },{
                title: '详细地址',
                dataIndex: 'address',
                key: 'address',
                width: "15%",
            },{
                title: '层数',
                dataIndex: 'floorNum',
                key: 'floorNum',
                width: "8%",
            },{
                title: '面积',
                dataIndex: 'areaMeasure',
                key: 'areaMeasure',
                width: "8%",
            },{
                title: '监理单位信息',
                dataIndex: 'supervisorCompany',
                key: 'supervisorCompany',
                width: "15%",
            },{
                title: '审图单位信息',
                dataIndex: 'drawingReviewCompany',
                key: 'drawingReviewCompany',
                width: "15%",
            },{
                title: '施工单位信息',
                dataIndex: 'constructionCompany',
                key: 'constructionCompany',
                width: "15%",
            },{
                title: '设计单位信息',
                dataIndex: 'designCompany',
                key: 'designCompany',
                width: "15%",
            },{
                title: '使用性质',
                dataIndex: 'useNature',
                key: 'useNature',
                width: "15%",
            },{
                title: '负责人',
                dataIndex: 'leader',
                key: 'leader',
                width: "10%",
                render: (text, record) => {
                    if(text){
                        return text
                    }else{
                        return "-"
                    }
                }
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'day',
                width: "10%",
                align: 'center',
                className: 'colnone',
                render: (text, record) => {
                    if(this.state.tab==1 || this.state.tab==2 || this.state.tab==4){
                        //这里计算当前的状态
                        if(record.startDate && record.endDate && record.normalDate && record.warningDate){
                            let dat = seTimeArea(record.startDate,record.endDate,record.normalDate,record.warningDate);
                            let dom = <span className={`iquan iquan${dat.now_status}`}></span>
                            return <div>{dom} <p className='sap'>{dat.use_day}/{dat.all_day}</p></div>
                        }else{
                            return "-";
                        }
                    }else{
                        return "-";
                    }
                }
            },
            {
                title: '操作',
                key: 'action',
                align: 'center',
                width: 180,
                render: (text, record) => {
                        //派单
                        // "status": 1,   //审核状态 0：待派单 1：待接收 2：待审核 3：通过 4：未通过
                        // "auditNum": 1,   //审核次数，1表示初审，>1表示复审；结合审核状态使用
                        if(record.status==0){
                            return <Link className='edit_action' to={`/drawing_review_p/drawing_detail_p/${record.id}`}>
                                派单
                            </Link>
                        }else if(record.status==1 && this.state.userRoid==9){
                            //接收
                            return <Link className='edit_action' to={`/drawing_review_p/drawing_examine_p/${record.id}`}>
                                接收
                            </Link>
                        }else if(record.status==2 && this.state.userRoid==9){
                            //审核
                            return <Link className='edit_action' to={`/drawing_review_p/drawing_examine_p/${record.id}`}>
                                审核
                            </Link>
                        }else{
                            //审核
                            return <Link className='edit_action' to={`/drawing_review_p/drawing_examine_p/${record.id}`}>
                                查看
                            </Link>
                        }
                },
            },
        ];

        //当某状态时隐藏状态栏
        let columns;
        if(this.state.tab==0 || this.state.tab==3){
            columns = now_columns.filter(item => {
                if (item.dataIndex=='status'){
                  return false
                }
                return true
              })
        }else{
            columns = now_columns;
        }
        
        const app = (
            <div className = 'content content_abs'>
                {userRoid==9
                ?
                <div className="itabs itabs4">
                    <span className={["tab_span ",this.state.tab==5 ? 'on' :'' ].join('')} onClick={e => this.setTabs(5)}>全部</span>
                    <span className={["tab_span ",this.state.tab==6 ? 'on' :'' ].join('')} onClick={e => this.setTabs(6)}>待接单</span>
                    <span className={["tab_span ",this.state.tab==7 ? 'on' :'' ].join('')} onClick={e => this.setTabs(7)}>待审核</span>
                    <span className={["tab_span ",this.state.tab==8 ? 'on' :'' ].join('')} onClick={e => this.setTabs(8)}>已完成</span>
                </div>
                :
                <div className="itabs itabs2">
                    <span className={["tab_span ",this.state.tab==0 ? 'on' :'' ].join('')} onClick={e => this.setTabs(0)}>全部</span>
                    <span className={["tab_span ",this.state.tab==1 ? 'on' :'' ].join('')} onClick={e => this.setTabs(1)}>初审</span>
                    <span className={["tab_span ",this.state.tab==2 ? 'on' :'' ].join('')} onClick={e => this.setTabs(2)}>复审</span>
                    <span className={["tab_span ",this.state.tab==3 ? 'on' :'' ].join('')} onClick={e => this.setTabs(3)}>已完成</span>
                    <span className={["tab_span ",this.state.tab==4 ? 'on' :'' ].join('')} onClick={e => this.setTabs(4)}>未通过</span>
                </div>
                }
                <div className="isea">
                    <div className="misea">
                        <Search
                            placeholder="请输入关键字搜索"
                            enterButton="搜索"
                            size="large"
                            onSearch={value => this.onSearch({"searchKey": value})}
                        />
                    </div>
                </div>
                <div className='main-table-container ts_table'>
                    <Table  scroll={{ y: 640 }}
                            columns={columns} 
                            dataSource={this.props.drawingReviewP && this.props.drawingReviewP.map(item=>{ return {...item, key:item.id}})} 
                            loading={this.props.loading}/>
                    <CustomPagination
                        total={this.props.total}
                        onChange={(e)=>{this.pageChangeHandler(e)}}/>
                </div>
            </div>
        );
        return app
    }

    setTabs(i){
        this.setState({
            tab : i
        })
        // 验收评定列表(全部)：type=2,searchType=1  0
        // 验收评定列表(初审)：type=2,searchType=2  1
        // 验收评定列表(复审)：type=2,searchType=3  2
        // 验收评定列表(已完成)：type=2,searchType=3,auditStatus=3  3
        // 验收评定列表(未通过)：type=2,searchType=3,auditStatus=4  4
        if(this.props.isAuth){
            let st = parseInt(i) + 1;
            if(i<3){
                this.getDatas({start:1,type:1,searchType: st});
            }else if(i>=3 && i<5){
                this.getDatas({start:1,type:1,searchType: 1,auditStatus: i});
            }else{
                //待接单列表：type=?,auditStatus=1,userId=?
                // 待审核列表：type=?,auditStatus=2,auditorId=?
                // 已完成列表：type=?,searchType=1,auditStatus=3
                //审图员、消防验收员列表：type=?,searchType=4,userId=? 
                if(i==5){
                    this.getDatas({start:1,type:1,searchType:4, userId: this.state.userId});
                }else if(i==6){ 
                    this.getDatas({start:1,type:1,auditStatus:1, userId: this.state.userId});
                }else if(i==7){
                    this.getDatas({start:1,type:1,auditStatus:2, auditorId: this.state.userId});
                }else if(i==8){
                    this.getDatas({start:1,type:1,searchType:4,auditStatus:3, userId: this.state.userId});
                }
            }
        }
    }
}

export default DrawingReviewP